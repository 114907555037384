import React, { FC, useEffect, useState } from "react"
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap"
import LinearGraph from "~/components/Common/Graphs/LinearGraph"
import AgentFilter from "~/components/Common/Modals/Filter/AgentFilter"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import "./summary.scss"
import { setPreloader } from "~/store/reducers/loader"
import { aggregateDashboard, countDashboard } from "~/store/reducers/dashboard/thunk"
import Async from "~/utils/Async"
import moment from "moment"
import { Series } from "~/models/Org.model"
import GraphFilter from "~/components/Common/Modals/Filter/GraphFilter"
type OptionType = {
  value: string | number
  label: string
  set?: boolean
}
const Summary: FC = () => {
  const dispatch = useAppDispatch()
  const { collapsed } = useAppSelector(state => state.sideBarReducer)

  const [access, setAccess] = useState<boolean>(true);

  const [filterModel, setFilterModel] = useState<boolean>(false)
  const [bS, setbs] = useState<number>(0)
  const [subTxtBs, setSubtxtBs] = useState<number>(0)

  const [patientGraph, setPatientGraph] = useState<Series[]>([])
  const [bsGraph, setBsGraph] = useState<Series[]>([])


  const [patientKpi, setPatientKpi] = useState<number>(0)

  const [leftPatient, setLeftPatient] = useState<number>(0)
  const [subTxtPatient, setSubtxtPatient] = useState<number>(0)

  const [dropValue, setDropValue] = useState<number>(0)
  const [dropValueBs, setDropValueBs] = useState<number>(0)
  const [sugarKpi, setSugarKpi] = useState<number>(0)

  //bp
  const [dropValueBp, setDropValueBp] = useState<number>(0)
  const [bpKpi, setBpKpi] = useState<number>(0)

  const [bpGraph, setBpGraph] = useState<Series[]>([])
  const [bp, setBp] = useState<number>(0)
  const [subBp, setSubBp] = useState<number>(0)

  //Patient active

  const [dropValuePa, setDropValuePa] = useState<number>(0)
  const [subPa, setSubPa] = useState<number>(0)
  const [activePatient, setActivePatient] = useState<number>(0)
  const [activePatientsGraph, setActivePatientsGraph] = useState<Series[]>([])
  const [paKpi, setPaKpi] = useState<number>(0)

  //loading
  const [patientLoading, setPatientLoading] = useState<boolean>(false)
  const [paLoading, setPaLoading] = useState<boolean>(false)
  const [sugarLoading, setSugarLoading] = useState<boolean>(false)
  const [bpLoading, setBpLoading] = useState<boolean>(false)
  const [filterList, setFilterList] = useState<any>()
  const [tempFilterList, setTempFilterList] = useState<any>([])
  const [patientTotal, setPatientTotal] = useState<number>(0)
  const [bpTotal, setBpTotal] = useState<number>(0)
  const [bsTotal, setBsTotal] = useState<number>(0)
  const [paTotal, setPaTotal] = useState<number>(0)

  const toggleFilterModal = () => setFilterModel(!filterModel)

  useEffect(() => {
    const superUser = localStorage.getItem("@super")

    if (superUser === "true") {
      var body: HTMLElement = document.body
      dispatch(setSecondaryBarTrue())
      if (!collapsed) {

        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      const data = Async.getItem("@auth")
      setAccess(data?.role?.permissions["dashboard-overview-view"])
      dispatch(setSecondaryBarTrue())

      setTimeout(() => {
        dispatch(setPreloader(false))
      }, 1000)
    }
  }, [])

  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    const strt = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )

    dispatch(aggregateDashboard({
      select: {
        patient: {
          filter: {
            organizationId: organizationLocalData.id,
            createdBetween: {
              start: dateStart ? new Date(dateStart + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date(strt).toISOString(),
              end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date().toISOString()
            },
            gender: gender ? gender : undefined,
            age: {
              start: ageFrom ? ageFrom : undefined,
              end: ageTo ? ageTo : undefined,
            },
            conditions: conditionArr,
          },
        },
      },
      aggregate:
        dropValue === 0 ?
          [
            {
              on: "patient",
              type: "count",
              path: "",
              groupBy: "user.profile.createdAt",
              groupByTransform: [
                {
                  "function": "week",
                  "args": []
                },
              ]
            },
            {
              on: "patient",
              type: "count",
              path: "",
            },
          ]
          :
          [
            {
              on: "patient",
              type: "count",
              path: "",
              groupBy: "user.profile.createdAt",
              groupByTransform: [
                {
                  "function": "dateTrunc",
                  "args": ["month"]
                },
              ]
            },
            {
              on: "patient",
              type: "count",
              path: "",
            },
          ]

    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          setPatientTotal(response.payload.aggregationResults[1])
          let dateList = [];
          let data: number[] = [];
          dateList = Object.keys(response.payload.aggregationResults[0]).sort().reverse();

          Object.keys(response.payload.aggregationResults[0]).sort().reverse().forEach(val => {
            data.push(response.payload.aggregationResults[0][`${val}`])
          });

          let list: Series[] = [];
          const body: Series =
          {
            name: "Patients",
            data: data,
            dates: dateList,
            complete: [],
            color: '#2A45CD',
            marker: {
              fillColor: '#2A45CD',
            }
          }

          list.push(body)
          setPatientGraph(list)
        }
      }
    })
  }, [filterList, dropValue])

  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    const strt = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )

    dispatch(aggregateDashboard({
      select: {
        vitalsMeasurements: {
          filter: {
            organizationId: organizationLocalData.id,
            type: "BLOOD_GLUCOSE",
            recordedBetween: {
              start: dateStart ? new Date(dateStart + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date(strt).toISOString(),
              end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date().toISOString()
            },
            patient: {
              gender: gender ? gender : undefined,
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            }
          },
          include: {
            patient: true
          },
        }
      },
      aggregate:
        dropValueBs === 0 ?
          [
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
              groupBy: "recordedAt",
              groupByTransform: [
                {
                  "function": "week",
                  "args": []
                },
              ]
            },
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
            },
          ]
          :
          [
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
              groupBy: "recordedAt",
              groupByTransform: [
                {
                  "function": "dateTrunc",
                  "args": ["month"]
                },
              ]
            },
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
            },
          ]

    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          setBsTotal(response.payload.aggregationResults[1])
          //Blood sugar 
          let dateList1 = [];
          let data1: number[] = [];
          dateList1 = Object.keys(response.payload.aggregationResults[0]).sort().reverse();

          Object.keys(response.payload.aggregationResults[0]).sort().reverse().forEach(val => {
            data1.push(response.payload.aggregationResults[0][`${val}`])
          });

          let list1: Series[] = [];
          const body1: Series =
          {
            name: "Patients",
            data: data1,
            dates: dateList1,
            complete: [],
            color: '#2A45CD',
            marker: {
              fillColor: '#2A45CD',
            }
          }

          list1.push(body1)
          setBsGraph(list1)
        }
      }
    })

  }, [filterList, dropValueBs])

  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    const strt = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )

    ///
    dispatch(aggregateDashboard({
      select: {
        vitalsMeasurements: {
          filter: {
            organizationId: organizationLocalData.id,
            type: "BLOOD_PRESSURE_S_D",
            recordedBetween: {
              start: dateStart ? new Date(dateStart + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date(strt).toISOString(),
              end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date().toISOString()
            },
            patient: {
              gender: gender ? gender : undefined,
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            }
          },
          include: {
            patient: true
          }
        }
      },
      aggregate:
        dropValueBp === 0 ?
          [
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
              groupBy: "recordedAt",
              groupByTransform: [
                {
                  "function": "week",
                  "args": []
                },
              ]
            },
            {
              on: "vitalsMeasurements",
              type: "count",
              path: ""
            }
          ]
          :
          [
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
              groupBy: "recordedAt",
              groupByTransform: [
                {
                  "function": "dateTrunc",
                  "args": ["month"]
                },
              ]
            },
            {
              on: "vitalsMeasurements",
              type: "count",
              path: ""
            }
          ]
    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          setBpTotal(response.payload.aggregationResults[1])
          let dateList2 = [];
          let data2: number[] = [];

          dateList2 = Object.keys(response.payload.aggregationResults[0]).sort().reverse();

          Object.keys(response.payload.aggregationResults[0]).sort().reverse().forEach(val => {
            data2.push(response.payload.aggregationResults[0][`${val}`])
          });

          let list2: Series[] = [];

          const body2: Series =
          {
            name: "Patients",
            data: data2,
            dates: dateList2,
            complete: [],
            color: '#2A45CD',
            marker: {
              fillColor: '#2A45CD',
            }
          }

          list2.push(body2)
          setBpGraph(list2)
        }
      }
    })
  }, [filterList, dropValueBp])

  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    const strt = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )

    ///
    dispatch(aggregateDashboard({
      select: {
        vitalsMeasurements: {
          filter: {
            organizationId: organizationLocalData.id,
            recordedBetween: {
              start: dateStart ? new Date(dateStart + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date(strt).toISOString(),
              end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date().toISOString()
            },
            patient: {
              gender: gender ? gender : undefined,
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            }
          },
        }
      },
      aggregate:
        dropValuePa === 0 ?
          [
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
              groupBy: "recordedAt",
              groupByTransform: [
                {
                  "function": "week",
                  "args": []
                },
              ],
              groupByMappers: [
                {
                  function: "dedupe",
                  args: [
                    "patientId"
                  ]
                }
              ]
            }
          ]
          :
          [
            {
              on: "vitalsMeasurements",
              type: "count",
              path: "",
              groupBy: "recordedAt",
              groupByTransform: [
                {
                  "function": "dateTrunc",
                  "args": ["month"]
                },
              ],
              groupByMappers: [
                {
                  function: "dedupe",
                  args: [
                    "patientId"
                  ]
                }
              ]
            }
          ]
    })).then((response: any) => {
      if (response?.payload?.aggregationResults?.length > 0) {


        let dateList2 = [];
        let data2: number[] = [];
        dateList2 = Object.keys(response.payload.aggregationResults[0]).sort().reverse();
        let count = 0;
        Object.keys(response.payload.aggregationResults[0]).sort().reverse().forEach(val => {
          data2.push(response.payload.aggregationResults[0][`${val}`])
          count = count + response.payload.aggregationResults[0][val];
        });
        setPaTotal(count)

        let list2: Series[] = [];

        const body2: Series =
        {
          name: "Patients",
          data: data2,
          dates: dateList2,
          complete: [],
          color: '#2A45CD',
          marker: {
            fillColor: '#2A45CD',
          }
        }

        list2.push(body2)
        setActivePatientsGraph(list2)
      }
    })
  }, [filterList, dropValuePa])

  //patient kpi
  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    setPatientLoading(true);
    let conditionArr: string[] = []
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })

    let startDate;
    let endDate;
    if (dropValue === 0) {
      startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';

    } else if (dropValue === 1) {
      startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    }
    let lastweek = 0;
    let currentweek = 0;
    dispatch(countDashboard({
      patient: {
        filter: {
          organizationId: organizationLocalData.id,
          createdBetween: {
            start: startDate,
            end: endDate
          },
          gender: gender ? gender : undefined,
          age: {
            start: ageFrom ? ageFrom : undefined,
            end: ageTo ? ageTo : undefined,
          },
          conditions: conditionArr,
        },
      },
    })).then((response: any) => {
      if (response?.payload) {
        lastweek = response.payload.patient
        setSubtxtPatient(lastweek)
        let startDateCurrent;
        let endDateCurrent;
        if (dropValue === 0) {
          startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
        } else if (dropValue === 1) {
          startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
        }

        let ed2 = moment(endDateCurrent).toISOString()

        dispatch(countDashboard({
          patient: {
            filter: {
              organizationId: organizationLocalData.id,
              createdBetween: {
                start: startDateCurrent,
                end: ed2
              },
              gender: gender ? gender : undefined,
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            },
          },
        })).then((response: any) => {
          if (response?.payload) {
            currentweek = response.payload.patient;

            setLeftPatient(currentweek)

            if (lastweek > currentweek) {
              const a = ((currentweek / lastweek) * 100) - 100;
              setPatientKpi(Math.round(a))
              setPatientLoading(false);

            } else if (currentweek > lastweek) {
              const a = 100 - ((lastweek / currentweek) * 100);
              setPatientKpi(Math.round(a))
              setPatientLoading(false);
            }
            else if (currentweek === lastweek) {
              setPatientKpi(0)
              setPatientLoading(false);

            }
          } else if (response.error) {
            setPatientLoading(false);
          }
        })
      } else if (response.error) {
        setPatientLoading(false);
      }
    })
  }, [dropValue, filterList])

  //sugar kpi
  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    setSugarLoading(true)
    let conditionArr: string[] = []
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    let startDate;
    let endDate;
    if (dropValueBs === 0) {
      startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    } else if (dropValueBs === 1) {
      startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    }
    let lastweek = 0;
    let currentweek = 0;

    dispatch(aggregateDashboard({
      select: {

        vitalsMeasurements: {
          filter: {
            organizationId: organizationLocalData.id,
            type: "BLOOD_GLUCOSE",
            recordedBetween: {
              start: startDate,
              end: endDate
            },
            patient: {
              gender: gender ? gender : undefined,
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            }
          },
          include: {
            patient: true
          }
        }
      },
      aggregate: [
        {
          on: "vitalsMeasurements",
          type: "count",
          path: "",
        },

      ]

    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          lastweek = response?.payload?.aggregationResults[0]
          setSubtxtBs(lastweek)
          let startDateCurrent;
          let endDateCurrent;
          if (dropValueBs === 0) {
            startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");

          } else if (dropValueBs === 1) {
            startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
          }

          let ed2 = moment(endDateCurrent).toISOString()

          dispatch(aggregateDashboard({
            select: {
              vitalsMeasurements: {
                filter: {
                  organizationId: organizationLocalData.id,
                  type: "BLOOD_GLUCOSE",
                  recordedBetween: {
                    start: startDateCurrent,
                    end: ed2
                  },
                  patient: {
                    gender: gender ? gender : undefined,
                    age: {
                      start: ageFrom ? ageFrom : undefined,
                      end: ageTo ? ageTo : undefined,
                    },
                    conditions: conditionArr,
                  }
                },
                include: {
                  patient: true
                }
              }
            },
            aggregate: [
              {
                on: "vitalsMeasurements",
                type: "count",
                path: "",
              },
            ]
          })).then((response: any) => {
            if (response) {
              if (response?.payload?.aggregationResults?.length > 0) {
                currentweek = response?.payload?.aggregationResults[0]
                setbs(response?.payload?.aggregationResults[0])

                if (lastweek > currentweek) {
                  const a = ((currentweek / lastweek) * 100) - 100;
                  setSugarKpi(Math.round(a))
                  setSugarLoading(false)

                } else if (currentweek > lastweek) {
                  const a = 100 - ((lastweek / currentweek) * 100);
                  setSugarKpi(Math.round(a))
                  setSugarLoading(false)

                }
                else if (currentweek === lastweek) {
                  setSugarKpi(0)
                  setSugarLoading(false)

                }
              } else if (response.error) {
                setSugarLoading(false)
              }
            }
          })
        } else if (response.error) {
          setSugarLoading(false)
        }
      }
    })
  }, [dropValueBs, filterList])

  //BP kpi
  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    setBpLoading(true)
    let conditionArr: string[] = []
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    let startDate;
    let endDate;
    if (dropValueBp === 0) {
      startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    } else if (dropValueBp === 1) {
      startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    }
    let lastweek = 0;
    let currentweek = 0;

    dispatch(aggregateDashboard({
      select: {

        vitalsMeasurements: {
          filter: {
            organizationId: organizationLocalData.id,
            type: "BLOOD_PRESSURE_S_D",
            recordedBetween: {
              start: startDate,
              end: endDate
            },
            patient: {
              gender: gender ? gender : undefined,
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            }
          },
          include: {
            patient: true
          }
        }
      },
      aggregate: [
        {
          on: "vitalsMeasurements",
          type: "count",
          path: "",
        },
      ]
    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          lastweek = response?.payload?.aggregationResults[0]
          setSubBp(lastweek)
          let startDateCurrent;
          let endDateCurrent;
          if (dropValueBp === 0) {
            startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
          } else if (dropValueBp === 1) {
            startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
          }
          let ed2 = moment(endDateCurrent).toISOString()

          dispatch(aggregateDashboard({
            select: {
              vitalsMeasurements: {
                filter: {
                  organizationId: organizationLocalData.id,
                  type: "BLOOD_PRESSURE_S_D",
                  recordedBetween: {
                    start: startDateCurrent,
                    end: ed2
                  },
                  patient: {
                    gender: gender ? gender : undefined,
                    age: {
                      start: ageFrom ? ageFrom : undefined,
                      end: ageTo ? ageTo : undefined,
                    },
                    conditions: conditionArr,
                  }
                },
                include: {
                  patient: true
                }
              }
            },
            aggregate: [
              {
                on: "vitalsMeasurements",
                type: "count",
                path: "",
              },
            ]
          })).then((response: any) => {
            if (response) {
              if (response?.payload?.aggregationResults?.length > 0) {
                currentweek = response?.payload?.aggregationResults[0]
                setBp(response?.payload?.aggregationResults[0])

                if (lastweek > currentweek) {
                  const a = ((currentweek / lastweek) * 100) - 100;
                  setBpKpi(Math.round(a))
                  setBpLoading(false)
                } else if (currentweek > lastweek) {
                  const a = 100 - ((lastweek / currentweek) * 100);
                  setBpKpi(Math.round(a))
                  setBpLoading(false)
                }
                else if (currentweek === lastweek) {
                  setBpKpi(0)
                  setBpLoading(false)
                }
              } else if (response.error) {
                setBpLoading(false)
              }
            }
          })
        } else if (response.error) {
          setBpLoading(false)
        }
      }
    })

  }, [dropValueBp, filterList])

  //Patient Active kpi
  useEffect(() => {
    const organizationLocalData = Async.getItem("@organization")
    setPaLoading(true)
    let conditionArr: string[] = []
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    let startDate;
    let endDate;
    if (dropValuePa === 0) {
      startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';

    } else if (dropValuePa === 1) {
      startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    }
    let lastweek = 0;
    let currentweek = 0;

    dispatch(aggregateDashboard({
      select: {
        vitalsMeasurements: {
          filter: {
            organizationId: organizationLocalData.id,
            recordedBetween: {
              start: startDate,
              end: endDate
            },
            patient: {
              gender: gender ? gender : undefined,
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            }
          },
        }
      },
      mappers: [
        {
          on: "vitalsMeasurements",
          function: "dedupe",
          args: [
            "patientId"
          ]
        }
      ],
      aggregate: [
        {
          on: "vitalsMeasurements",
          type: "count",
          path: ""
        }
      ]
    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {

          lastweek = response?.payload?.aggregationResults[0]
          setSubPa(lastweek)
          let startDateCurrent;
          let endDateCurrent;
          if (dropValuePa === 0) {
            startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
          } else if (dropValuePa === 1) {
            startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
          }
          let ed2 = moment(endDateCurrent).toISOString()

          dispatch(aggregateDashboard({
            select: {
              vitalsMeasurements: {
                filter: {
                  organizationId: organizationLocalData.id,
                  recordedBetween: {
                    start: startDateCurrent,
                    end: ed2
                  },
                  patient: {
                    gender: gender ? gender : undefined,
                    age: {
                      start: ageFrom ? ageFrom : undefined,
                      end: ageTo ? ageTo : undefined,
                    },
                    conditions: conditionArr,
                  }
                },
              }
            },
            mappers: [
              {
                on: "vitalsMeasurements",
                function: "dedupe",
                args: [
                  "patientId"
                ]
              }
            ],
            aggregate: [
              {
                on: "vitalsMeasurements",
                type: "count",
                path: ""
              }
            ]
          })).then((response: any) => {
            if (response) {
              if (response?.payload?.aggregationResults?.length > 0) {
                currentweek = response?.payload?.aggregationResults[0]
                setActivePatient(response?.payload?.aggregationResults[0])

                if (lastweek > currentweek) {
                  const a = ((currentweek / lastweek) * 100) - 100;
                  setPaKpi(Math.round(a))
                  setPaLoading(false)

                } else if (currentweek > lastweek) {
                  const a = 100 - ((lastweek / currentweek) * 100);
                  setPaKpi(Math.round(a))
                  setPaLoading(false)

                }
                else if (currentweek === lastweek) {
                  setPaKpi(0)
                  setPaLoading(false)

                }
              } else if (response.error) {
                setPaLoading(false)
              }
            }
          })
        } else if (response.error) {
          setPaLoading(false)
        }
      }
    })
  }, [dropValuePa, filterList])

  return (
    <React.Fragment>
      {access ?
        <div className="page-content">
          <Container fluid>
            <GraphFilter
              isOpen={filterModel}
              toggle={toggleFilterModal}
              setFilterList={setFilterList}
              tempFilterList={tempFilterList}
              setTempFilterList={setTempFilterList}
            />
            <Row>
              <Col lg="3">
                <h2 className="m-0 align-self-center text-nowrap">
                  <b className="text-nowrap sbl24">Summary dashboard</b>
                </h2>
              </Col>
              <Col lg="7" className="d-flex flex-row-reverse flex-wrap gap-1">
                {filterList?.map((item: OptionType, index: number) => (
                  <div
                    key={index}
                    className="btn ilaraLigthBlue d-flex align-items-center text-nowrap py-auto px-2 btnSelectedFilter"
                    style={{ height: "38px" }}
                  >
                    <p className="mb-0">{item.label}</p>
                    <i
                      onClick={() => {
                        filterList.forEach((itemf: any, i: number) => {
                          if (itemf.value === item.value) {
                            let temp = [...filterList]
                            temp[i].set = false
                            temp.splice(i, 1)
                            setFilterList(temp)
                          }
                        })
                      }}
                      className="bx bx-x font-size-20 ms-2 closeFilter"
                    ></i>
                  </div>
                ))}
              </Col>
              <Col lg="2" className="text-end text-nowrap">
                <button
                  type="button"
                  className="btn btn-light"
                  style={{
                    backgroundColor: "white",
                    color: "#2C3242",
                    border: "1px solid #E3E3E3",
                    width: "auto",
                  }}
                  onClick={() => {
                    setTempFilterList(filterList)
                    toggleFilterModal()
                  }}
                >
                  <i className="bx bx-filter font-size-22 align-middle me-1"></i>
                  <span className="sbold someTextClr">Filters</span>
                </button>
              </Col>
            </Row>
            <Row className="mt-2" cals>
              <Col lg={5} md={6} sm={12}>
                <LinearGraph
                  title="Patients Registered"
                  leftText={leftPatient}
                  leftSub={subTxtPatient}
                  rightText={patientKpi}
                  open={true}
                  graphData={patientGraph}
                  setDropValue={setDropValue}
                  dropValue={dropValue}
                  loading={patientLoading}
                  total={patientTotal}
                />
                <LinearGraph
                  title="Blood Sugars Recorded"
                  leftText={bS}
                  leftSub={subTxtBs}
                  rightText={sugarKpi}
                  open={true}
                  graphData={bsGraph}
                  setDropValue={setDropValueBs}
                  dropValue={dropValueBs}
                  loading={sugarLoading}
                  total={bsTotal}
                />
              </Col>
              <Col lg={5} md={6} sm={12}>
                <LinearGraph
                  title="Patients Active"
                  leftText={activePatient}
                  rightText={paKpi}
                  open={true}
                  graphData={activePatientsGraph}
                  setDropValue={setDropValuePa}
                  dropValue={dropValuePa}
                  leftSub={subPa}
                  loading={paLoading}
                  total={paTotal}
                />
                <LinearGraph
                  title="Blood Pressures Recorded"
                  leftText={bp}
                  leftSub={subBp}
                  rightText={bpKpi}
                  open={true}
                  graphData={bpGraph}
                  setDropValue={setDropValueBp}
                  dropValue={dropValueBp}
                  loading={bpLoading}
                  total={bpTotal}
                />
              </Col>
              {/* live feed */}
              {/* <Col lg={4} md={12} sm={12}>
              <Card className="shadow-sm">
                <CardHeader className="liveFeedHeader">
                  <Row>
                    <Col>
                      <b className="font-size-14">Live Feed</b>
                    </Col>
                    <Col>
                      <p className="font-size-11 text-end">
                        High readings
                        <span style={{ fontSize: "7px" }}>
                          <i className="bx bxs-down-arrow ms-1"></i>
                        </span>
                      </p>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="">
                  <div className="d-flex feedEntry">
                    <p className="font-size-12">
                      Leleti Saoli measured blood pressure
                    </p>
                    <p className="font-size-10 text-secondary text-end pEntry">
                      10 min ago
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
            </Row>
          </Container>
        </div>
        : <div className="permissionWarning">User does not have permissions to view this screen!</div>}
    </React.Fragment>
  )
}

export default Summary
