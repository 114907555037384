import React, { useState, FC, useEffect } from "react"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import { Link } from "react-router-dom"
import { withRouter, useHistory, useLocation } from "react-router-dom"

import { clearState } from "~/store/reducers/AuthSlice"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import Avatar from "react-avatar"
import Async from "~/utils/Async"
import { GetUsers, GetUserSelf } from "~/store/reducers/Select/thunk"
import { errorToast } from "~/utils/toast"
import { resetSelectActions, setAgentStatus } from "~/store/reducers/Select"

const ProfileMenu: FC = () => {
  const history = useHistory()
  let location = useLocation()
  const [menu, setMenu] = useState<boolean>(false)
  const [username, setusername] = useState<string>("Admin")
  const dispatch = useAppDispatch()

  const {
    success: userDataSuccess,
    loading: userDataLoading,
    error: userDataError,
    agent,
  } = useAppSelector(state => state.SelectReducer)

  const LogOut = (): void => {
    localStorage.clear()
    dispatch(clearState())
    var body: HTMLElement = document.body;
    const check = body.classList.contains("vertical-collpsed")

    if (check) {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  useEffect(() => {
    if (userDataSuccess && agent && Object.keys(agent).length > 0 && location.pathname !== "/AddEditAgentManagement") {
      history.push({
        pathname: "/AddEditAgentManagement",
        state: { page: "edit", data: agent },
      })
      dispatch(resetSelectActions())
    } else if (userDataError) {
      errorToast('Error fetching user data')
      dispatch(resetSelectActions())
    }
  }, [userDataSuccess, userDataError])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="d-inline-block align-self-center ms-2">
            <Row>
              <Col>
                <Avatar
                  name={Async.getItem("@auth").profile.firstName}
                  maxInitials={2}
                  textSizeRatio={2.2}
                  size="40"
                  color="#2A45CD30"
                  fgColor="#2A45CD"
                  round
                />
              </Col>
              <Col
                className="ps-0 m-0 me-5"
                style={{ textAlign: "left", verticalAlign: "super" }}
              >
                <div className="d-flex flex-row">
                  <h4 className="m-0 p-0 font-size-14">{Async.getItem("@auth").profile.firstName}</h4>
                  <i className="bx bx-chevron-down d-inline-block ms-2 my-auto" />
                </div>

                <p
                  className="m-0 p-0 font-size-12"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Ilara Health
                </p>
              </Col>
            </Row>
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {!Async.getItem("@super") &&
            <a onClick={() => {
              dispatch(setAgentStatus(''));
              dispatch(GetUserSelf({
                practitioner: {
                  include: {
                    vitalsMeasurements: false,
                    identifiers: true,
                    nextOfKin: true,
                    roleAssignments: true,
                  },
                },
              }))
            }} className="dropdown-item">
              <i className="bx bx-user font-size-18 align-middle me-2 " style={{ color: 'rgb(100 100 100)' }} />
              <span className="im font-size-14">{"Edit profile"}</span>
            </a>
          }
          <Link to="/logout" onClick={LogOut} className="dropdown-item">
            <i className="bx bx-power-off font-size-18 align-middle me-2 " style={{ color: 'rgb(100 100 100)' }} />
            <span className="im font-size-14">{"Logout"}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withRouter(ProfileMenu) as any
