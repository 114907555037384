import React, { useEffect, useState } from "react"
import { FC } from "react"
import { Col, Input, Modal } from "reactstrap"
import Select from "react-select"
import DatePicker from 'reactstrap-date-picker'
import "../filter.scss"
import moment from "moment"
interface FilterProps {
  isOpen: boolean
  toggle: () => void
  setFilterList: any
  tempFilterList: OptionType[]
  setTempFilterList: any
}
interface filterHeadProps {
  title: string
  id: number
}
type OptionType = {
  value: string | number
  label: string
  set?: boolean
  contain?: any
}

const PatientFilter: FC<FilterProps> = ({ isOpen, toggle, setFilterList, tempFilterList, setTempFilterList }) => {
  const [firstFilterBox, setFirstFilterBox] = useState<boolean>(true)
  const [secondFilterBox, setSecondFilterBox] = useState<boolean>(true)
  const [thirdFilterBox, setThirdFilterBox] = useState<boolean>(true)

  const [selectedOptionDays, setSelectedOptionDays] =
    useState<OptionType | null>(null)
  const [selectedOptionGender, setSelectedOptionGender] =
    useState<OptionType | null>(null)
  const [dateStart, setDateStart] = useState<string>("")
  const [dateEnd, setDateEnd] = useState<string>("")
  const [ageFrom, setAgeFrom] = useState<number | null>(null)
  const [ageTo, setAgeTo] = useState<number | null>(null)

  const [lowBP, setLowBP] = useState<boolean>(false)
  const [normalBP, setNormalBP] = useState<boolean>(false)
  const [normalBS, setNormalBS] = useState<boolean>(false)

  const [elevatedBP, setElevatedBP] = useState<boolean>(false)
  const [s1hyper, setS1hyper] = useState<boolean>(false)
  const [s2hyper, setS2hyper] = useState<boolean>(false)
  const [hyperCrysis, setHyperCrysis] = useState<boolean>(false)
  const [highBS, setHighBS] = useState<boolean>(false)
  const [lowBS, setLowBS] = useState<boolean>(false)
  const [preBs, setPreBs] = useState<boolean>(false)

  const days: OptionType[] = [
    { value: 7, label: "Last 7 days" },
    { value: 30, label: "Last 30 days" },
    { value: 90, label: "Last 3 months" },
  ]
  const gender: OptionType[] = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "OTHER", label: "Other" },
  ]
  const HeadFilterType = (props: filterHeadProps) => {
    return (
      <div
        className="d-flex flex-row ps-4 py-2 headerFilter"
        onClick={() => {
          props.id == 1
            ? setFirstFilterBox(!firstFilterBox)
            : props.id == 2
              ? setSecondFilterBox(!secondFilterBox)
              : props.id == 3
                ? setThirdFilterBox(!thirdFilterBox)
                : null
        }}
      >
        <h6
          className={`font-size-14 im m-0 ${(props.id == 1 && firstFilterBox) ||
            (props.id == 2 && secondFilterBox) ||
            (props.id == 3 && thirdFilterBox)
            ? "text-primary"
            : ""
            }`}
        >
          {props.title}
        </h6>
        <i
          className={`bx bx-chevron-down font-size-20 ms-2 ${(props.id == 1 && firstFilterBox) ||
            (props.id == 2 && secondFilterBox) ||
            (props.id == 3 && thirdFilterBox)
            ? "arrowIcon text-primary"
            : ""
            }`}
        ></i>
      </div>
    )
  }
  const resetStates = () => {
    setDateStart("")
    setDateEnd("")
    setAgeFrom(null)
    setAgeTo(null)
    setSelectedOptionGender(null)
    setSelectedOptionDays(null)
    setLowBP(false)
    setNormalBP(false)
    setNormalBS(false)
    setElevatedBP(false)
    setS1hyper(false)
    setS2hyper(false)
    setHyperCrysis(false)
    setHighBS(false)
    setLowBS(false)
    setPreBs(false)
  }
  useEffect(() => {
    if (selectedOptionDays) {
      const filterDate = new Date()
      const strt = new Date(filterDate.getFullYear(), filterDate.getMonth(), filterDate.getDate() - Number(selectedOptionDays.value))

      setDateEnd(`${moment(filterDate).format("yyyy-MM-DD")}`)
      setDateStart(`${moment(strt).format("yyyy-MM-DD")}`)
    }
  }, [selectedOptionDays])

  useEffect(() => {
    let tempArr: OptionType[] = [];
    lowBP && tempArr.push({ label: 'Low BP', value: 1, set: true, contain: 'BLOOD_PRESSURE_LOW' })
    normalBP && tempArr.push({ label: 'Normal BP', value: 2, set: true, contain: 'BLOOD_PRESSURE_NORMAL' })
    elevatedBP && tempArr.push({ label: 'Elevated BP', value: 3, set: true, contain: 'BLOOD_PRESSURE_ELEVATED' })
    s1hyper && tempArr.push({ label: 'Stage 1 hypertension', value: 4, set: true, contain: 'BLOOD_PRESSURE_HYPERTENSION_STAGE_1' })
    s2hyper && tempArr.push({ label: 'Stage 2 hypertension', value: 5, set: true, contain: 'BLOOD_PRESSURE_HYPERTENSION_STAGE_2' })
    hyperCrysis && tempArr.push({ label: 'Hypertensive crisis', value: 6, set: true, contain: 'BLOOD_PRESSURE_HYPERTENSION_CRISIS' })
    highBS && tempArr.push({ label: 'High blood sugar', value: 7, set: true, contain: 'BLOOD_GLUCOSE_HIGH' })
    lowBS && tempArr.push({ label: 'Low blood sugar', value: 8, set: true, contain: 'BLOOD_GLUCOSE_LOW' })
    preBs && tempArr.push({ label: 'Pre Diabetes', value: 15, set: true, contain: 'BLOOD_GLUCOSE_PRE_DIABETES' })

    dateStart && tempArr.push({ label: `Dated From:  ${dateStart}`, value: 9, set: true, contain: dateStart })
    dateEnd && tempArr.push({ label: `Dated To: ${dateEnd}`, value: 10, set: true, contain: dateEnd })
    ageFrom && tempArr.push({ label: `Age From: ${ageFrom.toString()}`, value: 11, set: true, contain: ageFrom })
    ageTo && tempArr.push({ label: `Age To: ${ageTo.toString()}`, value: 12, set: true, contain: ageTo })
    normalBS && tempArr.push({ label: 'Normal blood sugar', value: 14, set: true, contain: 'BLOOD_GLUCOSE_NORMAL' })

    selectedOptionGender && tempArr.push({ label: `Gender : ${selectedOptionGender.label}`, value: 13, set: true, contain: selectedOptionGender })
    setTempFilterList(tempArr)
  }, [
    lowBP, normalBP, elevatedBP, s1hyper, s2hyper, hyperCrysis, highBS, lowBS,
    dateStart, dateEnd, ageFrom, ageTo, selectedOptionGender, normalBS, preBs
  ])
  useEffect(() => {
    tempFilterList?.forEach((item: any) => {
      if (item.set === false) {
        switch (item.value) {
          case 1: setLowBP(false); break;
          case 2: setNormalBP(false); break;
          case 3: setElevatedBP(false); break;
          case 4: setS1hyper(false); break;
          case 5: setS2hyper(false); break;
          case 6: setHyperCrysis(false); break;
          case 7: setHighBS(false); break;
          case 8: setLowBS(false); break;
          case 9: setDateStart(''); break;
          case 10: setDateEnd(''); break;
          case 11: setAgeFrom(null); break;
          case 12: setAgeTo(null); break;
          case 13: setSelectedOptionGender(null); break;
          case 14: setNormalBS(false); break;
          case 15: setPreBs(false); break;
        }
      } else if (item.set === true) {
        switch (item.value) {
          case 1: setLowBP(item.contain); break;
          case 2: setNormalBP(item.contain); break;
          case 3: setElevatedBP(item.contain); break;
          case 4: setS1hyper(item.contain); break;
          case 5: setS2hyper(item.contain); break;
          case 6: setHyperCrysis(item.contain); break;
          case 7: setHighBS(item.contain); break;
          case 8: setLowBS(item.contain); break;
          case 9: setDateStart(item.contain); break;
          case 10: setDateEnd(item.contain); break;
          case 11: setAgeFrom(item.contain); break;
          case 12: setAgeTo(item.contain); break;
          case 13: setSelectedOptionGender(item.contain); break;
          case 14: setNormalBS(item.contain); break;
          case 15: setPreBs(item.contain); break;
        }
      }
    })
  }, [tempFilterList])

  const removeItem = (itemVal: number) => {
    tempFilterList.forEach((item: any, i: number) => {
      if (item.value === itemVal) {
        let temp = [...tempFilterList]
        temp[i].set = false
        setTempFilterList(temp)
      }
    })
  }
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop='static'
    >
      <div className="modal-body ">
        <h4
          className="modal-title mt-0 ms-2 sb font-size-18"
          id="myLargeModalLabel"
        >
          Filter Patients
        </h4>
        <button
          onClick={() => {
            resetStates()
            toggle()
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body px-0 py-0">
        <HeadFilterType title="Date" id={1} />
        {firstFilterBox ? (
          <div className="filtersBox">
            <div className="mb-3">
              <label className="col-md-2 col-form-label im">Duration</label>
              <Select
                className="selectFiler"
                defaultValue={selectedOptionDays}
                onChange={val => setSelectedOptionDays(val)}
                options={days}
                placeholder="Select Duration"
              />
            </div>
            <div className="align-self-center">
              <p> </p>
              or
            </div>
            <div className="d-flex flex-grow-1">
              <div className="mb-3 w-50 pe-3">
                <label className="col-md-2 col-form-label filterLabel im">
                  Start date
                </label>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  showClearButton={false}
                  className="rounded form-control"
                  value={dateStart}
                  onChange={(val: string) => { setDateStart(moment(val).format("yyyy-MM-DD")) }}
                />
              </div>
              <div className="mb-3 w-50 pe-3">
                <label className="col-md-2 col-form-label filterLabel im">
                  End date
                </label>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  showClearButton={false}
                  className="rounded form-control"
                  value={dateEnd}
                  onChange={(val: string) => setDateEnd(moment(val).format("yyyy-MM-DD"))}
                />
              </div>
            </div>
          </div>
        ) : null}
        <HeadFilterType title="Biological data" id={2} />
        {secondFilterBox ? (
          <div className="filtersBox">
            <div className="mb-3">
              <label className="col-md-2 col-form-label im">Gender</label>
              <Select
                className="selectFiler"
                defaultValue={selectedOptionGender}
                onChange={val => setSelectedOptionGender(val)}
                options={gender}
                placeholder="Select Gender"
              />
            </div>
            <div className="mb-3">
              <label className="col-md-2 col-form-label filterLabel im">
                Age from
              </label>
              <Input
                type="number"
                className="form-control numberFilter"
                placeholder="0"
                value={ageFrom ? ageFrom : ''}
                onChange={val => setAgeFrom(Number(val.target.value))}
              />
            </div>
            <div className="mb-3">
              <label className="col-md-2 col-form-label filterLabel im">
                Age to
              </label>
              <Input
                type="number"
                className="form-control numberFilter"
                placeholder="100"
                value={ageTo ? ageTo : ''}
                onChange={val => setAgeTo(Number(val.target.value))}
              />
            </div>
          </div>
        ) : null}
        <HeadFilterType title="Medical conditions" id={3} />
        {thirdFilterBox ? (
          <div className="filtersBox">
            <Col sm={4}>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={lowBP}
                  onChange={() => setLowBP(!lowBP)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">Low BP</h6>
              </div>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={normalBP}
                  onChange={() => setNormalBP(!normalBP)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Normal BP
                </h6>
              </div>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={elevatedBP}
                  onChange={() => setElevatedBP(!elevatedBP)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Elevated BP
                </h6>
              </div>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={s1hyper}
                  onChange={() => setS1hyper(!s1hyper)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Stage 1 hypertension
                </h6>
              </div>
            </Col>
            <Col sm={4}>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={s2hyper}
                  onChange={() => setS2hyper(!s2hyper)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Stage 2 hypertension
                </h6>
              </div>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={hyperCrysis}
                  onChange={() => setHyperCrysis(!hyperCrysis)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Hypertensive crisis
                </h6>
              </div>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={normalBS}
                  onChange={() => setNormalBS(!normalBS)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Normal blood sugar
                </h6>
              </div>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={highBS}
                  onChange={() => setHighBS(!highBS)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  High blood sugar
                </h6>
              </div>
            </Col>
            <Col >
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={lowBS}
                  onChange={() => setLowBS(!lowBS)}
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Low blood sugar
                </h6>
              </div>
              <div className="form-check form-check-end my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={preBs}
                  onChange={() => {
                    setPreBs(!preBs)
                  }
                  }
                />
                <h6 className="form-check-label font-size-14 ms-1 im">
                  Pre Diabetes
                </h6>
              </div>
            </Col>
          </div>
        ) : null}
        <div className="d-flex flex-wrap gap-2 px-4 py-3">
          {tempFilterList?.map((item: any, index: any) => (
            <div
              key={index}
              className="btn ilaraLigthBlue d-flex align-items-center text-nowrap py-0 px-2 btnSelectedFilter"
              style={{ height: '29px' }}
            >
              <p className="m-0 sb">{item.label}</p>
              <i
                onClick={() => removeItem(Number(item.value))}
                className="bx bx-x font-size-20 ms-3 closeFilter"
              ></i>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer px-3">
        <button
          type="button"
          className="discard-btn"
          onClick={() => {
            resetStates()
            toggle()
          }}
        >
          <h6 className="my-auto px-2 im">Dismiss</h6>
        </button>
        <div className="ms-auto">
          {tempFilterList?.length > 0 &&
            <button
              type="button"
              className="discard-btn"
              onClick={() => {
                resetStates()
              }}
            >
              <h6 className="my-auto px-2 im">Clear all filters ({tempFilterList?.length})</h6>
            </button>
          }
          <button
            onClick={() => {
              setFilterList(tempFilterList)
              resetStates()
              toggle()
            }}
            className="btn btn-primary ms-3 im"
          >
            Apply filters
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default PatientFilter
