import { useHistory } from "react-router-dom"
import React, { FC, useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap"
import Datatable from "~/components/Common/Datatable"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarFalse } from "~/store/reducers/sideBar"
import LinearGraph from "~/components/Common/Graphs/LinearGraph"
import { setPreloader } from "~/store/reducers/loader"
import { SearchOrganizations } from "~/store/reducers/Organizations/thunk"
import {
  SearchResetOrganizationStates,
  setIsSearchToFalse,
  setSearchData,
  setSearching,
  setTakeReducer,
  setLocalData
} from "~/store/reducers/Organizations"
import Async from "~/utils/Async"
import { errorToast, successToast } from "~/utils/toast"
import { resetArchiveItems } from "~/store/reducers/Permission"
import ArchiveConfirmationModal from "~/components/Common/Modals/confirmationModal/archive"
import { ArchiveItems } from "~/store/reducers/Permission/thunk"
import { aggregateDashboard, countDashboard } from "~/store/reducers/dashboard/thunk"
import { Series } from "~/models/Org.model"
import moment from "moment"

interface Archive {
  model: string,
  itemId: string
}

const Dashboard: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { showSecondaryBar } = useAppSelector(state => state.sideBarReducer)
  const {
    //search states
    errorSearch,
    isSearch,
    organizationsCompleteData,
    loadingSearch,
    searchData,
    searching,
    localData
  } = useAppSelector(state => state.OrganizationReducer)

  const { archiveItemsSuccess, archiveItemsError, archiveItemsLoading } = useAppSelector(state => state.PermissionReducer)

  const [access, setAccess] = useState<boolean>(true);

  const [filterModel, setFilterModel] = useState<boolean>(false)
  const toggleFilterModal = () => setFilterModel(!filterModel)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const [sorting, setSorting] = useState<string>("")
  const [sortCol, setSortCol] = useState<string>("")
  const [reset, setReset] = useState<boolean>(false)
  const [isDataRendered, setIsDataRendered] = useState<boolean>(false)
  const [patientCount, setPatientCount] = useState<number>(0)
  const [workerCount, setWorkerCount] = useState<number>(0)
  const [orgCount, setOrgCount] = useState<number>(0)
  const [dropValue, setDropValue] = useState<number>(0)
  const [orgGraph, setOrgGraph] = useState<Series[]>([])
  const [workerGraph, setWorkerGraph] = useState<Series[]>([])
  const [patientGraph, setPatientGraph] = useState<Series[]>([])
  const [orgLoading, setOrgLoading] = useState<boolean>(false)
  const [subOrg, setSubOrg] = useState<number>(0)
  const [orgKpi, setOrgKip] = useState<number>(0)
  //worker
  const [dropValueAgent, setDropValueAgent] = useState<number>(0)
  const [subWorker, setSubWorker] = useState<number>(0)
  const [workerLoading, setWorkerLoading] = useState<boolean>(false)
  const [workerKpi, setWokerKpi] = useState<number>(0)
  //patient
  const [dropValuePatient, setDropValuePatient] = useState<number>(0)
  const [subPatient, setSubPatient] = useState<number>(0)
  const [patientLoading, setPatientLoading] = useState<boolean>(false)
  const [patientKpi, setPatientKpi] = useState<number>(0)
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [idArchive, setIdArchive] = useState<string>('')
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [bulk, setBulk] = useState<boolean>(false)
  const [orgCountTotal, setOrgCountTotal] = useState<number>(0)
  const [orgPatientTotal, setPatientTotal] = useState<number>(0)
  const [workerTotal, setWorkerTotal] = useState<number>(0)
  const [allRows, setAll] = useState<boolean>(false)
  const [numberOfRowsSelected, setNumberOfRowsSelected] = useState<number>(0)
  const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false)

  useEffect(() => {
    const data = Async.getItem("@auth")
    setAccess(data?.role?.permissions["dashboard-overview-view"])
    if (showSecondaryBar) {
      dispatch(setSecondaryBarFalse())
    }
    setTimeout(() => {
      dispatch(setPreloader(false))
    }, 1000)
  }, [])

  useEffect(() => {
    const strt = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )

    dispatch(aggregateDashboard({
      select: {
        practitioner: {
          filter: {
            createdBetween: {
              start: new Date(strt).toISOString(),
              end: new Date().toISOString()
            },
          },
        },

      },
      aggregate:
        dropValueAgent === 0 ?
          [
            {
              on: "practitioner",
              type: "count",
              path: "",
              groupBy: "user.profile.createdAt",
              groupByTransform: [
                {
                  "function": "week",
                  "args": []
                },
              ]
            }
            ,
            {
              on: "practitioner",
              type: "count",
              path: "",
            },
          ]
          :
          [
            {
              on: "practitioner",
              type: "count",
              path: "",
              groupBy: "user.profile.createdAt",
              groupByTransform: [
                {
                  "function": "dateTrunc",
                  "args": ["month"]
                },
              ]
            },
            {
              on: "practitioner",
              type: "count",
              path: "",
            },
          ]

    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          //workers
          setWorkerTotal(response.payload.aggregationResults[1]);
          let dateList1 = [];
          let data1: number[] = [];
          dateList1 = Object.keys(response.payload.aggregationResults[0])
          data1 = Object.values(response.payload.aggregationResults[0]);
          let list1: Series[] = [];

          const body1: Series =
          {
            name: "worker",
            data: data1,
            dates: dateList1,
            complete: [],
            color: '#2A45CD',
            marker: {
              fillColor: '#2A45CD',
            }
          }

          list1.push(body1)
          setWorkerGraph(list1)
        }
      }

    })
  }, [dropValueAgent])


  useEffect(() => {
    const strt = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )

    dispatch(aggregateDashboard({
      select: {
        organization: {
          filter: {
            createdBetween: {
              start: new Date(strt).toISOString(),
              end: new Date().toISOString()
            },
          },
        },
      },
      aggregate:
        dropValue === 0 ?
          [
            {
              on: "organization",
              type: "count",
              path: "",
              groupBy: "createdAt",
              groupByTransform: [
                {
                  "function": "week",
                  "args": []
                },
              ]
            },
            {
              on: "organization",
              type: "count",
              path: "",
            },
          ]
          :
          [
            {
              on: "organization",
              type: "count",
              path: "",
              groupBy: "createdAt",
              groupByTransform: [
                {
                  "function": "dateTrunc",
                  "args": ["month"]
                },
              ]
            },
            {
              on: "organization",
              type: "count",
              path: "",
            },
          ]

    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          setOrgCountTotal(response?.payload?.aggregationResults[1])
          let dateList = [];
          let data: number[] = [];
          dateList = Object.keys(response.payload.aggregationResults[0])
          data = Object.values(response.payload.aggregationResults[0]);
          let list: Series[] = [];

          const body: Series =
          {
            name: "Patients",
            data: data,
            dates: dateList,
            complete: [],
            color: '#2A45CD',
            marker: {
              fillColor: '#2A45CD',
            }
          }

          list.push(body)
          setOrgGraph(list)

        }
      }

    })
  }, [dropValue])

  useEffect(() => {
    const strt = new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )

    dispatch(aggregateDashboard({
      select: {
        patient: {
          filter: {
            createdBetween: {
              start: new Date(strt).toISOString(),
              end: new Date().toISOString()
            },
          },
        },
      },
      aggregate:
        dropValuePatient === 0 ?
          [
            {
              on: "patient",
              type: "count",
              path: "",
              groupBy: "user.profile.createdAt",

              groupByTransform: [
                {
                  "function": "week",
                  "args": []
                },
              ]
            },
            {
              on: "patient",
              type: "count",
              path: "",
            },
          ]
          :
          [
            {
              on: "patient",
              type: "count",
              path: "",
              groupBy: "user.profile.createdAt",

              groupByTransform: [
                {
                  "function": "dateTrunc",
                  "args": ["month"]
                },
              ]
            },
            {
              on: "patient",
              type: "count",
              path: "",
            },
          ]


    })).then((response: any) => {
      if (response) {
        if (response?.payload?.aggregationResults?.length > 0) {
          setPatientTotal(response?.payload?.aggregationResults[1])
          //
          let dateList2 = [];
          let data2: number[] = [];
          dateList2 = Object.keys(response.payload.aggregationResults[0])
          data2 = Object.values(response.payload.aggregationResults[0]);
          let list2: Series[] = [];

          const body2: Series =
          {
            name: "worker",
            data: data2,
            dates: dateList2,
            complete: [],
            color: '#2A45CD',
            marker: {
              fillColor: '#2A45CD',
            }
          }

          list2.push(body2)
          setPatientGraph(list2)
        }
      }

    })
  }, [dropValuePatient])

  //org kpi
  useEffect(() => {
    setOrgLoading(true)
    let startDate;
    let endDate;
    if (dropValue === 0) {
      startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';

    } else if (dropValue === 1) {

      startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    }
    let lastweek = 0;
    let currentweek = 0;

    dispatch(countDashboard({
      organization: {
        filter: {
          createdBetween: {
            start: startDate,
            end: endDate
          },
        },
      },

    })).then((response: any) => {

      if (response?.payload) {
        lastweek = response?.payload?.organization

        setSubOrg(lastweek)
        let startDateCurrent;
        let endDateCurrent;
        if (dropValue === 0) {
          startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");

        } else if (dropValue === 1) {
          startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
        }

        let ed2 = moment(endDateCurrent).toISOString()

        dispatch(countDashboard({
          organization: {
            filter: {
              createdBetween: {
                start: startDateCurrent,
                end: ed2
              },
            },
          },
        })).then((response: any) => {
          if (response?.payload) {
            currentweek = response?.payload?.organization
            setOrgCount(currentweek)

            if (lastweek > currentweek) {
              const a = ((currentweek / lastweek) * 100) - 100;
              setOrgKip(Math.round(a))
              setOrgLoading(false)

            } else if (currentweek > lastweek) {
              const a = 100 - ((lastweek / currentweek) * 100);
              setOrgKip(Math.round(a))
              setOrgLoading(false)

            }
            else if (currentweek === lastweek) {
              setOrgKip(0)
              setOrgLoading(false)
            }
          }
          else if (response.error) {
            setOrgLoading(false)
          }
        })
      } else if (response.error) {
        setOrgLoading(false)
      }
    })
  }, [dropValue])

  //practitioner kpi
  useEffect(() => {
    setWorkerLoading(true)
    let startDate;
    let endDate;
    if (dropValueAgent === 0) {
      startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';

    } else if (dropValueAgent === 1) {

      startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    }
    let lastweek = 0;
    let currentweek = 0;

    dispatch(countDashboard({
      practitioner: {
        filter: {
          createdBetween: {
            start: startDate,
            end: endDate
          },
        },
      },

    })).then((response: any) => {

      if (response?.payload?.practitioner) {
        lastweek = response?.payload?.practitioner

        setSubWorker(lastweek)
        let startDateCurrent;
        let endDateCurrent;
        if (dropValueAgent === 0) {
          startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");

        } else if (dropValueAgent === 1) {
          startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
        }

        let ed2 = moment(endDateCurrent).toISOString()

        dispatch(countDashboard({
          practitioner: {
            filter: {
              createdBetween: {
                start: startDateCurrent,
                end: ed2
              },
            },
          },
        })).then((response: any) => {
          if (response?.payload) {
            currentweek = response?.payload?.practitioner
            setWorkerCount(currentweek)

            if (lastweek > currentweek) {
              const a = ((currentweek / lastweek) * 100) - 100;
              setWokerKpi(Math.round(a))
              setWorkerLoading(false)

            } else if (currentweek > lastweek) {
              const a = 100 - ((lastweek / currentweek) * 100);
              setWokerKpi(Math.round(a))
              setWorkerLoading(false)

            }
            else if (currentweek === lastweek) {
              setWokerKpi(0)
              setWorkerLoading(false)
            }
          }
          else if (response.error) {
            setWorkerLoading(false)
          }
        })
      } else if (response.error) {
        setWorkerLoading(false)
      }
    })
  }, [dropValueAgent])

  //practitioner kpi
  useEffect(() => {
    setPatientLoading(true)
    let startDate;
    let endDate;
    if (dropValuePatient === 0) {
      startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';

    } else if (dropValuePatient === 1) {

      startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
      endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
    }
    let lastweek = 0;
    let currentweek = 0;

    dispatch(countDashboard({
      patient: {
        filter: {
          createdBetween: {
            start: startDate,
            end: endDate
          },
        },
      },

    })).then((response: any) => {

      if (response?.payload?.patient) {
        lastweek = response?.payload?.patient

        setSubPatient(lastweek)
        let startDateCurrent;
        let endDateCurrent;
        if (dropValuePatient === 0) {
          startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");

        } else if (dropValuePatient === 1) {
          startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
          endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
        }

        let ed2 = moment(endDateCurrent).toISOString()

        dispatch(countDashboard({
          patient: {
            filter: {
              createdBetween: {
                start: startDateCurrent,
                end: ed2
              },
            },
          },
        })).then((response: any) => {
          if (response?.payload) {
            currentweek = response?.payload?.patient
            setPatientCount(currentweek)
            if (lastweek > currentweek) {
              const a = ((currentweek / lastweek) * 100) - 100;
              setPatientKpi(Math.round(a))
              setPatientLoading(false)

            } else if (currentweek > lastweek) {
              const a = 100 - ((lastweek / currentweek) * 100);
              setPatientKpi(Math.round(a))
              setPatientLoading(false)

            }
            else if (currentweek === lastweek) {
              setPatientKpi(0)
              setPatientLoading(false)
            }
          }
          else if (response.error) {
            setPatientLoading(false)
          }
        })
      } else if (response.error) {
        setPatientLoading(false)
      }
    })
  }, [dropValuePatient])

  useEffect(() => {
    dispatch(setSearchData(''));
  }, [])

  useEffect(() => {
    if (archiveItemsSuccess) {
      successToast('Organization Archived')
      toggleArchive()
      dispatch(resetArchiveItems())
      dispatch(setIsSearchToFalse())
      setNumberOfRowsSelected(0)
      setClearSelectedRows(true)
      setTimeout(() => setClearSelectedRows(false), 0.1)

      if (bulk) {
        setBulk(false)
      }
      if (allRows || organizationsCompleteData?.organizations.length === 1) {
        setSkip(0)
        setReset(true)
        dispatch(setSearchData(''));
        setTimeout(() => setReset(false), 0.1)
        const ele: any = document.getElementsByName('select-all-rows');
        ele.checked = false;
      }
      if (isSearch) {
        dispatch(
          SearchOrganizations({
            search: (allRows || organizationsCompleteData?.organizations.length === 1) ? "" : searchData,
            filter: {
              take: rowsPerPage,
              skip: (allRows || organizationsCompleteData?.organizations.length === 1) ? 0 : skip,
            },
            sort: [
              {
                field: sortCol === "" ? "name" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      } else {
        dispatch(
          SearchOrganizations({
            search: "",
            filter: {
              take: rowsPerPage,
              skip: (allRows || organizationsCompleteData?.organizations.length === 1) ? 0 : skip,
            },
            sort: [
              {
                field: sortCol === "" ? "name" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      }
    } else if (archiveItemsError) {
      if (bulk) {
        setBulk(false)
      }
      errorToast(archiveItemsError)
      toggleArchive()
      dispatch(resetArchiveItems())
    }
  }, [archiveItemsSuccess, archiveItemsError])

  useEffect(() => {
    dispatch(setIsSearchToFalse())

    if (
      organizationsCompleteData &&
      errorSearch === ""
    ) {
      dispatch(
        SearchOrganizations({
          search: "",
          filter: {
            take: rowsPerPage,
            skip: skip,
          },
          sort: [
            {
              field: "name",
              order: "asc",
            },
          ],
        })
      )
    } else if (errorSearch !== "") {
      dispatch(SearchResetOrganizationStates())
    }
  }, [])

  useEffect(() => {
    if (organizationsCompleteData?.organizations?.length > 0) {
      setIsDataRendered(true)
    }
  }, [organizationsCompleteData])

  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    window.addEventListener("resize", HandleWidth)
    return () => window.removeEventListener("resize", HandleWidth)
  }, [])
  const HandleWidth = () => {
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    if (rowsPerPage) {
      dispatch(setTakeReducer(rowsPerPage))
    }
  }, [rowsPerPage])

  const handleSort = async (column: any, sortDirection: any) => {
    setSorting(
      sorting === "" ? sortDirection : sorting === "asc" ? "desc" : "asc"
    )

    setSortCol(
      column.name.toLowerCase() === "workers"
        ? "practitioners"
        : column.name.toLowerCase()
    )
    dispatch(
      SearchOrganizations({
        search: searchData,
        filter: {
          take: rowsPerPage,
          skip: 0,
        },
        sort: [
          {
            field:
              column.name.toLowerCase() === "workers"
                ? "practitioners"
                : column.name.toLowerCase(),
            order:
              sorting === ""
                ? sortDirection
                : sorting === "asc"
                  ? "desc"
                  : "asc",
          },
        ],
      })
    )
  }
  const onRowsPerPageFetchData = (
    rowsPerPage2: number,
    currentPage: number
  ) => {
    dispatch(
      SearchOrganizations({
        search: searchData,
        filter: {
          take: rowsPerPage2,
          skip: 0,
        },
        sort: [
          {
            field: sortCol === "" ? "name" : sortCol,
            order: sorting === "" ? "asc" : sorting,
          },
        ],
      })
    )
    setReset(true)
    setTimeout(() => setReset(false), 0.1)
  }

  useEffect(() => {
    if (searching) {
      setReset(searching)
      dispatch(setSearching(!searching))
      setTimeout(() => setReset(!searching), 0.1)

      setClearSelectedRows(true)
      setTimeout(() => setClearSelectedRows(false), 0.1)
    }
  }, [searching])

  // next button
  useEffect(() => {
    const element = document.getElementById("pagination-next-page")
    if (element) {
      element.addEventListener("click", HandleNextPage)
      return () => element.removeEventListener("click", HandleNextPage)
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, searchData])

  const HandleNextPage = () => {
    const element: any = document.getElementsByClassName("hgiyph")

    if (element) {

      element[0]?.innerText
      let text = element[0]?.innerText?.split(" ")
      let firstTxt = text[0].split("-")
      let num = Number(firstTxt[1])
      let newPageNumber = Math.ceil(num / rowsPerPage)
      let skip222 = newPageNumber * rowsPerPage

      dispatch(
        SearchOrganizations({
          search: searchData,
          filter: {
            take: rowsPerPage,
            skip: skip222,
          },
          sort: [
            {
              field: sortCol === "" ? "name" : sortCol,
              order: sorting === "" ? "asc" : sorting,
            },
          ],
        })
      )
    }
  }

  //back button
  useEffect(() => {
    const element = document.getElementById("pagination-previous-page")
    if (element) {
      element.addEventListener("click", HandleBack)
      return () => element.removeEventListener("click", HandleBack)
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, searchData])

  const HandleBack = () => {
    const element: any = document.getElementsByClassName("hgiyph")

    if (element) {
      element[0]?.innerText
      let text = element[0]?.innerText?.split(" ")
      let firstTxt = text[0].split("-")
      let num = Number(firstTxt[1])
      let newPageNumber = Math.ceil(num / rowsPerPage)
      let x = newPageNumber - 1
      let skip222 = x * rowsPerPage - rowsPerPage

      dispatch(
        SearchOrganizations({
          search: searchData,
          filter: {
            take: rowsPerPage,
            skip: skip222,
          },
          sort: [
            {
              field: sortCol === "" ? "name" : sortCol,
              order: sorting === "" ? "asc" : sorting,
            },
          ],
        })
      )
    }
  }

  //First Page button
  useEffect(() => {
    const element = document.getElementById("pagination-first-page")
    if (element) {
      element.addEventListener("click", FirstPage)
      return () => element.removeEventListener("click", FirstPage)
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, searchData])

  const FirstPage = () => {
    dispatch(
      SearchOrganizations({
        search: searchData,
        filter: {
          take: rowsPerPage,
          skip: 0,
        },
        sort: [
          {
            field: sortCol === "" ? "name" : sortCol,
            order: sorting === "" ? "asc" : sorting,
          },
        ],
      })
    )
  }

  //last Page button
  useEffect(() => {
    const element = document.getElementById("pagination-last-page")
    if (element) {
      element.addEventListener("click", LastPage)
      return () => element.removeEventListener("click", LastPage)
    }
  }, [
    isDataRendered,
    rowsPerPage,
    sortCol,
    sorting,
    searchData,
    organizationsCompleteData,
  ])

  const LastPage = () => {
    let newPageNumber = Math.ceil(
      organizationsCompleteData?.total / rowsPerPage
    )
    let x = newPageNumber - 1
    let skip222 = x * rowsPerPage
    dispatch(
      SearchOrganizations({
        search: searchData,
        filter: {
          take: rowsPerPage,
          skip: skip222,
        },
        sort: [
          {
            field: sortCol === "" ? "name" : sortCol,
            order: sorting === "" ? "asc" : sorting,
          },
        ],
      })
    )
  }

  const toggleArchive = (id?: string) => {
    if (id !== 'bulk') {
      setShowConfirmationModal(!showConfirmationModal)
      id && setIdArchive(id)
    } else if (id === 'bulk' && selectedRows?.length > 0) {
      setShowConfirmationModal(!showConfirmationModal)
      setBulk(true)
    } else if (id === 'bulk' && selectedRows?.length === 0) {
      errorToast("First select rows")
    }
  }

  const onConfirm = () => {
    if (bulk) {
      let list: Archive[] = [];
      selectedRows.forEach((val: any) => {
        list.push(
          {
            model: 'organization',
            itemId: val.id
          }
        )
      })
      dispatch(ArchiveItems(list))

    } else {
      dispatch(ArchiveItems([{
        model: 'organization',
        itemId: idArchive
      }]))
    }
  }

  const onRowClicked = (row: any) => {
    Async.setItem("@organization", row)
    dispatch(setLocalData(!localData))
    Async.setItem("@Screen", "edit")
    history.push({
      pathname: "/reports/summary",
      state: {
        page: "edit",
        data: row,
      },
    })
  }

  const onSelectedRowsChange = (data: any) => {
    setSelectedRows(data.selectedRows)
    setAll(data.allSelected)
    setNumberOfRowsSelected(data.selectedCount)
  }

  return (
    <React.Fragment>
      {access ?
        <div>
          <ArchiveConfirmationModal
            toggle={toggleArchive}
            isOpen={showConfirmationModal}
            onConfirm={onConfirm}
            loading={archiveItemsLoading}
          />
          <div
            className="page-content"
            style={{ maxHeight: `${height - 5}px`, minHeight: "500px" }}
          >
            <Container fluid>
              <div
                style={{ minHeight: "150px", position: "sticky", zIndex: "1000" }}
              >
                <div className="d-sm-flex flex-wrap">
                  <h2 className="m-0 align-self-center">
                    <span className="sbl24">All Organizations</span>
                  </h2>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ color: "white", width: "auto" }}
                      onClick={() => {
                        Async.setItem("@Screen", "add")
                        history.push("/organizationDetails")
                      }}
                    >
                      <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                      Add New Organization
                    </button>
                  </div>
                </div>
                <Row className="mt-3">
                  <Col>
                    <LinearGraph
                      title="Organizations"
                      leftText={orgCount}
                      rightText={orgKpi}
                      open={false}
                      graphData={orgGraph}
                      setDropValue={setDropValue}
                      dropValue={dropValue}
                      loading={orgLoading}
                      leftSub={subOrg}
                      total={orgCountTotal}
                    />
                  </Col>
                  <Col>
                    <LinearGraph
                      title="Workers"
                      leftText={workerCount}
                      rightText={workerKpi}
                      open={false}
                      graphData={workerGraph}
                      setDropValue={setDropValueAgent}
                      dropValue={dropValueAgent}
                      loading={workerLoading}
                      leftSub={subWorker}
                      total={workerTotal}
                    />
                  </Col>
                  <Col>
                    <LinearGraph
                      title="Patients"
                      leftText={patientCount}
                      rightText={patientKpi}
                      open={false}
                      graphData={patientGraph}
                      setDropValue={setDropValuePatient}
                      dropValue={dropValuePatient}
                      total={orgPatientTotal}
                      loading={patientLoading}
                      leftSub={subPatient}
                    />
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  maxHeight: `${height - 290}px`,
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                }}
              >
                {loadingSearch && (
                  <div
                    style={{
                      width: "100%",
                      minHeight: "500px",
                      display: "flex",
                      flex: "1",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner
                      style={{
                        color: "#2a45cd",
                        alignSelf: "center",
                        width: "70px",
                        height: "70px",
                      }}
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )}
                <Card>
                  <CardBody
                    className={
                      loadingSearch
                        ? "px-0 py-1 border border-light rounded d-none"
                        : "px-0 py-1 border border-light rounded"
                    }
                  >
                    {isDataRendered ? (
                      <Datatable
                        type="dashboard"
                        toggleModal={toggleArchive}
                        data={organizationsCompleteData?.organizations}
                        defaultSortAsc={false}
                        handleSort={handleSort}
                        setRowsPerPage={setRowsPerPage}
                        onRowsPerPageFetchData={onRowsPerPageFetchData}
                        setSkip={setSkip}
                        rowsPerPage={rowsPerPage}
                        skip={skip}
                        loadingSearch={loadingSearch}
                        paginationTotal={organizationsCompleteData?.total}
                        currentPage={reset}
                        field={sortCol === "" ? "name" : sortCol}
                        order={sorting === "" ? "asc" : sorting}
                        onRowClicked={onRowClicked}
                        pointerOnHover={true}
                        onSelectedRowsChange={onSelectedRowsChange}
                        numberOfRowsSelected={numberOfRowsSelected}
                        clearSelectedRows={clearSelectedRows}

                      ></Datatable>
                    ) : (
                      <Datatable
                        type="dashboard"
                        toggleModal={toggleArchive}
                        data={[]}
                        defaultSortAsc={false}
                        handleSort={handleSort}
                        setRowsPerPage={setRowsPerPage}
                        onRowsPerPageFetchData={onRowsPerPageFetchData}
                        setSkip={setSkip}
                        rowsPerPage={rowsPerPage}
                        skip={skip}
                        loadingSearch={loadingSearch}
                        paginationTotal={organizationsCompleteData?.total}
                        currentPage={reset}
                        field={sortCol === "" ? "name" : sortCol}
                        order={sorting === "" ? "asc" : sorting}
                        pointerOnHover={true}
                        onSelectedRowsChange={onSelectedRowsChange}
                        numberOfRowsSelected={numberOfRowsSelected}
                        clearSelectedRows={clearSelectedRows}
                      ></Datatable>
                    )}
                  </CardBody>
                </Card>
              </div>
            </Container>
          </div>
        </div> : <div className="permissionWarning">User does not have permissions to view this screen!</div>}
    </React.Fragment>
  )
}

export default Dashboard
