import { createSlice } from "@reduxjs/toolkit"
import { ArchiveItems, saveCustomRolesPermissions } from "./thunk"
import { CustomRolesPermissionsStates } from "./types"

const initialState: CustomRolesPermissionsStates = {
  customRolesPermissionsSuccess: false,
  customRolesPermissionsLoading: false,
  customRolesPermissionsError: "",
  archiveItemsSuccess: false,
  archiveItemsLoading: false,
  archiveItemsError: "",
}
const customRolesPermissionsSlice = createSlice({
  name: "customRolesPermissions",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(saveCustomRolesPermissions.pending, state => {
        state.customRolesPermissionsLoading = true
        state.customRolesPermissionsSuccess = false
        state.customRolesPermissionsError = ""
      })
      .addCase(saveCustomRolesPermissions.fulfilled, (state, { payload }) => {
        state.customRolesPermissionsLoading = false
        state.customRolesPermissionsSuccess = true
        state.customRolesPermissionsError = ""
      })
      .addCase(saveCustomRolesPermissions.rejected, (state, action) => {
        state.customRolesPermissionsLoading = false
        state.customRolesPermissionsSuccess = false
        if (action.error) {
          state.customRolesPermissionsError = action.error.message as string
        } else {
          state.customRolesPermissionsError = "Error custom roles permission"
        }
      })
      .addCase(ArchiveItems.pending, state => {
        state.archiveItemsLoading = true
        state.archiveItemsSuccess = false
        state.archiveItemsError = ""
      })
      .addCase(ArchiveItems.fulfilled, (state, { payload }) => {
        state.archiveItemsLoading = false
        state.archiveItemsSuccess = true
        state.archiveItemsError = ""
      })
      .addCase(ArchiveItems.rejected, (state, action) => {
        state.archiveItemsLoading = false
        state.archiveItemsSuccess = false
        if (action.error) {
          state.archiveItemsError = action.error.message as string
        } else {
          state.archiveItemsError = "Error while moving to archive"
        }
      })
  },
  reducers: {
    resetCustomPermission(state) {
      state.customRolesPermissionsSuccess = false
      state.customRolesPermissionsLoading = false
      state.customRolesPermissionsError = ""
    },
    resetArchiveItems(state) {
      state.archiveItemsSuccess = false
      state.archiveItemsLoading = false
      state.archiveItemsError = ""
    },
  },
})

export const { resetCustomPermission, resetArchiveItems } = customRolesPermissionsSlice.actions
export default customRolesPermissionsSlice.reducer
