import { createAsyncThunk } from "@reduxjs/toolkit"
import { MyKnownError } from "~/models/Error.model";
import { Organization, OrgProp } from "~/models/Org.model";
import { Search } from "~/models/Search.model";
import { multipartRequest, postRequest } from "~/store/apiHelper"
import { CreateOrg, deleteLogo, Get, Logo, OrganizationByID } from "./types"

export const OrganizationById = createAsyncThunk<
  Organization,
  OrganizationByID,
  { rejectValue: MyKnownError }
>("organization/byId", async (data: OrganizationByID, thunkApi) => {
  return postRequest("/rpc/organizationsFindById", data, true, thunkApi)
});

export const AllOrganizations = createAsyncThunk<
  Organization[],
  Get,
  { rejectValue: MyKnownError }
>("organization/all", (data: Get, thunkApi) => {
  return postRequest("/rpc/organizationsGetAll", data, true, thunkApi)
});

// save organization 
export const saveOrganizations = createAsyncThunk<
  Organization[],
  OrgProp,
  { rejectValue: MyKnownError }
>("organization/save", (data: OrgProp, thunkApi) => {
  return postRequest("/rpc/organizationsCreate", data, true, thunkApi)
});
// update organization 
export const updateOrganizations = createAsyncThunk<
  Organization[],
  OrgProp,
  { rejectValue: MyKnownError }
>("organization/update", (data: OrgProp, thunkApi) => {
  return postRequest("/rpc/organizationsUpdate", data, true, thunkApi)
});

//Search organization
export const SearchOrganizations = createAsyncThunk<
  Organization[],
  Search,
  { rejectValue: MyKnownError }
>("organization/search", (data: Search, thunkApi) => {
  return postRequest("/rpc/organizationsSearch", data, true, thunkApi)
});

//Create Role 
export const CreateOrgDetail = createAsyncThunk<
  any,
  CreateOrg,
  { rejectValue: MyKnownError }
>("createOrg", (data: CreateOrg, thunkApi) => {
  return postRequest("/rpc/rolesCreate", data, true, thunkApi)
});

//Organization Logo
export const OrganizationLogo = createAsyncThunk<
  any,//TODO type check
  Logo,
  { rejectValue: MyKnownError }
>("organization/logo", (data: Logo, thunkApi) => {
  return multipartRequest("/rpc/organizationsUploadLogo", data, thunkApi)
});

//Delete Logo
export const DeleteOrganizationLogo = createAsyncThunk<
  any,//TODO type check
  deleteLogo,
  { rejectValue: MyKnownError }
>("organization/logoDelete", (data: deleteLogo, thunkApi) => {
  return postRequest("/rpc/organizationsDeleteLogo", data, true, thunkApi)
});

//Sort organization
export const SortOrganizations = createAsyncThunk<
  Organization[],
  Search,
  { rejectValue: MyKnownError }
>("organization/sort", (data: Search, thunkApi) => {
  return postRequest("/rpc/organizationsSearch", data, true, thunkApi)
});