import React, { FC, useEffect, useState } from "react";
import { Modal, Form, Label, Input } from "reactstrap";
import * as Yup from "yup"
import { useFormik } from "formik"
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { ChangeUserPassword } from "~/store/reducers/AuthSlice/thunks";
import { errorToast, successToast } from "~/utils/toast";
import { ResetUserPasswordStates } from "~/store/reducers/AuthSlice";

interface ResetPasswordProps {
  isOpen: boolean;
  toggle: () => void;
  userId: string;
}

const ResetPassword: FC<ResetPasswordProps> = ({ isOpen, toggle, userId }) => {
  const [showPass1, setShowPass1] = useState<boolean>(false);
  const [showPass2, setShowPass2] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const {
    errorPasswordChange: error,
    successPasswordChange: success,
    loadingPasswordChange: loading
  } = useAppSelector(state => state.AuthReducer)
  const validationNewPassword = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required('Please Enter New Password').min(8, "Password too Short!"),
      confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords do not match').min(8, "Password too Short!")
    }),
    onSubmit: values => {
      const body = {
        userId: userId,
        password: values.newPassword
      }
      dispatch(ChangeUserPassword(body));
    },
  });

  useEffect(() => {
    if (success) {
      dispatch(ResetUserPasswordStates());
      successToast('Password Changed successfully');
      toggle();
    }
    if (error) {
      dispatch(ResetUserPasswordStates());
      errorToast(error);
    }
  }, [success, error])

  return (
    <Modal
      // size="sm"
      style={{ width: '350px' }}
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-body card-container">
        <h5 className="mt-2">Enter new password</h5>
        <Form
          id="resetForm"
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validationNewPassword.handleSubmit()
            return false
          }}
        >
          <div className="mb-3 mt-2">
            <Label className="form-label">New Password</Label>
            <div className="passwordInput">
              <Input
                name="newPassword"
                value={validationNewPassword.values.newPassword || ""}
                type={showPass1 ? "text" : "password"}
                placeholder="Enter new password"
                onChange={validationNewPassword.handleChange}
                onBlur={validationNewPassword.handleBlur}
                className={
                  validationNewPassword.touched.newPassword &&
                    validationNewPassword.errors.newPassword
                    ? "form-control border-danger"
                    : "form-control"
                }
              />
              {showPass1 ? (
                <i
                  onClick={() => setShowPass1(false)}
                  className="fas fa-eye-slash eyeIcon"
                />
              ) : (
                <i
                  onClick={() => setShowPass1(true)}
                  className="fas fa-eye eyeIcon"
                />
              )}
            </div>
            {validationNewPassword.touched.newPassword &&
              validationNewPassword.errors.newPassword ? (
              <div className="error-container mt-1 mb-1">
                <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                <h6 className="text-danger mt-2 ms-2">
                  {validationNewPassword.errors.newPassword}
                </h6>
              </div>
            ) : null}
          </div>

          <div className="mb-3 mt-2">
            <Label className="form-label">Confirm Password</Label>
            <div className="passwordInput">
              <Input
                name="confirmPassword"
                value={validationNewPassword.values.confirmPassword || ""}
                type={showPass2 ? "text" : "password"}
                placeholder="Enter new password"
                onChange={validationNewPassword.handleChange}
                onBlur={validationNewPassword.handleBlur}
                className={
                  validationNewPassword.touched.confirmPassword &&
                    validationNewPassword.errors.confirmPassword
                    ? "form-control border-danger"
                    : "form-control"
                }
              />
              {showPass2 ? (
                <i
                  onClick={() => setShowPass2(false)}
                  className="fas fa-eye-slash eyeIcon"
                />
              ) : (
                <i
                  onClick={() => setShowPass2(true)}
                  className="fas fa-eye eyeIcon"
                />
              )}
            </div>
            {validationNewPassword.touched.confirmPassword &&
              validationNewPassword.errors.confirmPassword ? (
              <div className="error-container mt-1 mb-1">
                <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                <h6 className="text-danger mt-2 ms-2">
                  {validationNewPassword.errors.confirmPassword}
                </h6>
              </div>
            ) : null}
          </div>
          <div className="mt-4 d-grid mb-3">
            <button className="btn btn-primary btn-block" type="submit">
              Reset password
              {(loading) && (
                <i className="bx bx-loader bx-spin font-size-14 ms-2" />
              )}
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
export default ResetPassword;