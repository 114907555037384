import React, { useEffect, useState } from "react";
import { FC } from "react";
import { Input, Modal } from "reactstrap";
import { permissions } from "~/models/Roles.model";
import './userPermissions.scss'

interface PermissionProps {
  isOpen: boolean;
  toggle: () => void;
  permissions: permissions;
  roleName: string
  setPermissions: any
}
interface CheckBoxes {
  view: boolean
  edit: boolean
}
interface TableRow {
  data: CheckBoxes[]
}
const UserPermissionModal: FC<PermissionProps> = ({ isOpen, toggle, permissions, roleName, setPermissions }) => {

  return (
    <Modal
      size="lg"
      style={{ width: '750px' }}
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-body p-0">
        <div className="w-100">
          <div className="head-container font-size-13">
            <h5 className="heading-1 ms-3 w-75">Tasks</h5>
            <h5 className="heading-1 text-center">
              {roleName}
            </h5>
          </div>
          <div className="head-container2">
            <h6 className="heading-1 w-75"></h6>
            <div className="heading-1">
              <h6 className="color-gray">View</h6>
              <h6 className="color-gray">Edit</h6>
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Organization details</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["organization-details-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["organization-details-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["organization-details-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["organization-details-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Roles and permissions</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["roles-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["roles-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["roles-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["roles-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Worker management</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["practitioner-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["practitioner-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["practitioner-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["practitioner-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Patient management</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["patient-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["patient-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["patient-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["patient-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Dashboard: Overview</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-overview-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-overview-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-overview-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-overview-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Dashboard: Blood pressure</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-blood-pressure-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-blood-pressure-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-blood-pressure-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-blood-pressure-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Dashboard: Blood sugar</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-blood-glucose-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-blood-glucose-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-blood-glucose-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-blood-glucose-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Dashboard: BMI</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-bmi-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-bmi-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["dashboard-bmi-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["dashboard-bmi-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2">
            <div className="heading-2">
              <h6 >Data collection</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["data-collection-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["data-collection-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["data-collection-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["data-collection-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
          <div className="head-container2 mb-5">
            <div className="heading-2">
              <h6>Archive Data</h6>
            </div>
            <div className="heading-1">
              <Input
                type="checkbox"
                defaultChecked={permissions["archive-view"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["archive-view"] = checked;
                  setPermissions(data);
                }}
              />
              <Input
                type="checkbox"
                defaultChecked={permissions["archive-edit"]}
                onChange={e => {
                  const { checked } = e.target
                  let data = JSON.parse(JSON.stringify(permissions))
                  data["archive-edit"] = checked;
                  setPermissions(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default UserPermissionModal;