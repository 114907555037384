import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Organization } from "~/models/Org.model"
import Async from "~/utils/Async"
import {
  AllOrganizations,
  OrganizationById,
  saveOrganizations,
  updateOrganizations,
  SearchOrganizations,
  CreateOrgDetail,
  OrganizationLogo,
  DeleteOrganizationLogo,
} from "./thunk"
import { Organizations } from "./types"

const initialState: Organizations = {
  organizations: [],
  loading: false,
  error: "",
  organizationsByIdObj: {} as Organization,
  //save organization
  successSave: false,
  errorSave: "",
  loadingSave: false,
  //Update organization
  successUpdate: false,
  errorUpdate: "",
  loadingUpdate: false,
  screen: "edit",
  //search organization
  successSearch: false,
  errorSearch: "",
  loadingSearch: false,
  organizationsSearch: [],
  organizationsCompleteData: [],
  isSearch: false,
  //Create Role
  successCreateRole: false,
  errorCreateRole: "",
  loadingCreateRole: false,
  //Organization Logo
  successlogo: false,
  errorlogo: "",
  loadinglogo: false,
  //pagination
  take: 0,
  searchData: "",
  //Delete Organization Logo
  successDeletelogo: false,
  errorDeletelogo: "",
  loadingDeletelogo: false,
  searching: false,
  localData: false
}
const organizationSlice = createSlice({
  name: "organization",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(OrganizationById.pending, state => {
        state.loading = true
        state.error = ""
      })
      .addCase(OrganizationById.fulfilled, (state, { payload }) => {
        state.loading = false
        state.error = ""
        state.organizationsByIdObj = payload
      })
      .addCase(OrganizationById.rejected, (state, action) => {
        state.loading = false
        if (action.error) {
          state.error = action.error.message
        } else {
          state.error = "Error getting organization"
        }
      })
      .addCase(AllOrganizations.pending, state => {
        state.loading = true
        state.error = ""
      })
      .addCase(AllOrganizations.fulfilled, (state, { payload }) => {
        state.loading = false
        state.error = ""
        state.organizations = payload
      })
      .addCase(AllOrganizations.rejected, (state, action) => {
        state.loading = false
        if (action.error) {
          state.error = action.error.message
        } else {
          state.error = "Error getting organizations"
        }
      })
      //save
      .addCase(saveOrganizations.pending, state => {
        state.loadingSave = true
        state.errorSave = ""
        state.successSave = false
      })
      .addCase(saveOrganizations.fulfilled, state => {
        state.loadingSave = false
        state.errorSave = ""
        state.successSave = true
      })
      .addCase(saveOrganizations.rejected, (state, action) => {
        state.loadingSave = false
        state.successSave = false
        if (action.error) {
          state.errorSave = action.error.message as string
        } else {
          state.errorSave = "Error saving organizations"
        }
      })
      //Update
      .addCase(updateOrganizations.pending, state => {
        state.loadingUpdate = true
        state.errorUpdate = ""
        state.successUpdate = false
      })
      .addCase(updateOrganizations.fulfilled, state => {
        state.loadingUpdate = false
        state.errorUpdate = ""
        state.successUpdate = true
      })
      .addCase(updateOrganizations.rejected, (state, action) => {
        state.loadingUpdate = false
        state.successUpdate = false
        if (action.error) {
          state.errorUpdate = action.error.message as string
        } else {
          state.errorUpdate = "Error updating organizations"
        }
      })
      //search
      .addCase(SearchOrganizations.pending, state => {
        state.loadingSearch = true
        state.errorSearch = ""
        state.successSearch = false
      })
      .addCase(SearchOrganizations.fulfilled, (state, { payload }) => {
        state.loadingSearch = false
        state.errorSearch = ""
        state.successSearch = true
        // state.isSearch ? (state.organizationsSearch = payload) : null
        // !state.isSearch ? (state.organizationsCompleteData = payload) : null
        state.organizationsCompleteData = payload

      })
      .addCase(SearchOrganizations.rejected, (state, action) => {
        state.loadingSearch = false
        state.successSearch = false

        if (action.error) {
          state.errorSearch = action.error.message as string
        } else {
          state.errorSearch = "Error saving organizations"
        }
      })
      //Create Role
      .addCase(CreateOrgDetail.pending, state => {
        state.loadingCreateRole = true
        state.errorCreateRole = ""
        state.successCreateRole = false
      })
      .addCase(CreateOrgDetail.fulfilled, (state, { payload }) => {
        state.loadingCreateRole = false
        state.errorCreateRole = ""
        state.successCreateRole = true
      })
      .addCase(CreateOrgDetail.rejected, (state, action) => {
        state.loadingCreateRole = false
        state.successCreateRole = false

        if (action.error) {
          state.errorCreateRole = action.error.message as string
        } else {
          state.errorCreateRole = "Error creating organizations"
        }
      })
      //Organization Logo
      .addCase(OrganizationLogo.pending, state => {
        state.loadinglogo = true
        state.errorlogo = ""
        state.successlogo = false
      })
      .addCase(OrganizationLogo.fulfilled, (state, { payload }) => {
        Async.setItem("@organization", payload)
        state.loadinglogo = false
        state.errorlogo = ""
        state.successlogo = true
      })
      .addCase(OrganizationLogo.rejected, (state, action) => {
        state.loadinglogo = false
        state.successlogo = false
        if (action.error) {
          state.errorlogo = action.error.message as string
        } else {
          state.errorlogo = "Error uploading organization logo"
        }
      })
      //Delete Organization Logo
      .addCase(DeleteOrganizationLogo.pending, state => {
        state.loadingDeletelogo = true
        state.errorDeletelogo = ""
        state.successDeletelogo = false
      })
      .addCase(DeleteOrganizationLogo.fulfilled, (state, { payload }) => {
        state.loadingDeletelogo = false
        state.errorDeletelogo = ""
        state.successDeletelogo = true
        Async.setItem("@organization", payload)
      })
      .addCase(DeleteOrganizationLogo.rejected, (state, action) => {
        state.loadingDeletelogo = false
        state.successDeletelogo = false

        if (action.error) {
          state.errorDeletelogo = action.error.message as string
        } else {
          state.errorDeletelogo = "Error uploading organization logo"
        }
      })
  },

  reducers: {
    ResetOrganizationStates: state => {
      state.organizations = []
      state.loading = false
      state.error = ""
    },
    ResetSaveOrganizationStates: state => {
      state.successSave = false
      state.errorSave = ""
      state.loadingSave = false
    },
    organizationUpdateReset: state => {
      state.successUpdate = false
      state.errorUpdate = ""
      state.loadingUpdate = false
    },
    createRoleReset: state => {
      state.loadingCreateRole = false
      state.errorCreateRole = ""
      state.successCreateRole = false
    },
    SearchResetOrganizationStates: state => {
      //search organization
      state.successSearch = false
      state.errorSearch = ""
      state.loadingSearch = false
      state.organizationsSearch = []
      state.organizationsCompleteData = []
      state.isSearch = false
    },
    LogoStatesReset: state => {
      state.loadinglogo = false
      state.successlogo = false
      state.errorlogo = ""
    },
    DeleteLogoStatesReset: state => {
      state.loadingDeletelogo = false
      state.successDeletelogo = false
      state.errorDeletelogo = ''
    },
    ChangeOrganizationView: (state, action) => {
      state.screen = action.payload
    },
    setIsSearchToFalse(state) {
      state.isSearch = false
    },
    setIsSearchToTrue(state) {
      state.isSearch = true
    },
    setTakeReducer(state, action) {
      state.take = action.payload;
    },
    setSearchData(state, action) {
      state.searchData = action.payload;
    },
    setSearching(state, action) {
      state.searching = action.payload;
    },
    setLocalData(state, { payload }) {
      state.localData = payload;
    },
  },
})

export const {
  createRoleReset,
  ResetOrganizationStates,
  ResetSaveOrganizationStates,
  organizationUpdateReset,
  ChangeOrganizationView,
  SearchResetOrganizationStates,
  setIsSearchToFalse,
  setIsSearchToTrue,
  LogoStatesReset,
  setTakeReducer,
  setSearchData,
  DeleteLogoStatesReset,
  setSearching,
  setLocalData,
} = organizationSlice.actions
export default organizationSlice.reducer
