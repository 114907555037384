import { useHistory } from "react-router-dom"
import React, { FC, useEffect, useState } from "react"
import { Form, FormFeedback, Input, Spinner } from "reactstrap"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import DatePicker from "reactstrap-date-picker"
import { useFormik } from "formik"
import * as Yup from "yup"
import "./addEditAgentManagement.scss"
import UserPermissionModal from "~/components/Common/Modals/UserPermissions"
import ResetPassword from "~/components/Common/Modals/Authentication/ResetPassword"
import { WithRouterProps } from "~/models/WithRouterProps.model"
import {
  BatchAddIdentifier,
  BatchDeleteIdentifier,
  BatchEditIdentifier,
  CreateAgent,
} from "~/store/reducers/Agent/thunk"
import {
  ResetAddIdentifier,
  ResetAgentStates,
  ResetDeleteIdentifier,
  ResetEditIdentifier,
} from "~/store/reducers/Agent"
import { errorToast, successToast } from "~/utils/toast"
import Avatar from "react-avatar"
import { OrganizationRoles, UpdateUserRole } from "~/store/reducers/Roles/thunk"
import { CustomRolesPermissions, permissions } from "~/models/Roles.model"
import { isValidPhoneNumber } from "libphonenumber-js"
import {
  Agent,
  batchAddIdentifier,
  identifiersEdit,
} from "~/store/reducers/Agent/types"
import moment from "moment"
import { EditPatient } from "~/store/reducers/Patient/thunk"
import {
  contacts,
  IdentifiersType,
  nextOfKinBatch,
} from "~/models/Identifier.model"
import { ResetPatientUpdateStates } from "~/store/reducers/Patient"
import { GetUsers, GetUserSelf } from "~/store/reducers/Select/thunk"
import ConfirmPasswordResetModal from "~/components/Common/Modals/confirmPasswordResetModal"
import Async from "~/utils/Async"
import { resetSelectActions, setAgentStatus } from "~/store/reducers/Select"
import { saveCustomRolesPermissions } from "~/store/reducers/Permission/thunk"
import { resetCustomPermission } from "~/store/reducers/Permission"
import {
  BatchAddNextOfKin,
  BatchEditNextOfKin,
  DeleteNextOfKin,
} from "~/store/reducers/NextOfKin/thunk"
import {
  ResetBatchAddNextOfKin,
  ResetBatchEditNextOfKin,
  ResetDeleteNextOfKin,
} from "~/store/reducers/NextOfKin"
import { ResetUpdateUserRole } from "~/store/reducers/Roles"
import { GenerateUsername } from "~/store/reducers/AuthSlice/thunks"
import { ResetGenerateUsername } from "~/store/reducers/AuthSlice"
import ConfirmationModal from "~/components/Common/Modals/confirmationModal"
import { usersSendTemporaryPassword } from "~/store/reducers/AuthSlice/types"
interface PageType {
  page: string
  data: Agent | any
}
const AddEditAgentManagement: FC<WithRouterProps> = props => {
  const { location } = props
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const { roles: data } = useAppSelector(state => state.RolesReducer)
  const [permissionModel, setPermissionModel] = useState<boolean>(false)
  const togglePermissionModal = () => setPermissionModel(!permissionModel)

  const [resetPasswordModel, setResetPasswordModel] = useState<boolean>(false)
  const toggleResetPasswordModal = () =>
    setResetPasswordModel(!resetPasswordModel)
  const [self, setSelf] = useState<boolean>(false)
  const [addPage, setAddPage] = useState<boolean>(false)
  const [editData, setEditData] = useState<Agent>()
  const [email, setEmail] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [emailID, setEmailID] = useState<string>("")
  const [phoneNumberID, setPhoneNumberID] = useState<string>("")

  const [nextOfKin, setNextOfKin] = useState<contacts[]>([])
  const [permissions, setPermissions] = useState<permissions>()
  const [rolesNameList, setRolesNameList] = useState<string[]>()
  const [roleName, setRoleName] = useState<string>("")
  const [selectedRole, setSelectedRole] = useState<number>(0)
  const [nextOfKinErrors, setNextOfKinErrors] = useState<any[]>([])
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
    false
  )
  const [deleteIndex, setDeleteIndex] = useState<number>(0)
  const [confirmPasswordResetModal, setConfirmPasswordResetModal] = useState<
    boolean
  >(false)
  const [dataResetPass, setDataResetPass] = useState<
    usersSendTemporaryPassword
  >({
    mode: undefined,
    userId: undefined,
    email: undefined,
    phone: undefined,
  })
  const { generatedName, errorGenerateUsername } = useAppSelector(
    state => state.AuthReducer
  )
  const [workerEdit, setWorkerEdit] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [check, setCheck] = useState<boolean>(false)

  //states custom permisiion
  const {
    customRolesPermissionsSuccess,
    customRolesPermissionsError,
    customRolesPermissionsLoading,
  } = useAppSelector(state => state.PermissionReducer)

  const { updateLoading, updateSuccess, updateError } = useAppSelector(
    state => state.PatientReducer
  )

  const {
    loading,
    error,
    success,
    loadingIdentifier,
    successIdentifier,
    errorIdentifier,
    loadingAddIdentifier,
    successAddIdentifier,
    errorAddIdentifier,
    loadingDeleteIdentifier,
    successDeleteIdentifier,
    errorDeleteIdentifier,
  } = useAppSelector(state => state.AgentReducer)

  const {
    successKinBatch,
    errorKinBatch,
    loadingKinBatch,
    successDeleteKin,
    errorDeleteKin,
    loadingDeleteKin,
    loadingKinBatchEdit,
    errorKinBatchEdit,
    successKinBatchEdit,
  } = useAppSelector(state => state.NextOfKinReducer)
  const {
    success: updatedDataSuccess,
    loading: updatedDataLoading,
    error: updatedDataError,
    agent,
  } = useAppSelector(state => state.SelectReducer)

  const {
    userRoleUpdateSuccess,
    userRoleUpdateError,
    userRoleUpdateLoading,
  } = useAppSelector(state => state.RolesReducer)

  const toggleConfirmPasswordResetModal = () =>
    setConfirmPasswordResetModal(!confirmPasswordResetModal)

  const [access, setAccess] = useState<boolean>(true)

  const fetchUpdatedData = (id?: string) => {
    const organizationLocalData = Async.getItem("@organization")
    const page: PageType = location?.state as PageType
    dispatch(setAgentStatus(""))

    Async.getItem("@auth")?.profile?.id === page?.data?.userProfileId
      ? dispatch(
        GetUserSelf({
          practitioner: {
            include: {
              vitalsMeasurements: false,
              identifiers: true,
              nextOfKin: true,
              roleAssignments: true,
            },
          },
        })
      )
      : dispatch(
        GetUsers({
          practitioner: {
            filter: {
              id: id ? id : editData?.id,
              organizationId: organizationLocalData?.id,
            },
            include: {
              vitalsMeasurements: false,
              identifiers: true,
              nextOfKin: true,
              roleAssignments: true,
            },
          },
        })
      )
  }
  useEffect(() => {
    Async.setItem("self", self)
  }, [self])
  useEffect(() => {
    const page: PageType = location?.state as PageType
    setSelf(Async.getItem("@auth")?.profile?.id === page?.data?.userProfileId)
    dispatch(ResetGenerateUsername())
    if (page?.page === "add") {
      setAddPage(true)
    } else if (page?.page === "edit") {
      // setEditData(page?.data)
      setAddPage(false)
      //fetch updated data on page load
      if (!updatedDataLoading) {
        fetchUpdatedData(page?.data?.id!)
      }
    }
  }, [location])

  useEffect(() => {
    validation.setFieldValue("phoneNumber", phoneNumber)
  }, [phoneNumber])

  useEffect(() => {
    if (editData && Object.keys(editData).length > 0 && !addPage) {
      //set identifiers
      editData?.user?.identifiers?.forEach((element: IdentifiersType) => {
        if (element.type === "EMAIL") {
          setEmail(element.identifier)
          setEmailID(element.id)
        }
        if (element.type === "PHONE_NUMBER") {
          setPhoneNumber(element.identifier)
          validation.setFieldValue("phoneNumber", element.identifier)
          setPhoneNumberID(element.id)
        }
      })
      //set kin
      let newKin: contacts[] = []
      let list: any = []

      editData?.user?.profile?.nextOfKin?.forEach(
        (kin: contacts, index: number) => {
          const body = {
            // email: false,
            firstName: false,
            lastName: false,
            phone: false,
            relationship: false,
          }

          newKin.push(kin)
          list.push(body)
        }
      )
      setNextOfKinErrors(list)
      setNextOfKin(newKin)
    }
    setSelf(Async.getItem("@auth")?.profile?.id === editData?.userProfileId)
  }, [editData])

  useEffect(() => {
    if (updatedDataSuccess && agent && Object.keys(agent).length > 0) {
      setEditData(agent)
      dispatch(resetSelectActions())
    } else if (updatedDataError) {
      errorToast("Error Fetching updated data")
      // history.push("/agentManagement") // changed for betterment of mankind
      history.goBack()
      dispatch(resetSelectActions())
    }
  }, [updatedDataSuccess, updatedDataError])

  useEffect(() => {
    const superUser = localStorage.getItem("@super")
    const per = Async.getItem("@auth")
    setWorkerEdit(per.role.permissions["practitioner-edit"])

    if (superUser === "true") {
      var body: HTMLElement = document.body
      dispatch(setSecondaryBarTrue())
      if (!collapsed) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      const data = Async.getItem("@auth")
      setAccess(data?.role?.permissions["practitioner-view"])
      dispatch(setSecondaryBarTrue())
    }
    //get roles
    const organizationLocalData = Async.getItem("@organization")
    const body2 = {
      organizationId: organizationLocalData.id,
    }
    //here
    dispatch(OrganizationRoles(body2))
  }, [])

  useEffect(() => {
    //role dropdown values set
    if (data?.items) {
      let tempArr: string[] = []
      data?.items?.forEach((item: any, index: number) => {
        tempArr.push(item.name)
        if (
          editData &&
          item.name === editData?.user?.roleAssignments[0]?.role?.name
        ) {
          //set role
          setSelectedRole(index)
        }
      })

      setRolesNameList(tempArr)
    }
  }, [data, editData])

  useEffect(() => {

    if (rolesNameList && editData && data) {
      let check = true;
      data?.items.forEach((val: any) => {
        if (val.name === rolesNameList[selectedRole]) {
          setRoleName(rolesNameList[selectedRole])

          if (
            editData?.user?.roleAssignments[0]?.role?.name ===
            rolesNameList[selectedRole]
          ) {
            //set user default permissions
            if (editData?.user?.roleOverride?.permissions && Object.keys(editData?.user?.roleOverride?.permissions)?.length > 0) {
              setPermissions(
                editData?.user?.roleOverride?.permissions
              )
            } else if (editData?.user?.roleAssignments[0]?.role?.permissions) {
              setPermissions(
                editData?.user?.roleAssignments[0]?.role.permissions
              )
            }
            check = false
          }
        }
      })
      if (check) {
        rolesNameList &&
          data?.items.forEach((val: any) => {
            if (val.name === rolesNameList[selectedRole]) {
              setPermissions(val.permissions)
            }
          })
      }
    }
  }, [selectedRole, rolesNameList])

  //succe/eRR set
  useEffect(() => {
    if (success) {
      dispatch(ResetAgentStates())
      successToast("Worker added successfully")
      history.push("/agentManagement")
    } else if (error) {
      errorToast(error)
      dispatch(ResetAgentStates())
    }
  }, [success, error])

  useEffect(() => {
    //edit Data states
    if (updateSuccess) {
      successToast("Worker information updated")
      if (
        !loadingKinBatch ||
        !loadingKinBatchEdit ||
        !loadingIdentifier ||
        !userRoleUpdateLoading ||
        !loadingAddIdentifier ||
        !loadingDeleteIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetPatientUpdateStates())
      }
    }
    if (updateError) {
      errorToast(updateError)
    }
    //adding kin states
    if (successKinBatch) {
      successToast("Kin added successfully")
      if (
        !updateLoading ||
        !loadingKinBatchEdit ||
        !loadingIdentifier ||
        !userRoleUpdateLoading ||
        !loadingAddIdentifier ||
        !loadingDeleteIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetBatchAddNextOfKin())
      }
    } else if (errorKinBatch) {
      errorToast(errorKinBatch)
    }
    //edit kin states
    if (successKinBatchEdit) {
      successToast("Kin information updated")
      if (
        !updateLoading ||
        !loadingKinBatch ||
        !loadingIdentifier ||
        !userRoleUpdateLoading ||
        !loadingAddIdentifier ||
        !loadingDeleteIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetBatchEditNextOfKin())
      }
    } else if (errorKinBatchEdit) {
      errorToast(errorKinBatchEdit)
    }
    //edit identifier states
    if (successIdentifier) {
      successToast("Identifier Updated")
      if (
        !updateLoading ||
        !loadingKinBatch ||
        !loadingKinBatchEdit ||
        !userRoleUpdateLoading ||
        !loadingAddIdentifier ||
        !loadingDeleteIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetEditIdentifier())
      }
    } else if (errorIdentifier) {
      errorToast(errorIdentifier)
    }
    //add identifier states
    if (successAddIdentifier) {
      successToast("Identifier Added")
      if (
        !updateLoading ||
        !loadingKinBatch ||
        !loadingKinBatchEdit ||
        !userRoleUpdateLoading ||
        !loadingIdentifier ||
        !loadingDeleteIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetAddIdentifier())
      }
    } else if (errorAddIdentifier) {
      errorToast(errorAddIdentifier)
    }
    //delete identifier states
    if (successDeleteIdentifier) {
      successToast("Identifier deleted")
      if (
        !updateLoading ||
        !loadingKinBatch ||
        !loadingKinBatchEdit ||
        !userRoleUpdateLoading ||
        !loadingIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetDeleteIdentifier())
      }
    } else if (errorDeleteIdentifier) {
      errorToast(errorDeleteIdentifier)
    }
    //edit role states
    if (userRoleUpdateSuccess) {
      successToast("Worker role updated")
      // permissions update
      if (
        editData?.user?.profile?.userId &&
        permissions &&
        editData?.user?.roleOverride?.permissions !== permissions
      ) {
        const orgData = Async.getItem("@organization")

        const body: CustomRolesPermissions = {
          organizationId: orgData?.id,
          userId: editData?.user?.profile?.userId,
          permissions: permissions,
        }
        dispatch(saveCustomRolesPermissions(body))
      }
      if (
        !updateLoading ||
        !loadingKinBatch ||
        !loadingKinBatchEdit ||
        !loadingIdentifier ||
        !loadingAddIdentifier ||
        !loadingDeleteIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetUpdateUserRole())
      }
    } else if (userRoleUpdateError) {
      errorToast(userRoleUpdateError)
    }
    //on any error
    if (
      updateError ||
      errorKinBatch ||
      errorKinBatchEdit ||
      errorIdentifier ||
      errorAddIdentifier ||
      userRoleUpdateError ||
      errorDeleteIdentifier
    ) {
      dispatch(ResetPatientUpdateStates())
      dispatch(ResetBatchAddNextOfKin())
      dispatch(ResetBatchEditNextOfKin())
      dispatch(ResetEditIdentifier())
      dispatch(ResetAddIdentifier())
      dispatch(ResetUpdateUserRole())
      dispatch(ResetDeleteIdentifier())
    }
    // on success
    if (
      updateSuccess &&
      (successKinBatch || successKinBatchEdit) &&
      userRoleUpdateSuccess
    ) {
      //check again for identifier
      dispatch(ResetPatientUpdateStates())
      dispatch(ResetBatchAddNextOfKin())
      dispatch(ResetBatchEditNextOfKin())
      dispatch(ResetEditIdentifier())
      dispatch(ResetAddIdentifier())
      dispatch(ResetUpdateUserRole())
      dispatch(ResetDeleteIdentifier())
      if (!updatedDataLoading) {
        fetchUpdatedData()
      }
    }
  }, [
    updateError,
    updateSuccess,
    successKinBatch,
    errorKinBatch,
    successIdentifier,
    errorIdentifier,
    successKinBatchEdit,
    errorKinBatchEdit,
    userRoleUpdateSuccess,
    userRoleUpdateError,
    successAddIdentifier,
    errorAddIdentifier,
    successDeleteIdentifier,
    errorDeleteIdentifier,
  ])

  useEffect(() => {
    if (successDeleteKin) {
      fetchUpdatedData()
      dispatch(ResetDeleteNextOfKin())
      successToast("Kin deleted successfully!")
      toggleRemove()
    } else if (errorDeleteKin) {
      dispatch(ResetDeleteNextOfKin())
      errorToast(errorDeleteKin)
    }
  }, [successDeleteKin, errorDeleteKin])

  useEffect(() => {
    if (customRolesPermissionsSuccess) {
      fetchUpdatedData()
      successToast("Worker permissions updated")
      dispatch(resetCustomPermission())
    } else if (customRolesPermissionsError) {
      errorToast(customRolesPermissionsError)
      dispatch(resetCustomPermission())
    }
  }, [customRolesPermissionsSuccess, customRolesPermissionsError])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: !addPage ? `${editData?.user?.profile?.firstName}` : "",
      middleName: !addPage
        ? `${editData?.user?.profile?.middleName === null
          ? ""
          : editData?.user?.profile?.middleName
        }`
        : "",
      lastName: !addPage ? `${editData?.user?.profile?.lastName}` : "",
      username: !addPage ? `${editData?.user?.username}` : "",
      phoneNumber: !addPage ? `` : "", //todo check for error
      address: !addPage
        ? `${editData?.user?.profile?.address
          ? editData?.user?.profile?.address
          : ""
        }`
        : "",
      birthDate: !addPage
        ? `${editData?.user?.profile?.birthDate === null ||
          editData?.user?.profile?.birthDate === undefined
          ? ""
          : moment(editData?.user?.profile?.birthDate).format("yyyy-MM-DD")
        }`
        : "",
      gender: !addPage ? `${editData?.user?.profile?.gender}` : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^(?=.*\S).+$/, "Only spaces are not allowed")
        .required("Please enter first name"),
      lastName: Yup.string()
        .matches(/^(?=.*\S).+$/, "Only spaces are not allowed")
        .required("Please enter last name"),
      username: Yup.string()
        .min(5, "Too Short!")
        .max(20, "Too Long!")
        .matches(
          /^(?!\d)(?!.*-.*-)(?!.*-$)(?!-)[a-zA-Z0-9-]{5,20}$/,
          "Username must start with a letter. Contains only letters, numbers, and dashes or hyphens."
        )
        .required("Please enter username"),
      birthDate: Yup.string()
        .required("Please enter date of birth")
        .test(
          "Only past dates check",
          "Date cannot be in future.",
          e => (e && new Date(e) < new Date()) || false
        ),
      phoneNumber: Yup.string().test(
        "Required check",
        "Phone number is invalid",
        e => isValidPhoneNumber(phoneNumber)
      ),
      gender: Yup.string().required("Please select gender"),
    }),
    onSubmit: values => {
      if (addPage) {
        const localData = localStorage.getItem("@organization")
        if (localData) {
          if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${email}`) &&
            email!?.length > 0
          ) {
            setEmailError(true)
          } else {
            const data = {
              organizationId: JSON.parse(localData)?.id,
              username: values.username,
              identifiers: {
                email: email === "" ? undefined : email,
                phone: phoneNumber,
              },
              role: rolesNameList && rolesNameList[selectedRole],
              profile: {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                birthDate: moment.utc(new Date(values.birthDate)).toISOString(),
                gender: values.gender,
                address: values.address,
              },
            }
            dispatch(CreateAgent(data))
          }
        }
      } else if (!addPage) {
        let bool = true
        let data23 = [...nextOfKinErrors]

        nextOfKin.forEach((val, i) => {
          if (
            val.firstName === "" ||
            !val.firstName.replace(/\s/g, "").length
          ) {
            data23[i].firstName = true

            bool = false
          }
          if (val.lastName === "" || !val.lastName.replace(/\s/g, "").length) {
            data23[i].lastName = true

            bool = false
          }
          let check = isValidPhoneNumber(val.phone)
          if (val.phone === "" || !check) {
            data23[i].phone = true

            bool = false
          }
          if (val.relationship === "") {
            data23[i].relationship = true
            bool = false
          }
          if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
              `${val.email}`
            ) &&
            val?.email!?.length > 0
          ) {
            data23[i].email = true
            bool = false
          }
        })

        if (
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${email}`) &&
          email!?.length > 0
        ) {
          setEmailError(true)
          bool = false
        }
        setNextOfKinErrors(data23)

        if (bool) {
          //personal data
          let mid =
            editData?.user?.profile?.middleName === null
              ? ""
              : editData?.user?.profile?.middleName
          if (
            values.firstName !== editData?.user?.profile?.firstName ||
            values.middleName !== mid ||
            values.lastName !== editData?.user?.profile?.lastName ||
            values.address !== editData?.user?.profile?.address ||
            values.gender !== editData?.user?.profile?.gender ||
            !editData?.user?.profile?.birthDate.includes(values.birthDate)
          ) {
            if (editData?.user?.profile?.id) {
              const data = {
                id: editData?.user?.profile?.id,
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                birthDate: moment.utc(new Date(values.birthDate)).toISOString(),
                gender: values.gender,
                address: values.address,
              }
              dispatch(EditPatient(data))
            }
          }

          //role update
          if (
            editData &&
            editData?.user?.roleAssignments[0]?.role.name !== roleName
          ) {
            const orgData = Async.getItem("@organization")

            dispatch(
              UpdateUserRole({
                roleId: data?.items[selectedRole]?.id,
                userId: `${editData?.user?.profile?.userId}`,
                organizationId: orgData?.id,
              })
            )
          }
          // permissions update
          if (
            editData &&
            editData?.user?.roleAssignments[0]?.role.name === roleName &&
            editData?.user?.profile?.userId &&
            permissions &&
            editData?.user?.roleOverride?.permissions !== permissions
          ) {
            const orgData = Async.getItem("@organization")

            const body: CustomRolesPermissions = {
              organizationId: orgData?.id,
              userId: editData?.user?.profile?.userId,
              permissions: permissions,
            }
            dispatch(saveCustomRolesPermissions(body))
          }
          //kin add
          if (
            editData &&
            nextOfKin.length > editData?.user?.profile?.nextOfKin?.length!
          ) {
            let tempKin: nextOfKinBatch
            tempKin = {
              userProfileId: editData?.userProfileId,
              contacts: [],
            }
            nextOfKin.forEach((kin, index) => {
              if (index >= editData?.user?.profile?.nextOfKin?.length!) {
                // !kin?.middleName && delete kin.middleName
                // !kin?.email && delete kin.email
                if (!kin?.email) {
                  kin.email = null
                }
                tempKin.contacts.push(kin)
              }
            })
            dispatch(BatchAddNextOfKin(tempKin))
          }
          //edit kin
          else if (
            editData &&
            nextOfKin.length === editData?.user?.profile?.nextOfKin?.length!
          ) {
            let tempcontacts: contacts[]
            // let valid = true
            let changed = false
            tempcontacts = []
            nextOfKin.forEach((kin, i) => {
              //check for changes
              if (
                kin.firstName !==
                editData?.user?.profile?.nextOfKin![i]?.firstName ||
                kin.middleName !==
                editData?.user?.profile?.nextOfKin![i]?.middleName ||
                kin.lastName !==
                editData?.user?.profile?.nextOfKin![i]?.lastName ||
                kin.email !== editData?.user?.profile?.nextOfKin![i]?.email ||
                kin.phone !== editData?.user?.profile?.nextOfKin![i]?.phone ||
                kin.relationship !==
                editData?.user?.profile?.nextOfKin![i]?.relationship
              ) {
                //data changed
                changed = true
                delete kin.userProfileId
                delete kin.address
                //delete optional fields if empty
                // !kin?.middleName && delete kin.middleName
                // !kin?.email && delete kin.email
                if (!kin?.email) {
                  kin.email = null
                }
                tempcontacts.push(kin)
              }
            })
            if (changed) {
              dispatch(BatchEditNextOfKin({ contacts: tempcontacts }))
            }
          }
          //edit identifier
          let identifierChanged = false
          let identifierAdd = false
          let tempIdentifier: identifiersEdit[] = []
          let tempAddIdentifier: identifiersEdit[] = []

          editData?.user?.identifiers.forEach((identifier, index) => {
            if (
              email !== undefined &&
              email !== identifier.identifier &&
              identifier.type === "EMAIL"
            ) {
              if (emailID) {
                if (email) {
                  identifierChanged = true
                  tempIdentifier.push({
                    id: emailID,
                    type: "EMAIL",
                    value: email,
                  })
                } else {
                  dispatch(BatchDeleteIdentifier({ identifiers: [emailID] }))
                }
              }
            }
            if (
              phoneNumber !== identifier.identifier &&
              identifier.type === "PHONE_NUMBER"
            ) {
              if (phoneNumberID) {
                identifierChanged = true
                tempIdentifier.push({
                  id: phoneNumberID,
                  type: "PHONE_NUMBER",
                  value: phoneNumber,
                })
              }
            }
          })
          if (
            phoneNumber &&
            editData?.user?.identifiers &&
            !(
              editData?.user?.identifiers?.filter(
                i => i.type === "PHONE_NUMBER"
              )?.length > 0
            )
          ) {
            //add
            identifierAdd = true
            tempAddIdentifier.push({
              type: "PHONE_NUMBER",
              value: phoneNumber,
            })
          }
          if (
            email &&
            editData?.user?.identifiers &&
            !(
              editData?.user?.identifiers?.filter(i => i.type === "EMAIL")
                ?.length > 0
            )
          ) {
            //add
            identifierAdd = true
            tempAddIdentifier.push({
              type: "EMAIL",
              value: email,
            })
          }
          if (identifierAdd) {
            let body: batchAddIdentifier = {
              userId: `${editData?.user?.profile?.userId}`,
              identifiers: tempAddIdentifier,
            }
            self && delete body.userId
            dispatch(BatchAddIdentifier(body))
          }
          if (identifierChanged) {
            dispatch(BatchEditIdentifier({ identifiers: tempIdentifier }))
          }
        }
      }
    },
  })

  useEffect(() => {
    if (generatedName) {
      validation.setFieldValue("username", generatedName)
    }
  }, [generatedName])

  useEffect(() => {
    if (errorGenerateUsername) {
      errorToast(errorGenerateUsername)
    }
  }, [errorGenerateUsername])

  const toggleRemove = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const onConfirm = () => {
    if (nextOfKin[deleteIndex]?.id) {
      dispatch(DeleteNextOfKin({ id: nextOfKin[deleteIndex].id! }))
    } else {
      let newKin = [...nextOfKin]
      newKin.splice(deleteIndex, 1)
      setNextOfKin(newKin)

      let list = [...nextOfKinErrors]
      list.splice(deleteIndex, 1)
      setNextOfKinErrors(list)
      toggleRemove()
    }
  }

  return (
    <React.Fragment>
      <div>
        {permissions && (
          <UserPermissionModal
            isOpen={permissionModel}
            toggle={togglePermissionModal}
            permissions={permissions}
            roleName={roleName}
            setPermissions={setPermissions}
          />
        )}
        {confirmPasswordResetModal && (
          <ConfirmPasswordResetModal
            isOpen={confirmPasswordResetModal}
            toggle={toggleConfirmPasswordResetModal}
            dataResetPass={dataResetPass}
          />
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            toggle={toggleRemove}
            isOpen={true}
            onConfirm={onConfirm}
            text={"next of kin"}
          />
        )}
        <ResetPassword
          isOpen={resetPasswordModel}
          toggle={toggleResetPasswordModal}
          userId={`${editData?.userId}`}
        />

        <div className="page-content ms-3">
          {updatedDataLoading ? ( //TODO in case loading is needed
            <div
              style={{
                width: "100%",
                minHeight: "500px",
                display: "flex",
                flex: "1",
                justifyContent: "center",
              }}
            >
              <Spinner
                style={{
                  color: "#2a45cd",
                  alignSelf: "center",
                  width: "70px",
                  height: "70px",
                }}
                animation="border"
                variant="primary"
              />
            </div>
          ) : (

            <>
              {(access || self) ?
                <div>
                  <Form
                    className="form-horizontal col-md-12"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="d-sm-flex flex-wrap head-content">
                      {!addPage ? (
                        <div className="d-flex flex-row">
                          <Avatar
                            name={`${editData?.user?.profile?.firstName} ${editData?.user?.profile?.lastName}`}
                            maxInitials={2}
                            textSizeRatio={2.7}
                            size="60"
                            color="#2A45CD30"
                            fgColor="#2A45CD"
                            round
                          />
                          <h2 className="ms-2 mt-3">
                            <span className="sbl24">
                              {`${editData?.user?.profile?.firstName !== null
                                ? editData?.user?.profile?.firstName
                                : ""
                                } ${editData?.user?.profile?.middleName !== null
                                  ? editData?.user?.profile?.middleName
                                  : ""
                                } ${editData?.user?.profile?.lastName}`}
                            </span>
                          </h2>
                        </div>
                      ) : (
                        <h2 className="mb-3">
                          <b>Add new worker</b>
                        </h2>
                      )}
                      <div className="ms-auto">
                        <button
                          type="button"
                          className="discard-btn px-3"
                          onClick={() => {
                            validation.setFieldValue('firstName', addPage ? '' : editData?.user?.profile?.firstName)
                            validation.setFieldValue('middleName', addPage ? '' : editData?.user?.profile?.middleName === null ? '' : editData?.user?.profile?.middleName)
                            validation.setFieldValue('lastName', addPage ? '' : editData?.user?.profile?.lastName)
                            validation.setFieldValue('address', addPage ? '' : editData?.user?.profile?.address)
                            validation.setFieldValue('username', addPage ? '' : editData?.user?.username)
                            validation.setFieldValue('birthDate', addPage ? '' : editData?.user?.profile?.birthDate)
                            validation.setFieldValue('gender', addPage ? '' : editData?.user?.profile?.gender)
                            let p = false
                            let e = false;
                            //set identifiers
                            editData?.user?.identifiers?.forEach((element: IdentifiersType) => {
                              if (element.type === "EMAIL") {
                                if (addPage) {
                                  setEmail('')
                                  setEmailID('')
                                } else {
                                  setEmail(element.identifier)
                                  setEmailID(element.id)
                                }
                                e = true
                              }
                              if (element.type === "PHONE_NUMBER") {
                                if (addPage) {
                                  setPhoneNumber('')
                                  setPhoneNumberID('')
                                } else {
                                  setPhoneNumber(element.identifier)
                                  setPhoneNumberID(element.id)
                                }
                                p = true
                              }
                            })
                            !p && setPhoneNumber('');
                            !e && setEmail('');
                            validation.setFieldValue('phoneNumber', phoneNumber)
                            //set kin
                            let newKin: contacts[] = []
                            let list: any = []

                            editData?.user?.profile?.nextOfKin?.forEach(
                              (kin: contacts, index: number) => {
                                const body = {
                                  // email: false,
                                  firstName: false,
                                  lastName: false,
                                  phone: false,
                                  relationship: false,
                                }

                                newKin.push(kin)
                                list.push(body)
                              }
                            )
                            setNextOfKinErrors(list)
                            setNextOfKin(newKin)
                            //set role
                            data?.items?.forEach((item: any, index: number) => {
                              if (
                                editData &&
                                item.name === editData?.user?.roleAssignments[0]?.role?.name
                              ) {
                                //set role
                                setSelectedRole(index)
                              }
                            })
                            //permissions
                            rolesNameList &&
                              data?.items.forEach((val: any) => {
                                if (val.name === rolesNameList[selectedRole]) {
                                  setRoleName(rolesNameList[selectedRole])
                                  if (editData?.user?.roleAssignments[0]?.role.name === rolesNameList[selectedRole]) {
                                    //set user default permissions
                                    setPermissions(editData?.user?.roleOverride?.permissions ? editData?.user?.roleOverride?.permissions : editData?.user?.roleAssignments[0]?.role.permissions)
                                  } else {
                                    //set permissions of selected role
                                    setPermissions(val.permissions)
                                  }
                                }
                              })
                          }}
                        >
                          <h6 className="mt-2">
                            <span className="my-auto">Discard</span>
                          </h6>
                        </button>
                      </div>
                      {(workerEdit || self) && (
                        <div>
                          <button
                            className={(loading ||
                              updateLoading ||
                              loadingKinBatch ||
                              loadingIdentifier ||
                              loadingKinBatchEdit ||
                              loadingAddIdentifier ||
                              userRoleUpdateLoading ||
                              loadingDeleteIdentifier ||
                              customRolesPermissionsLoading) ?
                              "btn btn-primary ms-3 pointer-none" : "btn btn-primary ms-3"
                            }
                            type="submit"
                          >
                            Save changes
                            {(loading ||
                              updateLoading ||
                              loadingKinBatch ||
                              loadingIdentifier ||
                              loadingKinBatchEdit ||
                              loadingAddIdentifier ||
                              userRoleUpdateLoading ||
                              loadingDeleteIdentifier ||
                              customRolesPermissionsLoading) && (
                                <i className="bx bx-loader bx-spin font-size-14 ms-2" />
                              )}
                          </button>
                        </div>
                      )}
                    </div>
                    {/* loading here */}
                    {updatedDataLoading ? (
                      <div
                        style={{
                          width: "100%",
                          minHeight: "500px",
                          display: "flex",
                          flex: "1",
                          justifyContent: "center",
                        }}
                      >
                        <Spinner
                          style={{
                            color: "#2a45cd",
                            alignSelf: "center",
                            width: "70px",
                            height: "70px",
                          }}
                          animation="border"
                          variant="primary"
                        />
                      </div>
                    ) : (
                      <div>
                        {" "}
                        {/* content div start */}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">
                                First Name<span className="text-danger">*</span>
                              </h5>
                            </div>

                            <div className="col-md-6 mt-2">
                              <Input
                                name="firstName"
                                className={
                                  (workerEdit || self)
                                    ? "form-control input d-flex align-self-center"
                                    : "form-control input d-flex align-self-center pointer-none"
                                }
                                placeholder="Enter name"
                                type="text"
                                onChange={validation.handleChange}
                                value={validation.values.firstName || ""}
                                invalid={
                                  validation.touched.firstName &&
                                    validation.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstName &&
                                validation.errors.firstName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.firstName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">
                                Middle Name
                              </h5>
                            </div>

                            <div className="col-md-6 mt-1">
                              <Input
                                name="middleName"
                                className={
                                  (workerEdit || self)
                                    ? "form-control input d-flex align-self-center"
                                    : "form-control input d-flex align-self-center pointer-none"
                                }
                                placeholder="Enter middle name"
                                type="text"
                                onChange={validation.handleChange}
                                value={validation.values.middleName || ""}
                                invalid={
                                  validation.touched.middleName &&
                                    validation.errors.middleName
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">Last Name<span className="text-danger">*</span> </h5>
                            </div>

                            <div className="col-md-6 mt-1">
                              <Input
                                name="lastName"
                                className={
                                  (workerEdit || self)
                                    ? "form-control input d-flex align-self-center"
                                    : "form-control input d-flex align-self-center pointer-none"
                                }
                                placeholder="Enter last name"
                                type="text"
                                onChange={validation.handleChange}
                                value={validation.values.lastName || ""}
                                invalid={
                                  validation.touched.lastName &&
                                    validation.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastName &&
                                validation.errors.lastName ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.lastName}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">Email</h5>
                            </div>

                            <div className="col-md-6 mt-2">
                              <Input
                                name="email"
                                className={
                                  (workerEdit || self)
                                    ? "form-control input d-flex align-self-center"
                                    : "form-control input d-flex align-self-center pointer-none"
                                }
                                placeholder="Enter email"
                                type="text"
                                invalid={emailError}

                                onChange={e => {
                                  setEmail(e.target.value)
                                  if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${e.target.value}`) && e.target.value!?.length > 0) {
                                    setEmailError(true)
                                  } else {
                                    setEmailError(false)
                                  }
                                }}
                                value={email}
                              />
                              {emailError &&
                                <p className="font-size-10 text-danger pt-1">
                                  Invalid Email Address
                                </p>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">
                                Phone Number<span className="text-danger">*</span>
                              </h5>
                            </div>
                            <div className="col-md-6 mt-2">
                              <Input
                                name="phoneNumber"
                                className={
                                  (workerEdit || self)
                                    ? "form-control input d-flex align-self-center"
                                    : "form-control input d-flex align-self-center pointer-none"
                                }
                                placeholder="Enter phone number"
                                type="text"
                                onChange={ex => {
                                  setPhoneNumber(ex.target.value)
                                }}
                                value={phoneNumber}
                                invalid={
                                  validation.touched.phoneNumber &&
                                    validation.errors.phoneNumber
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.phoneNumber &&
                                validation.errors.phoneNumber ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.phoneNumber}
                                </FormFeedback>
                              ) : null}

                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">
                                Address
                              </h5>
                            </div>

                            <div className="col-md-6 mt-2">
                              <Input
                                name="address"
                                className={
                                  (workerEdit || self)
                                    ? "form-control input d-flex align-self-center"
                                    : "form-control input d-flex align-self-center pointer-none"
                                }
                                placeholder="Enter address"
                                type="text"
                                onChange={validation.handleChange}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                    validation.errors.address
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">Gender<span className="text-danger">*</span> </h5>
                            </div>
                            <div className="col-md-6 mt-1">
                              <Input
                                className={(workerEdit || self) ? "" : "pointer-none"}
                                type="select"
                                name="gender"
                                id="gender"
                                onChange={validation.handleChange}
                                value={validation.values.gender || ""}
                                invalid={
                                  validation.touched.gender &&
                                    validation.errors.gender
                                    ? true
                                    : false
                                }
                              >
                                <option value="">Select Gender</option>
                                <option value="MALE">Male</option>
                                <option value="FEMALE">Female</option>
                                <option value="OTHER">Other</option>
                              </Input>
                              {validation.touched.gender &&
                                validation.errors.gender ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.gender}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">
                                Date of birth<span className="text-danger">*</span>
                              </h5>
                            </div>
                            <div className="col-md-6 mt-1">
                              <DatePicker
                                id="birthDate"
                                dateFormat="DD/MM/YYYY"
                                placeholder="DD/MM/YYYY"
                                showClearButton={false}
                                style={
                                  validation.touched.birthDate &&
                                  validation.errors.birthDate && { border: '1px #f46a6a solid' }
                                }
                                className={
                                  (workerEdit || self)
                                    ? "rounded"
                                    : "rounded pointer-none"
                                }
                                value={validation.values.birthDate || ""}
                                onChange={(e: string) => validation.setFieldValue('birthDate', e)}
                                invalid={
                                  validation.touched.birthDate &&
                                    validation.errors.birthDate
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.birthDate &&
                                validation.errors.birthDate ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.birthDate}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="horizontal-line"></div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12  mt-3">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">
                                {addPage ? "Temporary username" : "Username"}<span className="text-danger">*</span>
                              </h5>
                            </div>

                            <div
                              className="col-md-6 mt-2"
                              style={{ display: "flex" }}
                            >
                              <div className="col-md-12">
                                <Input
                                  name="username"
                                  className="form-control  input d-flex align-self-center"
                                  placeholder="Enter username"
                                  type="text"
                                  disabled={true}
                                  onChange={validation.handleChange}
                                  value={validation.values.username}
                                  valid={
                                    addPage && validation.values.username?.length > 0
                                  }
                                  invalid={
                                    validation.touched.username &&
                                      validation.errors.username
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.username &&
                                  validation.errors.username ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.username}
                                  </FormFeedback>
                                ) : null}
                                {!validation.touched.username &&
                                  validation.values.username?.length > 0 &&
                                  !validation.errors.username && (
                                    <FormFeedback valid>
                                      Username is available
                                    </FormFeedback>
                                  )}
                              </div>
                              {addPage && (
                                <a
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "1.5%",
                                    color: "#2A45CD",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      GenerateUsername({
                                        firstName: validation.values.firstName,
                                        lastName: validation.values.lastName,
                                      })
                                    )
                                  }}
                                >
                                  Generate
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Password Inputs */}
                        {!addPage && (
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center im14">Password</h5>
                              </div>
                              <div>
                                {self ?
                                  <button
                                    type="button"
                                    className={
                                      self
                                        ? "discard-btn px-3"
                                        : "discard-btn px-3 pointer-none"
                                    }
                                    onClick={() => {
                                      toggleResetPasswordModal()
                                    }}
                                  >
                                    <h6 className="d-flex flex-row mb-0">
                                      <b className="my-auto font-size-12">
                                        Change password
                                      </b>
                                    </h6>
                                  </button>
                                  :
                                  <button
                                    type="button"
                                    className={
                                      workerEdit
                                        ? "discard-btn px-3"
                                        : "discard-btn px-3 pointer-none"
                                    }
                                    onClick={() => {
                                      setDataResetPass({
                                        mode: "PRACTITIONER",
                                        userId: `${editData?.userId}`,
                                        email: email ? email : undefined,
                                        phone: phoneNumber ? phoneNumber : undefined,
                                      })
                                      setConfirmPasswordResetModal(true)
                                    }}
                                  >
                                    <h6 className="d-flex flex-row pt-2">
                                      <i className="bx bx-link-alt link-icon"></i>

                                      <b className="my-auto font-size-12 ms-1">
                                        Send password reset link
                                      </b>
                                    </h6>
                                  </button>
                                }
                              </div>
                            </div>
                          </div>
                        )}
                        {!self && <div>
                          <div className="d-sm-flex flex-wrap head-content mt-3">
                            <h2 className="font-size-18 sb">
                              <span>Worker roles and permissions</span>
                            </h2>
                          </div>
                          <div className="horizontal-line mt-2"></div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center mt-1 im14">
                                  Assigned role
                                </h5>
                              </div>

                              <div className="col-md-6 mt-3 mb-3">
                                <Input
                                  type="select"
                                  name="role"
                                  className={(workerEdit || self) ? "" : "pointer-none"}
                                  onChange={(val: any) => {
                                    setSelectedRole(val.target.value)
                                  }}
                                  value={selectedRole}
                                >
                                  {rolesNameList?.map((role: string, index: number) => (
                                    <option key={index} value={index}>
                                      {role}
                                    </option>
                                  ))}
                                </Input>
                              </div>
                              {!addPage &&
                                <button
                                  className={
                                    (workerEdit || self)
                                      ? "bg-transparent border-0 text-primary im14 ms-2 text-nowrap"
                                      : "bg-transparent border-0 text-primary im14 ms-2 text-nowrap pointer-none"
                                  }
                                  type="button"
                                  onClick={() => togglePermissionModal()}
                                >
                                  Customize user permissions
                                </button>}
                            </div>
                          </div>
                        </div>}
                        <div className="d-sm-flex flex-wrap head-content mt-3">
                          <h2 className="font-size-18 sb">
                            <span>Next of kin information</span>
                          </h2>
                        </div>
                        <div className="horizontal-line mt-2"></div>
                        {nextOfKin.map((kin: any, i: number) => (
                          <div key={i}>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                              <div className="d-flex flex-row justify-content-space-between">
                                <div className="mt-3 mb-3 col-md-6">
                                  <h5 className="d-flex align-self-center im14">
                                    First name<span className="text-danger">*</span>
                                  </h5>
                                </div>

                                <div className="col-md-6 mt-3 mb-3">
                                  <Input
                                    name="role"
                                    className={
                                      (workerEdit || self)
                                        ? "form-control input d-flex align-self-center"
                                        : "form-control input d-flex align-self-center pointer-none"
                                    }
                                    placeholder="Enter first name"
                                    disabled={addPage}
                                    type="text"
                                    onChange={e => {
                                      let kin = JSON.parse(JSON.stringify(nextOfKin))
                                      kin[i].firstName = e.target.value

                                      setNextOfKin(kin)

                                      let errors = [...nextOfKinErrors]

                                      if (e.target.value === "" || !e.target.value.replace(/\s/g, '').length) {
                                        errors[i].firstName = true
                                      } else {
                                        errors[i].firstName = false
                                      }
                                      setNextOfKinErrors(errors)
                                    }}
                                    invalid={
                                      nextOfKinErrors[i]?.firstName ? true : false
                                    }
                                    value={nextOfKin[i]?.firstName}
                                  />

                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                              <div className="d-flex flex-row justify-content-space-between">
                                <div className="mt-3 mb-3 col-md-6">
                                  <h5 className="d-flex align-self-center im14">
                                    Middle name
                                  </h5>
                                </div>

                                <div className="col-md-6 mt-3 mb-3">


                                  <Input
                                    name="role"
                                    className={
                                      (workerEdit || self)
                                        ? "form-control input d-flex align-self-center"
                                        : "form-control input d-flex align-self-center pointer-none"
                                    }
                                    placeholder="Enter middle name"
                                    type="text"
                                    disabled={addPage}
                                    onChange={e => {
                                      let kin = JSON.parse(JSON.stringify(nextOfKin))
                                      kin[i].middleName = e.target.value
                                      setNextOfKin(kin)
                                    }}
                                    value={nextOfKin[i].middleName}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                              <div className="d-flex flex-row justify-content-space-between">
                                <div className="mt-3 mb-3 col-md-6">
                                  <h5 className="d-flex align-self-center im14">
                                    Last name<span className="text-danger">*</span>
                                  </h5>
                                </div>

                                <div className="col-md-6 mt-3 mb-3">
                                  <Input
                                    name="role"
                                    className={
                                      (workerEdit || self)
                                        ? "form-control input d-flex align-self-center"
                                        : "form-control input d-flex align-self-center pointer-none"
                                    }
                                    placeholder="Enter last name"
                                    type="text"
                                    disabled={addPage}
                                    invalid={
                                      nextOfKinErrors[i]?.lastName ? true : false
                                    }
                                    onChange={e => {
                                      let kin = JSON.parse(JSON.stringify(nextOfKin))

                                      kin[i].lastName = e.target.value
                                      setNextOfKin(kin)

                                      let errors = [...nextOfKinErrors]

                                      if (e.target.value === "" || !e.target.value.replace(/\s/g, '').length) {
                                        errors[i].lastName = true
                                      } else {
                                        errors[i].lastName = false
                                      }
                                      setNextOfKinErrors(errors)
                                    }}
                                    value={nextOfKin[i].lastName}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                              <div className="d-flex flex-row justify-content-space-between">
                                <div className="mt-3 mb-3 col-md-6">
                                  <h5 className="d-flex align-self-center im14">
                                    Phone Number<span className="text-danger">*</span>
                                  </h5>
                                </div>

                                <div className="col-md-6 mt-2">
                                  <Input
                                    name="role"
                                    className={
                                      (workerEdit || self)
                                        ? "form-control input d-flex align-self-center"
                                        : "form-control input d-flex align-self-center pointer-none"
                                    }
                                    placeholder="Enter phone number"
                                    type="text"
                                    disabled={addPage}
                                    invalid={nextOfKinErrors[i]?.phone ? true : false}
                                    onChange={e => {
                                      let kin = JSON.parse(JSON.stringify(nextOfKin))

                                      kin[i].phone = e.target.value
                                      setNextOfKin(kin)

                                      let errors = [...nextOfKinErrors]
                                      let check = isValidPhoneNumber(e.target.value)

                                      if (e.target.value === "" || !check) {
                                        errors[i].phone = true
                                      } else {
                                        errors[i].phone = false
                                      }
                                      setNextOfKinErrors(errors)
                                    }}
                                    value={nextOfKin[i].phone}
                                  />
                                  {!isValidPhoneNumber(nextOfKin[i].phone) &&
                                    nextOfKin[i]?.phone?.length > 0 && (
                                      <p className="font-size-10 text-danger pt-1">
                                        Invalid Phone Number
                                      </p>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                              <div className="d-flex flex-row justify-content-space-between">
                                <div className="mt-3 mb-3 col-md-6">
                                  <h5 className="d-flex align-self-center im14">
                                    Relationship<span className="text-danger">*</span>
                                  </h5>
                                </div>

                                <div className="col-md-6 mt-3 mb-3">
                                  <Input
                                    className={(workerEdit || self) ? "" : "pointer-none"}
                                    type="select"
                                    name="role"
                                    id="role"
                                    disabled={addPage}
                                    invalid={
                                      nextOfKinErrors[i]?.relationship ? true : false
                                    }
                                    onChange={e => {
                                      let kin = JSON.parse(JSON.stringify(nextOfKin))

                                      kin[i].relationship = e.target.value
                                      setNextOfKin(kin)

                                      let errors = [...nextOfKinErrors]

                                      if (e.target.value === "") {
                                        errors[i].relationship = true
                                      } else {
                                        errors[i].relationship = false
                                      }
                                      setNextOfKinErrors(errors)
                                    }}
                                    value={nextOfKin[i].relationship}
                                  >
                                    <option value="">Select relation</option>
                                    <option value="PARENT">Parent</option>
                                    <option value="CHILD">Child</option>
                                    <option value="SIBLING">Sibling</option>
                                    <option value="SPOUSE">Spouse</option>
                                    <option value="GUARDIAN">Guardian</option>
                                  </Input>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                              <div className="d-flex flex-row justify-content-space-between">
                                <div className="mt-3 mb-3 col-md-6">
                                  <h5 className="d-flex align-self-center im14">Email</h5>
                                </div>

                                <div className="col-md-6 mt-2">
                                  <Input
                                    name="email"
                                    className={
                                      (workerEdit || self)
                                        ? "form-control input d-flex align-self-center"
                                        : "form-control input d-flex align-self-center pointer-none"
                                    }
                                    placeholder="Enter email"
                                    type="email"
                                    disabled={addPage}
                                    invalid={nextOfKinErrors[i]?.email ? true : false}
                                    onChange={e => {
                                      let kin = JSON.parse(JSON.stringify(nextOfKin))

                                      kin[i].email = e.target.value

                                      let data = [...nextOfKinErrors]

                                      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${kin[i].email}`) && kin[i]?.email!?.length > 0) {
                                        data[i].email = true
                                      } else {
                                        data[i].email = false
                                      }
                                      setNextOfKinErrors(data)

                                      setNextOfKin(kin)
                                    }}
                                    value={nextOfKin[i].email || ''}
                                  />
                                  {(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                    `${nextOfKin[i].email}`
                                  ) && nextOfKin[i]?.email!?.length > 0) ? (
                                    <p className="font-size-10 text-danger pt-1">
                                      Invalid Email Address
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <button
                              type="button"
                              style={{ width: "170px" }}
                              className={
                                (workerEdit || self)
                                  ? "discard-btn px-3 mt-3"
                                  : "discard-btn px-3 mt-3 pointer-none"
                              }
                              disabled={addPage || loadingDeleteKin}
                              onClick={() => {
                                toggleRemove()
                                setDeleteIndex(i)
                              }}
                            >
                              <h6 className="d-flex flex-row pt-2">
                                <i
                                  className={
                                    addPage
                                      ? "bx bx-trash-alt trash-icon  text-muted"
                                      : "bx bx-trash-alt trash-icon"
                                  }
                                ></i>

                                <b
                                  className={
                                    addPage
                                      ? "my-auto font-size-12 sb ms-1  text-muted"
                                      : "my-auto font-size-12 sb ms-1"
                                  }
                                >
                                  Remove next of kin
                                </b>
                              </h6>
                            </button>
                            <div className="horizontal-line mt-2"></div>
                          </div>
                        ))}
                        <button
                          type="button"
                          style={{ width: "170px" }}
                          className={
                            (workerEdit || self)
                              ? "discard-btn px-3 mt-3"
                              : "discard-btn px-3 mt-3 pointer-none"
                          }
                          disabled={addPage}
                          onClick={() => {
                            let newKin = [...nextOfKin]
                            newKin.push({
                              firstName: "",
                              middleName: "",
                              lastName: "",
                              relationship: "",
                              phone: "",
                              email: "",
                            })

                            let list = [...nextOfKinErrors]

                            const body = {
                              email: false,
                              firstName: false,
                              lastName: false,
                              phone: false,
                              relationship: false,
                            }
                            list.push(body)
                            setNextOfKinErrors(list)
                            setNextOfKin(newKin)
                          }}
                        >
                          <h6 className="d-flex flex-row pt-2">
                            <i
                              className={
                                addPage
                                  ? "bx bx-user-plus kin-icon  text-muted"
                                  : "bx bx-user-plus kin-icon"
                              }
                            ></i>

                            <b
                              className={
                                addPage
                                  ? "my-auto font-size-12 sb ms-1  text-muted"
                                  : "my-auto font-size-12 sb ms-1"
                              }
                            >
                              Add next of kin
                            </b>
                          </h6>
                        </button>
                      </div>
                    )}{" "}
                    {/* content div end */}
                  </Form>
                </div>
                : <div className="permissionWarning">User does not have permissions to view this screen!</div>}

            </>

          )}
        </div>

      </div>

    </React.Fragment>
  )
}

export default AddEditAgentManagement
