import { createSlice } from "@reduxjs/toolkit";
import { SideBarType } from "./types";

const initialState: SideBarType =  {
  showSecondaryBar: false,
  collapsed : false,
}
const sideBarSlice = createSlice({
  
  name: "sideBar",
  initialState ,
  reducers: {
    setSecondaryBarTrue: (state) => {
      state.showSecondaryBar = true;
    },
    setSecondaryBarFalse: (state) => {
      state.showSecondaryBar = false;
    },
    toggle: (state,action) => {
      state.collapsed=action.payload;
    },
  },
});

export const { setSecondaryBarTrue, setSecondaryBarFalse, toggle} = sideBarSlice.actions;

export default sideBarSlice.reducer;

