import { createSlice } from "@reduxjs/toolkit"
import { AddNextOfKin, BatchAddNextOfKin, BatchEditNextOfKin, DeleteNextOfKin, FetchNextOfKin } from "./thunk"
import { nextOfKinSlice } from "./types"

const initialState: nextOfKinSlice = {
  loadingNextOfKin: false,
  errorNextOfKin: "",
  successNextOfKin: false,

  loadingKinBatch: false,
  errorKinBatch: "",
  successKinBatch: false,

  loadingKinBatchEdit: false,
  errorKinBatchEdit: "",
  successKinBatchEdit: false,
  //Fetch next of kin
  loadingFetchNextOfKin: false,
  errorFetchNextOfKin: "",
  successFetchNextOfKin: false,
  nextOfKin: [],

  loadingDeleteKin: false,
  errorDeleteKin: '',
  successDeleteKin: false,
}
const nextOfKinSlice = createSlice({
  name: "nextOfKin",
  initialState,
  extraReducers: builder => {
    builder
      // Add next of kin
      .addCase(AddNextOfKin.pending, state => {
        state.loadingNextOfKin = true
        state.errorNextOfKin = ""
        state.successNextOfKin = false
      })
      .addCase(AddNextOfKin.fulfilled, (state, { payload }) => {
        state.loadingNextOfKin = false
        state.errorNextOfKin = ""
        state.successNextOfKin = true
      })
      .addCase(AddNextOfKin.rejected, (state, action) => {
        state.loadingNextOfKin = false
        state.successNextOfKin = false
        if (action.error) {
          state.errorNextOfKin = action.error.message as string
        } else {
          state.errorNextOfKin = "Error Add Next Of Kin"
        }
      })
      // Add next of kin batch
      .addCase(BatchAddNextOfKin.pending, state => {
        state.loadingKinBatch = true
        state.errorKinBatch = ""
        state.successKinBatch = false
      })
      .addCase(BatchAddNextOfKin.fulfilled, (state, { payload }) => {
        state.loadingKinBatch = false
        state.errorKinBatch = ""
        state.successKinBatch = true
      })
      .addCase(BatchAddNextOfKin.rejected, (state, action) => {
        state.loadingKinBatch = false
        state.successKinBatch = false
        if (action.error) {
          state.errorKinBatch = action.error.message as string
        } else {
          state.errorKinBatch = "Error Adding batch Next Of Kin"
        }
      })
      // Edit next of kin batch
      .addCase(BatchEditNextOfKin.pending, state => {
        state.loadingKinBatchEdit = true
        state.errorKinBatchEdit = ""
        state.successKinBatchEdit = false
      })
      .addCase(BatchEditNextOfKin.fulfilled, (state, { payload }) => {
        state.loadingKinBatchEdit = false
        state.errorKinBatchEdit = ""
        state.successKinBatchEdit = true
      })
      .addCase(BatchEditNextOfKin.rejected, (state, action) => {
        state.loadingKinBatchEdit = false
        state.successKinBatchEdit = false
        if (action.error) {
          state.errorKinBatchEdit = action.error.message as string
        } else {
          state.errorKinBatchEdit = "Error Editing batch Next Of Kin"
        }
      })

      // Fetch Next of kin
      .addCase(FetchNextOfKin.pending, state => {
        state.loadingFetchNextOfKin = true
        state.errorFetchNextOfKin = ""
        state.successFetchNextOfKin = false
      })
      .addCase(FetchNextOfKin.fulfilled, (state, { payload }) => {
        state.loadingFetchNextOfKin = false
        state.errorFetchNextOfKin = ""
        state.nextOfKin = payload
        state.successFetchNextOfKin = true
      })
      .addCase(FetchNextOfKin.rejected, (state, action) => {
        state.loadingFetchNextOfKin = false
        state.successFetchNextOfKin = false
        if (action.error) {
          state.errorFetchNextOfKin = action.error.message as string
        } else {
          state.errorFetchNextOfKin = "Error Fetch Next Of Kin"
        }
      })
      // Delete Next of kin
      .addCase(DeleteNextOfKin.pending, state => {
        state.loadingDeleteKin = true
        state.errorDeleteKin = ""
        state.successDeleteKin = false
      })
      .addCase(DeleteNextOfKin.fulfilled, (state, { payload }) => {
        state.loadingDeleteKin = false
        state.errorDeleteKin = ""
        state.successDeleteKin = true
      })
      .addCase(DeleteNextOfKin.rejected, (state, action) => {
        state.loadingDeleteKin = false
        state.successDeleteKin = false
        if (action.error) {
          state.errorDeleteKin = action.error.message as string
        } else {
          state.errorDeleteKin = "Error Fetch Next Of Kin"
        }
      })
  },
  reducers: {
    ResetAddNextOfKin: state => {
      state.loadingNextOfKin = false
      state.errorNextOfKin = ""
      state.successNextOfKin = false
    },
    ResetBatchAddNextOfKin: state => {
      state.loadingKinBatch = false
      state.errorKinBatch = ""
      state.successKinBatch = false
    },
    ResetBatchEditNextOfKin: state => {
      state.loadingKinBatchEdit = false
      state.errorKinBatchEdit = ""
      state.successKinBatchEdit = false
    },
    ResetDeleteNextOfKin: state => {
      state.loadingDeleteKin = false
      state.errorDeleteKin = ""
      state.successDeleteKin = false
    },
  },
})

export const { ResetAddNextOfKin, ResetBatchAddNextOfKin, ResetDeleteNextOfKin, ResetBatchEditNextOfKin } = nextOfKinSlice.actions
export default nextOfKinSlice.reducer
