import React, { FC, useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import RightSidebar from "../Common/RightSidebar"
import { WithRouterProps } from "~/models/WithRouterProps.model"
import SecondarySidebar from "./SecondarySidebar"
import { useAppSelector } from "~/store/hooks"

const Layout: FC<WithRouterProps> = ({ children }) => {
  const { showSecondaryBar, collapsed } = useAppSelector(
    state => state.sideBarReducer
  )
  const { isPreloader } = useAppSelector(state => state.loaderReducer)
  const [superUser, setSuperUser] = useState<string>("")

  useEffect(() => {
    if (isPreloader === true) {
      ;(
        document.getElementById("preloader") as HTMLInputElement
      ).style.display = "block"
      ;(document.getElementById("status") as HTMLInputElement).style.display =
        "block"
    } else {
      ;(
        document.getElementById("preloader") as HTMLInputElement
      ).style.display = "none"
      ;(document.getElementById("status") as HTMLInputElement).style.display =
        "none"
    }
  }, [isPreloader])

  useEffect(() => {
    window.scrollTo(0, 0)
    const superUser = localStorage.getItem("@super")
    if(superUser){
      setSuperUser(superUser)
    }
  }, [])

  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        {superUser === "true" && <Sidebar type={"default"} />}
        {superUser !== "" && <Header superUser={superUser} />}
        {showSecondaryBar && superUser !== "" && (
          <SecondarySidebar superUser={superUser} />
        )}
        {superUser !== "" && (
          <div
            style={
              superUser === "false"
                ? {
                    marginLeft: "-250px",
                  }
                : {}
            }
          >
            <div
              className={
                !collapsed && !showSecondaryBar
                  ? "main-content"
                  : collapsed && !showSecondaryBar
                  ? "main-content-all-collapsed"
                  : collapsed && showSecondaryBar
                  ? "main-content-secondary-open"
                  : !collapsed && showSecondaryBar
                  ? "main-content-all-open"
                  : ""
              }
            >
              {children}
            </div>
          </div>
        )}
      </div>
      <RightSidebar />
    </React.Fragment>
  )
}

export default withRouter(Layout) as any
