import { createAsyncThunk } from "@reduxjs/toolkit";
import { MyKnownError } from "~/models/Error.model";
import { Search } from "~/models/Search.model";
import { postRequest } from "~/store/apiHelper";
import { Agent, AgentUpdateStatusType, batchAddIdentifier, batchEditIdentifier, UpdateAgent, updateAgentStatus, usersBatchDeleteIdentifier } from "./types";
import Async from "~/utils/Async"

export const CreateAgent = createAsyncThunk<
  Agent,
  Agent,
  { rejectValue: MyKnownError }
>("agent/add", async (data: Agent, thunkApi) => {
  return postRequest("/rpc/usersCreateAsPractitioner", data, true, thunkApi)
});

export const ActivateAgent = createAsyncThunk<
  Agent,
  updateAgentStatus,
  { rejectValue: MyKnownError }
>("agent/activate", async (data: updateAgentStatus, thunkApi) => {
  return postRequest("/rpc/practitionersActivate", data, true, thunkApi)
})

export const DeactivateAgent = createAsyncThunk<
  Agent,
  updateAgentStatus,
  { rejectValue: MyKnownError }
>("agent/deactivate", async (data: updateAgentStatus, thunkApi) => {
  return postRequest("/rpc/practitionersDeactivate", data, true, thunkApi)
})

//Identifier Add
export const BatchAddIdentifier = createAsyncThunk<
  Agent,
  batchAddIdentifier,
  { rejectValue: MyKnownError }
>("agent/usersBatchAddIdentifier", async (data: batchAddIdentifier, thunkApi) => {
  return postRequest(Async.getItem('self') ? '/rpc/usersBatchAddIdentifierSelf' : "/rpc/usersBatchAddIdentifier", data, true, thunkApi)
})
//Identifier Delete
export const BatchDeleteIdentifier = createAsyncThunk<
  Agent,
  usersBatchDeleteIdentifier,
  { rejectValue: MyKnownError }
>("agent/usersBatchDeleteIdentifier", async (data: usersBatchDeleteIdentifier, thunkApi) => {
  return postRequest(Async.getItem('self') ? '/rpc/usersBatchDeleteIdentifierSelf' : "/rpc/usersBatchDeleteIdentifier", data, true, thunkApi)
})
//Identifier Edit
export const BatchEditIdentifier = createAsyncThunk<
  Agent,
  batchEditIdentifier,
  { rejectValue: MyKnownError }
>("agent/usersBatchEditIdentifier", async (data: batchEditIdentifier, thunkApi) => {
  return postRequest(Async.getItem('self') ? "/rpc/usersBatchEditIdentifierSelf" : "/rpc/usersBatchEditIdentifier", data, true, thunkApi)
})
//Update Verified Status
export const UpdateVerifiedStatus = createAsyncThunk<
  Agent,
  AgentUpdateStatusType,
  { rejectValue: MyKnownError }
>("agent/practitionersUpdateVerifiedStatus", async (data: AgentUpdateStatusType, thunkApi) => {
  return postRequest("/rpc/practitionersUpdateVerifiedStatus", data, true, thunkApi)
})

//Search
export const SearchAgent = createAsyncThunk<
  any,
  Search,
  { rejectValue: MyKnownError }
>("practitionersSearchByName", async (data: any, thunkApi) => {
  return postRequest("/rpc/practitionersSearchByName", data, true, thunkApi)
})

//bulk 
export const bulkAproveReject = createAsyncThunk<
  any,
  any,
  { rejectValue: MyKnownError }
>("practitionersBatchUpdateVerifiedStatus", async (data: any, thunkApi) => {
  return postRequest("/rpc/practitionersBatchUpdateVerifiedStatus", data, true, thunkApi)
})