import { createSlice } from '@reduxjs/toolkit';
import { ActivateAgent, BatchAddIdentifier, BatchDeleteIdentifier, BatchEditIdentifier, bulkAproveReject, CreateAgent, DeactivateAgent, SearchAgent, UpdateVerifiedStatus } from './thunk';
import { addAgent } from './types';

const initialState: addAgent = {
  success: false,
  loading: false,
  error: '',
  agentStatusChangeSuccess: false,
  agentStatusChangeLoading: false,
  agentStatusChangeError: '',

  successIdentifier: false,
  loadingIdentifier: false,
  errorIdentifier: '',

  successDeleteIdentifier: false,
  loadingDeleteIdentifier: false,
  errorDeleteIdentifier: '',

  successAddIdentifier: false,
  loadingAddIdentifier: false,
  errorAddIdentifier: '',

  successAgentVerifiedStatus: false,
  loadingAgentVerifiedStatus: false,
  errorAgentVerifiedStatus: '',

  takeAgent: 0,
  totalAgent: 0,
  searchDataAgent: "",
  isSearchAgent: false, 

  //search
  loadingSearchAgent: false,
  errorSearchAgent: "",
  successSearchAgent: false,
  searchAgent: [],
  activeTabAgent: "",
  //Bulk 
  bulkSuccess: false,
  bulkError: "",
  bulkLoading: false,
  
  resetTableAgent:false
};
const agentSlice = createSlice({
  name: 'agent',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(CreateAgent.pending, state => {
        state.loading = true;
        state.success = false;
        state.error = '';
      })
      .addCase(CreateAgent.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
        state.error = '';
      })
      .addCase(CreateAgent.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        if (action.error) {
          state.error = action.error.message as string;
        } else {
          state.error = 'Error creating agent profile';
        }
      })
      .addCase(ActivateAgent.pending, state => {
        state.agentStatusChangeLoading = true
        state.agentStatusChangeSuccess = false
        state.agentStatusChangeError = ""
      })
      .addCase(ActivateAgent.fulfilled, state => {
        state.agentStatusChangeLoading = false
        state.agentStatusChangeSuccess = true
        state.agentStatusChangeError = ""
      })
      .addCase(ActivateAgent.rejected, (state, action) => {
        state.agentStatusChangeLoading = false
        state.agentStatusChangeSuccess = false
        if (action.error) {
          state.agentStatusChangeError = action.error.message as string
        } else {
          state.agentStatusChangeError = "Error activating Agent"
        }
      })
      .addCase(DeactivateAgent.pending, state => {
        state.agentStatusChangeLoading = true
        state.agentStatusChangeSuccess = false
        state.agentStatusChangeError = ""
      })
      .addCase(DeactivateAgent.fulfilled, state => {
        state.agentStatusChangeLoading = false
        state.agentStatusChangeSuccess = true
        state.agentStatusChangeError = ""
      })
      .addCase(DeactivateAgent.rejected, (state, action) => {
        state.agentStatusChangeLoading = false
        state.agentStatusChangeSuccess = false
        if (action.error) {
          state.agentStatusChangeError = action.error.message as string
        } else {
          state.agentStatusChangeError = "Error deactivating Agent"
        }
      })
      .addCase(BatchEditIdentifier.pending, state => {
        state.loadingIdentifier = true
        state.successIdentifier = false
        state.errorIdentifier = ""
      })
      .addCase(BatchEditIdentifier.fulfilled, state => {
        state.loadingIdentifier = false
        state.successIdentifier = true
        state.errorIdentifier = ""
      })
      .addCase(BatchEditIdentifier.rejected, (state, action) => {
        state.loadingIdentifier = false
        state.successIdentifier = false
        if (action.error) {
          state.errorIdentifier = action.error.message as string
        } else {
          state.errorIdentifier = "Error updating identifier"
        }
      })
      //add identifiers
      .addCase(BatchAddIdentifier.pending, state => {
        state.loadingAddIdentifier = true
        state.successAddIdentifier = false
        state.errorAddIdentifier = ""
      })
      .addCase(BatchAddIdentifier.fulfilled, state => {
        state.loadingAddIdentifier = false
        state.successAddIdentifier = true
        state.errorAddIdentifier = ""
      })
      .addCase(BatchAddIdentifier.rejected, (state, action) => {
        state.loadingAddIdentifier = false
        state.successAddIdentifier = false
        if (action.error) {
          state.errorAddIdentifier = action.error.message as string
        } else {
          state.errorAddIdentifier = "Error updating identifier"
        }
      })
      //delete identifiers
      .addCase(BatchDeleteIdentifier.pending, state => {
        state.loadingDeleteIdentifier = true
        state.successDeleteIdentifier = false
        state.errorDeleteIdentifier = ""
      })
      .addCase(BatchDeleteIdentifier.fulfilled, state => {
        state.loadingDeleteIdentifier = false
        state.successDeleteIdentifier = true
        state.errorDeleteIdentifier = ""
      })
      .addCase(BatchDeleteIdentifier.rejected, (state, action) => {
        state.loadingDeleteIdentifier = false
        state.successDeleteIdentifier = false
        if (action.error) {
          state.errorDeleteIdentifier = action.error.message as string
        } else {
          state.errorDeleteIdentifier = "Error deleting identifier"
        }
      })
      .addCase(UpdateVerifiedStatus.pending, state => {
        state.loadingAgentVerifiedStatus = true
        state.successAgentVerifiedStatus = false
        state.errorAgentVerifiedStatus = ""
      })
      .addCase(UpdateVerifiedStatus.fulfilled, state => {
        state.loadingAgentVerifiedStatus = false
        state.successAgentVerifiedStatus = true
        state.errorAgentVerifiedStatus = ""
      })
      .addCase(UpdateVerifiedStatus.rejected, (state, action) => {
        state.loadingAgentVerifiedStatus = false
        state.successAgentVerifiedStatus = false
        if (action.error) {
          state.errorAgentVerifiedStatus = action.error.message as string
        } else {
          state.errorAddIdentifier = "Error changing status!"
        }
      })
      //Search patient
      .addCase(SearchAgent.pending, state => {
        state.loadingSearchAgent = true
        state.errorSearchAgent = ""
        state.successSearchAgent = false
      })
      .addCase(SearchAgent.fulfilled, (state, { payload }) => {
        state.loadingSearchAgent = false
        state.errorSearchAgent = ""
        state.successSearchAgent = true
        state.searchAgent = payload.practitioner
        state.totalAgent = payload.total
      })
      .addCase(SearchAgent.rejected, (state, action) => {
        state.loadingSearchAgent = false
        state.successSearchAgent = false

        if (action.error) {
          state.errorSearchAgent = action.error.message as string
        } else {
          state.errorSearchAgent = "Error search worker"
        }
      })
      //Bulk 
      .addCase(bulkAproveReject.pending, state => {
        state.bulkLoading = true
        state.bulkError = ""
        state.bulkSuccess = false
      })
      .addCase(bulkAproveReject.fulfilled, (state) => {
        state.bulkLoading = false
        state.bulkError = ""
        state.bulkSuccess = true
      })
      .addCase(bulkAproveReject.rejected, (state, action) => {
        state.bulkLoading = false
        state.bulkSuccess = false

        if (action.error) {
          state.bulkError = action.error.message as string
        } else {
          state.bulkError = "Error search worker"
        }
      });
  },
  reducers: {
    ResetAgentStates: (state) => {
      state.success = false;
      state.loading = false;
      state.error = '';
    },
    ResetEditIdentifier: (state) => {
      state.successIdentifier = false;
      state.loadingIdentifier = false;
      state.errorIdentifier = '';
    },
    ResetDeleteIdentifier: (state) => {
      state.successDeleteIdentifier = false;
      state.loadingDeleteIdentifier = false;
      state.errorDeleteIdentifier = '';
    },
    ResetAddIdentifier: (state) => {
      state.successAddIdentifier = false;
      state.loadingAddIdentifier = false;
      state.errorAddIdentifier = '';
    },
    ResetAgentStatusStates: state => {
      state.agentStatusChangeSuccess = false
      state.agentStatusChangeLoading = false
      state.agentStatusChangeError = ""
    },
    ResetUpdateVerifiedStatus: state => {
      state.successAgentVerifiedStatus = false,
        state.loadingAgentVerifiedStatus = false,
        state.errorAgentVerifiedStatus = ''
    },
    setSearchAgent(state, action) {
      state.searchDataAgent = action.payload;
    },
    setIsSearchAgent(state, action) {
      state.isSearchAgent = action.payload;
    },
    setActiveTabAgent(state, action) {
      state.activeTabAgent = action.payload;
    },
    ResetSearchAgentStates: state => {
      state.loadingSearchAgent = false;
      state.errorSearchAgent = "";
      state.successSearchAgent = false;
      state.searchAgent = [];
    },
    ResetSearchAgentStates2: state => {
      state.loadingSearchAgent = false;
      state.errorSearchAgent = "";
      state.successSearchAgent = false;
    },
    ResetbulkStates: state => {
      state.bulkLoading = false
      state.bulkError = ""
      state.bulkSuccess = false
    },
    setAgentTakeReducer(state, action) {
      state.takeAgent = action.payload;
    },
    setResetTableAgent(state, action) {
      state.resetTableAgent = action.payload;
    }, 
  },
});

export const { setAgentTakeReducer, ResetbulkStates, ResetSearchAgentStates,ResetSearchAgentStates2, setSearchAgent, setActiveTabAgent,
  setIsSearchAgent, ResetAgentStates, ResetAgentStatusStates, ResetEditIdentifier, ResetAddIdentifier, ResetUpdateVerifiedStatus, setResetTableAgent,
  ResetDeleteIdentifier } = agentSlice.actions;
export default agentSlice.reducer;
