import { createAsyncThunk } from "@reduxjs/toolkit";
import { MyKnownError } from "~/models/Error.model";
import { archiveItems, CustomRolesPermissions } from "~/models/Roles.model";
import { postRequest } from "~/store/apiHelper";

//default roles
export const saveCustomRolesPermissions = createAsyncThunk<
  any,
  CustomRolesPermissions,
  { rejectValue: MyKnownError }
>("permission/rolesOverrideUserPermissions", async (data: CustomRolesPermissions, thunkApi) => {
  return postRequest("/rpc/rolesOverrideUserPermissions", data, true, thunkApi)
});
//archive Items
export const ArchiveItems = createAsyncThunk<
  any,
  archiveItems[],
  { rejectValue: MyKnownError }
>("permission/archiveItems", async (data: archiveItems[], thunkApi) => {
  return postRequest("/rpc/archiveItems", data, true, thunkApi)
});
