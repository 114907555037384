import { createSlice } from "@reduxjs/toolkit"
import { FetchSignUpLink } from "./thunk"
import { SignUpLink } from "./types"

const initialState: SignUpLink = {
  loading: false,
  error: "",
  success: false,
  signUpLink: "",
}
const signUpLinkSlice = createSlice({
  name: "signUpLink",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(FetchSignUpLink.pending, state => {
        state.loading = true
        state.error = ""
        state.success = false
      })
      .addCase(FetchSignUpLink.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.error = ""
        state.signUpLink = payload
      })
      .addCase(FetchSignUpLink.rejected, (state, action) => {
        state.success = false
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = "Error getting sign up link "
        }
      })
  },
  reducers: {
    resetSignUpLink(state) {
      state.loading = false
      state.error = ""
      state.success = false
      state.signUpLink = ""
    },
  },
})

export const { resetSignUpLink } = signUpLinkSlice.actions
export default signUpLinkSlice.reducer
