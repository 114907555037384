import React, { FC, useEffect, useState } from "react"
import Async from "~/utils/Async"
import logo from "~/assets/images/svg/Logo.svg"

import SecondarySideBarContent from "./SecondarySideBarContent"
import { useAppSelector } from "~/store/hooks"
interface SecondarySidebarProps {
  superUser: string
}
const SecondarySidebar: FC<SecondarySidebarProps> = props => {
  const { superUser } = props
  const [organizationLocalData, setorganizationLocalData] = useState<any>()
  const [height, setHeight] = useState(window.innerHeight)

  const { localData } = useAppSelector(state => state.OrganizationReducer)
  useEffect(() => {
    window.addEventListener("resize", HandleWidth)
    return () => window.removeEventListener("resize", HandleWidth)
  }, [])
  const HandleWidth = () => {
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    Async.getItem("@organization") && setorganizationLocalData(Async.getItem("@organization"))
  }, [localData])
  return (
    <React.Fragment>
      {height !== 0 && (
        <div
          id="second-sidebar"
          className="second-vertical-menu"
          style={
            superUser === "false"
              ? {
                maxHeight: `${height}px`,
                marginLeft: "-250px",
              }
              : { maxHeight: `${height}px` }
          }
        >
          <div className="second-side-bar-top ms-3 me-3">
            <h1 style={{ fontSize: "18px" }}>
              <b>
                {localStorage.getItem("@super") === 'true' ? (
                  Async.getItem("@Screen") === "edit"
                    ? organizationLocalData
                      ? organizationLocalData.name
                      : null
                    : "Add Organization"
                )
                  : (
                    <div >
                      <div style={{ whiteSpace: "nowrap" }}>
                        <img src={logo} alt="" height="36.88" className=" " style={{ marginLeft: '-6px' }} />
                        <span className="font-size-18 sbl" style={{ fontWeight: "bold" }}>
                          ILARA HEALTH
                        </span>
                      </div>
                      <div className="mt-3 sb font-size-15">
                        {organizationLocalData
                          ? organizationLocalData.name
                          : null}
                      </div>
                    </div>
                  )}
              </b>
            </h1>
          </div>
          <div data-simplebar>
            <SecondarySideBarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      )}
    </React.Fragment>
  )
}

export default SecondarySidebar
