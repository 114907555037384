function debounce(func:any, wait:number) {
  let timeout:any;

  return function (...args:any[]) {
    const context = this;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

export { debounce };
