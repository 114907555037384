import { createAsyncThunk } from "@reduxjs/toolkit"
import { AxiosError } from "axios"
import jwt_decode from "jwt-decode"
import { MyKnownError } from "~/models/Error.model"
import { User } from "~/models/User.model"
import { postRequest } from "~/store/apiHelper"
import Async from "../../../utils/Async/index"
import { Instance } from "../../../utils/axios"
import { authCheckCredentialsAvailable, authGetResetPasswordIdentifiers, authResetPassword, authSendResetPasswordKeyByUsername, authVerifyOtp, generateUsernameResponse, NewUserProps, UserPassword, usersGenerateUsername, usersPractitionerSelfSignUp, usersSendTemporaryPassword } from "./types"

export const SignInUser = createAsyncThunk<
  User,
  NewUserProps,
  { rejectValue: MyKnownError }
>("auth/signIn", async (data: NewUserProps, thunkApi) => {
  try {
    const body: NewUserProps = {
      username: data.username,
      password: data.password,
      mode: data.mode,
      remember: data.remember
    }
    const response = await Instance.post("/rpc/login", body)

    if (response.status !== 200) {
      return thunkApi.rejectWithValue(response.data as MyKnownError)
    }
    const token = response.data.data.token
    const decoded = jwt_decode(token) as any
    if (!decoded?.modeProfile) {
      return thunkApi.rejectWithValue({
        message: "Wrong username, password",
      } as MyKnownError)
    }

    const userData: User = {
      id: decoded.userId,
      patientId: decoded.modeProfile.id,
      organizationId: decoded.organizationId,
      mode: decoded.mode,
      profile: decoded.profile,
      token: token,
      username: decoded.username,
      role: decoded.role,
      resetKey: response.data.data.resetKey,
      requirePasswordChange: response.data.data.requirePasswordChange
    }

    Async.setItem("@auth", userData)
    return userData
  } catch (err: any) {
    const error: AxiosError<MyKnownError> = err.response.data.error

    if (!error.response) {
      throw error
    }
    return thunkApi.rejectWithValue(error)
  }
})

//usersChangePasswordOnBehalf
export const ChangeUserPassword = createAsyncThunk<
  any,//TODO
  UserPassword,
  { rejectValue: MyKnownError }
>("auth/usersChangePasswordOnBehalf", (data: UserPassword, thunkApi) => {
  let self = Async.getItem('self')
  self && delete data.userId
  return postRequest(self ? "/rpc/usersChangePasswordSelf" : "/rpc/usersChangePasswordOnBehalf", data, true, thunkApi)
});

//users Generate Username
export const GenerateUsername = createAsyncThunk<
  generateUsernameResponse,
  usersGenerateUsername,
  { rejectValue: MyKnownError }
>("auth/usersGenerateUsername", (data: usersGenerateUsername, thunkApi) => {
  return postRequest("/rpc/usersGenerateUsername", data, true, thunkApi)
});

//users Send Temporary Password
export const SendTemporaryPassword = createAsyncThunk<
  any,
  usersSendTemporaryPassword,
  { rejectValue: MyKnownError }
>("auth/usersSendTemporaryPassword", (data: usersSendTemporaryPassword, thunkApi) => {
  return postRequest("/rpc/usersSendTemporaryPassword", data, true, thunkApi)
});

//users Practitioner Self SignUp
export const PractitionerSelfSignUp = createAsyncThunk<
  any,//TODO
  usersPractitionerSelfSignUp,
  { rejectValue: MyKnownError }
>("auth/usersPractitionerSelfSignUp", (data: usersPractitionerSelfSignUp, thunkApi) => {
  return postRequest("/rpc/usersPractitionerSelfSignUp", data, true, thunkApi)
});
//auth Reset Password
export const ResetPassword = createAsyncThunk<
  any,//TODO
  authResetPassword,
  { rejectValue: MyKnownError }
>("auth/authResetPassword", (data: authResetPassword, thunkApi) => {
  return postRequest("/rpc/authResetPassword", data, true, thunkApi)
});
//auth Check Credentials Available
export const CheckCredentialsAvailable = createAsyncThunk<
  any,//TODO
  authCheckCredentialsAvailable,
  { rejectValue: MyKnownError }
>("auth/authCheckCredentialsAvailable", (data: authCheckCredentialsAvailable, thunkApi) => {
  return postRequest("/rpc/authCheckCredentialsAvailable", data, true, thunkApi)
});
//auth Send Reset Password Key By Username
export const SendResetPasswordKeyByUsername = createAsyncThunk<
  any,//TODO
  authSendResetPasswordKeyByUsername,
  { rejectValue: MyKnownError }
>("auth/authSendResetPasswordKeyByUsername", (data: authSendResetPasswordKeyByUsername, thunkApi) => {
  return postRequest("/rpc/authSendResetPasswordKeyByUsername", data, true, thunkApi)
});
//auth Verify Otp
export const VerifyOtp = createAsyncThunk<
  any,//TODO
  authVerifyOtp,
  { rejectValue: MyKnownError }
>("auth/authVerifyOtp", (data: authVerifyOtp, thunkApi) => {
  return postRequest("/rpc/authVerifyOtp", data, true, thunkApi)
});
//auth Get Reset Password Identifiers
export const GetResetPasswordIdentifiers = createAsyncThunk<
  any,//TODO
  authGetResetPasswordIdentifiers,
  { rejectValue: MyKnownError }
>("auth/authGetResetPasswordIdentifiers", (data: authGetResetPasswordIdentifiers, thunkApi) => {
  return postRequest("/rpc/authGetResetPasswordIdentifiers", data, true, thunkApi)
});