import React, { FC, useEffect, useState } from "react"

import "./vitalsHistory.scss"
import { Col, Row, Spinner } from "reactstrap"
import moment from "moment"

interface ListVitalsType {
  value: boolean
  index: number
}
interface VitalProps {
  data: any
}

const ListVitals: FC<VitalProps> = ({ data }) => {
  //toggleVitalDetails
  const [vitalDetailsBox, setVitalDetailsBox] = useState<ListVitalsType>({
    value: true,
    index: -1,
  })
  const [filteredData, setFilteredData] = useState<any>([])

  useEffect(() => {
    if (data?.length > 0) {
      const dates = new Set()
      let orgData = data
      data.forEach((element: any) => {
        const onlyDate = moment(element.recordedAt).format("DD MMM YY")
        dates.add(onlyDate)
      })
      let completeData: any = []
      dates.forEach(val => {
        const data2 = orgData.filter(
          (value: any) => val === moment(value.recordedAt).format("DD MMM YY")
        )
        completeData.push(data2.reverse())
      })
      setFilteredData(completeData.reverse())
    } else if (data?.length === 0) {
      setFilteredData([])
    }
  }, [data])

  const VitalRow = (vitalData: any) => {
    return (
      <>
        <div className="d-flex flex-row mb-1 py-1 horizontal-line">
          <b className="vital">
            <span>{moment(vitalData.recordedAt).format("hh:mm A")}</span>
            <span> · </span>
            <span>
              {vitalData.type === "BLOOD_PRESSURE_S_D"
                ? "Blood Pressure"
                : vitalData.type === "HEART_RATE"
                  ? "Heart Rate"
                  : vitalData.type === "BLOOD_GLUCOSE"
                    ? `Blood Glucose (${vitalData.component[1].valueQuantity.value === 'POST_MEAL' ? 'Post meal' :
                      vitalData.component[1].valueQuantity.value === 'PRE_MEAL' ? 'Pre meal' :
                        vitalData.component[1].valueQuantity.value === 'FASTING' ? 'Fasting' : ''
                    })`
                    : null}
            </span>
          </b>
          <b className="value ms-auto">
            {vitalData.type === "BLOOD_PRESSURE_S_D" &&
              (vitalData.component[0].valueQuantity.value >= 140 ||
                vitalData.component[1].valueQuantity.value >= 90) ? (
              <span className="valueDanger">H</span>
            ) : vitalData.type === "BLOOD_GLUCOSE" &&
              (vitalData.component[1].valueQuantity.value === "fasting"
                ? vitalData.component[0].valueQuantity.value > 7
                : vitalData.component[0].valueQuantity.value > 11) ? (
              <span className="valueDanger">H</span>
            ) : null}

            {vitalData?.type === "BLOOD_PRESSURE_S_D"
              ? `${vitalData.component[0].valueQuantity.value}/${vitalData.component[1].valueQuantity.value} ${vitalData.component[0].valueQuantity.unit}`
              : vitalData?.type === "HEART_RATE"
                ? `${vitalData.component[0].valueQuantity.value} ${vitalData.component[0].valueQuantity.unit}`
                : vitalData?.type === "BLOOD_GLUCOSE"
                  ? `${vitalData.component[0].valueQuantity.value} ${vitalData.component[0].valueQuantity.unit}`
                  : null}
          </b>
        </div>
      </>
    )
  }
  return (
    <div className={filteredData.length > 0 ? "vitalsTable" : ""}>
      {filteredData.length > 0 ? (
        filteredData?.map((val: any, index: number) => (
          <div key={index}>
            <div
              className="headingVital"
              onClick={() => {
                vitalDetailsBox.index == index
                  ? setVitalDetailsBox({ value: !vitalDetailsBox.value, index })
                  : setVitalDetailsBox({ value: true, index })
              }}
            >
              <i
                className={`bx bx-chevron-down font-size-20 ${vitalDetailsBox.index == index && vitalDetailsBox.value
                  ? " arrowIcon"
                  : ""
                  }`}
              ></i>
              <h6 className="ms-3 mb-0 headingMainText">
                {moment(val[0].recordedAt).format("DD MMM YYYY")}
              </h6>
              <i className="bx bx-dots-vertical-rounded font-size-18 edit-icons ms-auto"></i>
            </div>
            {vitalDetailsBox.index == index && vitalDetailsBox.value ? (
              <div
                className="bodyVital"
                style={{
                  maxHeight: "400px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Row className="contentBody">
                  <Col className="py-0 px-5 mx-4">
                    {val.map((vitalData: any, ind: number) => (
                      <div key={ind}>{VitalRow(vitalData)}</div>
                    ))}
                  </Col>
                </Row>
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <>
          <div className="text-center m-3">No record found</div>
          <div className="horizontal-line"></div>
        </>
      )}
    </div>
  )
}

export default ListVitals
