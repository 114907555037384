import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit"
import {
  sideBarReducer,
  loaderReducer,
  AuthReducer,
  OrganizationReducer,
  SelectReducer,
  PatientReducer,
  AgentReducer,
  RolesReducer,
  NextOfKinReducer,
  SignUpLinkReducer,
  PermissionReducer,
} from "./reducers"

const combinedReducer = combineReducers({
  sideBarReducer,
  loaderReducer,
  AuthReducer,
  OrganizationReducer,
  SelectReducer,
  PatientReducer,
  AgentReducer,
  RolesReducer,
  NextOfKinReducer,
  SignUpLinkReducer,
  PermissionReducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/clearState") {
    // check for action type
    state = undefined
  }
  return combinedReducer(state, action)
}

export const store: any = configureStore({
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
