import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { APP_ROOT } from '~/config';
import { App } from '~/App';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './store/configStore';

const rootElement = document.getElementById(APP_ROOT);

function ReactApp(): JSX.Element {
  return (
    <Suspense fallback={<div />}>
      <Provider store={store}>
         <App />
      </Provider>
    </Suspense>
  );
}

render(<ReactApp />, rootElement);
