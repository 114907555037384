import React, { FC } from 'react';
import { Switch, RouteProps, BrowserRouter as Router } from "react-router-dom"
import { authProtectedRoutes, publicRoutes } from "~/routes"
import AuthMiddleWare from "~/routes/route";
import NonAuthLayout from "~/components/NonAuthLayout";
import VerticalLayout from "~/components/VerticalLayout"
import { ToastContainer } from 'react-toastify';

export const App: FC = () => {
 
  return (
    <React.Fragment>
      <Router>
        <ToastContainer />

        <Switch>
          {publicRoutes.map((route :RouteProps, idx: number) => (
            <AuthMiddleWare
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route: RouteProps, idx: number) => (
            <AuthMiddleWare
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};
