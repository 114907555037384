import React, { FC, useEffect, useRef, useState } from "react"
import SimpleBar from "simplebar-react"
import { Link } from "react-router-dom"
import { withRouter } from "react-router-dom"
import { WithRouterProps } from "~/models/WithRouterProps.model"
import { useAppSelector } from "~/store/hooks"
import Async from "~/utils/Async"

const SecondarySidebarContent: FC<WithRouterProps> = ({ location }) => {
  const ref = useRef<any>()
  const [arrowUp, setArrowUp] = useState<boolean>(false)

  const [viewPatient, setViewPatient] = useState<boolean>(false)
  const [viewWorker, setViewWorker] = useState<boolean>(false)
  const [orgView, setOrgView] = useState<boolean>(false)
  const [bpView, setBpView] = useState<boolean>(false)
  const [bmiView, setBmiView] = useState<boolean>(false)
  const [bsView, setBsView] = useState<boolean>(false)
  const [viewSummary, setViewSummary] = useState<boolean>(false)

  const showOrHide = (zap: string) => {
    const ele: HTMLInputElement = document.getElementById(
      zap
    ) as HTMLInputElement
    const ul = ele.style
    if (ul) {
      if (ul.display === "none" || ul.display === "") {
        ul.display = "block"
        setArrowUp(true)
      } else {
        ul.display = "none"
        setArrowUp(false)
      }
    }
  }
  useEffect(() => {
    if (location.pathname.includes("reports")) {
      showOrHide("menulink")
    }
  }, [])

  useEffect(() => {
    const per = Async.getItem("@auth")
    const patientView = per.role.permissions["patient-view"]
    setViewWorker(per.role.permissions["practitioner-view"])
    setViewPatient(patientView)
    setBpView(per.role.permissions["dashboard-blood-pressure-view"])
    
    setBmiView(per.role.permissions["dashboard-bmi-view"])
    setBsView(per.role.permissions["dashboard-blood-glucose-view"])
    setOrgView(per.role.permissions["organization-details-view"])
    setViewSummary(per.role.permissions["dashboard-overview-view"])
  }, [])

  return (
    <React.Fragment>
      <SimpleBar ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">PATIENT MONITORING APP</li>
            {(Async.getItem("@Screen") === "edit" ||
              localStorage.getItem("@super") === "false") && (
                <>
                  <li>
                    <a onClick={() => showOrHide("menulink")}>
                      <span>
                        <b className="sb13">Stats Dashboard</b>
                      </span>
                      {arrowUp ? (
                        <i className="li-arrow bx bx-chevron-up"></i>
                      ) : (
                        <i className="li-arrow bx bx-chevron-down"></i>
                      )}
                    </a>

                    <ul className="sub-menu block" id="menulink">
                      {viewSummary && (
                        <li>
                          <Link
                            to="/reports/summary"
                            className={
                              location.pathname === "/reports/summary"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <span className="sb13">Summary</span>
                          </Link>
                        </li>
                      )}

                      {bpView && (
                        <li>
                          <Link
                            to="/reports/bloodPressure"
                            className={
                              location.pathname === "/reports/bloodPressure"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <span className="sb13">Blood pressure</span>
                          </Link>
                        </li>
                      )}
                      {bsView && (
                        <li>
                          <Link
                            to="/reports/bloodSugar"
                            className={
                              location.pathname === "/reports/bloodSugar"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <span className="sb13">Blood sugar</span>
                          </Link>
                        </li>
                      )}

                      {/* {bmiView && (
                        <li>
                          <Link
                            to="/reports/bmi"
                            className={
                              location.pathname === "/reports/bmi"
                                ? "mm-active"
                                : ""
                            }
                          >
                            <span className="sb13">BMI</span>
                          </Link>
                        </li>
                      )} */}
                    </ul>
                  </li>
                </>
              )}
            {Async.getItem("@Screen") === "edit" ||
              localStorage.getItem("@super") === "false" ? (
              <>
                {viewWorker && (
                  <li>
                    <Link
                      to="/agentManagement"
                      className={
                        location.pathname === "/agentManagement" ||
                          location.pathname === "/AddEditAgentManagement"
                          ? "mm-active"
                          : ""
                      }
                    >
                      <span>
                        <span className="sb13">Worker Management</span>
                      </span>
                    </Link>
                  </li>
                )}

                {viewPatient && (
                  <li>
                    <Link
                      to="/patientManagement"
                      className={
                        location.pathname === "/patientManagement" ||
                          location.pathname === "/AddEditPatientManagement"
                          ? "mm-active"
                          : ""
                      }
                    >
                      <span>
                        <span className="sb13">Patient Management</span>
                      </span>
                    </Link>
                  </li>
                )}
              </>
            ) : null}
            {orgView && (
              <li>
                <Link
                  to="/organizationDetails"
                  className={
                    location.pathname === "/organizationDetails"
                      ? "mm-active"
                      : ""
                  }
                >
                  <span>
                    <span className="sb13">Organization Settings</span>
                  </span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

export default withRouter(SecondarySidebarContent) as any
