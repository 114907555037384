import React from "react";
import { Redirect, RouteProps } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Authentication";
import OrganizationDetails from "~/pages/OrganizationDetails";
import AgentManagement from "~/pages/AgentManagement";
import PatientManagement from "~/pages/PatientManagement";
import Summary from "~/pages/Reports/Summary";
import AddEditAgentManagement from "~/pages/AgentManagement/AddEditAgentManagement";
import AddEditPatientManagement from "~/pages/PatientManagement/AddEditPatientManagement";
import BloodPressure from "~/pages/Reports/BloodPressure";
import BloodSugar from "~/pages/Reports/BloodSugar";
import BMI from "~/pages/Reports/BMI";
import VitalsHistory from "~/pages/PatientManagement/VitalsHistory";
import Signup from "~/pages/Authentication/Signup";
import PasswordReset from "~/pages/Authentication/PasswordReset";

const authProtectedRoutes: RouteProps[] = [
  { path: "/dashboard", component: Dashboard },
  { path: "/organizationDetails", component: OrganizationDetails },
  { path: "/agentManagement", component: AgentManagement },
  { path: "/patientManagement", component: PatientManagement },
  { path: "/reports/summary", component: Summary },
  { path: "/AddEditAgentManagement", component: AddEditAgentManagement },
  { path: "/AddEditPatientManagement", component: AddEditPatientManagement },
  { path: "/reports/bloodPressure", component: BloodPressure },
  { path: "/reports/bloodSugar", component: BloodSugar },
  { path: "/reports/bmi", component: BMI },
  { path: "/vitalsHistory", component: VitalsHistory },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to={localStorage.getItem("@super") === 'false' ? "/reports/summary" : "/dashboard"} /> },
]

const publicRoutes: RouteProps[] = [
  { path: "/login", component: Login },
  { path: "/sign-up/:id", component: Signup },
  { path: "/password-reset", exact: false, component: PasswordReset },
]

export { authProtectedRoutes, publicRoutes };
