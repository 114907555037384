import React, { FC } from "react"
import { Route, Redirect, RouteComponentProps } from "react-router-dom";

interface Props {
  location: RouteComponentProps["location"];
}
const AuthMiddleWare: FC<any> = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props: Props) => {
      if (isAuthProtected && !localStorage.getItem("@auth")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

export default AuthMiddleWare;
