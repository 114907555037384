import { useHistory } from "react-router-dom"
import React, { FC, useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"
import Datatable from "~/components/Common/Datatable"
import classnames from "classnames"
import "./agentManagement.scss"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import AgentFilter from "~/components/Common/Modals/Filter/AgentFilter"
import { GetUsers } from "~/store/reducers/Select/thunk"
import {
  setAgentStatus,
  setOrgApproved,
  setOrgPen,
  setOrgRej,
} from "~/store/reducers/Select"
import Async from "~/utils/Async"
import { ResetAgentStatusStates, ResetbulkStates, ResetSearchAgentStates, ResetUpdateVerifiedStatus, setActiveTabAgent, setAgentTakeReducer, setIsSearchAgent, setResetTableAgent, setSearchAgent } from "~/store/reducers/Agent"
import { errorToast, successToast } from "~/utils/toast"
import { Instance } from "~/utils/axios"
import moment from "moment"
import { bulkAproveReject, SearchAgent } from "~/store/reducers/Agent/thunk"
import { Search } from "~/models/Search.model"
import { resetArchiveItems } from "~/store/reducers/Permission"
import ArchiveConfirmationModal from "~/components/Common/Modals/confirmationModal/archive"
import { ArchiveItems } from "~/store/reducers/Permission/thunk"
import { List } from "~/models/User.model"
import { setPreloader } from "~/store/reducers/loader"

type OptionType = {
  value: string | number
  label: string
  set?: boolean
}
interface Archive {
  model: string,
  itemId: string
}

const AgentManagement: FC = () => {
  const history = useHistory()

  const [access, setAccess] = useState<boolean>(true);

  const [activeTab, toggleTab] = useState<string>("2")
  const dispatch = useAppDispatch()
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const [filterModel, setFilterModel] = useState<boolean>(false)
  const toggleFilterModal = () => setFilterModel(!filterModel)
  const [height, setHeight] = useState(window.innerHeight)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const [sorting, setSorting] = useState<string>("")
  const [sortCol, setSortCol] = useState<string>("")
  const [isDataRendered, setIsDataRendered] = useState<boolean>(false)
  const [totalNumber, setTotalNumber] = useState<number>(0)
  const [workerEdit, setWorkerEdit] = useState<boolean>(false)
  const [filterList, setFilterList] = useState<any>()
  const [tempFilterList, setTempFilterList] = useState<any>([])
  const [roleArray, setRoleArray] = useState<any>()
  const [reset, setReset] = useState<boolean>(false)
  const [archiveWorker, setArchiveWorker] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [bulk, setBulk] = useState<boolean>(false)
  const [allRows, setAll] = useState<boolean>(false)
  const [numberOfRowsSelected, setNumberOfRowsSelected] = useState<number>(0)
  const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false)
  const [closeDrop, setCloseDrop] = useState<boolean>(false)

  const { pendingAgents, approvedAgents, rejectedAgents, loading, status, success } =
    useAppSelector(state => state.SelectReducer)
  const {
    archiveItemsSuccess,
    archiveItemsError,
    archiveItemsLoading
  } = useAppSelector(state => state.PermissionReducer)
  const {
    agentStatusChangeSuccess,
    agentStatusChangeError,
    successAgentVerifiedStatus,
    errorAgentVerifiedStatus,
    //search 
    loadingSearchAgent,
    searchAgent,
    totalAgent,
    searchDataAgent,
    successSearchAgent,
    errorSearchAgent,

    isSearchAgent,
    bulkSuccess,
    bulkError,
    bulkLoading,
    resetTableAgent
  } = useAppSelector(state => state.AgentReducer)

  const organizationLocalData = Async.getItem("@organization")
  useEffect(() => {
    const superUser = localStorage.getItem("@super")

    const per = Async.getItem("@auth")
    setWorkerEdit(per.role.permissions["practitioner-edit"])
    setArchiveWorker(per.role.permissions["archive-edit"])
    if (superUser === "true") {
      var body: HTMLElement = document.body
      dispatch(setSecondaryBarTrue())
      if (!collapsed) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      setTimeout(() => {
        dispatch(setPreloader(false))
      }, 1000)
      const data = Async.getItem("@auth")
      setAccess(data?.role?.permissions["practitioner-view"])
      dispatch(setSecondaryBarTrue())
    }
  }, [])

  const getToken = () => {
    const data = localStorage.getItem("@auth")
    if (data) {
      const token = JSON.parse(data).token
      return token
    }
    return ""
  }
  useEffect(() => {
    return () => {
      dispatch(setSearchAgent(""))
      dispatch(setIsSearchAgent(false))
    };
  }, []);

  useEffect(() => {
    if (resetTableAgent) {
      setNumberOfRowsSelected(0)
      setClearSelectedRows(true)
      setTimeout(() => setClearSelectedRows(false), 0.1)
    }
  }, [resetTableAgent]);

  useEffect(() => {
    if (successSearchAgent) {
      dispatch(setResetTableAgent(false));
    } else if (errorSearchAgent) {
      dispatch(setResetTableAgent(false));
    }
  }, [successSearchAgent, errorSearchAgent]);
  //archive
  useEffect(() => {
    if (archiveItemsSuccess) {
      successToast('Worker Archived')
      toggleArchive()
      dispatch(resetArchiveItems())
      countAPICall();
      setNumberOfRowsSelected(0)
      setClearSelectedRows(true)
      setTimeout(() => setClearSelectedRows(false), 0.1)

      if (bulk) {
        setBulk(false)
      }
      //update table
      let organizationId = organizationLocalData.id
      if (organizationId !== "") {
        let status = "";
        if (activeTab === "1") {
          status = "PENDING";
        } else if (activeTab === "2") {
          status = "APPROVED";
        } else if (activeTab === "3") {
          status = "REJECTED";
        }
        let dateStart: string = ""
        let dateEnd: string = ""
        let ageTo: string | null = null
        let ageFrom: string | null = null
        let gender: string | null = null
        let role: string | null = null

        filterList?.forEach((itemList: any) => {
          if (itemList.set) {
            if (itemList.value === 0) {
              role = itemList.contain
            } else if (itemList.value === 1) {
              dateEnd = itemList.contain
            } else if (itemList.value === 2) {
              dateStart = itemList.contain
            } else if (itemList.value === 3) {
              ageTo = itemList.contain
            } else if (itemList.value === 4) {
              ageFrom = itemList.contain
            } else if (itemList.value === 5) {
              gender = itemList.contain.value
            }
          }
        })

        dispatch(setAgentStatus(status))

        if (allRows || (searchAgent.length === 1 || approvedAgents.length === 1 || rejectedAgents?.length === 1)) {
          setSkip(0)
          setReset(true)
          dispatch(setSearchAgent(""))
          setTimeout(() => setReset(false), 0.1)
          const ele: any = document.getElementsByName('select-all-rows');
          ele.checked = false;
        }
        if (isSearchAgent) {
          dispatch(
            SearchAgent({
              search: allRows || (searchAgent.length === 1 || approvedAgents.length === 1 || rejectedAgents?.length === 1) ? "" : searchDataAgent,
              organizationId: organizationLocalData.id,
              filter: {
                take: rowsPerPage,
                skip: allRows || (searchAgent.length === 1 || approvedAgents.length === 1 || rejectedAgents?.length === 1) ? 0 : skip,
                status: status,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            })
          )
        } else {
          dispatch(
            GetUsers({
              practitioner: {
                filter: {
                  organizationId: organizationId,
                  status: status,
                  take: rowsPerPage,
                  skip: allRows || (searchAgent.length === 1 || approvedAgents.length === 1 || rejectedAgents?.length === 1) ? 0 : skip,

                  gender: gender ? gender : undefined,
                  createdAt: {
                    start: dateStart
                      ? new Date(dateStart).toISOString()
                      : undefined,
                    end: dateEnd
                      ? new Date(
                        dateEnd +
                        "T" +
                        moment(new Date().toUTCString()).format("HH:mm")
                      ).toISOString()
                      : undefined,
                  },
                  age: {
                    start: ageFrom ? ageFrom : undefined,
                    end: ageTo ? ageTo : undefined,
                  },
                  role: role ? role : undefined,
                },
                sort: [
                  {
                    field: sortCol === "" ? "firstName" : sortCol,
                    order: sorting === "" ? "asc" : sorting,
                  },
                ],
                include: {
                  vitalsMeasurements: false,
                  identifiers: false,
                  nextOfKin: false,
                  roleAssignments: true,
                },
              },
            })
          )
        }
      }
    } else if (archiveItemsError) {
      errorToast(archiveItemsError)
      toggleArchive()
      dispatch(resetArchiveItems())
      if (bulk) {
        setBulk(false)
      }
    }
  }, [archiveItemsSuccess, archiveItemsError])
  //api call count
  useEffect(() => {
    if (success) {
      countAPICall();
    }

  }, [success])

  const countAPICall = () => {
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null
    let role: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if (itemList.value === 0) {
          role = itemList.contain
        } else if (itemList.value === 1) {
          dateEnd = itemList.contain
        } else if (itemList.value === 2) {
          dateStart = itemList.contain
        } else if (itemList.value === 3) {
          ageTo = itemList.contain
        } else if (itemList.value === 4) {
          ageFrom = itemList.contain
        } else if (itemList.value === 5) {
          gender = itemList.contain.value
        }
      }
    })

    const data = {
      practitioner: {
        filter: {
          organizationId: organizationLocalData.id,
          gender: gender ? gender : undefined,
          createdAt: {
            start: dateStart ? new Date(dateStart).toISOString() : undefined,
            end: dateEnd
              ? new Date(
                dateEnd +
                "T" +
                moment(new Date().toUTCString()).format("HH:mm")
              ).toISOString()
              : undefined,
          },
          age: {
            start: ageFrom ? ageFrom : undefined,
            end: ageTo ? ageTo : undefined,
          },
          role: role ? role : undefined,
          status:
            activeTab === "1"
              ? "PENDING"
              : activeTab === "2"
                ? "APPROVED"
                : activeTab === "3"
                  ? "REJECTED"
                  : "",
        },
      },
    }
    const headers = {
      "X-Access-Token": false,
    }

    headers["X-Access-Token"] = getToken()

    Instance.post(
      "https://diagnostics-mono-backend-wdjc5wyhsa-nw.a.run.app/rpc/count",
      data,
      { headers: headers }
    ).then((response: any) => {
      if (response) {
        setTotalNumber(response?.data?.data?.practitioner)
      }
    })
  }
  useEffect(() => {
    if (successAgentVerifiedStatus) {
      successToast("Successful!")
      let organizationId = organizationLocalData.id
      setNumberOfRowsSelected(0)
      setClearSelectedRows(true)
      setTimeout(() => setClearSelectedRows(false), 0.1)

      if (organizationId !== "") {

        let status = "";
        if (activeTab === "1") {
          status = "PENDING";
        } else if (activeTab === "2") {
          status = "APPROVED";
        } else if (activeTab === "3") {
          status = "REJECTED";
        }

        let dateStart: string = ""
        let dateEnd: string = ""
        let ageTo: string | null = null
        let ageFrom: string | null = null
        let gender: string | null = null
        let role: string | null = null

        filterList?.forEach((itemList: any) => {
          if (itemList.set) {
            if (itemList.value === 0) {
              role = itemList.contain
            } else if (itemList.value === 1) {
              dateEnd = itemList.contain
            } else if (itemList.value === 2) {
              dateStart = itemList.contain
            } else if (itemList.value === 3) {
              ageTo = itemList.contain
            } else if (itemList.value === 4) {
              ageFrom = itemList.contain
            } else if (itemList.value === 5) {
              gender = itemList.contain.value
            }
          }
        })

        dispatch(setAgentStatus(status))

        if (searchAgent.length === 1 || pendingAgents?.length === 1 || rejectedAgents?.length === 1) {
          setSkip(0)
          setReset(true)
          dispatch(setSearchAgent(""))
          setTimeout(() => setReset(false), 0.1)
          const ele: any = document.getElementsByName('select-all-rows');
          ele.checked = false;
        }

        if (isSearchAgent) {
          dispatch(
            SearchAgent({
              search: (searchAgent.length === 1 || pendingAgents?.length === 1 || rejectedAgents?.length === 1) ? '' : searchDataAgent,
              organizationId: organizationLocalData.id,
              filter: {
                take: rowsPerPage,
                skip: (searchAgent.length === 1 || pendingAgents?.length === 1 || rejectedAgents?.length === 1) ? 0 : skip,
                status: status,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            })
          )
        } else {
          dispatch(
            GetUsers({
              practitioner: {
                filter: {
                  organizationId: organizationId,
                  status: status,
                  take: rowsPerPage,
                  skip: (searchAgent.length === 1 || pendingAgents?.length === 1 || rejectedAgents?.length === 1) ? 0 : skip,

                  gender: gender ? gender : undefined,
                  createdAt: {
                    start: dateStart
                      ? new Date(dateStart).toISOString()
                      : undefined,
                    end: dateEnd
                      ? new Date(
                        dateEnd +
                        "T" +
                        moment(new Date().toUTCString()).format("HH:mm")
                      ).toISOString()
                      : undefined,
                  },
                  age: {
                    start: ageFrom ? ageFrom : undefined,
                    end: ageTo ? ageTo : undefined,
                  },
                  role: role ? role : undefined,
                },
                sort: [
                  {
                    field: sortCol === "" ? "firstName" : sortCol,
                    order: sorting === "" ? "asc" : sorting,
                  },
                ],
                include: {
                  vitalsMeasurements: false,
                  identifiers: false,
                  nextOfKin: false,
                  roleAssignments: true,
                },
              },
            })
          )
        }
      }

      dispatch(ResetUpdateVerifiedStatus())
    } else if (errorAgentVerifiedStatus) {
      errorToast(errorAgentVerifiedStatus)
      dispatch(ResetUpdateVerifiedStatus())
    }
  }, [successAgentVerifiedStatus, errorAgentVerifiedStatus])

  useEffect(() => {

    dispatch(setActiveTabAgent(activeTab))
    let organizationId = organizationLocalData.id
    dispatch(setSearchAgent(""))
    if (organizationId !== "") {

      let status = "";
      if (activeTab === "1") {
        status = "PENDING";
        dispatch(setOrgPen(organizationId))

      } else if (activeTab === "2") {
        dispatch(setOrgApproved(organizationId))
        status = "APPROVED";
      } else if (activeTab === "3") {
        dispatch(setOrgRej(organizationId))
        status = "REJECTED";
      }
      dispatch(setAgentStatus(status))

      dispatch(
        GetUsers({
          practitioner: {
            filter: {
              organizationId: organizationId,
              status: status,
              take: rowsPerPage,
              skip: 0,
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
            include: {
              vitalsMeasurements: false,
              identifiers: false,
              nextOfKin: false,
              roleAssignments: true,
            },
          },
        })
      )
    }
  }, [activeTab])

  //handle on agent status change
  useEffect(() => {
    if (agentStatusChangeSuccess) {
      const organizationLocalData = Async.getItem("@organization")
      dispatch(setAgentStatus("APPROVED"))

      let dateStart: string = ""
      let dateEnd: string = ""
      let ageTo: string | null = null
      let ageFrom: string | null = null
      let gender: string | null = null
      let role: string | null = null

      filterList?.forEach((itemList: any) => {
        if (itemList.set) {
          if (itemList.value === 0) {
            role = itemList.contain
          } else if (itemList.value === 1) {
            dateEnd = itemList.contain
          } else if (itemList.value === 2) {
            dateStart = itemList.contain
          } else if (itemList.value === 3) {
            ageTo = itemList.contain
          } else if (itemList.value === 4) {
            ageFrom = itemList.contain
          } else if (itemList.value === 5) {
            gender = itemList.contain.value
          }
        }
      })

      if (isSearchAgent) {
        dispatch(
          SearchAgent({
            search: searchDataAgent,
            organizationId: organizationLocalData.id,
            filter: {
              take: rowsPerPage,
              skip: skip,
              status: "APPROVED",
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      } else {
        dispatch(
          GetUsers({
            practitioner: {
              filter: {
                organizationId: organizationLocalData.id,
                status: "APPROVED",
                take: rowsPerPage,
                skip: skip,
                gender: gender ? gender : undefined,
                createdAt: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd
                    ? new Date(
                      dateEnd +
                      "T" +
                      moment(new Date().toUTCString()).format("HH:mm")
                    ).toISOString()
                    : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                role: role ? role : undefined,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
                roleAssignments: true,
              },
            },
          })
        )
      }

      successToast("Worker active status changed!")
      dispatch(ResetAgentStatusStates())
    } else if (agentStatusChangeError) {
      errorToast(agentStatusChangeError)
      dispatch(ResetAgentStatusStates())
    }
  }, [agentStatusChangeSuccess, agentStatusChangeError])

  //bulk
  useEffect(() => {
    if (bulkSuccess) {
      successToast("Successful!")
      let organizationId = organizationLocalData.id
      if (bulk) {
        setBulk(false)
      }
      if (allRows) {
        setSkip(0)
        setReset(true)
        dispatch(setSearchAgent(""))
        setTimeout(() => setReset(false), 0.1)
        const ele: any = document.getElementsByName('select-all-rows');
        ele.checked = false;
      }
      if (organizationId !== "") {

        let status = "";
        if (activeTab === "1") {
          status = "PENDING";
        } else if (activeTab === "2") {
          status = "APPROVED";
        } else if (activeTab === "3") {
          status = "REJECTED";
        }

        let dateStart: string = ""
        let dateEnd: string = ""
        let ageTo: string | null = null
        let ageFrom: string | null = null
        let gender: string | null = null
        let role: string | null = null

        filterList?.forEach((itemList: any) => {
          if (itemList.set) {
            if (itemList.value === 0) {
              role = itemList.contain
            } else if (itemList.value === 1) {
              dateEnd = itemList.contain
            } else if (itemList.value === 2) {
              dateStart = itemList.contain
            } else if (itemList.value === 3) {
              ageTo = itemList.contain
            } else if (itemList.value === 4) {
              ageFrom = itemList.contain
            } else if (itemList.value === 5) {
              gender = itemList.contain.value
            }
          }
        })

        dispatch(setAgentStatus(status))

        if (isSearchAgent) {
          dispatch(
            SearchAgent({
              search: allRows ? "" : searchDataAgent,
              organizationId: organizationLocalData.id,
              filter: {
                take: rowsPerPage,
                skip: allRows ? 0 : skip,
                status: status,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            })
          )
        } else {
          dispatch(
            GetUsers({
              practitioner: {
                filter: {
                  organizationId: organizationId,
                  status: status,
                  take: rowsPerPage,
                  skip: allRows ? 0 : skip,

                  gender: gender ? gender : undefined,
                  createdAt: {
                    start: dateStart
                      ? new Date(dateStart).toISOString()
                      : undefined,
                    end: dateEnd
                      ? new Date(
                        dateEnd +
                        "T" +
                        moment(new Date().toUTCString()).format("HH:mm")
                      ).toISOString()
                      : undefined,
                  },
                  age: {
                    start: ageFrom ? ageFrom : undefined,
                    end: ageTo ? ageTo : undefined,
                  },
                  role: role ? role : undefined,
                },
                sort: [
                  {
                    field: sortCol === "" ? "firstName" : sortCol,
                    order: sorting === "" ? "asc" : sorting,
                  },
                ],
                include: {
                  vitalsMeasurements: false,
                  identifiers: false,
                  nextOfKin: false,
                  roleAssignments: true,
                },
              },
            })
          )
        }
      }
      dispatch(ResetbulkStates())
    } else if (bulkError) {
      if (bulk) {
        setBulk(false)
      }
      errorToast(bulkError)
      dispatch(ResetbulkStates())
    }
  }, [bulkSuccess, bulkError])


  useEffect(() => {
    window.addEventListener("resize", HandleWidth)
    return () => window.removeEventListener("resize", HandleWidth)
  }, [])

  const HandleWidth = () => {
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    if (pendingAgents && pendingAgents.length > 0 && activeTab === "1") {
      setIsDataRendered(true)
    } else if (
      pendingAgents &&
      pendingAgents.length === 0 &&
      activeTab === "1"
    ) {
      setIsDataRendered(false)
    } else if (
      approvedAgents &&
      approvedAgents.length > 0 &&
      activeTab === "2"
    ) {
      setIsDataRendered(true)
    } else if (
      approvedAgents &&
      approvedAgents.length === 0 &&
      activeTab === "2"
    ) {
      setIsDataRendered(false)
    } else if (
      rejectedAgents &&
      rejectedAgents.length > 0 &&
      activeTab === "3"
    ) {
      setIsDataRendered(true)
    } else if (
      rejectedAgents &&
      rejectedAgents.length === 0 &&
      activeTab === "3"
    ) {
      setIsDataRendered(false)
    }
  }, [pendingAgents, approvedAgents, rejectedAgents])

  useEffect(() => {
    if (isSearchAgent) {
      setFilterList(undefined)
      setIsDataRendered(false)

    }
  }, [isSearchAgent])

  useEffect(() => {
    if (searchAgent && searchAgent.length > 0) {
      setIsDataRendered(true)
    }

  }, [searchAgent])

  // next button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-next-page")

      if (element) {
        element.addEventListener("click", HandleNextPage)
        return () => element.removeEventListener("click", HandleNextPage)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, activeTab, filterList, organizationLocalData, isSearchAgent, searchDataAgent])

  const HandleNextPage = () => {
    const element: any = document.getElementsByClassName("hgiyph")

    if (element) {
      element[0]?.innerText
      let text = element[0]?.innerText?.split(" ")
      let firstTxt = text[0].split("-")
      let num = Number(firstTxt[1])
      let newPageNumber = Math.ceil(num / rowsPerPage)
      let skip222 = newPageNumber * rowsPerPage
      let organizationId = organizationLocalData.id

      let dateStart: string = ""
      let dateEnd: string = ""
      let ageTo: string | null = null
      let ageFrom: string | null = null
      let gender: string | null = null
      let role: string | null = null

      filterList?.forEach((itemList: any) => {
        if (itemList.set) {
          if (itemList.value === 0) {
            role = itemList.contain
          } else if (itemList.value === 1) {
            dateEnd = itemList.contain
          } else if (itemList.value === 2) {
            dateStart = itemList.contain
          } else if (itemList.value === 3) {
            ageTo = itemList.contain
          } else if (itemList.value === 4) {
            ageFrom = itemList.contain
          } else if (itemList.value === 5) {
            gender = itemList.contain.value
          }
        }
      })

      if (organizationId !== "") {

        let status = "";
        if (activeTab === "1") {
          status = "PENDING";
        } else if (activeTab === "2") {
          status = "APPROVED";
        } else if (activeTab === "3") {
          status = "REJECTED";
        }
        dispatch(setAgentStatus(status))

        if (isSearchAgent) {
          dispatch(
            SearchAgent({
              search: searchDataAgent,
              organizationId: organizationId,
              filter: {
                take: rowsPerPage,
                skip: skip222,
                status: status
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            })
          )
        } else {
          dispatch(
            GetUsers({
              practitioner: {
                filter: {
                  organizationId: organizationId,
                  status: status,
                  take: rowsPerPage,
                  skip: skip222,

                  gender: gender ? gender : undefined,
                  createdAt: {
                    start: dateStart
                      ? new Date(dateStart).toISOString()
                      : undefined,
                    end: dateEnd
                      ? new Date(
                        dateEnd +
                        "T" +
                        moment(new Date().toUTCString()).format("HH:mm")
                      ).toISOString()
                      : undefined,
                  },
                  age: {
                    start: ageFrom ? ageFrom : undefined,
                    end: ageTo ? ageTo : undefined,
                  },
                  role: role ? role : undefined,
                },
                sort: [
                  {
                    field: sortCol === "" ? "firstName" : sortCol,
                    order: sorting === "" ? "asc" : sorting,
                  },
                ],
                include: {
                  vitalsMeasurements: false,
                  identifiers: false,
                  nextOfKin: false,
                  roleAssignments: true,
                },
              },
            })
          )
        }
      }
    }
  }

  // //back button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-previous-page")
      if (element) {
        element.addEventListener("click", HandleBack)
        return () => element.removeEventListener("click", HandleBack)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, filterList, organizationLocalData, isSearchAgent, searchDataAgent])

  const HandleBack = () => {
    const element: any = document.getElementsByClassName("hgiyph")

    if (element) {
      element[0]?.innerText
      let text = element[0]?.innerText?.split(" ")
      let firstTxt = text[0].split("-")
      let num = Number(firstTxt[1])
      let newPageNumber = Math.ceil(num / rowsPerPage)
      let x = newPageNumber - 1
      let skip222 = x * rowsPerPage - rowsPerPage
      let organizationId = organizationLocalData.id

      let dateStart: string = ""
      let dateEnd: string = ""
      let ageTo: string | null = null
      let ageFrom: string | null = null
      let gender: string | null = null
      let role: string | null = null

      filterList?.forEach((itemList: any) => {
        if (itemList.set) {
          if (itemList.value === 0) {
            role = itemList.contain
          } else if (itemList.value === 1) {
            dateEnd = itemList.contain
          } else if (itemList.value === 2) {
            dateStart = itemList.contain
          } else if (itemList.value === 3) {
            ageTo = itemList.contain
          } else if (itemList.value === 4) {
            ageFrom = itemList.contain
          } else if (itemList.value === 5) {
            gender = itemList.contain.value
          }
        }
      })
      if (organizationId !== "") {

        let status = "";
        if (activeTab === "1") {
          status = "PENDING";
        } else if (activeTab === "2") {
          status = "APPROVED";
        } else if (activeTab === "3") {
          status = "REJECTED";
        }
        dispatch(setAgentStatus(status))

        if (isSearchAgent) {
          dispatch(
            SearchAgent({
              search: searchDataAgent,
              organizationId: organizationId,
              filter: {
                take: rowsPerPage,
                skip: skip222,
                status: status
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            })
          )
        } else {
          dispatch(
            GetUsers({
              practitioner: {
                filter: {
                  organizationId: organizationId,
                  status: status,
                  take: rowsPerPage,
                  skip: skip222,

                  gender: gender ? gender : undefined,
                  createdAt: {
                    start: dateStart
                      ? new Date(dateStart).toISOString()
                      : undefined,
                    end: dateEnd
                      ? new Date(
                        dateEnd +
                        "T" +
                        moment(new Date().toUTCString()).format("HH:mm")
                      ).toISOString()
                      : undefined,
                  },
                  age: {
                    start: ageFrom ? ageFrom : undefined,
                    end: ageTo ? ageTo : undefined,
                  },
                  role: role ? role : undefined,
                },
                sort: [
                  {
                    field: sortCol === "" ? "firstName" : sortCol,
                    order: sorting === "" ? "asc" : sorting,
                  },
                ],
                include: {
                  vitalsMeasurements: false,
                  identifiers: false,
                  nextOfKin: false,
                  roleAssignments: true,
                },
              },
            })
          )
        }


      }
    }
  }

  // //First Page button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-first-page")
      if (element) {
        element.addEventListener("click", FirstPage)
        return () => element.removeEventListener("click", FirstPage)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, filterList, organizationLocalData, isSearchAgent, searchDataAgent])

  const FirstPage = () => {
    let organizationId = organizationLocalData.id

    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null
    let role: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if (itemList.value === 0) {
          role = itemList.contain
        } else if (itemList.value === 1) {
          dateEnd = itemList.contain
        } else if (itemList.value === 2) {
          dateStart = itemList.contain
        } else if (itemList.value === 3) {
          ageTo = itemList.contain
        } else if (itemList.value === 4) {
          ageFrom = itemList.contain
        } else if (itemList.value === 5) {
          gender = itemList.contain.value
        }
      }
    })
    if (organizationId !== "") {

      let status = "";
      if (activeTab === "1") {
        status = "PENDING";
      } else if (activeTab === "2") {
        status = "APPROVED";
      } else if (activeTab === "3") {
        status = "REJECTED";
      }

      dispatch(setAgentStatus(status))

      if (isSearchAgent) {
        dispatch(
          SearchAgent({
            search: searchDataAgent,
            organizationId: organizationId,
            filter: {
              take: rowsPerPage,
              skip: 0,
              status: status
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      } else {
        dispatch(
          GetUsers({
            practitioner: {
              filter: {
                organizationId: organizationId,
                status: status,
                take: rowsPerPage,
                skip: 0,

                gender: gender ? gender : undefined,
                createdAt: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd
                    ? new Date(
                      dateEnd +
                      "T" +
                      moment(new Date().toUTCString()).format("HH:mm")
                    ).toISOString()
                    : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                role: role ? role : undefined,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
                roleAssignments: true,
              },
            },
          })
        )
      }
    }
  }

  // //last Page button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-last-page")
      if (element) {
        element.addEventListener("click", LastPage)
        return () => element.removeEventListener("click", LastPage)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, totalNumber, filterList, organizationLocalData, isSearchAgent, searchDataAgent])

  const LastPage = () => {
    let newPageNumber = -1;
    if (isSearchAgent) {
      newPageNumber = Math.ceil(totalAgent / rowsPerPage)
    } else {
      newPageNumber = Math.ceil(totalNumber / rowsPerPage)
    }
    let x = newPageNumber - 1
    let skip222 = x * rowsPerPage
    let organizationId = organizationLocalData.id

    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null
    let role: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if (itemList.value === 0) {
          role = itemList.contain
        } else if (itemList.value === 1) {
          dateEnd = itemList.contain
        } else if (itemList.value === 2) {
          dateStart = itemList.contain
        } else if (itemList.value === 3) {
          ageTo = itemList.contain
        } else if (itemList.value === 4) {
          ageFrom = itemList.contain
        } else if (itemList.value === 5) {
          gender = itemList.contain.value
        }
      }
    })
    if (organizationId !== "") {

      let status = "";
      if (activeTab === "1") {
        status = "PENDING";
      } else if (activeTab === "2") {
        status = "APPROVED";
      } else if (activeTab === "3") {
        status = "REJECTED";
      }

      dispatch(setAgentStatus(status))
      if (isSearchAgent) {
        dispatch(
          SearchAgent({
            search: searchDataAgent,
            organizationId: organizationId,
            filter: {
              take: rowsPerPage,
              skip: skip222,
              status: status
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      } else {

        dispatch(
          GetUsers({
            practitioner: {
              filter: {
                organizationId: organizationId,
                status: status,
                take: rowsPerPage,
                skip: skip222,

                gender: gender ? gender : undefined,
                createdAt: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd
                    ? new Date(
                      dateEnd +
                      "T" +
                      moment(new Date().toUTCString()).format("HH:mm")
                    ).toISOString()
                    : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                role: role ? role : undefined,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
                roleAssignments: true,
              },
            },
          })
        )
      }

    }
  }

  useEffect(() => {
    if (rowsPerPage) {
      dispatch(setAgentTakeReducer(rowsPerPage))
    }
  }, [rowsPerPage])

  const handleSort = async (column: any, sortDirection: any) => {
    setSorting(
      sorting === "" ? sortDirection : sorting === "asc" ? "desc" : "asc"
    )
    setSortCol(
      column.name.toLowerCase() === "last active"
        ? "lastActiveAt"
        : column.name.toLowerCase() === "date added"
          ? "createdAt"
          : column.name.toLowerCase() === "name"
            ? "firstName"
            : column.name.toLowerCase() === "role"
              ? "role"
              : column.name.toLowerCase() === "username"
                ? "username"
                : ""
    )


    let organizationId = organizationLocalData.id

    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null
    let role: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if (itemList.value === 0) {
          role = itemList.contain
        } else if (itemList.value === 1) {
          dateEnd = itemList.contain
        } else if (itemList.value === 2) {
          dateStart = itemList.contain
        } else if (itemList.value === 3) {
          ageTo = itemList.contain
        } else if (itemList.value === 4) {
          ageFrom = itemList.contain
        } else if (itemList.value === 5) {
          gender = itemList.contain.value
        }
      }
    })
    if (organizationId !== "") {

      let status = "";
      if (activeTab === "1") {
        status = "PENDING";
      } else if (activeTab === "2") {
        status = "APPROVED";
      } else if (activeTab === "3") {
        status = "REJECTED";
      }

      dispatch(setAgentStatus(status))

      if (isSearchAgent) {

        const body: Search = {
          search: searchDataAgent,
          organizationId: organizationLocalData.id,
          filter: {
            take: rowsPerPage,
            skip: 0,
            status: status
          },
          sort: [
            {
              field:
                column.name.toLowerCase() === "last active"
                  ? "lastActiveAt"
                  : column.name.toLowerCase() === "date added"
                    ? "createdAt"
                    : column.name.toLowerCase() === "name"
                      ? "firstName"
                      : column.name.toLowerCase() === "role"
                        ? "role"
                        : column.name.toLowerCase() === "username"
                          ? "username"
                          : "",
              order:
                sorting === ""
                  ? sortDirection
                  : sorting === "asc"
                    ? "desc"
                    : "asc",
            },
          ],
        }
        dispatch(SearchAgent(body))

      } else {
        dispatch(
          GetUsers({
            practitioner: {
              filter: {
                organizationId: organizationId,
                status: status,
                take: rowsPerPage,
                skip: 0,

                gender: gender ? gender : undefined,
                createdAt: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd
                    ? new Date(
                      dateEnd +
                      "T" +
                      moment(new Date().toUTCString()).format("HH:mm")
                    ).toISOString()
                    : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                role: role ? role : undefined,
              },
              sort: [
                {
                  field:
                    column.name.toLowerCase() === "last active"
                      ? "lastActiveAt"
                      : column.name.toLowerCase() === "date added"
                        ? "createdAt"
                        : column.name.toLowerCase() === "name"
                          ? "firstName"
                          : column.name.toLowerCase() === "role"
                            ? "role"
                            : column.name.toLowerCase() === "username"
                              ? "username"
                              : "",
                  order:
                    sorting === ""
                      ? sortDirection
                      : sorting === "asc"
                        ? "desc"
                        : "asc",
                },
              ],
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
                roleAssignments: true,
              },
            },
          })
        )
      }

    }
  }
  const onRowsPerPageFetchData = (
    rowsPerPage2: number,
    currentPage: number
  ) => {
    let organizationId = organizationLocalData.id

    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null
    let role: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if (itemList.value === 0) {
          role = itemList.contain
        } else if (itemList.value === 1) {
          dateEnd = itemList.contain
        } else if (itemList.value === 2) {
          dateStart = itemList.contain
        } else if (itemList.value === 3) {
          ageTo = itemList.contain
        } else if (itemList.value === 4) {
          ageFrom = itemList.contain
        } else if (itemList.value === 5) {
          gender = itemList.contain.value
        }
      }
    })
    if (organizationId !== "") {

      let status = "";
      if (activeTab === "1") {
        status = "PENDING";
      } else if (activeTab === "2") {
        status = "APPROVED";
      } else if (activeTab === "3") {
        status = "REJECTED";
      }
      dispatch(setAgentStatus(status))

      if (isSearchAgent) {

        dispatch(
          SearchAgent({
            search: searchDataAgent,
            organizationId: organizationId,
            filter: {
              take: rowsPerPage2,
              skip: 0,
              status: status
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      } else {

        dispatch(
          GetUsers({
            practitioner: {
              filter: {
                organizationId: organizationId,
                status: status,
                take: rowsPerPage2,
                skip: 0,
                gender: gender ? gender : undefined,
                createdAt: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd
                    ? new Date(
                      dateEnd +
                      "T" +
                      moment(new Date().toUTCString()).format("HH:mm")
                    ).toISOString()
                    : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                role: role ? role : undefined,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
                roleAssignments: true,
              },
            },
          })
        )
      }
      setReset(true)
      setTimeout(() => setReset(false), 0.1)
    }
  }
  useEffect(() => {
    if (filterList?.length > 0 || filterList?.length === 0) {
      applyFilters()
    }
  }, [filterList])

  const applyFilters = () => {
    const organizationLocalData = Async.getItem("@organization")
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null
    let role: string | null = null

    filterList.forEach((itemList: any) => {
      if (itemList.set) {
        if (itemList.value === 0) {
          role = itemList.contain
        } else if (itemList.value === 1) {
          dateEnd = itemList.contain
        } else if (itemList.value === 2) {
          dateStart = itemList.contain
        } else if (itemList.value === 3) {
          ageTo = itemList.contain
        } else if (itemList.value === 4) {
          ageFrom = itemList.contain
        } else if (itemList.value === 5) {
          gender = itemList.contain.value
        }
      }
    })

    setReset(true)
    setTimeout(() => setReset(false), 0.1)

    dispatch(setIsSearchAgent(false))
    dispatch(ResetSearchAgentStates())
    dispatch(setSearchAgent(""))

    dispatch(
      GetUsers({
        practitioner: {
          filter: {
            organizationId: organizationLocalData.id,
            take: rowsPerPage,
            skip: 0,
            status: status, //'APPROVED',//status
            gender: gender ? gender : undefined,
            createdAt: {
              start: dateStart ? new Date(dateStart).toISOString() : undefined,
              end: dateEnd
                ? new Date(
                  dateEnd +
                  "T" +
                  moment(new Date().toUTCString()).format("HH:mm")
                ).toISOString()
                : undefined,
            },
            age: {
              start: ageFrom ? ageFrom : undefined,
              end: ageTo ? ageTo : undefined,
            },
            role: role ? role : undefined,
          },
          sort: [
            {
              field: sortCol === "" ? "firstName" : sortCol,
              order: sorting === "" ? "asc" : sorting,
            },
          ],
          include: {
            vitalsMeasurements: false,
            identifiers: false,
            nextOfKin: false,
            roleAssignments: true,
          },
        },
      })
    )
    setFilterModel(false)
  }

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [idArchive, setIdArchive] = useState<string>('')

  const toggleArchive = (id?: string) => {

    if (id !== 'bulk' && activeTab === '2' || id !== 'bulk' && activeTab === '3') {
      setShowConfirmationModal(!showConfirmationModal)
      id && setIdArchive(id)

    } else if ((id === 'bulk' && activeTab === '2' && selectedRows?.length > 0) || (id === 'bulk' && activeTab === '3' && selectedRows?.length > 0)) {
      setShowConfirmationModal(!showConfirmationModal)
      setBulk(true)
    } else if ((id === 'bulk' && activeTab === '3' && selectedRows?.length === 0) || (id === 'bulk' && activeTab === '2' && selectedRows?.length === 0)) {
      errorToast("First select rows")
    }
  }

  const onConfirm = () => {
    if ((bulk && activeTab === '2') || (bulk && activeTab === '3')) {
      let list: Archive[] = [];
      selectedRows.forEach((val: any) => {
        list.push(
          {
            model: 'practitioner',
            itemId: val.id
          }
        )
      })
      dispatch(ArchiveItems(list))

    } else if ((!bulk && activeTab === '2') || (!bulk && activeTab === '3')) {
      dispatch(ArchiveItems([{
        model: 'practitioner',
        itemId: idArchive
      }]))
    }
  }

  const onRowClicked = (row: any) => {
    history.push({
      pathname: "/AddEditAgentManagement",
      state: { page: "edit", data: row },
    })
  }

  const onSelectedRowsChange = (data: any) => {
    setSelectedRows(data.selectedRows)
    setAll(data.allSelected)
    setNumberOfRowsSelected(data.selectedCount)
  }

  const handleAccept = () => {

    if (activeTab === '1' && selectedRows?.length > 0 || activeTab === '3' && selectedRows?.length > 0) {
      let list: List[] = [];
      selectedRows.forEach(val => {

        const body = {
          id: val.id,
          status: 'APPROVED'
        }
        list.push(body)
      })
      dispatch(bulkAproveReject(list))
    } else if (activeTab === '1' && selectedRows?.length === 0 || activeTab === '3' && selectedRows?.length === 0) {
      errorToast("First select rows")
    }
  }

  const handleReject = () => {
    if (activeTab === '1' && selectedRows?.length > 0 || activeTab === '3' && selectedRows?.length > 0) {
      let list: List[] = [];
      selectedRows.forEach(val => {

        const body = {
          id: val.id,
          status: 'REJECTED'
        }
        list.push(body)
      })
      dispatch(bulkAproveReject(list))
    } else if (activeTab === '1' && selectedRows?.length === 0 || activeTab === '3' && selectedRows?.length === 0) {
      errorToast("First select rows")
    }
  }

  return (
    <React.Fragment>
      {access ?
        <div>
          <ArchiveConfirmationModal
            toggle={toggleArchive}
            isOpen={showConfirmationModal}
            onConfirm={onConfirm}
            loading={archiveItemsLoading}
          />
          <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
            <Container fluid>
              <AgentFilter
                isOpen={filterModel}
                toggle={toggleFilterModal}
                dispatch={dispatch}
                setFilterList={setFilterList}
                roleArray={roleArray}
                setRoleArray={setRoleArray}
                tempFilterList={tempFilterList}
                setTempFilterList={setTempFilterList}
              />
              <Row>
                <Col lg="3">
                  <h2 className="m-0 align-self-center">
                    <b className="text-nowrap sbl24">Worker Management</b>
                  </h2>
                </Col>
                <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1">
                  {filterList?.map((item: OptionType, index: number) => (
                    <div key={index}>
                      <div
                        className="btn ilaraLigthBlue d-flex align-items-center text-nowrap py-auto px-2 btnSelectedFilter"
                        style={{ height: "38px" }}
                      >
                        <p className="m-0">{item.label}</p>
                        <i
                          onClick={() => {
                            filterList.forEach((itemf: any, i: number) => {
                              if (itemf.value === item.value) {
                                let temp = JSON.parse(JSON.stringify(filterList)) //[...filterList]
                                temp[i].set = false
                                temp.splice(i, 1)
                                setFilterList(temp)
                              }
                            })
                          }}
                          className="bx bx-x font-size-20 ms-2 closeFilter"
                        ></i>
                      </div>
                    </div>
                  ))}
                </Col>
                <Col lg="4" className="text-end text-nowrap">
                  <button
                    type="button"
                    className="btn btn-light me-3 sbold someTextClr"
                    style={{
                      backgroundColor: "white",
                      color: "#2C3242",
                      border: "1px solid #E3E3E3",
                      width: "auto",
                    }}
                    onClick={() => {
                      setTempFilterList(filterList)
                      toggleFilterModal()
                    }}
                  >
                    <i className="bx bx-filter font-size-22 align-middle me-2"></i>
                    Filters
                  </button>

                  {workerEdit && (
                    <button
                      type="button"
                      className="btn btn-primary sbold"
                      style={{ color: "white", width: "auto" }}
                      onClick={() => {
                        history.push({
                          pathname: "/AddEditAgentManagement",
                          state: { page: "add" },
                        })
                      }}
                    >
                      <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                      Add Worker
                    </button>
                  )}
                </Col>
              </Row>

              <div className="horizontal-line mt-3"></div>

              <Row
                className=""
                style={{
                  maxHeight: `${height - 170}px`,
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                }}
              >
                <Col>
                  <ul
                    id="topBorderTab"
                    className="nav nav-tabs nav-tabs-custom pt-2"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        to="#"
                        id="tabBackground"
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                          setIsDataRendered(false)
                          setFilterList(undefined)
                          dispatch(setSearchAgent(""))
                          dispatch(setIsSearchAgent(false))
                          setNumberOfRowsSelected(0)
                          setClearSelectedRows(true)
                          setTimeout(() => setClearSelectedRows(false), 0.1)
                          setCloseDrop(true)
                          setTimeout(() => setCloseDrop(false), 0.1)
                        }}
                      >
                        <span className="im14">Pending Approval</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        id="tabBackground"
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                          setIsDataRendered(false)
                          dispatch(setSearchAgent(""))
                          dispatch(setIsSearchAgent(false))
                          setFilterList(undefined)
                          setNumberOfRowsSelected(0)
                          setClearSelectedRows(true)
                          setTimeout(() => setClearSelectedRows(false), 0.1)
                          setCloseDrop(true)
                          setTimeout(() => setCloseDrop(false), 0.1)
                        }}
                      >
                        <span className="im14">Approved</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        id="tabBackground"
                        className={classnames({
                          active: activeTab === "3",
                        })}
                        onClick={() => {
                          toggleTab("3")
                          setIsDataRendered(false)
                          dispatch(setSearchAgent(""))
                          dispatch(setIsSearchAgent(false))
                          setFilterList(undefined)
                          setNumberOfRowsSelected(0)
                          setClearSelectedRows(true)
                          setTimeout(() => setClearSelectedRows(false), 0.1)
                          setCloseDrop(true)
                          setTimeout(() => setCloseDrop(false), 0.1)
                        }}
                      >
                        <span className="im14">Rejected</span>
                      </NavLink>
                    </NavItem>
                  </ul>

                  {(loading || loadingSearchAgent) && (
                    <div
                      style={{
                        width: "100%",
                        minHeight: "500px",
                        display: "flex",
                        flex: "1",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner
                        style={{
                          color: "#2a45cd",
                          alignSelf: "center",
                          width: "70px",
                          height: "70px",
                        }}
                        animation="border"
                        variant="primary"
                      />
                    </div>
                  )}
                  <TabContent
                    activeTab={activeTab}
                    className={(loading || loadingSearchAgent) ? "mt-4 d-none" : "mt-4"}
                  >
                    {activeTab === "1" ? (
                      <TabPane tabId="1">
                        <Card>
                          <CardBody className="px-0 py-1 border border-light rounded">
                            {isDataRendered ? (
                              <Datatable
                                type="agentPending"
                                data={isSearchAgent ? searchAgent : pendingAgents}
                                toggleModal={toggleArchive}
                                defaultSortAsc={false}
                                handleSort={handleSort}
                                setRowsPerPage={setRowsPerPage}
                                onRowsPerPageFetchData={onRowsPerPageFetchData}
                                setSkip={setSkip}
                                rowsPerPage={rowsPerPage}
                                skip={skip}
                                loadingSearch={loading}
                                paginationTotal={isSearchAgent ? totalAgent : totalNumber}
                                field={sortCol === "" ? "firstName" : sortCol}
                                order={sorting === "" ? "asc" : sorting}
                                handleAccept={handleAccept}
                                currentPage={reset}
                                archiveWorker={archiveWorker}
                                onSelectedRowsChange={onSelectedRowsChange}
                                handleReject={handleReject}
                                activeTab={activeTab}
                                numberOfRowsSelected={numberOfRowsSelected}
                                clearSelectedRows={clearSelectedRows}
                                closeDrop={closeDrop}

                              ></Datatable>
                            ) : (
                              <Datatable
                                type="agentPending"
                                toggleModal={toggleArchive}
                                data={[]}
                                defaultSortAsc={false}
                                handleSort={handleSort}
                                setRowsPerPage={setRowsPerPage}
                                onRowsPerPageFetchData={onRowsPerPageFetchData}
                                setSkip={setSkip}
                                rowsPerPage={rowsPerPage}
                                skip={skip}
                                loadingSearch={loading}
                                handleAccept={handleAccept}
                                paginationTotal={isSearchAgent ? totalAgent : totalNumber}
                                field={sortCol === "" ? "firstName" : sortCol}
                                order={sorting === "" ? "asc" : sorting}
                                currentPage={reset}
                                archiveWorker={archiveWorker}
                                onSelectedRowsChange={onSelectedRowsChange}
                                handleReject={handleReject}
                                activeTab={activeTab}
                                numberOfRowsSelected={numberOfRowsSelected}
                                clearSelectedRows={clearSelectedRows}
                                closeDrop={closeDrop}

                              ></Datatable>
                            )}
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : activeTab === "2" ? (
                      <TabPane tabId="2">
                        <Card>
                          <CardBody className="px-0 py-1 border border-light rounded">
                            {isDataRendered ? (
                              <Datatable
                                type="approved"
                                data={isSearchAgent ? searchAgent : approvedAgents}
                                toggleModal={toggleArchive}
                                defaultSortAsc={false}
                                handleSort={handleSort}
                                setRowsPerPage={setRowsPerPage}
                                onRowsPerPageFetchData={onRowsPerPageFetchData}
                                setSkip={setSkip}
                                rowsPerPage={rowsPerPage}
                                skip={skip}
                                loadingSearch={loading}
                                paginationTotal={isSearchAgent ? totalAgent : totalNumber}
                                field={sortCol === "" ? "firstName" : sortCol}
                                order={sorting === "" ? "asc" : sorting}
                                editBtnWorker={workerEdit}
                                currentPage={reset}
                                archiveWorker={archiveWorker}
                                onRowClicked={onRowClicked}
                                pointerOnHover={true}
                                onSelectedRowsChange={onSelectedRowsChange}
                                activeTab={activeTab}
                                numberOfRowsSelected={numberOfRowsSelected}
                                clearSelectedRows={clearSelectedRows}
                                closeDrop={closeDrop}

                              ></Datatable>
                            ) : (
                              <Datatable
                                type="approved"
                                toggleModal={toggleArchive}
                                data={[]}
                                defaultSortAsc={false}
                                handleSort={handleSort}
                                setRowsPerPage={setRowsPerPage}
                                onRowsPerPageFetchData={onRowsPerPageFetchData}
                                setSkip={setSkip}
                                rowsPerPage={rowsPerPage}
                                skip={skip}
                                loadingSearch={loading}
                                paginationTotal={isSearchAgent ? totalAgent : totalNumber}
                                field={sortCol === "" ? "firstName" : sortCol}
                                order={sorting === "" ? "asc" : sorting}
                                editBtnWorker={workerEdit}
                                currentPage={reset}
                                archiveWorker={archiveWorker}
                                onRowClicked={onRowClicked}
                                pointerOnHover={true}
                                onSelectedRowsChange={onSelectedRowsChange}
                                activeTab={activeTab}
                                numberOfRowsSelected={numberOfRowsSelected}
                                clearSelectedRows={clearSelectedRows}
                                closeDrop={closeDrop}

                              ></Datatable>
                            )}
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : activeTab === "3" ? (
                      <TabPane tabId="3">
                        <Card>
                          <CardBody className="px-0 py-1 border border-light rounded">
                            {isDataRendered ? (
                              <Datatable
                                type="rejected"
                                data={isSearchAgent ? searchAgent : rejectedAgents}
                                toggleModal={toggleArchive}
                                defaultSortAsc={false}
                                handleSort={handleSort}
                                setRowsPerPage={setRowsPerPage}
                                onRowsPerPageFetchData={onRowsPerPageFetchData}
                                setSkip={setSkip}
                                rowsPerPage={rowsPerPage}
                                skip={skip}
                                loadingSearch={loading}
                                paginationTotal={isSearchAgent ? totalAgent : totalNumber}
                                field={sortCol === "" ? "firstName" : sortCol}
                                order={sorting === "" ? "asc" : sorting}
                                currentPage={reset}
                                archiveWorker={archiveWorker}
                                onSelectedRowsChange={onSelectedRowsChange}
                                handleAccept={handleAccept}
                                handleReject={handleReject}
                                activeTab={activeTab}
                                numberOfRowsSelected={numberOfRowsSelected}
                                clearSelectedRows={clearSelectedRows}
                                closeDrop={closeDrop}

                              ></Datatable>
                            ) : (
                              <Datatable
                                type="rejected"
                                toggleModal={toggleArchive}
                                data={[]}
                                defaultSortAsc={false}
                                handleSort={handleSort}
                                setRowsPerPage={setRowsPerPage}
                                onRowsPerPageFetchData={onRowsPerPageFetchData}
                                setSkip={setSkip}
                                rowsPerPage={rowsPerPage}
                                skip={skip}
                                loadingSearch={loading}
                                paginationTotal={isSearchAgent ? totalAgent : totalNumber}
                                field={sortCol === "" ? "firstName" : sortCol}
                                order={sorting === "" ? "asc" : sorting}
                                currentPage={reset}
                                archiveWorker={archiveWorker}
                                onSelectedRowsChange={onSelectedRowsChange}
                                handleAccept={handleAccept}
                                handleReject={handleReject}
                                activeTab={activeTab}
                                numberOfRowsSelected={numberOfRowsSelected}
                                clearSelectedRows={clearSelectedRows}
                                closeDrop={closeDrop}
                              ></Datatable>
                            )}
                          </CardBody>
                        </Card>
                      </TabPane>
                    ) : null}
                  </TabContent>
                </Col>
              </Row>
            </Container>
          </div>
        </div> : <div className="permissionWarning">User does not have permissions to view this screen!</div>}
    </React.Fragment>
  )
}

export default AgentManagement
