import { createAsyncThunk } from "@reduxjs/toolkit";
import { MyKnownError } from "~/models/Error.model";
import { postRequest } from "~/store/apiHelper";
import { DeleteRole, Get, rolesUpdateUserRole } from "./types";

//default roles
export const GetListDefaultRoles = createAsyncThunk<
  any,
  Get,
  { rejectValue: MyKnownError }
>("roles/defaultRoles", async (data: Get, thunkApi) => {
  return postRequest("/rpc/rolesListAllDefaultRoles", data, true, thunkApi)
});

//organization roles
export const OrganizationRoles = createAsyncThunk<
  any,
  Get,
  { rejectValue: MyKnownError }
>("roles/getOrgRole", async (data: Get, thunkApi) => {
  return postRequest("/rpc/rolesGetOrganizationRoles", data, true, thunkApi)
});

//organization roles update
export const RolesUpdateBatch = createAsyncThunk<
  any,
  Get,
  { rejectValue: MyKnownError }
>("roles/update", async (data: Get, thunkApi) => {
  return postRequest("/rpc/rolesUpdateBatch", data, true, thunkApi)
});

//organization role delete
export const RolesDeleteCustomRole = createAsyncThunk<
  any,
  DeleteRole,
  { rejectValue: MyKnownError }
>("roles/delete", async (data: DeleteRole, thunkApi) => {
  return postRequest("/rpc/rolesDeleteCustomRole", data, true, thunkApi)
});

//user role update
export const UpdateUserRole = createAsyncThunk<
  any,
  rolesUpdateUserRole,
  { rejectValue: MyKnownError }
>("roles/rolesUpdateUserRole", async (data: rolesUpdateUserRole, thunkApi) => {
  return postRequest("/rpc/rolesUpdateUserRole", data, true, thunkApi)
});