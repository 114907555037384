import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from '~/models/User.model';
import { ChangeUserPassword, CheckCredentialsAvailable, GenerateUsername, GetResetPasswordIdentifiers, PractitionerSelfSignUp, ResetPassword, SendResetPasswordKeyByUsername, SendTemporaryPassword, SignInUser, VerifyOtp } from './thunks';
import { Auth } from './types';

const initialState: Auth = {
  id: '',
  organizationId: '',
  mode: '',
  profile: {} as IProfile,
  loading: false,
  error: '',
  token: '',
  username: '',
  patientId: '',
  //ChangeUserPassword
  loadingPasswordChange: false,
  successPasswordChange: false,
  errorPasswordChange: '',
  //Practitioner Self SignUp
  loadingSelfSignup: false,
  successSelfSignup: false,
  errorSelfSignup: '',
  //ResetPassword
  loadingResetPassword: false,
  successResetPassword: false,
  errorResetPassword: '',
  //CheckCredentialsAvailable
  loadingCheckCredentials: false,
  successCheckCredentials: false,
  errorCheckCredentials: '',
  //SendResetPasswordKeyByUsername
  loadingResetKeyByUsername: false,
  successResetKeyByUsername: false,
  errorResetKeyByUsername: '',
  //VerifyOtp
  loadingVerifyOtp: false,
  successVerifyOtp: false,
  errorVerifyOtp: '',
  //GetResetPasswordIdentifiers
  loadingGetIdentifiersByUsername: false,
  successGetIdentifiersByUsername: false,
  errorGetIdentifiersByUsername: '',
  IdentifiersByUsername: {},
  //SendTemporaryPassword
  loadingSendTemporaryPassword: false,
  successSendTemporaryPassword: false,
  errorSendTemporaryPassword: '',
  //GenerateUsername
  loadingGenerateUsername: false,
  successGenerateUsername: false,
  errorGenerateUsername: '',
  generatedName: '',
  requirePasswordChange: false,
  resetKey: ''
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(SignInUser.pending, state => {
        state.loading = true;
        state.error = '';
      })
      .addCase(SignInUser.fulfilled, (state, { payload }) => {
        state.id = payload.id;
        state.organizationId = payload.organizationId;
        state.mode = payload.mode;
        state.profile = payload.profile;
        state.loading = false;
        state.error = '';
        state.token = payload.token;
        state.requirePasswordChange = payload.requirePasswordChange;
        state.resetKey = payload.resetKey;
        state.username = payload.username;
        state.patientId = payload.patientId;
      })
      .addCase(SignInUser.rejected, (state, action) => {
        state.loading = false;
        if (action.error) {
          state.error = action.error.message;
        } else {
          state.error = 'Wrong username, password';
        }
      })
      //Change password for user on behalf
      .addCase(ChangeUserPassword.pending, state => {
        state.loadingPasswordChange = true;
        state.errorPasswordChange = '';
        state.successPasswordChange = false;
      })
      .addCase(ChangeUserPassword.fulfilled, (state) => {
        state.loadingPasswordChange = false;
        state.errorPasswordChange = '';
        state.successPasswordChange = true;
      })
      .addCase(ChangeUserPassword.rejected, (state, action) => {
        state.loadingPasswordChange = false;
        state.successPasswordChange = false;
        if (action.error) {
          state.errorPasswordChange = action.error.message as string;
        } else {
          state.errorPasswordChange = 'Error changing password';
        }
      })
      //Generate Username
      .addCase(GenerateUsername.pending, state => {
        state.loadingGenerateUsername = true;
        state.errorGenerateUsername = '';
        state.successGenerateUsername = false;
      })
      .addCase(GenerateUsername.fulfilled, (state, { payload }) => {
        state.loadingGenerateUsername = false;
        state.errorGenerateUsername = '';
        state.successGenerateUsername = true;
        state.generatedName = payload;
      })
      .addCase(GenerateUsername.rejected, (state, action) => {
        state.loadingGenerateUsername = false;
        state.successGenerateUsername = false;
        if (action.error) {
          state.errorGenerateUsername = action.error.message as string;
        } else {
          state.errorGenerateUsername = 'Error generating username';
        }
      })
      //Send Temporary Password
      .addCase(SendTemporaryPassword.pending, state => {
        state.loadingSendTemporaryPassword = true;
        state.errorSendTemporaryPassword = '';
        state.successSendTemporaryPassword = false;
      })
      .addCase(SendTemporaryPassword.fulfilled, (state, { payload }) => {
        state.loadingSendTemporaryPassword = false;
        state.errorSendTemporaryPassword = '';
        state.successSendTemporaryPassword = true;
      })
      .addCase(SendTemporaryPassword.rejected, (state, action) => {
        state.loadingSendTemporaryPassword = false;
        state.successSendTemporaryPassword = false;
        if (action.error) {
          state.errorSendTemporaryPassword = action.error.message as string;
        } else {
          state.errorSendTemporaryPassword = 'Error sending temporary password';
        }
      })
      //Reset Password
      .addCase(ResetPassword.pending, state => {
        state.loadingResetPassword = true;
        state.errorResetPassword = '';
        state.successResetPassword = false;
      })
      .addCase(ResetPassword.fulfilled, (state, { payload }) => {
        state.loadingResetPassword = false;
        state.errorResetPassword = '';
        state.successResetPassword = true;
      })
      .addCase(ResetPassword.rejected, (state, action) => {
        state.loadingResetPassword = false;
        state.successResetPassword = false;
        if (action.error) {
          state.errorResetPassword = action.error.message as string;
        } else {
          state.errorResetPassword = 'Error reseting password';
        }
      })
      //Practitioner Self SignUp
      .addCase(PractitionerSelfSignUp.pending, state => {
        state.loadingSelfSignup = true;
        state.errorSelfSignup = '';
        state.successSelfSignup = false;
      })
      .addCase(PractitionerSelfSignUp.fulfilled, (state, { payload }) => {
        state.loadingSelfSignup = false;
        state.errorSelfSignup = '';
        state.successSelfSignup = true;
      })
      .addCase(PractitionerSelfSignUp.rejected, (state, action) => {
        state.loadingSelfSignup = false;
        state.successSelfSignup = false;
        if (action.error) {
          state.errorSelfSignup = action.error.message as string;
        } else {
          state.errorSelfSignup = 'Error signing up';
        }
      })
      //Check Credentials Available
      .addCase(CheckCredentialsAvailable.pending, state => {
        state.loadingCheckCredentials = true;
        state.errorCheckCredentials = '';
        state.successCheckCredentials = false;
      })
      .addCase(CheckCredentialsAvailable.fulfilled, (state, { payload }) => {
        state.loadingCheckCredentials = false;
        state.errorCheckCredentials = '';
        state.successCheckCredentials = true;
      })
      .addCase(CheckCredentialsAvailable.rejected, (state, action) => {
        state.loadingCheckCredentials = false;
        state.successCheckCredentials = false;
        if (action.error) {
          state.errorCheckCredentials = action.error.message as string;
        } else {
          state.errorCheckCredentials = 'Error checking credentials';
        }
      })
      //Send Reset Password Key By Username
      .addCase(SendResetPasswordKeyByUsername.pending, state => {
        state.loadingResetKeyByUsername = true;
        state.errorResetKeyByUsername = '';
        state.successResetKeyByUsername = false;
      })
      .addCase(SendResetPasswordKeyByUsername.fulfilled, (state, { payload }) => {
        state.loadingResetKeyByUsername = false;
        state.errorResetKeyByUsername = '';
        state.successResetKeyByUsername = true;
      })
      .addCase(SendResetPasswordKeyByUsername.rejected, (state, action) => {
        state.loadingResetKeyByUsername = false;
        state.successResetKeyByUsername = false;
        if (action.error) {
          state.errorResetKeyByUsername = action.error.message as string;
        } else {
          state.errorResetKeyByUsername = 'Error sending key';
        }
      })
      //Get Reset Password Identifiers
      .addCase(GetResetPasswordIdentifiers.pending, state => {
        state.loadingGetIdentifiersByUsername = true;
        state.errorGetIdentifiersByUsername = '';
        state.successGetIdentifiersByUsername = false;
      })
      .addCase(GetResetPasswordIdentifiers.fulfilled, (state, { payload }) => {
        state.loadingGetIdentifiersByUsername = false;
        state.errorGetIdentifiersByUsername = '';
        state.successGetIdentifiersByUsername = true;
        state.IdentifiersByUsername = payload
      })
      .addCase(GetResetPasswordIdentifiers.rejected, (state, action) => {
        state.loadingGetIdentifiersByUsername = false;
        state.successGetIdentifiersByUsername = false;
        if (action.error) {
          state.errorGetIdentifiersByUsername = action.error.message as string;
        } else {
          state.errorGetIdentifiersByUsername = 'Error getting identifiers';
        }
      })
      //Verify Otp
      .addCase(VerifyOtp.pending, state => {
        state.loadingVerifyOtp = true;
        state.errorVerifyOtp = '';
        state.successVerifyOtp = false;
      })
      .addCase(VerifyOtp.fulfilled, (state, { payload }) => {
        state.loadingVerifyOtp = false;
        state.errorVerifyOtp = '';
        state.successVerifyOtp = true;
      })
      .addCase(VerifyOtp.rejected, (state, action) => {
        state.loadingVerifyOtp = false;
        state.successVerifyOtp = false;
        if (action.error) {
          state.errorVerifyOtp = action.error.message as string;
        } else {
          state.errorVerifyOtp = 'Error verifying otp';
        }
      })
      ;
  },
  reducers: {
    clearState(state) {
      state.id = '';
      state.organizationId = '';
      state.mode = '';
      state.profile = {} as IProfile;
      state.loading = false;
      state.error = '';
      state.token = '';
      state.username = '';
    },
    ResetUserPasswordStates(state) {
      state.loadingPasswordChange = false;
      state.successPasswordChange = false;
      state.errorPasswordChange = '';
    },
    ResetGenerateUsername(state) {
      state.loadingGenerateUsername = false;
      state.successGenerateUsername = false;
      state.errorGenerateUsername = '';
      state.generatedName = ''
    },
    ResetSendTemporaryPassword(state) {
      state.loadingSendTemporaryPassword = false;
      state.successSendTemporaryPassword = false;
      state.errorSendTemporaryPassword = '';
    },
    ResetPractitionerSelfSignUp(state) {
      state.loadingSelfSignup = false;
      state.successSelfSignup = false;
      state.errorSelfSignup = '';
    },
    ResetResetPassword(state) {
      state.loadingResetPassword = false;
      state.successResetPassword = false;
      state.errorResetPassword = '';
    },
    ResetCheckCredentials(state) {
      state.loadingCheckCredentials = false;
      state.successCheckCredentials = false;
      state.errorCheckCredentials = '';
    },
    ResetResetKeyByUsername(state) {
      state.loadingResetKeyByUsername = false;
      state.successResetKeyByUsername = false;
      state.errorResetKeyByUsername = '';
    },
    ResetGetResetPasswordIdentifiers(state) {
      state.loadingGetIdentifiersByUsername = false;
      state.successGetIdentifiersByUsername = false;
      state.errorGetIdentifiersByUsername = '';
    },
    ResetVerifyOtp(state) {
      state.loadingVerifyOtp = false;
      state.successVerifyOtp = false;
      state.errorVerifyOtp = '';
    },
  },
});

// // Action creators are generated for each case reducer function
export const { clearState,
  ResetUserPasswordStates, ResetGenerateUsername,
  ResetSendTemporaryPassword, ResetPractitionerSelfSignUp,
  ResetResetPassword, ResetCheckCredentials,
  ResetResetKeyByUsername, ResetGetResetPasswordIdentifiers,
  ResetVerifyOtp } =
  authSlice.actions;
export default authSlice.reducer;
