import { createSlice } from "@reduxjs/toolkit"
import { GetUsers, GetUserSelf, SelectsAggregation } from "./thunk"
import { Users, User } from "./types"

const initialState: Users = {
  pendingAgents: [],
  approvedAgents: [],
  rejectedAgents: [],
  agent: {} as User,
  status: "",
  patients: [],
  patient: {} as User,
  loading: false,
  success: false,
  error: "",
  orgId: "",
  vitalScreen: "",

  orgIdApr: "",
  orgIdRej: "",
  orgIdPen: "",
  total: 0,

  loadingAggregation: false,
  successAggregation: false,
  errorAggregation: "",
  dataListAggregation: [],

  // loadingSelf: false,
  // successSelf: false,
  // errorSelf: "",
  // dataSelf: {} as User,
}
const selectSlice = createSlice({
  name: "select",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(GetUsers.pending, state => {
        state.loading = true
        state.error = ""
        state.success = false
      })
      .addCase(GetUsers.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.error = ""
        if (payload.practitioner) {
          if (state.status === "APPROVED") {
            state.approvedAgents = payload.practitioner
          } else if (state.status === "PENDING") {
            state.pendingAgents = payload.practitioner
          } else if (state.status === "REJECTED") {
            state.rejectedAgents = payload.practitioner
          } else {
            state.agent = payload.practitioner[0]
          }
        } else if (payload.patient) {
          if (
            state.vitalScreen === "/vitalsHistory" ||
            state.vitalScreen === "/AddEditPatientManagement"
          ) {
            state.patient = payload.patient[0]
            state.vitalScreen = ""
            state.total = payload.total
          } else {
            state.patients = payload.patient
            state.total = payload.total
          }
        }
      })
      .addCase(GetUsers.rejected, (state, action) => {
        state.success = false
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = "Error getting data"
        }
      })

      //aggregations
      .addCase(SelectsAggregation.pending, state => {
        state.loadingAggregation = true
        state.errorAggregation = ""
        state.successAggregation = false
      })
      .addCase(SelectsAggregation.fulfilled, (state, { payload }) => {
        state.loadingAggregation = false
        state.successAggregation = true
        state.errorAggregation = ""
        state.dataListAggregation = payload
      })
      .addCase(SelectsAggregation.rejected, (state, action) => {
        state.successAggregation = false
        state.loadingAggregation = false
        if (action.payload) {
          state.errorAggregation = action.payload.message
        } else {
          state.errorAggregation = "Error fetching data"
        }
      })
      //GetUserSelf
      .addCase(GetUserSelf.pending, state => {
        state.loading = true
        state.error = ""
        state.success = false
      })
      .addCase(GetUserSelf.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.error = ""
        state.agent = payload.practitioner
      })
      .addCase(GetUserSelf.rejected, (state, action) => {
        state.success = false
        state.loading = false
        if (action.payload) {
          state.error = action.payload.message
        } else {
          state.error = "Error fetching data"
        }
      })
  },
  reducers: {
    setOrg(state, action) {
      state.orgId = action.payload
    },
    setAgentStatus(state, action) {
      state.status = action.payload
    },
    setVitalScreenRedux(state, action) {
      state.vitalScreen = action.payload
    },
    setOrgApproved(state, action) {
      state.orgIdApr = action.payload
    },
    setOrgPen(state, action) {
      state.orgIdPen = action.payload
    },
    setOrgRej(state, action) {
      state.orgIdRej = action.payload
    },
    resetSelectActions(state) {
      state.success = false
      state.loading = false
      state.error = ""
      state.agent = {} as User
    }
  },
})

export const {
  setOrg,
  setAgentStatus,
  setVitalScreenRedux,
  setOrgPen,
  setOrgRej,
  setOrgApproved,
  resetSelectActions
} = selectSlice.actions
export default selectSlice.reducer
