import { useHistory } from "react-router-dom"
import React, { FC, useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"
import Datatable from "~/components/Common/Datatable"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import PatientFilter from "~/components/Common/Modals/Filter/PatientFilter"
import { GetUsers } from "~/store/reducers/Select/thunk"
import Async from "~/utils/Async"
import { setOrg } from "~/store/reducers/Select"
import { Instance } from "~/utils/axios"
import moment from "moment"
import { ResetSearchPatientStates, setIsSearchPatient, setPatientTakeReducer, setResetTable, setSearchPatient } from "~/store/reducers/Patient"
import { SearchPatient } from "~/store/reducers/Patient/thunk"
import { errorToast, successToast } from "~/utils/toast"
import { resetArchiveItems } from "~/store/reducers/Permission"
import ArchiveConfirmationModal from "~/components/Common/Modals/confirmationModal/archive"
import { ArchiveItems } from "~/store/reducers/Permission/thunk"
import { setPreloader } from "~/store/reducers/loader"

type OptionType = {
  value: string | number
  label: string
  set?: boolean
}
interface Archive {
  model: string,
  itemId: string
}
const PatientManagement: FC = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const [filterModel, setFilterModel] = useState<boolean>(false)
  const toggleFilterModal = () => setFilterModel(!filterModel)
  const { patients, loading, orgId } = useAppSelector(
    state => state.SelectReducer
  )
  const {
    loadingSearchPatient,
    searchPatient,
    total,
    patientSearchData,
    isSearch,
    resetTable,
    errorSearchPatient,
    successSearchPatient
  } = useAppSelector(state => state.PatientReducer)

  const {
    archiveItemsSuccess,
    archiveItemsError, archiveItemsLoading } = useAppSelector(state => state.PermissionReducer)

  const organizationLocalData = Async.getItem("@organization")

  const [access, setAccess] = useState<boolean>(true);
  const [height, setHeight] = useState(window.innerHeight)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [skip, setSkip] = useState<number>(0)
  const [sorting, setSorting] = useState<string>("")
  const [sortCol, setSortCol] = useState<string>("")
  const [isDataRendered, setIsDataRendered] = useState<boolean>(false)
  const [totalNumber, setTotalNumber] = useState<number>(0)
  const [organizationId, setOrganizationId] = useState<string>("")
  const [editPatient, setEditPatient] = useState<boolean>(false)
  const [filterList, setFilterList] = useState<any>()
  const [tempFilterList, setTempFilterList] = useState<any>([])
  const [reset, setReset] = useState<boolean>(false)
  const [archivePatient, setArchivePatient] = useState<boolean>(false)
  const [bulk, setBulk] = useState<boolean>(false)
  const [allRows, setAll] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [numberOfRowsSelected, setNumberOfRowsSelected] = useState<number>(0)
  const [clearSelectedRows, setClearSelectedRows] = useState<boolean>(false)

  const getToken = () => {
    const data = localStorage.getItem("@auth")
    if (data) {
      const token = JSON.parse(data).token
      return token
    }
    return ""
  }
  useEffect(() => {

    if (archiveItemsSuccess) {
      setNumberOfRowsSelected(0)
      setClearSelectedRows(true)
      setTimeout(() => setClearSelectedRows(false), 0.1)
      successToast('Patient Archived')
      toggleArchive();
      dispatch(resetArchiveItems())
      countAPICall()
      if (bulk) {
        setBulk(false)
      }
      const organizationLocalData = Async.getItem("@organization")

      let conditionArr: string[] = []
      let dateStart: string = ""
      let dateEnd: string = ""
      let ageTo: string | null = null
      let ageFrom: string | null = null
      let gender: string | null = null

      filterList?.forEach((itemList: any) => {
        if (itemList.set) {
          if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
            conditionArr.push(itemList.contain)
          } else if (itemList.value === 9) {
            dateStart = itemList.contain
          } else if (itemList.value === 10) {
            dateEnd = itemList.contain
          } else if (itemList.value === 11) {
            ageFrom = itemList.contain
          } else if (itemList.value === 12) {
            ageTo = itemList.contain
          } else if (itemList.value === 13) {
            gender = itemList.contain.value
          }
        }
      })
      if (allRows || searchPatient?.length === 1 || patients?.length === 1) {
        dispatch(setSearchPatient(''));
        setSkip(0)
        setReset(true)
        setTimeout(() => setReset(false), 0.1)
        const ele: any = document.getElementsByName('select-all-rows');
        ele.checked = false;
      }
      if (isSearch) {
        dispatch(
          SearchPatient({
            search: (allRows || searchPatient?.length === 1 || patients?.length === 1) ? "" : patientSearchData,
            organizationId: organizationLocalData.id,
            filter: {
              take: rowsPerPage,
              skip: (allRows || searchPatient?.length === 1 || patients?.length === 1) ? 0 : skip,
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )

      } else {
        dispatch(
          GetUsers({
            patient: {
              filter: {
                organizationId: organizationLocalData.id,
                take: rowsPerPage,
                skip: (allRows || searchPatient?.length === 1 || patients?.length === 1) ? 0 : skip,

                gender: gender ? gender : undefined,
                createdBetween: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd
                    ? new Date(
                      dateEnd +
                      "T" +
                      moment(new Date().toUTCString()).format("HH:mm")
                    ).toISOString()
                    : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                conditions: conditionArr,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
              },
            },
          })
        )
      }
    } else if (archiveItemsError) {
      if (bulk) {
        setBulk(false)
      }
      errorToast(archiveItemsError)
      toggleArchive();
      dispatch(resetArchiveItems())
    }
  }, [archiveItemsSuccess, archiveItemsError])

  useEffect(() => {
    if (filterList?.length > 0 || filterList?.length === 0) {
      applyFilters()
    }
  }, [filterList])

  useEffect(() => {
    const data = Async.getItem("@auth")
    setAccess(data?.role?.permissions["patient-view"])
    setTimeout(() => {
      dispatch(setPreloader(false))
    }, 1000)
    return () => {
      dispatch(setSearchPatient(""))
    };
  }, []);

  useEffect(() => {
    if (successSearchPatient) {
      dispatch(setResetTable(false));
    } else if (errorSearchPatient) {
      dispatch(setResetTable(false));
    }
  }, [
    errorSearchPatient,
    successSearchPatient
  ]);

  useEffect(() => {
    if (resetTable) {
      setNumberOfRowsSelected(0)
      setClearSelectedRows(true)
      setTimeout(() => setClearSelectedRows(false), 0.1)
    }
  }, [resetTable]);


  const applyFilters = () => {
    const organizationLocalData = Async.getItem("@organization")
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    setReset(true)
    setTimeout(() => setReset(false), 0.1)

    dispatch(setIsSearchPatient(false))
    dispatch(ResetSearchPatientStates())
    dispatch(setSearchPatient(""))

    dispatch(
      GetUsers({
        patient: {
          filter: {
            take: rowsPerPage,
            skip: 0,
            organizationId: organizationLocalData.id,
            gender: gender ? gender : undefined,
            createdBetween: {
              start: dateStart ? new Date(dateStart).toISOString() : undefined,
              end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
            },
            age: {
              start: ageFrom ? ageFrom : undefined,
              end: ageTo ? ageTo : undefined,
            },
            conditions: conditionArr,
          },
          sort: [
            {
              field: sortCol === "" ? "firstName" : sortCol,
              order: sorting === "" ? "asc" : sorting,
            },
          ],
          include: {
            vitalsMeasurements: false,
            identifiers: false,
            nextOfKin: false,
          },
        },
      })
    )
    setFilterModel(false)
  }
  //api call count
  useEffect(() => {
    countAPICall();
  }, [filterList])

  const countAPICall = () => {
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })

    const data = {
      patient: {
        filter: {
          organizationId: organizationLocalData.id,

          gender: gender ? gender : undefined,
          createdBetween: {
            start: dateStart ? new Date(dateStart).toISOString() : undefined,
            end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
          },
          age: {
            start: ageFrom ? ageFrom : undefined,
            end: ageTo ? ageTo : undefined,
          },
          conditions: conditionArr,
        },
      },
    }
    const headers = {
      "X-Access-Token": false,
    }

    headers["X-Access-Token"] = getToken()

    Instance.post(
      "https://diagnostics-mono-backend-wdjc5wyhsa-nw.a.run.app/rpc/count",
      data,
      { headers: headers }
    ).then((response: any) => {
      if (response) {
        setTotalNumber(response?.data?.data?.patient)
      }
    })
  }

  useEffect(() => {
    if (rowsPerPage) {
      dispatch(setPatientTakeReducer(rowsPerPage))
    }
  }, [rowsPerPage])

  useEffect(() => {
    if (patients && patients.length > 0) {
      setIsDataRendered(true)
    }
  }, [patients])

  useEffect(() => {
    if (searchPatient && searchPatient.length > 0) {
      setIsDataRendered(true)
    }

  }, [searchPatient])


  useEffect(() => {
    const per = Async.getItem("@auth")
    setEditPatient(per.role.permissions["patient-edit"])
    setArchivePatient(per.role.permissions["archive-edit"])

    const superUser = localStorage.getItem("@super")
    dispatch(setIsSearchPatient(false))
    if (superUser === "true") {
      //get all patients
      if (organizationLocalData) {
        const organizationId = organizationLocalData.id
        setOrganizationId(organizationId)

        if (organizationLocalData) {
          dispatch(setOrg(organizationId))
          dispatch(
            GetUsers({
              patient: {
                filter: {
                  organizationId: organizationLocalData.id,
                  take: rowsPerPage,
                  skip: 0,
                },
                include: {
                  vitalsMeasurements: false,
                  identifiers: false,
                  nextOfKin: false,
                },
                sort: [
                  {
                    field: sortCol === "" ? "firstName" : sortCol,
                    order: sorting === "" ? "asc" : sorting,
                  },
                ],
              },
            })
          )
        }
      }
      //sideBar set
      var body: HTMLElement = document.body
      dispatch(setSecondaryBarTrue())
      if (!collapsed) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      dispatch(setSecondaryBarTrue())
      const auth = Async.getItem("@auth")
      if (auth.organizationId) {
        dispatch(setOrg(auth.organizationId))
        setOrganizationId(auth.organizationId)

        dispatch(
          GetUsers({
            patient: {
              filter: {
                organizationId: auth.organizationId,
                take: rowsPerPage,
                skip: 0,
              },
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            },
          })
        )
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", HandleWidth)
    return () => window.removeEventListener("resize", HandleWidth)
  }, [])

  const HandleWidth = () => {
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    if (isSearch) {
      setFilterList(undefined)
      setIsDataRendered(false)
    }
  }, [isSearch])

  useEffect(() => {
    setReset(true)
    setTimeout(() => setReset(false), 0.1)
  }, [patientSearchData])

  // next button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-next-page")
      if (element) {

        element.addEventListener("click", HandleNextPage)
        return () => element.removeEventListener("click", HandleNextPage)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, filterList, isSearch, organizationId, patientSearchData])

  const HandleNextPage = () => {
    const element: any = document.getElementsByClassName("hgiyph")

    if (element) {
      element[0]?.innerText
      let text = element[0]?.innerText?.split(" ")
      let firstTxt = text[0].split("-")
      let num = Number(firstTxt[1])
      let newPageNumber = Math.ceil(num / rowsPerPage)
      let skip222 = newPageNumber * rowsPerPage

      let conditionArr: string[] = []
      let dateStart: string = ""
      let dateEnd: string = ""
      let ageTo: string | null = null
      let ageFrom: string | null = null
      let gender: string | null = null

      filterList?.forEach((itemList: any) => {
        if (itemList.set) {
          if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
            conditionArr.push(itemList.contain)
          } else if (itemList.value === 9) {
            dateStart = itemList.contain
          } else if (itemList.value === 10) {
            dateEnd = itemList.contain
          } else if (itemList.value === 11) {
            ageFrom = itemList.contain
          } else if (itemList.value === 12) {
            ageTo = itemList.contain
          } else if (itemList.value === 13) {
            gender = itemList.contain.value
          }
        }
      })

      if (isSearch) {

        dispatch(
          SearchPatient({
            search: patientSearchData,
            organizationId: organizationId,
            filter: {
              take: rowsPerPage,
              skip: skip222,
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      } else {
        dispatch(
          GetUsers({
            patient: {
              filter: {
                organizationId: organizationId,
                take: rowsPerPage,
                skip: skip222,

                gender: gender ? gender : undefined,
                createdBetween: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                conditions: conditionArr,
              },
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
              },
              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            },
          })
        )
      }

    }
  }

  // //back button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-previous-page")
      if (element) {
        element.addEventListener("click", HandleBack)
        return () => element.removeEventListener("click", HandleBack)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, filterList, isSearch, organizationId, patientSearchData])

  const HandleBack = () => {
    const element: any = document.getElementsByClassName("hgiyph")

    if (element) {
      element[0]?.innerText
      let text = element[0]?.innerText?.split(" ")
      let firstTxt = text[0].split("-")
      let num = Number(firstTxt[1])
      let newPageNumber = Math.ceil(num / rowsPerPage)
      let x = newPageNumber - 1
      let skip222 = x * rowsPerPage - rowsPerPage

      let conditionArr: string[] = []
      let dateStart: string = ""
      let dateEnd: string = ""
      let ageTo: string | null = null
      let ageFrom: string | null = null
      let gender: string | null = null

      filterList?.forEach((itemList: any) => {
        if (itemList.set) {
          if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
            conditionArr.push(itemList.contain)
          } else if (itemList.value === 9) {
            dateStart = itemList.contain
          } else if (itemList.value === 10) {
            dateEnd = itemList.contain
          } else if (itemList.value === 11) {
            ageFrom = itemList.contain
          } else if (itemList.value === 12) {
            ageTo = itemList.contain
          } else if (itemList.value === 13) {
            gender = itemList.contain.value
          }
        }
      })

      if (isSearch) {

        dispatch(
          SearchPatient({
            search: patientSearchData,
            organizationId: organizationId,
            filter: {
              take: rowsPerPage,
              skip: skip222,
            },
            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          })
        )
      } else {
        dispatch(
          GetUsers({
            patient: {
              filter: {
                organizationId: organizationId,
                take: rowsPerPage,
                skip: skip222,

                gender: gender ? gender : undefined,
                createdBetween: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                conditions: conditionArr,
              },
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
              },

              sort: [
                {
                  field: sortCol === "" ? "firstName" : sortCol,
                  order: sorting === "" ? "asc" : sorting,
                },
              ],
            },
          })
        )
      }

    }
  }

  // //First Page button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-first-page")
      if (element) {
        element.addEventListener("click", FirstPage)
        return () => element.removeEventListener("click", FirstPage)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, filterList, organizationId, isSearch, patientSearchData])

  const FirstPage = () => {
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })

    if (isSearch) {

      dispatch(
        SearchPatient({
          search: patientSearchData,
          organizationId: organizationId,
          filter: {
            take: rowsPerPage,
            skip: 0,
          },
          sort: [
            {
              field: sortCol === "" ? "firstName" : sortCol,
              order: sorting === "" ? "asc" : sorting,
            },
          ],
        })
      )
    } else {

      dispatch(
        GetUsers({
          patient: {
            filter: {
              organizationId: organizationId,
              take: rowsPerPage,
              skip: 0,
              gender: gender ? gender : undefined,
              createdBetween: {
                start: dateStart ? new Date(dateStart).toISOString() : undefined,
                end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
              },
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            },
            include: {
              vitalsMeasurements: false,
              identifiers: false,
              nextOfKin: false,
            },

            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          },
        })
      )
    }

  }

  // //last Page button
  useEffect(() => {
    if (isDataRendered) {
      const element = document.getElementById("pagination-last-page")
      if (element) {
        element.addEventListener("click", LastPage)
        return () => element.removeEventListener("click", LastPage)
      }
    }
  }, [isDataRendered, rowsPerPage, sortCol, sorting, totalNumber, filterList, organizationId, isSearch, total, patientSearchData])

  const LastPage = () => {
    let newPageNumber = -1;
    if (isSearch) {
      newPageNumber = Math.ceil(total / rowsPerPage)
    } else {
      newPageNumber = Math.ceil(totalNumber / rowsPerPage)
    }
    let x = newPageNumber - 1
    let skip222 = x * rowsPerPage

    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })

    if (isSearch) {

      dispatch(
        SearchPatient({
          search: patientSearchData,
          organizationId: organizationId,
          filter: {
            take: rowsPerPage,
            skip: skip222,
          },
          sort: [
            {
              field: sortCol === "" ? "firstName" : sortCol,
              order: sorting === "" ? "asc" : sorting,
            },
          ],
        })
      )
    } else {
      dispatch(
        GetUsers({
          patient: {
            filter: {
              organizationId: organizationId,
              take: rowsPerPage,
              skip: skip222,

              gender: gender ? gender : undefined,
              createdBetween: {
                start: dateStart ? new Date(dateStart).toISOString() : undefined,
                end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
              },
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            },
            include: {
              vitalsMeasurements: false,
              identifiers: false,
              nextOfKin: false,
            },

            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          },
        })
      )
    }
  }

  const handleSort = async (column: any, sortDirection: any) => {
    setSorting(
      sorting === "" ? sortDirection : sorting === "asc" ? "desc" : "asc"
    )

    setSortCol(
      column.name.toLowerCase() === "last active"
        ? "lastActiveAt"
        : column.name.toLowerCase() === "date added"
          ? "createdAt"
          : column.name.toLowerCase() === "name"
            ? "firstName"
            : ""
    )
    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })

    if (isSearch) {

      dispatch(
        SearchPatient({
          search: patientSearchData,
          organizationId: organizationId,
          filter: {
            take: rowsPerPage,
            skip: 0,
          },
          sort: [
            {
              field:
                column.name.toLowerCase() === "last active"
                  ? "lastActiveAt"
                  : column.name.toLowerCase() === "date added"
                    ? "createdAt"
                    : column.name.toLowerCase() === "name"
                      ? "firstName"
                      : column.name.toLowerCase() === "username"
                        ? "username"
                        : "",
              order:
                sorting === ""
                  ? sortDirection
                  : sorting === "asc"
                    ? "desc"
                    : "asc",
            },
          ],
        })
      )
    } else {
      dispatch(
        GetUsers({
          patient: {
            filter: {
              organizationId: organizationId,
              take: rowsPerPage,
              skip: 0,

              gender: gender ? gender : undefined,
              createdBetween: {
                start: dateStart ? new Date(dateStart).toISOString() : undefined,
                end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
              },
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            },
            include: {
              vitalsMeasurements: false,
              identifiers: false,
              nextOfKin: false,
            },
            sort: [
              {
                field:
                  column.name.toLowerCase() === "last active"
                    ? "lastActiveAt"
                    : column.name.toLowerCase() === "date added"
                      ? "createdAt"
                      : column.name.toLowerCase() === "name"
                        ? "firstName"
                        : column.name.toLowerCase() === "username"
                          ? "username"
                          : "",
                order:
                  sorting === ""
                    ? sortDirection
                    : sorting === "asc"
                      ? "desc"
                      : "asc",
              },
            ],
          },
        })
      )
    }
  }
  const onRowsPerPageFetchData = (
    rowsPerPage2: number,
    currentPage: number
  ) => {

    let conditionArr: string[] = []
    let dateStart: string = ""
    let dateEnd: string = ""
    let ageTo: string | null = null
    let ageFrom: string | null = null
    let gender: string | null = null

    filterList?.forEach((itemList: any) => {
      if (itemList.set) {
        if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
          conditionArr.push(itemList.contain)
        } else if (itemList.value === 9) {
          dateStart = itemList.contain
        } else if (itemList.value === 10) {
          dateEnd = itemList.contain
        } else if (itemList.value === 11) {
          ageFrom = itemList.contain
        } else if (itemList.value === 12) {
          ageTo = itemList.contain
        } else if (itemList.value === 13) {
          gender = itemList.contain.value
        }
      }
    })
    if (isSearch) {

      dispatch(
        SearchPatient({
          search: patientSearchData,
          organizationId: organizationId,
          filter: {
            take: rowsPerPage2,
            skip: 0,
          },
          sort: [
            {
              field: sortCol === "" ? "firstName" : sortCol,
              order: sorting === "" ? "asc" : sorting,
            },
          ],
        })
      )
    } else {
      dispatch(
        GetUsers({
          patient: {
            filter: {
              organizationId: organizationId,
              take: rowsPerPage2,
              skip: 0,

              gender: gender ? gender : undefined,
              createdBetween: {
                start: dateStart ? new Date(dateStart).toISOString() : undefined,
                end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : undefined,
              },
              age: {
                start: ageFrom ? ageFrom : undefined,
                end: ageTo ? ageTo : undefined,
              },
              conditions: conditionArr,
            },
            include: {
              vitalsMeasurements: false,
              identifiers: false,
              nextOfKin: false,
            },

            sort: [
              {
                field: sortCol === "" ? "firstName" : sortCol,
                order: sorting === "" ? "asc" : sorting,
              },
            ],
          },
        })
      )
    }
    setReset(true)
    setTimeout(() => setReset(false), 0.1)
  }

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
  const [idArchive, setIdArchive] = useState<string>('')
  const toggleArchive = (id?: string) => {
    if (id !== 'bulk') {
      setShowConfirmationModal(!showConfirmationModal)
      id && setIdArchive(id)
    } else if (id === 'bulk' && selectedRows?.length > 0) {
      setShowConfirmationModal(!showConfirmationModal)
      setBulk(true)
    } else if (id === 'bulk' && selectedRows?.length === 0) {
      errorToast("First select rows")
    }
  }

  const onConfirm = () => {

    if (bulk) {
      let list: Archive[] = [];
      selectedRows.forEach((val: any) => {
        list.push(
          {
            model: 'patient',
            itemId: val.id
          }
        )
      })
      dispatch(ArchiveItems(list))

    } else {
      dispatch(ArchiveItems([{
        model: 'patient',
        itemId: idArchive
      }]))
    }
  }

  const onRowClicked = (row: any) => {
    history.push({
      pathname: "/vitalsHistory",
      state: { id: row.id },
    })
  }

  const onSelectedRowsChange = (data: any) => {
    setSelectedRows(data.selectedRows)
    setAll(data.allSelected)
    setNumberOfRowsSelected(data.selectedCount)
  }

  return (
    <React.Fragment>
      {access ?
        <div>
          <ArchiveConfirmationModal
            toggle={toggleArchive}
            isOpen={showConfirmationModal}
            onConfirm={onConfirm}
            loading={archiveItemsLoading}
          />
          <div className="page-content" style={{ maxHeight: `${height - 10}px` }}>
            <Container fluid>
              <PatientFilter
                isOpen={filterModel}
                toggle={toggleFilterModal}
                setFilterList={setFilterList}
                tempFilterList={tempFilterList}
                setTempFilterList={setTempFilterList}
              />
              <Row>
                <Col lg="3">
                  <h2 className="m-0 align-self-center text-nowrap">
                    <b className="text-nowrap sbl24">Patient Management</b>
                  </h2>
                </Col>
                <Col lg="5" className="d-flex flex-row-reverse flex-wrap gap-1">
                  {filterList?.map((item: OptionType, index: number) => (
                    <div
                      key={index}
                      className="btn ilaraLigthBlue d-flex align-items-center text-nowrap py-auto px-2 btnSelectedFilter"
                      style={{ height: "38px" }}
                    >
                      <p className="mb-1">{item.label}</p>
                      <i
                        onClick={() => {
                          filterList.forEach((itemf: any, i: number) => {
                            if (itemf.value === item.value) {
                              let temp = [...filterList]
                              temp[i].set = false
                              temp.splice(i, 1)
                              setFilterList(temp)
                            }
                          })
                        }}
                        className="bx bx-x font-size-20 ms-2 closeFilter"
                      ></i>
                    </div>
                  ))}
                </Col>
                <Col lg="4" className="text-end text-nowrap">
                  <button
                    type="button"
                    className="btn btn-light mx-3 sbold someTextClr"
                    style={{
                      backgroundColor: "white",
                      color: "#2C3242",
                      border: "1px solid #E3E3E3",
                      width: "auto",
                    }}
                    onClick={() => {
                      setTempFilterList(filterList)
                      toggleFilterModal()
                    }}
                  >
                    <i className="bx bx-filter font-size-22 align-middle me-1"></i>
                    Filters
                  </button>

                  {editPatient && (
                    <button
                      type="button"
                      className="btn btn-primary sbold"
                      style={{ color: "white", width: "auto" }}
                      onClick={() => {
                        Async.setItem("@ScreenPatient", "add"),
                          history.push({
                            pathname: "/AddEditPatientManagement",
                            state: { page: "add" },
                          })
                      }}
                    >
                      <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                      Add Patient
                    </button>
                  )}
                </Col>
              </Row>

              <div className="horizontal-line mt-3"></div>

              {(loading || loadingSearchPatient) && (
                <div
                  style={{
                    width: "100%",
                    minHeight: "500px",
                    display: "flex",
                    flex: "1",
                    justifyContent: "center",
                  }}
                >
                  <Spinner
                    style={{
                      color: "#2a45cd",
                      alignSelf: "center",
                      width: "70px",
                      height: "70px",
                    }}
                    animation="border"
                    variant="primary"
                  />
                </div>
              )}
              <Row
                className="mt-4 ms-0 "
                style={{
                  maxHeight: `${height - 170}px`,
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                }}
              >
                <Card className={(loading || loadingSearchPatient) ? "p-0 d-none" : "p-0"}>
                  <CardBody className="px-0 py-1 border border-light rounded">
                    {isDataRendered ? (
                      <Datatable
                        type="patient"
                        toggleModal={toggleArchive}
                        data={isSearch ? searchPatient : patients}
                        defaultSortAsc={false}
                        handleSort={handleSort}
                        setRowsPerPage={setRowsPerPage}
                        onRowsPerPageFetchData={onRowsPerPageFetchData}
                        setSkip={setSkip}
                        rowsPerPage={rowsPerPage}
                        skip={skip}
                        loadingSearch={loading}
                        paginationTotal={isSearch ? total : totalNumber}
                        field={sortCol === "" ? "firstName" : sortCol}
                        order={sorting === "" ? "asc" : sorting}
                        currentPage={reset}
                        editBtn={editPatient}
                        filterList={filterList}
                        archivePatient={archivePatient}
                        onRowClicked={onRowClicked}
                        pointerOnHover={true}
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedRows={clearSelectedRows}
                        numberOfRowsSelected={numberOfRowsSelected}

                      ></Datatable>
                    ) : (
                      <Datatable
                        type="patient"
                        toggleModal={toggleArchive}
                        data={[]}
                        defaultSortAsc={false}
                        handleSort={handleSort}
                        setRowsPerPage={setRowsPerPage}
                        onRowsPerPageFetchData={onRowsPerPageFetchData}
                        setSkip={setSkip}
                        rowsPerPage={rowsPerPage}
                        skip={skip}
                        loadingSearch={loading}
                        paginationTotal={isSearch ? total : totalNumber}
                        field={sortCol === "" ? "firstName" : sortCol}
                        order={sorting === "" ? "asc" : sorting}
                        currentPage={reset}
                        editBtn={editPatient}
                        archivePatient={archivePatient}
                        onRowClicked={onRowClicked}
                        pointerOnHover={true}
                        onSelectedRowsChange={onSelectedRowsChange}
                        clearSelectedRows={clearSelectedRows}
                        numberOfRowsSelected={numberOfRowsSelected}

                      ></Datatable>
                    )}
                  </CardBody>
                </Card>
              </Row>
            </Container>
          </div>
        </div> : <div className="permissionWarning">User does not have permissions to view this screen!</div>}
    </React.Fragment>
  )
}

export default PatientManagement
