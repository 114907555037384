import React, { FC, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Card, Form, Label, Input, FormFeedback } from "reactstrap"
import LogoSvg from "../../assets/images/svg/Logo.svg"
import backgroundSvg from "../../assets/images/png/background.png"
import "./login.scss"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { GetResetPasswordIdentifiers, ResetPassword, SendResetPasswordKeyByUsername, SignInUser, VerifyOtp } from "~/store/reducers/AuthSlice/thunks"
import { setPreloader } from "~/store/reducers/loader"
import Async from "~/utils/Async"
import { OrganizationById } from "~/store/reducers/Organizations/thunk"
import { errorToast, successToast } from "~/utils/toast"
import { ResetGetResetPasswordIdentifiers, ResetResetKeyByUsername, ResetResetPassword, ResetVerifyOtp } from "~/store/reducers/AuthSlice"

const Login: FC = () => {
  const history = useHistory()
  const { token, error, loading, requirePasswordChange, resetKey, username: userNameCurrentUser,
    loadingResetPassword, successResetPassword, errorResetPassword,
    loadingResetKeyByUsername, errorResetKeyByUsername, successResetKeyByUsername,
    IdentifiersByUsername, loadingGetIdentifiersByUsername, successGetIdentifiersByUsername,
    errorGetIdentifiersByUsername, successVerifyOtp, errorVerifyOtp, loadingVerifyOtp } = useAppSelector(
      state => state.AuthReducer
    )
  const [phone, setPhone] = useState<boolean>(false)
  const [email, setEmail] = useState<boolean>(false)
  const [showPass, setShowPass] = useState<boolean>(false)
  const [username, setUsername] = useState<string>("")
  const [showPass1, setShowPass1] = useState<boolean>(false)
  const [showPass2, setShowPass2] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [resetPassword, setResetPassword] = useState<number>(0)
  const [resetType, setResetType] = useState<number>(-1)
  const dispatch = useAppDispatch()
  const {
    organizationsByIdObj,
    loading: loadingOrg,
    error: errorOrg,
  } = useAppSelector(state => state.OrganizationReducer)

  useEffect(() => {
    if (token !== "") {
      const data = Async.getItem("@auth")

      if (data?.role?.permissions["ilara-superuser"]) {
        Async.setItem("@super", true)
        history.push("/dashboard")
      } else {
        Async.setItem("@super", false)
        const body = {
          organizationId: data.organizationId,
        }

        if (requirePasswordChange) {
          setResetPassword(3)
        } else {
          dispatch(OrganizationById(body))
        }
      }
    }
    if (error !== "") {
      dispatch(setPreloader(false))
    }
  }, [token, error])
  useEffect(() => {
    if (Object.keys(IdentifiersByUsername).length > 0) {
      IdentifiersByUsername?.items?.forEach((element: any) => {
        if (element.type === 'EMAIL') {
          setEmail(element.identifier)
        } else if (element.type === 'PHONE_NUMBER') {
          setPhone(element.identifier)
        }
      });
    }
  }, [IdentifiersByUsername])

  useEffect(() => {
    if (successVerifyOtp) {
      dispatch(ResetVerifyOtp())
      successToast('OTP verification successful')
      history.push({
        pathname: "/password-reset",
        state: { username: username, otp: validationOTP.values.otp },
      })
    } else if (errorVerifyOtp) {
      errorToast(errorVerifyOtp)
      dispatch(ResetVerifyOtp())
    }
  }, [successVerifyOtp, errorVerifyOtp])

  useEffect(() => {
    if (
      organizationsByIdObj &&
      Object.keys(organizationsByIdObj).length !== 0
    ) {
      const data = Async.getItem("@auth")
      Async.setItem("@organization", organizationsByIdObj)
      let path: string = ''
      if (data?.role?.permissions["organization-details-view"]) {
        path = '/organizationDetails';
      } else if (data?.role?.permissions["dashboard-overview-view"]) {
        path = '/reports/summary'
      } else if (data?.role?.permissions["dashboard-blood-pressure-view"]) {
        path = '/reports/bloodPressure'
      } else if (data?.role?.permissions["dashboard-blood-glucose-view"]) {
        path = '/reports/bloodSugar'
      } else if (data?.role?.permissions["practitioner-view"]) {
        path = '/agentManagement'
      } else if (data?.role?.permissions["patient-view"]) {
        path = '/patientManagement'
      }
      history.push(path)
    }
  }, [organizationsByIdObj, error])

  useEffect(() => {
    if (successResetPassword) {
      successToast('Password reset successful, please login to continue!')
      setResetPassword(0)
      dispatch(ResetResetPassword())
    } else if (errorResetPassword) {
      errorToast(errorResetPassword)
      dispatch(ResetResetPassword())
    }
  }, [successResetPassword, errorResetPassword])
  useEffect(() => {
    if (successGetIdentifiersByUsername) {
      setResetPassword(2)
      dispatch(ResetGetResetPasswordIdentifiers())
    } else if (errorGetIdentifiersByUsername) {
      // errorToast(errorGetIdentifiersByUsername)
      setTimeout(() => {
        dispatch(ResetGetResetPasswordIdentifiers())
      }, 3000)
    }
  }, [successGetIdentifiersByUsername, errorGetIdentifiersByUsername])

  useEffect(() => {
    if (successResetKeyByUsername) {
      if (resetType === 0) {
        successToast('Password reset link sent to your email, check email to continue!')
        setResetPassword(0)
      } else if (resetType === 1) {
        successToast('6-digit code sent to provided phone number')
        setResetPassword(4)
      }
      validationReset.setFieldValue('username', '')
      validationReset.setFieldTouched('username', false, false)
      validation.setFieldValue('email', '')
      validation.setFieldValue('password', '')
      validation.setFieldTouched('email', false, false)
      validation.setFieldTouched('password', false, false)
      setResetType(-1)
      dispatch(ResetResetKeyByUsername())
    } else if (errorResetKeyByUsername) {
      errorToast(errorResetKeyByUsername)
      dispatch(ResetResetKeyByUsername())
    }
  }, [successResetKeyByUsername, errorResetKeyByUsername])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().min(5, 'Too short!').max(20, 'Too long!').matches(
        /^(?!\d)(?!.*-.*-)(?!.*-$)(?!-)[a-zA-Z0-9-]{5,20}$/,
        "Username must start with a letter. Contains only letters, numbers, and dashes or hyphens."
      ).required("Please enter username"),
      password: Yup.string()
        .min(8, "Password too Short!")
        .required("Please enter your password"),
    }),
    onSubmit: values => {
      dispatch(setPreloader(true))
      const data = {
        username: values.email.trim(),
        password: values.password.trim(),
        mode: "PRACTITIONER".toUpperCase(),
        remember: rememberMe,
      }
      dispatch(SignInUser(data))
    },
  })

  const validationReset = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().min(5, 'Too short!').max(20, 'Too long!').matches(
        /^(?!\d)(?!.*-.*-)(?!.*-$)(?!-)[a-zA-Z0-9-]{5,20}$/,
        "Username must start with a letter. Contains only letters, numbers, and dashes or hyphens."
      ).required("Please enter username"),
    }),
    onSubmit: values => {
      dispatch(GetResetPasswordIdentifiers({
        username: values.username
      }))
      setUsername(values.username)
    },
  })
  const validationOTP = useFormik({
    enableReinitialize: true,
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.number().test('Length Check', 'Please enter 6-digit code', e => e?.toString().length === 6)
        .required("Please enter 6-digit code"),
    }),
    onSubmit: values => {
      dispatch(VerifyOtp({
        otp: values.otp,
        username: username
      }))
    },
  })

  const validationNewPassword = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().min(8, "Password too short!").required("Please enter new password"),
      confirmPassword: Yup.string().min(8, "Password too short!")
        .test('Check if password',
          'Confirm password must be same as new password',
          e => {
            if (validationNewPassword.values.newPassword === e)
              return true
            else return false
          }
        )
        .required("Please enter confirm password"),
    }),
    onSubmit: values => {
      if (resetKey && userNameCurrentUser) {
        dispatch(ResetPassword({
          otp: resetKey,
          username: userNameCurrentUser,
          newPassword: values.newPassword
        }))
      }
    },
  })
  return (
    <React.Fragment>
      <img src={backgroundSvg} alt="" className="bg-img" />
      <div className="col-md-10 mt-5 offset-1 card-container">
        <div className="content">
          <div>
            <div className="logoContainer">
              <div className="logoContent">
                <img src={LogoSvg} alt="" height="36" />
                <p
                  className="logoText align-self-center sbl"
                  style={{ whiteSpace: "nowrap" }}
                >
                  ILARA HEALTH
                </p>
              </div>
            </div>
            <h5 className="ms-2 mt-2 sbold font-size-15">Patient Monitoring App</h5>
            <h5 className="ms-2 mb-4 sbold font-size-15">Admin Panel</h5>
          </div>

          <div className="right-text">
            <h2 className="text sbold font-size-24">
              A suite of{" "}
              <span className="text-primary ms-1">proprietary digital </span>
            </h2>
            <h2 className="text sbold font-size-24">
              <span className="text-primary me-1">health tools</span>to digitize
              our
            </h2>
            <h2 className="text sbold font-size-24">clinic partners</h2>
          </div>
        </div>
        {resetPassword === 0 && (
          <div className="col-sm-4 pe-5">
            <Card className="p-3 shadow-lg ">
              <h5 className="mt-2 sbold text-head">
                Log In
              </h5>
              <h6 className="text-muted mb-3">
                Enter your credentials to access the admin panel
              </h6>
              <Form
                className="form-horizontal"
                id="loginForm"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Label className="form-label im">Username</Label>
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Enter Username"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <div className="error-container mt-1 mb-1">
                      <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                      <h6 className="text-danger mt-2 ms-2">
                        {validation.errors.email}
                      </h6>
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <Label className="form-label im">Password</Label>
                  <div className="passwordInput">
                    <Input
                      name="password"
                      value={validation.values.password || ""}
                      type={showPass ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      className={
                        validation.touched.password &&
                          validation.errors.password
                          ? "form-control border-danger"
                          : "form-control"
                      }
                    />
                    {showPass ? (
                      <i
                        onClick={() => setShowPass(false)}
                        className="fas fa-eye-slash eyeIcon"
                      />
                    ) : (
                      <i
                        onClick={() => setShowPass(true)}
                        className="fas fa-eye eyeIcon"
                      />
                    )}
                  </div>
                  {validation.touched.password && validation.errors.password ? (
                    <div className="error-container mt-1 mb-1">
                      <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                      <h6 className="text-danger mt-2 ms-2">
                        {validation.errors.password}
                      </h6>
                    </div>
                  ) : null}
                </div>

                {error && error !== "" && (
                  <div className="error-container mt-1 mb-1">
                    <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                    <h6 className="text-danger mt-2 ms-2">{error}</h6>
                  </div>
                )}
                <Label check className="im">
                  <Input
                    type="checkbox"
                    id="checkbox2"
                    defaultChecked={rememberMe}
                    onChange={val => {
                      const { checked } = val.target
                      setRememberMe(checked)
                    }}
                  />
                  <span className="ms-2">Remember me</span>
                </Label>
                <div className="mt-3 d-grid">
                  <button className="btn btn-primary btn-block " type="submit">
                    Log In
                    {loading && (
                      <i className="bx bx-loader bx-spin font-size-16 loader" />
                    )}
                  </button>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="bg-transparent border-0"
                    type="button"
                    onClick={() => setResetPassword(1)}
                  >
                    <h6 className="text-primary im">Forgot your password?</h6>
                  </button>
                </div>
              </Form>
            </Card>
          </div>
        )}

        {resetPassword === 1 && (
          <div className="col-sm-4 pe-5">
            <Card className="p-3 shadow-lg ">
              <h5 className="mt-2 sbold text-head">
                Reset password
              </h5>
              <h6 className="text-muted">
                Enter your email to receive instructions for resetting your
                password.
              </h6>
              <Form
                id="resetForm"
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validationReset.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Label className="form-label im mt-1">Username</Label>
                  <Input
                    name="username"
                    className="form-control"
                    placeholder="Enter username"
                    type="text"
                    onChange={validationReset.handleChange}
                    value={validationReset.values.username || ""}
                    invalid={
                      validationReset.touched.username &&
                        validationReset.errors.username
                        ? true
                        : false
                    }
                  />
                  {(validationReset.touched.username &&
                    validationReset.errors.username) || errorGetIdentifiersByUsername ? (
                    <div className="error-container mt-1 mb-1">
                      <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                      <h6 className="text-danger mt-2 ms-2">
                        {validationReset.errors.username ? validationReset.errors.username : errorGetIdentifiersByUsername}
                      </h6>
                    </div>
                  ) : null}
                </div>

                <div className="mt-4 d-grid">
                  <button className="btn btn-primary btn-block" type="submit">
                    Reset my password
                  </button>
                </div>
                <div className="d-flex justify-content-end">
                  <h6
                    className="text-primary mt-4 txt-w "
                    role="button"
                    onClick={() => {
                      setResetPassword(0);
                      validationReset.setFieldValue('username', '')
                      validationReset.setFieldTouched('username', false, false)
                      validation.setFieldValue('email', '')
                      validation.setFieldValue('password', '')
                      validation.setFieldTouched('email', false, false)
                      validation.setFieldTouched('password', false, false)
                    }}
                  >
                    Already a user?
                  </h6>
                </div>
              </Form>
            </Card>
          </div>
        )}
        {resetPassword === 2 && (
          <div className="col-sm-4 pe-5">
            <Card className="p-3 shadow-lg ">
              <h5 className="mt-2 sbold text-head">
                Hello {username},
              </h5>
              <h6 className="text-muted">
                Please select how you would like to receive your password reset
                process.
              </h6>
              {email &&
                <div
                  className="form-check form-radio-primary mt-2"
                  onClick={() => {
                    !loadingResetKeyByUsername && setResetType(0)
                  }}
                  style={
                    resetType === 0
                      ? {
                        border: "1px solid #2a45cd",
                        borderRadius: "8px",
                        paddingLeft: "20px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: '#FBFBFB',
                      }
                      : {
                        border: "1px solid #E3E3E3",
                        borderRadius: "8px",
                        paddingLeft: "20px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: '#FBFBFB',
                      }
                  }
                >
                  <div
                    style={{ marginLeft: "10px" }}
                  >
                    <input
                      type="radio"
                      id="customRadiocolor1"
                      name="customRadiocolor1"
                      className="form-check-input"
                      checked={resetType === 0 ? true : false}
                      onChange={val => {
                        const { checked } = val.target;
                        !loadingResetKeyByUsername && setResetType(checked ? 0 : -1)
                      }}
                    />
                    <a className="text-primary" style={{ fontSize: "14px" }}>
                      <b>Reset password via email</b>
                      <br></br>
                    </a>
                    <a style={{ fontSize: "12px" }}>
                      A password reset link will be sent to the email attached to
                      this account:{" "}
                      <span style={{ color: "#2C3242", fontSize: "12px" }}>
                        <b>{email}</b>
                      </span>
                    </a>
                  </div>
                </div>
              }
              {(phone && email) &&
                <h6 className="text-muted text-center mt-2 mb-2">Or</h6>
              }
              {phone &&
                <div
                  className="form-check form-radio-primary mb-1"
                  onClick={() => {
                    !loadingResetKeyByUsername && setResetType(1)
                  }}
                  style={
                    resetType === 1
                      ? {
                        border: "1px solid #2a45cd",
                        borderRadius: "8px",
                        paddingLeft: "20px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: '#FBFBFB',
                      }
                      : {
                        border: "1px solid #E3E3E3",
                        borderRadius: "8px",
                        paddingLeft: "20px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        background: '#FBFBFB',
                      }
                  }
                >
                  <div style={{ marginLeft: "10px" }}>
                    <input
                      type="radio"
                      id="customRadiocolor2"
                      name="customRadiocolor2"
                      className="form-check-input"
                      checked={resetType === 1 ? true : false}
                      onChange={val => {
                        const { checked } = val.target;
                        !loadingResetKeyByUsername && setResetType(checked ? 1 : -1)
                      }}
                    />
                    <a className="text-primary" style={{ fontSize: "14px" }}>
                      <b>Reset password via SMS</b>
                      <br></br>
                    </a>
                    <a style={{ fontSize: "12px" }}>
                      A 6-digit code will be sent to the following number,
                      <span style={{ color: "#2C3242", fontSize: "12px" }}>
                        <b>{phone}</b>
                      </span>
                    </a>
                  </div>
                </div>
              }
              <div className="mt-3 mb-2 d-grid">
                <button
                  className="btn btn-primary btn-block"
                  type="button"
                  disabled={resetType === -1 || loadingResetKeyByUsername ? true : false}
                  onClick={() => {
                    dispatch(SendResetPasswordKeyByUsername({
                      username: username,
                      channel: `${resetType === 0 ? 'EMAIL' : 'PHONE_NUMBER'}`
                    }))
                  }}
                >
                  Continue
                  {loadingResetKeyByUsername && (
                    <i className="bx bx-loader bx-spin font-size-16 loader" />
                  )}
                </button>
              </div>
            </Card>
          </div>
        )}
        {resetPassword === 3 && (
          <div className="col-sm-4 pe-5">
            <Card className="p-3 shadow-lg ">
              <h5 className="mt-2 text-head">Enter new password</h5>
              <Form
                id="resetForm"
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validationNewPassword.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3 mt-2">
                  <Label className="form-label im">New Password</Label>
                  <div className="passwordInput">
                    <Input
                      name="newPassword"
                      value={validationNewPassword.values.newPassword || ""}
                      type={showPass1 ? "text" : "password"}
                      placeholder="Enter new password"
                      onChange={validationNewPassword.handleChange}
                      onBlur={validationNewPassword.handleBlur}
                      className={
                        validationNewPassword.touched.newPassword &&
                          validationNewPassword.errors.newPassword
                          ? "form-control border-danger"
                          : "form-control"
                      }
                    />
                    {showPass1 ? (
                      <i
                        onClick={() => setShowPass1(false)}
                        className="fas fa-eye-slash eyeIcon"
                      />
                    ) : (
                      <i
                        onClick={() => setShowPass1(true)}
                        className="fas fa-eye eyeIcon"
                      />
                    )}
                  </div>
                  {validationNewPassword.touched.newPassword &&
                    validationNewPassword.errors.newPassword ? (
                    <div className="error-container mt-1 mb-1">
                      <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                      <h6 className="text-danger mt-2 ms-2">
                        {validationNewPassword.errors.newPassword}
                      </h6>
                    </div>
                  ) : null}
                </div>

                <div className="mb-3 mt-2">
                  <Label className="form-label im">Confirm Password</Label>
                  <div className="passwordInput">
                    <Input
                      name="confirmPassword"
                      value={validationNewPassword.values.confirmPassword || ""}
                      type={showPass2 ? "text" : "password"}
                      placeholder="Enter new password"
                      onChange={validationNewPassword.handleChange}
                      onBlur={validationNewPassword.handleBlur}
                      className={
                        validationNewPassword.touched.confirmPassword &&
                          validationNewPassword.errors.confirmPassword
                          ? "form-control border-danger"
                          : "form-control"
                      }
                    />
                    {showPass2 ? (
                      <i
                        onClick={() => setShowPass2(false)}
                        className="fas fa-eye-slash eyeIcon"
                      />
                    ) : (
                      <i
                        onClick={() => setShowPass2(true)}
                        className="fas fa-eye eyeIcon"
                      />
                    )}
                  </div>
                  {validationNewPassword.touched.confirmPassword &&
                    validationNewPassword.errors.confirmPassword ? (
                    <div className="error-container mt-1 mb-1">
                      <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                      <h6 className="text-danger mt-2 ms-2">
                        {validationNewPassword.errors.confirmPassword}
                      </h6>
                    </div>
                  ) : null}
                </div>
                <div className="mt-4 d-grid mb-3">
                  <button className="btn btn-primary btn-block" type="submit">
                    Reset my password and log in
                    {loadingResetPassword && (
                      <i className="bx bx-loader bx-spin font-size-16 loader" />
                    )}
                  </button>
                </div>
              </Form>
            </Card>
          </div>
        )}
        {resetPassword === 4 && (
          <div className="col-sm-4 pe-5">
            <Card className="p-3 shadow-lg ">
              <h5 className="mt-2 text-head">
                <b>Enter 6-digit code</b>
              </h5>
              <h6 className="text-muted">
                Enter the code you have received via SMS
              </h6>
              <Form
                id="otpForm"
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validationOTP.handleSubmit()
                  return false
                }}
              >
                <div className="mb-3">
                  <Input
                    name="otp"
                    className="form-control"
                    placeholder="******"
                    type="text"
                    onChange={validationOTP.handleChange}
                    onBlur={validationOTP.handleBlur}
                    value={validationOTP.values.otp || ""}
                    invalid={
                      validationOTP.touched.otp &&
                        validationOTP.errors.otp
                        ? true
                        : false
                    }
                  />
                  {validationOTP.touched.otp &&
                    validationOTP.errors.otp ? (
                    <div className="error-container mt-1 mb-1">
                      <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                      <h6 className="text-danger mt-2 ms-2">
                        {validationOTP.errors.otp}
                      </h6>
                    </div>
                  ) : null}
                </div>

                <div className="mt-4 d-grid">
                  <button className="btn btn-primary btn-block mb-3" type="submit">
                    Validate code and reset my password
                    {loadingVerifyOtp && (
                      <i className="bx bx-loader bx-spin font-size-16 loader" />
                    )}
                  </button>
                </div>
              </Form>
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default Login
