import React, { useState } from "react";
import { FC } from "react";
import { Input, Modal } from "reactstrap";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface MessageProps {
    isOpen: boolean;
    toggle: ()=>void;
}

const Message: FC<MessageProps> = ({isOpen, toggle}) => {
    const [messageBody, setMessageBody] = useState<string>('');

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            toggle={toggle}
        >
            <div className="modal-header">
                <h4
                    className="modal-title mt-0 ms-2 font-size-18"
                    id="myLargeModalLabel"
                >
                    Send message
                </h4>
                <button
                    onClick={() => {
                        toggle();
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body p-0">
                <div className="mb-3 px-4 py-2">
                    <label className="col-md-2 col-form-label">Sending to</label>
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="Recipient Name(s).."
                    />
                </div>
                <div className="mb-3">
                    <label className="col-md-2 col-form-label px-4 pb-1">Edit Message</label>
                    <div className="px-4 pt-4 pb-1" style={{background: '#FBFBFB', borderTop:'1px solid #e3e3e3', borderBottom:'1px solid #e3e3e3'}}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={messageBody}
                            id='editor1'
                            // onReady={(editor:any) => {
                            //     console.log('Editor is ready to use!', editor);
                            // }}
                            onChange={(event:any, editor:any) => {
                                setMessageBody(editor.getData())
                            }}
                        />
                        <p className="py-1 text-end">Sending to <span className="text-primary"><b>0</b></span> recepient(s)</p>
                    </div>
                </div>
                <div className="form-check form-check-end mx-4 mb-3">
                    <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    defaultChecked
                    />
                    <label
                    className="form-check-label font-size-14"
                    htmlFor="defaultCheck2"
                    >
                        Schedule for later
                    </label>
                </div>
                <div className="col-sm-8 col-md-6 col-lg-4 mb-3 px-4 py-2">
                    <label className=" col-form-label text-nowrap">Select date and time</label>
                    <Input
                        type="date"
                        className="form-control w-100"
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="ms-auto">
                    <button
                        type="button"
                        className="discard-btn"
                        onClick={() => {
                            toggle();
                        }}
                    >
                        <h6 className="my-auto px-2">
                            Dismiss
                        </h6>
                    </button>
                    <button className="btn btn-primary d-inline-flex ms-3">
                        <h6 className="my-auto text-white">
                            Send
                        </h6>
                        <i className='bx bx-send font-size-18 ms-2'></i>
                    </button>
                </div>
            </div>
        </Modal>
    )
}
export default Message;