import { createAsyncThunk } from "@reduxjs/toolkit"
import { MyKnownError } from "~/models/Error.model"
import { nextOfKin, nextOfKinBatch } from "~/models/Identifier.model"
import { postRequest } from "~/store/apiHelper"
import Async from "~/utils/Async"
import { deleteNextOfKin, fetchNextOfKin } from "./types"

//Add next of kin
export const AddNextOfKin = createAsyncThunk<
  any,
  nextOfKin,
  { rejectValue: MyKnownError }
>("nextOfKin/usersAddNextOfKin", async (data: nextOfKin, thunkApi) => {
  return postRequest("/rpc/usersAddNextOfKin", data, true, thunkApi)
})

//Add next of kin batch
export const BatchAddNextOfKin = createAsyncThunk<
  any,
  nextOfKinBatch,
  { rejectValue: MyKnownError }
>("nextOfKin/BatchAddNextOfKin", async (data: nextOfKinBatch, thunkApi) => {
  let self = Async.getItem('self');
  self && delete data.userProfileId
  return postRequest(self ? '/rpc/usersBatchAddNextOfKinSelf' : "/rpc/usersBatchAddNextOfKin", data, true, thunkApi)
})
//Edit next of kin batch
export const BatchEditNextOfKin = createAsyncThunk<
  any,
  nextOfKinBatch,
  { rejectValue: MyKnownError }
>("nextOfKin/BatchEditNextOfKin", async (data: nextOfKinBatch, thunkApi) => {
  let self = Async.getItem('self');
  return postRequest(self ? '/rpc/usersBatchEditNextOfKinSelf' : "/rpc/usersBatchEditNextOfKin", data, true, thunkApi)
})

//Fetch NextOfKin
export const FetchNextOfKin = createAsyncThunk<
  any,
  fetchNextOfKin,
  { rejectValue: MyKnownError }
>("nextOfKin/usersFetchNextOfKin", async (data: fetchNextOfKin, thunkApi) => {
  return postRequest("/rpc/usersFetchNextOfKin", data, true, thunkApi)
})

//Delete NextOfKin
export const DeleteNextOfKin = createAsyncThunk<
  any,
  deleteNextOfKin,
  { rejectValue: MyKnownError }
>("nextOfKin/usersDeleteNextOfKin", async (data: deleteNextOfKin, thunkApi) => {
  let self = Async.getItem('self');
  let dataArr: string[] = []
  dataArr.push(data.id)
  let body = { ids: dataArr }
  return postRequest(self ? '/rpc/usersBatchDeleteNextOfKinSelf' : "/rpc/usersBatchDeleteNextOfKin", body, true, thunkApi)
})