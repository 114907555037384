import React, { useEffect, useState } from "react"
import { FC } from "react"
import { Dropdown, DropdownItem } from "reactstrap"
import { useHistory } from "react-router-dom"
import DataTable from "react-data-table-component"
import "./dataTable.scss"
import Message from "../Modals/Message"
import moment from "moment"
import Async from "~/utils/Async"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { organizationUpdateReset, setLocalData } from "~/store/reducers/Organizations"
import {
  SearchOrganizations,
  updateOrganizations,
} from "~/store/reducers/Organizations/thunk"
import { errorToast, successToast } from "~/utils/toast"
import {
  ActivatePatient,
  DeactivatePatient,
  SearchPatient,
} from "~/store/reducers/Patient/thunk"
import { GetUsers } from "~/store/reducers/Select/thunk"
import { ResetPatientStatusStates } from "~/store/reducers/Patient"
import Avatar from "react-avatar"
import {
  ActivateAgent,
  DeactivateAgent,
  UpdateVerifiedStatus,
} from "~/store/reducers/Agent/thunk"

interface DataTableProps {
  type: string
  data?: any
  setRowsPerPage?: any
  setSkip?: any
  toggleModal?: any
  paginationTotal?: number
  onRowsPerPageFetchData?: any
  rowsPerPage?: number
  skip?: number
  loadingSearch?: boolean
  handleSort?: any
  defaultSortAsc?: boolean
  currentPage?: boolean
  field: string
  order: string
  editBtn?: boolean
  editBtnWorker?: boolean
  filterList?: []
  archivePatient?: boolean
  archiveWorker?: boolean
  onRowClicked?: any
  pointerOnHover?: boolean
  onSelectedRowsChange?: any
  handleAccept?: any
  handleReject?: any
  activeTab?: string
  numberOfRowsSelected?: number
  clearSelectedRows?: boolean
  closeDrop?: boolean
}
interface OpenDropDownType {
  value: boolean
  id: number
}
const Datatable: FC<DataTableProps> = ({
  type,
  data,
  setRowsPerPage,
  setSkip,
  toggleModal,
  paginationTotal,
  onRowsPerPageFetchData,
  rowsPerPage,
  skip,
  loadingSearch,
  handleSort,
  defaultSortAsc,
  currentPage,
  field,
  order,
  editBtn,
  editBtnWorker,
  filterList,
  archivePatient,
  archiveWorker,
  onRowClicked,
  pointerOnHover,
  onSelectedRowsChange,
  handleAccept,
  handleReject,
  activeTab,
  numberOfRowsSelected,
  clearSelectedRows,
  closeDrop
}) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [openDropdown, setOpenDropdown] = useState<OpenDropDownType>({
    value: false,
    id: -1,
  })
  const [messageModel, setmessageModel] = useState<boolean>(false)
  const [bulkDropDown, setBulkDropDown] = useState<boolean>(false)

  const toggleMessageModal = () => setmessageModel(!messageModel)

  const { successUpdate, errorUpdate, loadingUpdate, isSearch, searchData, localData } =
    useAppSelector(state => state.OrganizationReducer)

  const { statusChangeSuccess, statusChangeError, statusChangeLoading, isSearch: isSearchPatient, patientSearchData
  } =
    useAppSelector(state => state.PatientReducer)

  const { agentStatusChangeLoading } = useAppSelector(
    state => state.AgentReducer
  )

  //handle on organization status change
  useEffect(() => {
    if (successUpdate) {
      dispatch(organizationUpdateReset())

      if (isSearch) {
        dispatch(
          SearchOrganizations({
            search: searchData,
            filter: {
              take: rowsPerPage,
              skip: skip,
            },
            sort: [
              {
                field: field,
                order: order,
              },
            ],
          })
        )
      } else {
        dispatch(
          SearchOrganizations({
            search: "",
            filter: {
              take: rowsPerPage,
              skip: skip,
            },
            sort: [
              {
                field: field,
                order: order,
              },
            ],
          })
        )
      }

      successToast("Organization active status changed!")
    } else if (errorUpdate) {
      errorToast(errorUpdate)
      dispatch(organizationUpdateReset())
    }
  }, [successUpdate, errorUpdate])

  //handle on patient status change
  useEffect(() => {
    if (statusChangeSuccess) {
      dispatch(ResetPatientStatusStates())
      const organizationLocalData = Async.getItem("@organization")

      let conditionArr: string[] = []
      let dateStart: string = ""
      let dateEnd: string = ""
      let ageTo: string | null = null
      let ageFrom: string | null = null
      let gender: string | null = null

      filterList?.forEach((itemList: any) => {
        if (itemList.set) {
          if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
            conditionArr.push(itemList.contain)
          } else if (itemList.value === 9) {
            dateStart = itemList.contain
          } else if (itemList.value === 10) {
            dateEnd = itemList.contain
          } else if (itemList.value === 11) {
            ageFrom = itemList.contain
          } else if (itemList.value === 12) {
            ageTo = itemList.contain
          } else if (itemList.value === 13) {
            gender = itemList.contain.value
          }
        }
      })

      if (isSearchPatient) {

        dispatch(
          SearchPatient({
            search: patientSearchData,
            organizationId: organizationLocalData.id,
            filter: {
              take: rowsPerPage,
              skip: skip,
            },
            sort: [
              {
                field: field,
                order: order,
              },
            ],
          })
        )

      } else {
        dispatch(
          GetUsers({
            patient: {
              filter: {
                organizationId: organizationLocalData.id,
                take: rowsPerPage,
                skip: skip,

                gender: gender ? gender : undefined,
                createdBetween: {
                  start: dateStart
                    ? new Date(dateStart).toISOString()
                    : undefined,
                  end: dateEnd
                    ? new Date(
                      dateEnd +
                      "T" +
                      moment(new Date().toUTCString()).format("HH:mm")
                    ).toISOString()
                    : undefined,
                },
                age: {
                  start: ageFrom ? ageFrom : undefined,
                  end: ageTo ? ageTo : undefined,
                },
                conditions: conditionArr,
              },
              sort: [
                {
                  field: field,
                  order: order,
                },
              ],
              include: {
                vitalsMeasurements: false,
                identifiers: false,
                nextOfKin: false,
              },
            },
          })
        )
      }

      successToast("Patient active status changed!")
    } else if (statusChangeError) {
      errorToast(statusChangeError)
      dispatch(ResetPatientStatusStates())
    }
  }, [statusChangeSuccess, statusChangeError])

  const IconMenu = (props: any) => {

    const { openDropdown, setBulkDropDown, type, setOpenDropdown } = props;
    return (
      <>
        <div className="d-flex">
          <Dropdown
            isOpen={openDropdown}
            onClick={
              () => {
                setBulkDropDown(!openDropdown);
                setOpenDropdown({
                  value: false,
                  id: -1,
                });
              }
            }
          >
            <i className="bx bx-dots-vertical-rounded font-size-18 edit-icons"></i>
            {openDropdown && (
              <div
                style={{
                  position: "absolute",
                  left: "-90px",
                  top: "0px",
                  background: "#FFFFFF",
                  borderRadius: "8px",
                  border: "1px solid #e3e3e3",
                  boxShadow: "0px 3px 12px #00000029",
                  paddingTop: '10px',
                  paddingBottom: '5px',
                  zIndex: '100'
                }}
              >
                {type === 'pending' ?
                  <>
                    <DropdownItem
                      style={{ marginTop: "-3%" }}
                      onClick={
                        handleAccept
                      }
                    >
                      <span className="im font-size-13">Accept</span>
                    </DropdownItem>
                    <DropdownItem
                      style={{ marginTop: "-3%" }}
                      onClick={handleReject}
                    >
                      <span className="im font-size-13 text-danger">Reject</span>

                    </DropdownItem>
                  </>
                  :
                  type === 'reject' ?
                    <>
                      <DropdownItem
                        className="text-danger"
                        style={{ marginTop: "-3%" }}
                        onClick={() => {
                          toggleModal('bulk');
                          setBulkDropDown(!openDropdown)
                        }}
                      >
                        <span className="im font-size-13">Archive</span>
                      </DropdownItem>
                      <DropdownItem
                        style={{ marginTop: "-3%" }}
                        onClick={
                          handleAccept
                        }
                      >
                        <span className="im font-size-13">Accept</span>
                      </DropdownItem>
                    </>
                    :
                    <DropdownItem
                      className="text-danger"
                      style={{ marginTop: "-3%" }}
                      onClick={() => {
                        toggleModal('bulk');
                        setBulkDropDown(!openDropdown)
                      }}
                    >
                      <span className="im font-size-13">Archive</span>
                    </DropdownItem>
                }

              </div>
            )}
          </Dropdown>
        </div>
      </>
    )
  }
  //Columns for Dashboard Screen
  const allOrganizationsColumns = [
    {
      name: "Name",
      sortable: true,
      cell: (row: any) => (
        <div className="d-flex" style={{ alignItems: "center" }} onClick={() => { onRowClicked(row) }}>
          <Avatar
            src={row.logo}
            name={row.name}
            maxInitials={2}
            textSizeRatio={2.6}
            size="30"
            color="#2A45CD50"
            fgColor="#2A45CD"
            round
          />
          <p className="ms-3 mb-0 im14">{row.name}</p>
        </div>
      ),
    },
    {
      name: "Patients",
      sortable: true,
      center: true,
      style: { paddingRight: '30px' },
      cell: (row: any) => (
        <p className="mb-0 im14" onClick={() => { onRowClicked(row) }}>{row.patients}</p>
      ),
    },
    {
      name: "Workers",
      sortable: true,
      center: true,
      style: { paddingRight: '30px' },
      cell: (row: any) => (
        <p className="mb-0 im14" onClick={() => { onRowClicked(row) }}>{row.practitioners}</p>
      ),
    },
    {
      name: "Active Status",
      center: true,
      sortable: false,
      cell: (row: any) => (
        <div className="form-check form-switch form-switch-md" >
          <input
            type="checkbox"
            className={
              row.active
                ? "form-check-input bg-success"
                : "form-check-input bg-light"
            }
            style={{ cursor: "pointer" }}
            id="customSwitch"
            disabled={loadingUpdate}
            checked={row.active}
            onChange={e => {
              dispatch(
                updateOrganizations({
                  organizationId: row.id,
                  active: e.target.checked,
                })
              )
            }}
          />
        </div>
      ),
    },
    {
      name: <IconMenu openDropdown={bulkDropDown} setBulkDropDown={setBulkDropDown} setOpenDropdown={setOpenDropdown} />,
      sortable: false,
      center: true,
      width: "100px",
      cell: (row: any, index: number) => (
        <div className="d-flex">
          <Dropdown
            isOpen={openDropdown.id == row.id ? openDropdown.value : false}
            onClick={
              () => {
                setBulkDropDown(false)
                setOpenDropdown({ value: !openDropdown.value, id: row.id })
              }
            }
          >
            <i className="bx bx-dots-vertical-rounded font-size-18 edit-icons"></i>
            {openDropdown.value && openDropdown.id === row.id && (
              <div
                style={{
                  position: "absolute",
                  left: "-165px",
                  top: "-60px",
                  background: "#FFFFFF",
                  borderRadius: "8px",
                  border: "1px solid #e3e3e3",
                  boxShadow: "0px 3px 12px #00000029",
                  paddingTop: '10px',
                  paddingBottom: '5px',
                }}
              >
                <DropdownItem
                  className="text-danger"
                  style={{ marginTop: "-3%" }}
                  onClick={() => {
                    toggleModal(row.id);
                    setOpenDropdown({ value: false, id: row.id })
                  }}
                >
                  <span className="im font-size-13">Archive</span>
                </DropdownItem>
                <DropdownItem
                  style={{ marginTop: "-3%" }}
                  onClick={() => {
                    Async.setItem("@organization", row)
                    dispatch(setLocalData(!localData))
                    Async.setItem("@Screen", "edit")
                    history.push({
                      pathname: "/reports/summary",
                      state: {
                        page: "edit",
                        data: row,
                      },
                    })
                  }}
                >
                  <span className="im font-size-13">Summary</span>
                </DropdownItem>
                <DropdownItem
                  style={{ marginTop: "-3%" }}
                  onClick={() => {
                    Async.setItem("@organization", row)
                    dispatch(setLocalData(!localData))
                    Async.setItem("@Screen", "edit")
                    history.push({
                      pathname: "/organizationDetails",
                      state: {
                        page: "edit",
                        data: row,
                      },
                    })
                  }}
                >
                  <span className="im font-size-13">Organization details</span>
                </DropdownItem>

                {/* <DropdownItem
         //           onClick={() => {
        //             toggleMessageModal()
        //             setOpenDropdown({ value: false, id: row.id })
        //           }}
        style={{ marginTop: "-3%" }}
      >
        <span className="im font-size-13">Message</span>
      </DropdownItem> */}

              </div>
            )}
          </Dropdown>
        </div>
      ),
    },
  ]

  //Columns for Patient Management Screen
  const patientManagementColumns = [
    {
      name: "Name",
      sortable: true,
      width: "25%",
      cell: (row: any) => (
        <div className="d-flex" style={{ alignItems: "center" }} onClick={() => { onRowClicked(row) }}>
          <Avatar
            name={`${row.user.profile.firstName} ${row.user.profile.lastName}`}
            maxInitials={2}
            textSizeRatio={2.6}
            size="30"
            color="#2A45CD50"
            fgColor="#2A45CD"
            round
          />
          <p className="ms-3 mb-0 font-size-14">
            {`${row.user?.profile?.firstName} ${row.user.profile?.middleName !== null
              ? row.user.profile?.middleName
              : ""
              } ${row.user?.profile?.lastName}`}
          </p>
        </div>
      ),
    },
    {
      name: "Username",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14" onClick={() => { onRowClicked(row) }}>
          {row.user?.username}
        </p>
      ),
    },
    {
      name: "Date Added",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14" onClick={() => { onRowClicked(row) }}>
          {moment(row.createdAt).format("MMM DD, YYYY")}
        </p>
      ),
    },
    {
      name: "Last Active",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14" onClick={() => { onRowClicked(row) }}>
          {moment(row.user?.lastActiveAt).format("MMM DD, YYYY")}
        </p>
      ),
    },
    {
      name: "Active Status",
      center: true,
      sortable: false,
      cell: (row: any) => (
        <div className="form-check form-switch form-switch-md">
          <input
            type="checkbox"
            className={
              editBtn
                ? row.active
                  ? "form-check-input bg-success"
                  : "form-check-input bg-light"
                : row.active
                  ? "form-check-input bg-success pointer-none"
                  : "form-check-input bg-light pointer-none"
            }
            style={{ cursor: "pointer" }}
            disabled={statusChangeLoading}
            id="customSwitch"
            checked={row.active}
            onChange={e => {
              e.target.checked
                ? dispatch(ActivatePatient({ id: row.id }))
                : dispatch(DeactivatePatient({ id: row.id }))
            }}
          />
        </div>
      ),
    },
    {
      name: <IconMenu openDropdown={bulkDropDown} setBulkDropDown={setBulkDropDown} setOpenDropdown={setOpenDropdown} />,
      sortable: false,
      center: true,
      width: "100px",
      cell: (row: any) => (
        <div className="d-flex">
          <Dropdown
            isOpen={openDropdown.id == row.id ? openDropdown.value : false}
            onClick={
              () => {
                setBulkDropDown(false)
                setOpenDropdown({ value: !openDropdown.value, id: row.id })
              }
            }
          >
            <i className="bx bx-dots-vertical-rounded font-size-18 edit-icons"></i>
            {openDropdown.value && openDropdown.id === row.id && (
              <div
                style={{
                  position: "absolute",
                  left: "-140px",
                  top: "-58px",
                  background: "#FFFFFF",
                  borderRadius: "8px",
                  border: "1px solid #e3e3e3",
                  boxShadow: "0px 3px 12px #00000029",
                  paddingTop: '5px',
                  paddingBottom: '5px',
                }}
              >
                {archivePatient &&
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      toggleModal(row.id)
                    }}
                  >
                    <span className="im font-size-13">Archive</span>
                  </DropdownItem>}
                <DropdownItem
                  style={{ marginTop: "-3%" }}

                  onClick={() => {
                    history.push({
                      pathname: "/vitalsHistory",
                      state: { id: row.id },
                    })
                  }}
                >
                  <span className="im font-size-13">Vitals history</span>
                </DropdownItem>
                <DropdownItem
                  style={{ marginTop: "-3%" }}
                  onClick={() => {
                    Async.setItem("@patient", row)
                    Async.setItem("@ScreenPatient", "edit"),
                      history.push({
                        pathname: "/AddEditPatientManagement",
                        state: { page: "edit", data: row },
                      })
                  }}
                >
                  <span className="im font-size-13">Edit Information</span>
                </DropdownItem>
                {/* <DropdownItem
                  onClick={() => {
                    toggleMessageModal()
                    setOpenDropdown({ value: false, id: row.id })
                  }}
                  style={{ marginTop: "-3%" }}
                >
                  <span className="im font-size-13">Message</span>
                </DropdownItem> */}
              </div>
            )}
          </Dropdown>
        </div>
      ),
    },
  ]
  //Columns for Agent Management Screen
  const agentActiveColumns = [
    {
      name: "Name",
      sortable: true,
      width: "25%",
      cell: (row: any) => (
        <div className="d-flex" style={{ alignItems: "center" }} onClick={() => { onRowClicked(row) }}>
          <Avatar
            name={`${row.user.profile.firstName} ${row.user.profile.lastName}`}
            maxInitials={2}
            textSizeRatio={2.6}
            size="30"
            color="#2A45CD50"
            fgColor="#2A45CD"
            round
          />
          <p className="ms-3 mb-0 font-size-14">
            {" "}
            {`${row.user?.profile?.firstName} ${row.user.profile?.middleName !== null
              ? row.user.profile?.middleName
              : ""
              } ${row.user?.profile?.lastName}`}
          </p>
        </div>
      ),
    },
    {
      name: "Username",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14" onClick={() => { onRowClicked(row) }}>
          {row.user?.username}
        </p>
      ),
    },
    {
      name: "Role",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14" onClick={() => { onRowClicked(row) }}>
          {row.user?.roleAssignments?.length > 0
            ? row.user?.roleAssignments[0]?.role?.name
            : ""}
        </p>
      ),
    },
    {
      name: "Last Active",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14" onClick={() => { onRowClicked(row) }}>
          {moment(row.user?.lastActiveAt).format("MMM DD, YYYY")}
        </p>
      ),
    },

    {
      name: "Active Status",
      center: true,
      sortable: false,
      cell: (row: any) => (
        <div className="form-check form-switch form-switch-md">
          <input
            type="checkbox"
            className={
              editBtnWorker
                ? row.active
                  ? "form-check-input bg-success"
                  : "form-check-input bg-light"
                : row.active
                  ? "form-check-input bg-success pointer-none"
                  : "form-check-input bg-light pointer-none"
            }
            style={{ cursor: "pointer" }}
            disabled={agentStatusChangeLoading || loadingSearch}
            id="customSwitch"
            checked={row.active}
            onChange={e => {
              e.target.checked
                ? dispatch(ActivateAgent({ id: row.id }))
                : dispatch(DeactivateAgent({ id: row.id }))
            }}
          />
        </div>
      ),
    },
    {
      name: <IconMenu openDropdown={bulkDropDown} setBulkDropDown={setBulkDropDown} setOpenDropdown={setOpenDropdown} />,
      center: true,
      sortable: false,
      width: "100px",
      cell: (row: any) => (
        <div className="d-flex">
          <Dropdown
            isOpen={openDropdown.id == row.id ? openDropdown.value : false}
            onClick={
              () => {
                setBulkDropDown(false)
                setOpenDropdown({ value: !openDropdown.value, id: row.id })
              }
            }
          >
            <i className="bx bx-dots-vertical-rounded font-size-18 edit-icons"></i>
            {openDropdown.value && openDropdown.id === row.id && (
              <div
                style={{
                  position: "absolute",
                  left: "-140px",
                  top: "-35px",
                  background: "#FFFFFF",
                  borderRadius: "8px",
                  border: "1px solid #e3e3e3",
                  boxShadow: "0px 3px 12px #00000029",
                  paddingTop: '10px',
                  paddingBottom: '5px',
                }}
              >
                {archiveWorker &&
                  <DropdownItem
                    className="text-danger"
                    style={{ marginTop: "-3%" }}
                    onClick={() => {
                      setOpenDropdown({ value: false, id: row.id });
                      toggleModal(row.id);
                    }}
                  >
                    <span className="im font-size-13">Archive</span>
                  </DropdownItem>
                }

                <DropdownItem
                  style={{ marginTop: "-3%" }}
                  onClick={() => {
                    history.push({
                      pathname: "/AddEditAgentManagement",
                      state: { page: "edit", data: row },
                    })
                  }}
                >
                  <span className="im font-size-13">Edit Information</span>
                </DropdownItem>

                {/* <DropdownItem
                  onClick={() => {
                    toggleMessageModal()
                    setOpenDropdown({ value: false, id: row.id })
                  }}
                  style={{ marginTop: "-3%" }}
                >
                  <span className="im font-size-13">Message</span>
                </DropdownItem> */}

              </div>
            )}
          </Dropdown>
        </div>
      ),
    },
  ]
  // agent Pending columns
  const agentPendingColumns = [
    {
      name: "Name",
      sortable: true,
      cell: (row: any) => (
        <div className="d-flex" style={{ alignItems: "center" }} >
          <Avatar
            name={`${row.user.profile.firstName} ${row.user.profile.lastName}`}
            maxInitials={2}
            textSizeRatio={2.6}
            size="30"
            color="#2A45CD50"
            fgColor="#2A45CD"
            round
          />
          <p className="ms-3 mb-0 font-size-14">
            {" "}
            {`${row.user?.profile?.firstName} ${row.user.profile?.middleName !== null
              ? row.user.profile?.middleName
              : ""
              } ${row.user?.profile?.lastName}`}
          </p>
        </div>
      ),
    },
    {
      name: "Role",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14">
          {row?.user?.roleAssignments[0]?.role?.name}
        </p>
      ),
    },
    {
      name: "Date Added",
      sortable: true,
      cell: (row: any) => (
        <p className="mb-0 font-size-14">
          {moment(row.createdAt).format("MMM DD, YYYY")}
        </p>
      ),
    },
    {
      name: <IconMenu openDropdown={bulkDropDown} setBulkDropDown={setBulkDropDown} setOpenDropdown={setOpenDropdown} type={activeTab === '1' ? 'pending' : activeTab === '3' ? 'reject' : ''} />,
      center: true,
      sortable: false,
      cell: (row: any) => (

        <div className="d-flex gap-3 font-size-16 text-nowrap text-white">
          <button className="btn-accept"
            onClick={() => {
              dispatch(
                UpdateVerifiedStatus({
                  id: row.id,
                  status: "APPROVED",
                })
              )
            }}
          >
            <span className="btn-txt">Accept</span>
          </button>
          {type === "rejected" ? (
            <i
              className="bx bx-trash rounded-circle p-1 cursePointer"
              style={{ color: "#707070", border: "1px solid #707070" }}
              onClick={() => {
                toggleModal(row.id)
              }}
            ></i>
          ) : (
            <i
              onClick={() => {
                dispatch(
                  UpdateVerifiedStatus({
                    id: row.id,
                    status: "REJECTED",
                  })
                )
              }}
              className="bx bx-x bg-danger rounded-circle p-1 cursePointer"
            ></i>
          )}
        </div>
      ),
    },
  ]

  const handlePageChange = (page: any) => {
    if (rowsPerPage) {
      setSkip((page - 1) * rowsPerPage)
    }
  }

  const handlePerRowsChange = async (
    currentRowsPerPage: number,
    currentPage: number
  ) => {
    setRowsPerPage(currentRowsPerPage)
    onRowsPerPageFetchData(currentRowsPerPage, currentPage)
  }

  const options = {
    persistSelectedOnSort: true,
    persistSelectedOnPageChange: true
  }


  useEffect(() => {
    if (closeDrop) {
      setBulkDropDown(false);
      setOpenDropdown({
        value: false,
        id: -1,
      })
    }

  }, [closeDrop])


  return (
    <>
      <Message isOpen={messageModel} toggle={toggleMessageModal} />

      <DataTable
        columns={
          type == "dashboard"
            ? allOrganizationsColumns
            : type == "patient"
              ? patientManagementColumns
              : type == "approved"
                ? agentActiveColumns
                : type == "rejected"
                  ? agentPendingColumns
                  : agentPendingColumns
        }
        data={data}
        pagination
        paginationServer={true}
        sortServer={true}
        paginationServerOptions={options}
        paginationTotalRows={paginationTotal}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        noHeader={true}
        sortIcon={<i className="bx bx-chevron-up"></i>}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[10, 20, 30, 50, 100]}
        selectableRows
        onSort={handleSort}
        defaultSortAsc={defaultSortAsc}
        paginationResetDefaultPage={currentPage}
        onRowClicked={onRowClicked}
        pointerOnHover={pointerOnHover}
        onSelectedRowsChange={onSelectedRowsChange}
        clearSelectedRows={clearSelectedRows}
      />
      <p className="number-of-rows"> Selected rows: {numberOfRowsSelected}</p>
    </>
  )
}
export default Datatable
