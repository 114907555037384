import React, { useState } from "react"
import { FC } from "react"
import { ButtonDropdown, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner, Table } from "reactstrap"
import ReactApexChart from "react-apexcharts"
import "./graphs.scss"
import HighchartsReact from "highcharts-react-official"
import Highcharts from "highcharts/highstock"
import HC_more from "highcharts/highcharts-more"
import moment from "moment"
interface Series {
  name: string
  data: number[]
  complete: string[]
  dates: string[]
  marker?: any
  color?: string
  readingType?: string[]
}
interface cardProps {
  title: string
  leftText?: string | number
  leftSub?: string
  rightText?: number
  type: string
  dropValue?: number
  setDropValue?: any
  graphData?: Series[] | any
  loading?: boolean
  typeDashboard?: string
  total?: number
}
interface SeriesType {
  type?: string
  name?: string
  data: number[]
}
HC_more(Highcharts)
const BPGraphs: FC<cardProps> = ({
  title,
  leftText,
  leftSub,
  rightText,
  type,
  dropValue, setDropValue,
  graphData,
  typeDashboard,
  loading,
  total
}) => {
  const [dropdown, setDropdown] = useState<boolean>(false)
  const [statsDropdown, setStatsDropdown] = useState<any>([
    { name: "vs Last week", type: 0 },
    { name: "vs Last month", type: 1 },
    { name: "All time", type: 2 },
  ])

  return (
    <>
      <Card
        style={{ minWidth: "250px", width: "100%", height: "270px" }}
        className="shadow-sm"
      >
        <CardBody>
          <Row style={{ fontWeight: "bold" }}>
            <Col xs={6}>
              <div>
                <p className="heading font-size-14 mb-2">{title}</p>
                {leftText !== undefined && dropValue !== 2 && !loading && !loading && graphData?.length > 0 && graphData[0]?.data?.length > 0 ? (
                  <h5 style={{ color: "#2A45CD" }} className="font-size-18 isb">
                    {leftText}
                    <span className="textBPSpan isb">
                      {leftSub ? leftSub : null}
                    </span>
                  </h5>
                ) : null}

                {leftText !== undefined && dropValue === 2 && !loading && total !== undefined ? (
                  <h5 style={{ color: "#2A45CD" }} className="font-size-18 isb">
                    {leftText}
                  </h5>
                ) : null}

                {leftText !== undefined && title === 'Age Distribution' && !loading  && leftText !== 0 ? (
                  <h5 style={{ color: "#2A45CD" }} className="font-size-18 isb">
                    {leftText}
                  </h5>
                ) : null}

              </div>
            </Col>
            {dropValue !== undefined ? (
              <Col className="text-end" xs={6}>
                <div className="font-size-10">
                  <div className="mb-2">
                    {/* vs Last week */}
                    <ButtonDropdown
                      isOpen={dropdown}
                      toggle={() => setDropdown(!dropdown)}
                    >
                      <DropdownToggle
                        caret
                        color="#2C3242"
                        className="btn bg-white btn-sm im"
                        style={{
                          borderRadius: "17px",
                          border: "0px solid #E3E3E3",
                        }}
                      >
                        <span style={{ color: "#2C3242" }}>{statsDropdown[dropValue].name}</span>
                        <i style={{ fontSize: '7px' }} className='bx bxs-down-arrow ms-1'></i>
                      </DropdownToggle>
                      <DropdownMenu className='' style={{ minWidth: 'max-content' }}>
                        {statsDropdown.map((val: any) => (
                          <DropdownItem
                            className="py-1 px-3"
                            key={val.type}
                            onClick={() => {
                              setDropValue(val.type)
                            }}
                          >
                            <p className="text-muted im m-0">{val.name}</p>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  {
                    dropValue !== 2 && graphData?.length > 0 && graphData[0]?.data?.length > 0 && !loading && rightText !== undefined &&
                    <div className="me-2">
                      <h5 style={{ color: '#19B707', margin: 0 }}>
                        {rightText >= 0 ?
                          <span className="text-success ib18">
                            {rightText}%
                            <i className="bx bx-up-arrow-alt rounded-circle ms-1" style={{ background: '#19B70720' }}></i>
                          </span>
                          :
                          <span className="text-danger ib18">
                            {-rightText}%
                            <i className="bx bx-down-arrow-alt rounded-circle ms-1" style={{ background: '#E53C4120' }}></i>
                          </span>
                        }
                      </h5>
                    </div>
                  }
                </div>
              </Col>
            ) : null}
          </Row>
          {loading &&
            <div
              style={{
                width: "100%",
                minHeight: "180px",
                display: "flex",
                flex: "1",
                justifyContent: "center",
              }}
            >
              <Spinner
                style={{
                  color: "#2a45cd",
                  alignSelf: "center",
                  width: "50px",
                  height: "50px",
                }}
                animation="border"
                variant="primary"
              />
            </div>
          }
          {!loading && type == "line" ? (
            <div id="overview-chart" dir="ltr">
              {graphData && graphData?.length > 0 && graphData[0].data.length > 0 ?
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    credits: {
                      enabled: true,
                      position: {
                        align: "right",
                        x: -10,
                        verticalAlign: "bottom",
                        y: -5,
                      },
                      text: "",
                    },
                    plotOptions: {
                      column: {
                        pointPadding: 0.2,
                        borderWidth: 0.5,
                      },
                    },

                    chart: {
                      height: 180,
                      backgroundColor: "#ffffff",
                    },
                    title: {
                      text: "",
                    },
                    legend: {
                      enabled: false,
                    },
                    subtitle: {},

                    tooltip: {
                      shared: true,
                      useHTML: true,
                      backgroundColor: null,
                      borderWidth: 0,
                      shadow: false,

                      formatter: function () {
                        return (
                          "<div>" +
                          `<a class="toolTip-heading">${title}</a>` +
                          "<br>" +
                          `<a class="toolTip-value">${graphData[0].data[
                          this.points[0].point.index
                          ]
                          }</a>` +
                          "<br>" +
                          "</div>" +
                          "<br>" +
                          "<div>" +
                          `<a class="date">
                          ${dropValue === 0 ?
                            graphData[0].dates[
                            this.points[0].point.index
                            ]
                            :
                            moment(
                              graphData[0].dates[
                              this.points[0].point.index
                              ]
                            ).format("DD MMM YY")}
                                </a>` +
                          "</div>"
                        )
                      },
                    },
                    xAxis: {
                      type: "category",
                      uniqueNames: false,
                      min: 0,
                      max:
                        graphData[0].dates.length > 5
                          ? 4
                          : graphData[0].dates.length - 1,
                      reversed: true,
                      scrollbar: {
                        enabled: true,
                        showFull: false,
                      },

                      labels: dropValue !== 0 ?
                        {
                          formatter: function () {
                            return moment(
                              this.value.toString()
                            ).format("DD MMM YY")
                          },
                        } :
                        {
                          formatter: function () {
                            return this.value.toString()

                          },
                        },
                      categories: graphData[0].dates,
                    },
                    yAxis: {
                      title: {
                        text: "",
                      },
                      minRange: 0,
                    },

                    series: graphData,
                  }}
                />
                :
                !loading && graphData?.length > 0 && graphData[0]?.data?.length <= 0 && <p className="text-center"
                  style={{
                    width: "100%",
                    minHeight: !open ? "10px" : "190px",
                    display: "flex",
                    alignItems: 'center',
                    flex: "1",
                    justifyContent: "center",
                  }}>
                  No Data
                </p>
              }
            </div>
          ) : (!loading && graphData?.data?.length > 0 && type == "bar") ? (
            <div id="overview-chart" dir="ltr">
              {graphData &&
                <ReactApexChart
                  series={[
                    {
                      data: graphData.data,
                    },
                  ]}
                  id={'charts'}
                  options={{
                    tooltip: {
                      custom: function (series: any) {
                        return (
                          "<div class='bg-white'>" +
                          `<a class="toolTip-heading1">${title}</a>` +
                          "<br>" +
                          `<a class="toolTip-value ">
                          ${graphData.data[series?.dataPointIndex]}
                          </a>` +
                          "</div>" +
                          "<div class='bg-white age-div'>" +
                          `<a class="date1">
                          Age: ${graphData.categories[series?.dataPointIndex]}
                          </a>` +
                          "</div>"
                        )
                      }
                    },
                    chart: {
                      toolbar: {
                        show: false,
                      },
                    },
                    plotOptions: {
                      bar: {
                        horizontal: false,
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },

                    colors: ["rgba(42, 69, 205, 1)"],

                    grid: {
                      borderColor: "#f1f1f1",
                    },
                    xaxis: {
                      labels: {
                        style: {
                          colors: '#6b6f7a'
                        }
                      },
                      categories: graphData.categories,
                    },
                  }}
                  type="bar"
                  height={180}
                />
              }
            </div>
          ) : (!loading && graphData && Object.keys(graphData)?.length > 0 && type == "table") ? (
            <div className="table-responsive">
              {graphData &&
                <Table responsive borderless className="table table-sm m-0">
                  <thead className="thead-custom">
                    <tr>
                      <th className="im10 color-black radius1">{typeDashboard === 'BS' ? 'BS Classification' : 'BP Classification'}</th>
                      <th className="im10 color-black ">Count</th>
                      <th className="im10 color-black radius2">Percent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {graphData.BLOOD_PRESSURE_LOW &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Low</th>
                        <td className="im10 color-black bottom-bottom ">
                          <span className="center-text">{graphData.BLOOD_PRESSURE_LOW}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_PRESSURE_LOW / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_PRESSURE_NORMAL &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Normal</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_PRESSURE_NORMAL}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_PRESSURE_NORMAL / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_PRESSURE_ELEVATED &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Elevated</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_PRESSURE_ELEVATED}</span>
                        </td>

                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_PRESSURE_ELEVATED / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_PRESSURE_HYPERTENSION_STAGE_1 &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Hypertension - Stage 1</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_PRESSURE_HYPERTENSION_STAGE_1}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_PRESSURE_HYPERTENSION_STAGE_1 / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_PRESSURE_HYPERTENSION_STAGE_2 &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Hypertension - Stage 2</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_PRESSURE_HYPERTENSION_STAGE_2}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_PRESSURE_HYPERTENSION_STAGE_2 / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_PRESSURE_HYPERTENSION_CRISIS &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Hypertensive Crisis</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_PRESSURE_HYPERTENSION_CRISIS}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_PRESSURE_HYPERTENSION_CRISIS / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_GLUCOSE_LOW &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Low</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_GLUCOSE_LOW}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_GLUCOSE_LOW / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_GLUCOSE_HIGH &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">High</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_GLUCOSE_HIGH}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_GLUCOSE_HIGH / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                    {graphData.BLOOD_GLUCOSE_NORMAL &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Normal</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_GLUCOSE_NORMAL}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_GLUCOSE_NORMAL / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }

                    {graphData.BLOOD_GLUCOSE_PRE_DIABETES &&
                      <tr>
                        <th scope="row" className="im10 color-black bottom-bottom">Pre Diabetes</th>
                        <td className="im10 color-black bottom-bottom">
                          <span className="center-text">  {graphData.BLOOD_GLUCOSE_PRE_DIABETES}</span>
                        </td>
                        <td className="im10 color-black bottom-bottom">{((graphData.BLOOD_GLUCOSE_PRE_DIABETES / Object.values(graphData).reduce((partialSum: number, a: any) => partialSum + a, 0)) * 100).toFixed(2)}%</td>
                      </tr>
                    }
                  </tbody>
                </Table>
              }
            </div>
          ) :
            !loading && <p className="text-center"
              style={{
                width: "100%",
                minHeight: !open ? "10px" : "190px",
                display: "flex",
                alignItems: 'center',
                flex: "1",
                justifyContent: "center",
              }}>
              No Data
            </p>
          }
        </CardBody>
      </Card>
    </>
  )
}
export default BPGraphs
