import React, { FC, useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"
//i18n
import logo from "~/assets/images/svg/Logo.svg"
import { WithRouterProps } from "~/models/WithRouterProps.model"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarFalse, toggle } from "~/store/reducers/sideBar"
import business from "~/assets/images/svg/business.svg"

interface SideBarProps extends WithRouterProps {
  type: string
}

const Sidebar: FC<SideBarProps> = ({ type, location }) => {
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const [height, setHeight] = useState(window.innerHeight)

  const dispatch = useAppDispatch()

  function tToggle() {
    var body: HTMLElement = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      if (collapsed) {
        dispatch(toggle(false))
      } else {
        dispatch(toggle(true))
      }
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [location.pathname])

  useEffect(() => {
    handleResize()
  }, [])
  const handleResize = () => {
    const { innerWidth: width } = window
    var body: HTMLElement = document.body
    const check = body.classList.contains("vertical-collpsed")

    if (width <= 998 && location.pathname === "/dashboard") {
      if (!check) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else if (width > 998 && location.pathname === "/dashboard") {
      if (check) {
        dispatch(toggle(false))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    }
  }
  // const handleCollapse = () => {
  //   var body: HTMLElement = document.body
  //   const check = body.classList.contains("vertical-collpsed")

  //     if (!check) {
  //       dispatch(toggle(true))
  //       body.classList.toggle("vertical-collpsed")
  //       body.classList.toggle("sidebar-enable")
  //     }
  //     if (check) {
  //       dispatch(toggle(false))
  //       body.classList.toggle("vertical-collpsed")
  //       body.classList.toggle("sidebar-enable")
  //     }
  // }
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <div style={{ cursor: "pointer" }} className="logo logo-dark">
            <span className="logo-sm ms-2">
              <button
                type="button"
                onClick={() => {
                  tToggle()
                }}
                className="btn btn-sm p-0 m-0 font-size-16 header-item "
                id="vertical-menu-btn"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </span>

            <span className="logo-lg">
              <div style={{ whiteSpace: "nowrap" }}>
                <img src={logo} alt="" height="32" className="m-1 mb-2" />
                <span className="font-size-16 sbl" style={{ fontWeight: "bold" }}>
                  ILARA HEALTH
                </span>
                <button
                  type="button"
                  style={{
                    marginTop: '-5px',
                    marginLeft: '20px'
                  }}
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm font-size-16"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
              </div>
            </span>

          </div>
        </div>
        <div className={collapsed ? "position-fixed width-close" : "position-fixed width-open"}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title"></li>
              <li className="mm-active">
                <Link
                  onClick={() => {
                    dispatch(setSecondaryBarFalse())
                  }}
                  to="/#"
                  className=""
                >
                  {collapsed ? (
                    <>
                      <img
                        src={business}
                        alt=""
                        height="20"
                        className="mb-1 ms-1"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={business}
                        alt=""
                        height="16"
                        className="mb-1 me-1"
                      />
                      <span className="sb13">All Organizations</span>
                    </>
                  )}
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default withRouter(Sidebar) as any
