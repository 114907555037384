import React, { useEffect, useState } from "react";
import { FC } from "react";
import { ButtonDropdown, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, Spinner } from "reactstrap";
import ReactApexChart from "react-apexcharts"
import './graphs.scss';
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import HC_more from "highcharts/highcharts-more"
import moment from "moment";
interface Series {
  name: string
  data: number[]
  complete: string[]
  dates: string[]
  marker?: any
  color?: string
  readingType?: string[]
}
interface cardProps {
  title: string;
  leftText: number;
  rightText: number;
  open: boolean;
  graphData: Series[]
  dropValue?: number
  setDropValue?: any
  leftSub: number
  loading: boolean
  total?: number
}
interface SeriesType {
  data: number[]
}
HC_more(Highcharts)

const LinearGraph: FC<cardProps> = ({ title, graphData, leftText, rightText, open, dropValue, setDropValue, leftSub, loading, total }) => {
  const [cardOpen, setCardOpen] = useState<boolean>(false);

  const [dropdown, setDropdown] = useState<boolean>(false)
  const [statsDropdown, setStatsDropdown] = useState<any>([
    { name: "vs Last week", type: 0 },
    { name: "vs Last month", type: 1 },
    { name: "All time", type: 2 },
  ])

  const series: SeriesType[] = [
    {
      data: [11, 27, 74, 66, 75, 38],
    }
  ];
  const options: ApexCharts.ApexOptions = {
    chart: { toolbar: { show: !1 } },
    dataLabels: { enabled: !1 },
    stroke: { width: 2, colors: ['#2A45CD'], dashArray: [0, 0, 3] },
    // fill: { type: "solid", colors:['#fff'], opacity: [0.15, 0.05, 1] },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
      ],
    },
    colors: ["#2A45CD", "#3452e1", "#2A45CD"],
  }
  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      <Dropdown
        isOpen={cardOpen}
        onMouseOver={() => setCardOpen(true)}
        onMouseLeave={() => setCardOpen(false)}
      >
        <Card style={{
          minWidth: '250px',
          minHeight: !open ? "87px" : "270px",
          position: !open ? 'absolute' : 'unset',
          zIndex: 10,
          width: '100%',
          boxShadow: '1px 2px 5px #00000029'
        }} >
          <CardBody className="px-4" style={{ paddingTop: '14px', paddingBottom: '14px' }}>
            <Row style={{ fontWeight: 'bold' }}>
              <Col xs={6}>
                <p style={{ whiteSpace: 'nowrap' }} className="heading font-size-14 mb-2">{title}</p>

                {!loading && graphData[0]?.data?.length > 0 && dropValue !== 2 ? (
                  <h5 style={{ color: "#2A45CD" }} className="font-size-18 isb">
                    {numberWithCommas(leftText)}
                    <span className="textBPSpan">
                      {`from ${leftSub ? numberWithCommas(leftSub) : 0}`}
                    </span>
                  </h5>
                ) : null}

                {!loading && total !== undefined && graphData[0]?.data?.length > 0 && dropValue === 2 ? (
                  <h5 style={{ color: "#2A45CD" }} className="font-size-18 isb">
                    {numberWithCommas(total)}
                  </h5>
                ) : null}

              </Col>
              <Col className="text-end" xs={6}>
                {!loading && dropValue !== undefined && statsDropdown &&
                  <div className="font-size-10">
                    <div className="mb-2">
                      {/* vs Last week */}
                      <ButtonDropdown
                        isOpen={dropdown}
                        toggle={() => setDropdown(!dropdown)}
                      >
                        <DropdownToggle
                          caret
                          color="#2C3242"
                          className="btn bg-white btn-sm im"
                          style={{
                            borderRadius: "17px",
                            border: "0px solid #E3E3E3",
                          }}
                        >
                          <span style={{ color: "#2C3242"}}>{statsDropdown[dropValue].name}</span>
                          <i style={{ fontSize: '7px' }} className='bx bxs-down-arrow ms-1'></i>
                        </DropdownToggle>
                        <DropdownMenu className='' style={{ minWidth: 'max-content' }}>
                          {statsDropdown.map((val: any) => (
                            <DropdownItem
                              className="py-1 px-3"
                              key={val.type}
                              onClick={() => {
                                setDropValue(val.type)
                              }}
                            >
                              <p className="text-muted im m-0">{val.name}</p>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                    {graphData[0]?.data?.length > 0 &&
                      dropValue !== 2 &&
                      <div className="me-2">
                        <h5 style={{ color: '#19B707', margin: 0 }}>
                          {rightText >= 0 ?
                            <span className="text-success isb font-size-18">
                              {rightText}%
                              <i className="bx bx-up-arrow-alt rounded-circle ms-1" style={{ background: '#19B70720' }}></i>
                            </span>
                            :
                            <span className="text-danger isb font-size-18">
                              {-rightText}%
                              <i className="bx bx-down-arrow-alt rounded-circle ms-1" style={{ background: '#E53C4120' }}></i>
                            </span>
                          }
                        </h5>
                      </div>
                    }
                  </div>}
              </Col>
            </Row>
            {loading &&

              <div
                style={{
                  width: "100%",
                  minHeight: !open ? "10px" : "210px",
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  style={{
                    color: "#2a45cd",
                    alignSelf: "center",
                    width: !open ? "30px" : "50px",
                    height: !open ? "30px" : "50px",
                  }}
                  animation="border"
                  variant="primary"
                />
              </div>}

            {cardOpen || open ?
              <div id="overview-chart"
                className="" dir="ltr"

              >
                {!loading && graphData && graphData?.length > 0 && graphData[0]?.data?.length > 0 ?
                  <div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        credits: {
                          enabled: true,
                          position: {
                            align: "right",
                            x: -10,
                            verticalAlign: "bottom",
                            y: -5,
                          },
                          text: "",
                        },
                        plotOptions: {
                          column: {
                            pointPadding: 0.2,
                            borderWidth: 0.5,
                          },
                        },

                        chart: {
                          height: 180,
                          backgroundColor: "#ffffff",
                        },
                        title: {
                          text: "",
                        },
                        legend: {
                          enabled: false,
                        },
                        subtitle: {},

                        tooltip: {
                          shared: true,
                          useHTML: true,
                          backgroundColor: null,
                          borderWidth: 0,
                          shadow: false,

                          formatter: function () {
                            return (
                              "<div>" +
                              `<a class="toolTip-heading">${title}</a>` +
                              "<br>" +
                              `<a class="toolTip-value">${graphData[0].data[
                              this.points[0].point.index
                              ]
                              }</a>` +
                              "<br>" +
                              "</div>" +
                              "<br>" +
                              "<div>" +
                              `<a class="date">
                                    ${dropValue === 0 ?
                                graphData[0].dates[
                                this.points[0].point.index
                                ]
                                :
                                moment(
                                  graphData[0].dates[
                                  this.points[0].point.index
                                  ]
                                ).format("DD MMM YY")}
                                    </a>` +
                              "</div>"
                            )
                          },
                        },
                        xAxis: {
                          type: "category",
                          uniqueNames: false,
                          min: 0,
                          max:
                            graphData[0].dates.length > 4
                              ? 3
                              : graphData[0].dates.length - 1,
                          reversed: true,
                          scrollbar: {
                            enabled: true,
                            showFull: false,
                          },

                          labels:
                            dropValue !== 0 ?
                              {
                                formatter: function () {
                                  return moment(
                                    this.value.toString()
                                  ).format("DD MMM YY")
                                },
                              } :
                              {
                                formatter: function () {
                                  return this.value.toString()

                                },
                              },
                          categories: graphData[0].dates,
                        },
                        yAxis: {
                          title: {
                            text: "",
                          },
                          minRange: 0,
                        },

                        series: graphData,
                      }}
                    />
                  </div> :
                  !loading &&
                  <p className="text-center"
                    style={{
                      width: "100%",
                      minHeight: !open ? "10px" : "190px",
                      display: "flex",
                      alignItems: 'center',
                      flex: "1",
                      justifyContent: "center",
                    }}>
                    No Data
                  </p>
                }

              </div> : null}
          </CardBody>
        </Card>
      </Dropdown>
    </>
  )
}
export default LinearGraph;