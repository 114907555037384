import { createSlice } from "@reduxjs/toolkit"
import {
  ActivatePatient,
  CreatePatient,
  DeactivatePatient,
  EditPatient,
  SearchPatient,
} from "./thunk"
import { addPatient } from "./types"

const initialState: addPatient = {
  success: false,
  loading: false,
  error: "",
  statusChangeSuccess: false,
  statusChangeLoading: false,
  statusChangeError: "",
  //update
  updateLoading: false,
  updateSuccess: false,
  updateError: "",
  // Fetch Identifiers
  loadingIdentifiers: false,
  errorIdentifiers: "",
  Identifiers: { items: [] },
  successIdentifiers: false,
  //search
  loadingSearchPatient : false,
  errorSearchPatient : "",
  successSearchPatient : false,
  searchPatient : [],

  take: 0,
  total: 0,
  patientSearchData:"",
  isSearch:false,

  resetTable:false
}
const patientSlice = createSlice({
  name: "patient",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(CreatePatient.pending, state => {
        state.loading = true
        state.success = false
        state.error = ""
      })
      .addCase(CreatePatient.fulfilled, state => {
        state.loading = false
        state.success = true
        state.error = ""
      })
      .addCase(CreatePatient.rejected, (state, action) => {
        state.loading = false
        state.success = false
        if (action.error) {
          state.error = action.error.message as string
        } else {
          state.error = "Error creating patient profile"
        }
      })
      .addCase(ActivatePatient.pending, state => {
        state.statusChangeLoading = true
        state.statusChangeSuccess = false
        state.statusChangeError = ""
      })
      .addCase(ActivatePatient.fulfilled, state => {
        state.statusChangeLoading = false
        state.statusChangeSuccess = true
        state.statusChangeError = ""
      })
      .addCase(ActivatePatient.rejected, (state, action) => {
        state.statusChangeLoading = false
        state.statusChangeSuccess = false
        if (action.error) {
          state.statusChangeError = action.error.message as string
        } else {
          state.statusChangeError = "Error activating patient"
        }
      })
      .addCase(DeactivatePatient.pending, state => {
        state.statusChangeLoading = true
        state.statusChangeSuccess = false
        state.statusChangeError = ""
      })
      .addCase(DeactivatePatient.fulfilled, state => {
        state.statusChangeLoading = false
        state.statusChangeSuccess = true
        state.statusChangeError = ""
      })
      .addCase(DeactivatePatient.rejected, (state, action) => {
        state.statusChangeLoading = false
        state.statusChangeSuccess = false
        if (action.error) {
          state.statusChangeError = action.error.message as string
        } else {
          state.statusChangeError = "Error deactivationg patient"
        }
      })
      // Update used for both patient and agent
      .addCase(EditPatient.pending, state => {
        state.updateLoading = true
        state.updateSuccess = false
        state.updateError = ""
      })
      .addCase(EditPatient.fulfilled, state => {
        state.updateLoading = false
        state.updateSuccess = true
        state.updateError = ""
      })
      .addCase(EditPatient.rejected, (state, action) => {
        state.updateLoading = false
        state.updateSuccess = false
        if (action.error) {
          state.updateError = action.error.message as string
        } else {
          state.updateError = "Error updating patient"
        }
      })
      //Search patient
      .addCase(SearchPatient.pending, state => {
        state.loadingSearchPatient = true
        state.errorSearchPatient = ""
        state.successSearchPatient = false
      })
      .addCase(SearchPatient.fulfilled, (state, { payload }) => {
        state.loadingSearchPatient = false
        state.errorSearchPatient = ""
        state.successSearchPatient = true
        state.searchPatient = payload.patient
        state.total = payload.total
      })
      .addCase(SearchPatient.rejected, (state, action) => {
        state.loadingSearchPatient = false
        state.successSearchPatient = false

        if (action.error) {
          state.errorSearchPatient = action.error.message as string
        } else {
          state.errorSearchPatient = "Error search patients"
        }
      })
  },
  reducers: {
    setPatientTakeReducer(state, action) {
      state.take = action.payload;
    },
    ResetPatientStates: state => {
      state.success = false
      state.loading = false
      state.error = ""
    },
    ResetSearchPatientStates: state => {
      state.loadingSearchPatient= false;
      state.errorSearchPatient= "";
      state.successSearchPatient= false;
      state.searchPatient= [];
    },
    ResetPatientStatusStates: state => {
      state.statusChangeSuccess = false
      state.statusChangeLoading = false
      state.statusChangeError = ""
    },
    ResetPatientUpdateStates: state => {
      state.updateSuccess = false
      state.updateLoading = false
      state.updateError = ""
    },
    setSearchPatient(state, action) {
      state.patientSearchData = action.payload;
    },
    setIsSearchPatient(state, action) {
      state.isSearch = action.payload;
    },
    resetSearchStates: state => {
      state.loadingSearchPatient = false;
      state.errorSearchPatient = "";
      state.successSearchPatient = false;
    },
    setResetTable(state, action) {
      state.resetTable = action.payload;
    },
  },
}) 

export const {
  ResetPatientStates,
  ResetPatientStatusStates,
  ResetPatientUpdateStates,
  setPatientTakeReducer,
  setSearchPatient,
  setIsSearchPatient,
  ResetSearchPatientStates,
  resetSearchStates,
  setResetTable
} = patientSlice.actions
export default patientSlice.reducer
