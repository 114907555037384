import { createAsyncThunk } from "@reduxjs/toolkit"
import { MyKnownError } from "~/models/Error.model";
import { postRequest } from "~/store/apiHelper"
import { SignUpLinkBody } from "./types";


export const FetchSignUpLink = createAsyncThunk<
  any,
  SignUpLinkBody,
  { rejectValue: MyKnownError }
>("signUpLink/getOrganization", async (data: SignUpLinkBody, thunkApi) => {
  return postRequest("/rpc/organizationsGetSignUpLink", data, true, thunkApi)
})