import { createAsyncThunk } from "@reduxjs/toolkit"
import { MyKnownError } from "~/models/Error.model"
import { IdentifiersObj } from "~/models/Identifier.model"
import { Search } from "~/models/Search.model"
import { postRequest } from "~/store/apiHelper"
import Async from "~/utils/Async"
import {
  UpdatePatient,
  Patient,
  updatePatientStatus,
  FetchIdentifier,
  PatientSearch,
} from "./types"

export const CreatePatient = createAsyncThunk<
  Patient,
  Patient,
  { rejectValue: MyKnownError }
>("patient/add", async (data: Patient, thunkApi) => {
  return postRequest("/rpc/usersCreateAsPatient", data, true, thunkApi)
})

export const ActivatePatient = createAsyncThunk<
  Patient,
  updatePatientStatus,
  { rejectValue: MyKnownError }
>("patient/activate", async (data: updatePatientStatus, thunkApi) => {
  return postRequest("/rpc/patientsActivate", data, true, thunkApi)
})

export const DeactivatePatient = createAsyncThunk<
  Patient,
  updatePatientStatus,
  { rejectValue: MyKnownError }
>("patient/deactivate", async (data: updatePatientStatus, thunkApi) => {
  return postRequest("/rpc/patientsDeactivate", data, true, thunkApi)
})

//Edit Patients
export const EditPatient = createAsyncThunk<
  Patient,
  UpdatePatient,
  { rejectValue: MyKnownError }
>("patient/edit", async (data: UpdatePatient, thunkApi) => {
  let self = Async.getItem('self');
  self && delete data.id;
  return postRequest(self ? "/rpc/usersUpdateProfileSelf" : "/rpc/usersUpdateProfile", data, true, thunkApi)
})

//Search
export const SearchPatient = createAsyncThunk<
  PatientSearch,
  Search,
  { rejectValue: MyKnownError }
>("patientsSearchByName", async (data: any, thunkApi) => {
  
  return postRequest("/rpc/patientsSearchByName", data, true, thunkApi)
})