import React, { FC, useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Card, Form, Label, Input } from "reactstrap"
import LogoSvg from "../../assets/images/svg/Logo.svg"
import backgroundSvg from "../../assets/images/png/background1.png"
import "./login.scss"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { ResetPassword, } from "~/store/reducers/AuthSlice/thunks"
import { errorToast, successToast } from "~/utils/toast"
import { ResetResetPassword } from "~/store/reducers/AuthSlice"
import { WithRouterProps } from "~/models/WithRouterProps.model"

interface PageType {
    username: string
    otp: string
}

const PasswordReset: FC<WithRouterProps> = props => {
    const { location } = props
    const history = useHistory()
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const [pageData, setPageData] = useState<PageType>()
    const { loadingResetPassword, successResetPassword, errorResetPassword,
    } = useAppSelector(
        state => state.AuthReducer
    )
    const [showPass, setShowPass] = useState<boolean>(false)
    const [showPass2, setShowPass2] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const page: PageType = location?.state as PageType
        setPageData(page)
    }, [location])

    useEffect(() => {
        if (successResetPassword) {
            successToast('Password reset successful, please login to continue!')
            history.push('/login')
            dispatch(ResetResetPassword())
        } else if (errorResetPassword) {
            errorToast(errorResetPassword)
            dispatch(ResetResetPassword())
        }
    }, [successResetPassword, errorResetPassword])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, "Password too short!")
                .required("Please enter your password"),
            confirmPassword: Yup.string()
                .test('Check if password same',
                    'Confirm password must be same as new password',
                    e => {
                        if (validation.values.password === e)
                            return true
                        else return false
                    }
                )
                .min(8, "Password too short!")
                .required("Please enter your password"),
        }),
        onSubmit: values => {
            dispatch(ResetPassword({
                otp: `${query.get("key") ? query.get("key") : pageData?.otp}`,
                username: `${query.get("username") ? query.get("username") : pageData?.username}`,
                newPassword: values.password
            }))
        },
    })


    return (
        <React.Fragment>
            <div className="bg-img" />
            <img src={backgroundSvg} alt="" className="bg-img" />
            <div className="col-md-10 mt-5 offset-1 card-container">
                <div className="content">
                    <div>
                        <div className="logoContainer">
                            <div className="logoContent">
                                <img src={LogoSvg} alt="" height="36" />
                                <p
                                    className="logoText align-self-center ms-1 sbl"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    ILARA HEALTH
                                </p>
                            </div>
                        </div>
                        <h5 className="ms-2 mt-2">Patient Monitoring App</h5>
                        <h5 className="ms-2 mb-4">Admin Panel</h5>
                    </div>

                    <div className="right-text">
                        <h2 className="text">
                            A suite of{" "}
                            <span className="text-primary ms-1">proprietary digital </span>
                        </h2>
                        <h2 className="text">
                            <span className="text-primary me-1">health tools</span>to digitize
                            our
                        </h2>
                        <h2 className="text">clinic partners</h2>
                    </div>
                </div>

                <div className="col-sm-4 pe-5">
                    <Card className="p-3 shadow-lg ">
                        <h5 className="mt-2 text-head">
                            <b>Enter new password</b>
                        </h5>
                        <Form
                            className="form-horizontal"
                            id="loginForm"
                            onSubmit={e => {
                                e.preventDefault()
                                validation.handleSubmit()
                                return false
                            }}
                        >
                            <div className="mb-3">
                                <Label className="form-label">New Password</Label>
                                <div className="passwordInput">
                                    <Input
                                        name="password"
                                        value={validation.values.password || ""}
                                        type={showPass ? "text" : "password"}
                                        placeholder="Enter Password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        className={
                                            validation.touched.password &&
                                                validation.errors.password
                                                ? "form-control border-danger"
                                                : "form-control"
                                        }
                                    />
                                    {showPass ? (
                                        <i
                                            onClick={() => setShowPass(false)}
                                            className="fas fa-eye-slash eyeIcon"
                                        />
                                    ) : (
                                        <i
                                            onClick={() => setShowPass(true)}
                                            className="fas fa-eye eyeIcon"
                                        />
                                    )}
                                </div>
                                {validation.touched.password && validation.errors.password ? (
                                    <div className="error-container mt-1 mb-1">
                                        <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                                        <h6 className="text-danger mt-2 ms-2">
                                            {validation.errors.password}
                                        </h6>
                                    </div>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">Confirm password</Label>
                                <div className="passwordInput">
                                    <Input
                                        name="confirmPassword"
                                        value={validation.values.confirmPassword || ""}
                                        type={showPass2 ? "text" : "password"}
                                        placeholder="Re-enter password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        className={
                                            validation.touched.confirmPassword &&
                                                validation.errors.confirmPassword
                                                ? "form-control border-danger"
                                                : "form-control"
                                        }
                                    />
                                    {showPass2 ? (
                                        <i
                                            onClick={() => setShowPass2(false)}
                                            className="fas fa-eye-slash eyeIcon"
                                        />
                                    ) : (
                                        <i
                                            onClick={() => setShowPass2(true)}
                                            className="fas fa-eye eyeIcon"
                                        />
                                    )}
                                </div>
                                {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                    <div className="error-container mt-1 mb-1">
                                        <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                                        <h6 className="text-danger mt-2 ms-2">
                                            {validation.errors.confirmPassword}
                                        </h6>
                                    </div>
                                ) : null}
                            </div>

                            {errorResetPassword && errorResetPassword !== "" && (
                                <div className="error-container mt-1 mb-1">
                                    <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                                    <h6 className="text-danger mt-2 ms-2">{errorResetPassword}</h6>
                                </div>
                            )}
                            <div className="mt-3 d-grid">
                                <button
                                    className="btn btn-primary btn-block mb-3"
                                    disabled={loadingResetPassword}
                                    type="submit">
                                    Reset my password and log in
                                    {loadingResetPassword && (
                                        <i className="bx bx-loader bx-spin font-size-16 loader" />
                                    )}
                                </button>
                            </div>

                        </Form>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PasswordReset
