import React, { FC, useCallback, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
// Import menuDropdown
import NotificationDropdown from "../Common/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../Common/TopbarDropdown/ProfileMenu"
// import images
import logo from "../../assets/images/svg/Logo.svg"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { debounce } from "~/utils/debounce"
import { Search } from "~/models/Search.model"
import { SearchOrganizations } from "~/store/reducers/Organizations/thunk"
import {
  setIsSearchToTrue,
  setSearchData,
  setSearching,
} from "~/store/reducers/Organizations"
import { SearchPatient } from "~/store/reducers/Patient/thunk"
import Async from "~/utils/Async"
import { setIsSearchPatient, setResetTable, setSearchPatient } from "~/store/reducers/Patient"
import { setIsSearchAgent, setResetTableAgent, setSearchAgent } from "~/store/reducers/Agent"
import { SearchAgent } from "~/store/reducers/Agent/thunk"
interface HeaderProps {
  superUser: string
}
const Header: FC<HeaderProps> = props => {
  const { superUser } = props
  let location = useLocation()
  const dispatch = useAppDispatch()

  const [search, setSearch] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")

  const { showSecondaryBar, collapsed } = useAppSelector(
    state => state.sideBarReducer
  )
  const { take: takeReducer, searchData } = useAppSelector(
    state => state.OrganizationReducer
  )

  const {
    take: takePatient,
    patientSearchData
  } = useAppSelector(state => state.PatientReducer)

  const {
    takeAgent,
    searchDataAgent,
    activeTabAgent
  } = useAppSelector(state => state.AgentReducer)

  useEffect(() => {
    setSearchValue(searchData)
  }, [searchData])

  useEffect(() => {
    setSearchValue(searchDataAgent)
  }, [searchDataAgent])

  useEffect(() => {
    setSearchValue(patientSearchData)
  }, [patientSearchData])

  const handleSearch = (search: string): void => {
    let data: string = search.trim()

    if (location.pathname === "/dashboard") {
      const body: Search = {
        search: data,
        filter: {
          take: takeReducer,
          skip: 0,
        },
        sort: [
          {
            field: "name",
            order: "asc",


          },
        ],
      }
      dispatch(setSearchData(data))
      dispatch(setSearching(true))
      dispatch(setIsSearchToTrue())
      dispatch(SearchOrganizations(body))
    }
    else if (location.pathname === "/patientManagement") {
      const organizationLocalData = Async.getItem("@organization")
      dispatch(setSearchPatient(data))
      dispatch(setIsSearchPatient(true))
      dispatch(setResetTable(true));

      const body: Search = {
        search: data,
        organizationId: organizationLocalData.id,

        filter: {
          take: takePatient,
          skip: 0,
        },
        sort: [
          {
            field: "firstName",
            order: "asc",
          },
        ],
      }
      dispatch(SearchPatient(body))
    }

    else if (location.pathname === "/agentManagement") {
      const organizationLocalData = Async.getItem("@organization")
      dispatch(setSearchAgent(data))
      dispatch(setIsSearchAgent(true))
      dispatch(setResetTableAgent(true));

      let status = "";
      if (activeTabAgent === "1") {
        status = "PENDING";
      } else if (activeTabAgent === "2") {
        status = "APPROVED";
      } else if (activeTabAgent === "3") {
        status = "REJECTED";
      }

      const body: Search = {
        search: data,
        organizationId: organizationLocalData.id,
        filter: {
          take: takeAgent,
          skip: 0,
          status: status
        },
        sort: [
          {
            field: "firstName",
            order: "asc",
          },
        ],
      }
      dispatch(SearchAgent(body))
    }
  }
  const handleSearchDebounce = useCallback(debounce(handleSearch, 500), [
    takeReducer,
    search,
    takePatient,
    activeTabAgent,
    takeAgent
  ])

  return (
    <React.Fragment>
      <header
        style={
          superUser === "false"
            ? {
              marginLeft: "-250px",
            }
            : {}
        }
        className={
          !collapsed && !showSecondaryBar
            ? "page-topbar-left-margin"
            : collapsed && !showSecondaryBar
              ? "page-topbar-collapsed"
              : collapsed && showSecondaryBar
                ? "page-topbar-right-margin"
                : !collapsed && showSecondaryBar
                  ? "page-topbar-full "
                  : ""
        }
        id="page-topbar"
      >
        <div className="navbar-header">
          <div className="d-flex flex-fill">
            {!showSecondaryBar && (
              <div className="navbar-brand-box d-lg-none d-md-block">
                <Link to="/">
                  <div className="brand-box-content">
                    <img src={logo} alt="" className="logo-size" />
                    <span
                      className="font-size-16 mt-1 ms-1 sbl"
                      style={{ fontWeight: "bold", color: "rgb(73, 80, 87)" }}
                    >
                      ILARA HEALTH
                    </span>
                  </div>
                </Link>
              </div>
            )}
            {showSecondaryBar && (
              <div className="navbar-brand-box d-lg-none d-md-block ms-0">
                <Link to="/" className="">
                  <div className="brand-box-content">
                    <img src={logo} alt="" className="logo-size" />
                    {location.pathname !== "/dashboard" && collapsed ? (
                      <span></span>
                    ) : (
                      <span
                        className="font-size-16 mt-1 ms-1 sbl"
                        style={{ fontWeight: "bold", color: "rgb(73, 80, 87)" }}
                      >
                        ILARA HEALTH
                      </span>
                    )}
                  </div>
                </Link>
              </div>
            )}
            {location.pathname !== "/organizationDetails" &&
              location.pathname !== "/AddEditPatientManagement" &&
              location.pathname !== "/AddEditAgentManagement" &&
              location.pathname !== "/vitalsHistory" &&
              (!location?.pathname?.includes('/reports/')) ? (
              <div className="app-search d-none d-lg-block ps-4 w-100">
                <div className="position-relative">
                  <input
                    type="text"
                    id="input1"
                    className="form-control bg-white font-size-14"
                    placeholder={"Search" + "..."}
                    onChange={val => {
                      setSearchValue(val.target.value)
                      handleSearchDebounce(val.target.value)
                    }}
                    value={searchValue || ""}
                  />
                  <span className="bx bx-search-alt-2" />
                </div>
              </div>
            ) : null}
          </div>
          <div className="d-flex">
            {location.pathname !== "/organizationDetails" &&
              location.pathname !== "/AddEditPatientManagement" &&
              location.pathname !== "/vitalsHistory" &&
              location.pathname !== "/AddEditAgentManagement" ? (
              <div className="dropdown d-inline-block d-lg-none ms-2">
                <button
                  onClick={() => {
                    setSearch(!search)
                  }}
                  type="button"
                  className="btn header-item noti-icon "
                  id="page-header-search-dropdown"
                >
                  <i className="bx bx-search-alt-2" />
                </button>
                <div
                  className={
                    search
                      ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                      : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                  }
                  aria-labelledby="page-header-search-dropdown"
                >
                  <form className="p-3">
                    <div className="form-group m-0">
                      <div className="input-group">
                        <input
                          id="input2"
                          type="text"
                          className="form-control"
                          placeholder="Search ..."
                          aria-label="Search"
                          onChange={val => {
                            setSearchValue(val.target.value)
                            handleSearchDebounce(val.target.value)
                          }}
                          value={searchValue || ""}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
