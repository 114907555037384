import React, { FC, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Card, Form, FormFeedback, Input, Label, Modal, Spinner, Tooltip } from "reactstrap"
import "./organizationDetails.scss"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import { useFormik } from "formik"
import * as Yup from "yup"
import Dropzone from "react-dropzone"

import { Organization, OrgProp } from "~/models/Org.model"
import {
  CreateOrgDetail,
  DeleteOrganizationLogo,
  OrganizationLogo,
  saveOrganizations,
  SearchOrganizations,
  OrganizationById,
  updateOrganizations,
} from "~/store/reducers/Organizations/thunk"
import { errorToast, successToast } from "~/utils/toast"
import {
  createRoleReset,
  DeleteLogoStatesReset,
  LogoStatesReset,
  organizationUpdateReset,
  ResetSaveOrganizationStates,
  SearchResetOrganizationStates,
  setLocalData,
} from "~/store/reducers/Organizations"
import Async from "~/utils/Async"
import {
  OrganizationRoles,
  RolesDeleteCustomRole,
  RolesUpdateBatch,
} from "~/store/reducers/Roles/thunk"
import {
  ResetDeleteRoleStates,
  ResetRolesStates,
  ResetRolesUpdateStates,
} from "~/store/reducers/Roles"
import { FetchSignUpLink } from "~/store/reducers/SignUpLink/thunk"
import { resetSignUpLink } from "~/store/reducers/SignUpLink"
import ConfirmationModal from "~/components/Common/Modals/confirmationModal"
import { setPreloader } from "~/store/reducers/loader"
interface CheckBoxes {
  view: boolean
  edit: boolean
}
interface TableRow {
  data: CheckBoxes[]
}

const OrganizationDetails: FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const {
    signUpLink,
  } = useAppSelector(state => state.SignUpLinkReducer)
  const {
    roles: data,
    loading: loadingRoles,
    successDelete,
    loadingDelete,
    errorDelete,
    roleUpdateSuccess,
    roleUpdateError,
  } = useAppSelector(state => state.RolesReducer)
  const {
    organizationsByIdObj,
    successSave,
    errorSave,
    loadingSave,
    successUpdate,
    errorUpdate,
    loadingUpdate,
    successCreateRole,
    errorCreateRole,
    loadingCreateRole,
    successlogo,
    errorlogo,
    loadinglogo,
    successDeletelogo,
    loadingDeletelogo,
    errorDeletelogo,
    localData,
    loading
  } = useAppSelector(state => state.OrganizationReducer)

  //from local storage

  const screen: string =
    localStorage.getItem("@super") === "true"
      ? Async.getItem("@Screen")
      : "edit"

  const [access, setAccess] = useState<boolean>(true);

  const [modal_center, setmodal_center] = useState<boolean>(false)
  const [rolesView, setRolesView] = useState<boolean>(false)
  const [rolesEdit, setRolesEdit] = useState<boolean>(false)
  const [orgEdit, setOrgEdit] = useState<boolean>(false)
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false)
  const [newRole, setNewRole] = useState<string>("")
  const [newRoleError, setNewRoleError] = useState<string>("")
  const [deleteHeading, setDeleteHeading] = useState<string>("")

  const [allowPractitionerSignup, setAllowPractitionerSignup] =
    useState<boolean>(false)
  const [headings, setHeadings] = useState<string[]>([])
  const [tableData, setTableData] = useState<TableRow[]>([])
  const [roles, setRoles] = useState<any>()
  //for Logo
  const [selectedFiles, setselectedFiles] = useState<File[]>()
  const [logoDel, setLogoDel] = useState<boolean>(false)
  const [toolTip, setToolTip] = useState<boolean>(false)
  const [orgData, setOrgData] = useState<Organization>()

  function handleAcceptedFiles(file: File[]) {
    setselectedFiles(file)
  }

  useEffect(() => {
    const data = Async.getItem("@auth")
    setAccess(data?.role?.permissions["organization-details-view"])
    let orgData1 = Async.getItem("@organization")
    dispatch(OrganizationById({ organizationId: orgData1?.id }));
    setTimeout(() => {
      dispatch(setPreloader(false))
    }, 1000)
  }, [])

  useEffect(() => {
    if (Object.keys(organizationsByIdObj).length > 0) {
      Async.setItem("@organization", organizationsByIdObj)
      setOrgData(organizationsByIdObj)
      dispatch(setLocalData(!localData))
    }
  }, [organizationsByIdObj])

  useEffect(() => {
    if (selectedFiles && orgData?.id) {
      dispatch(
        OrganizationLogo({ organizationId: orgData.id, file: selectedFiles[0] })
      )
    }
  }, [selectedFiles])

  useEffect(() => {
    if (successlogo && orgData) {
      successToast("Logo uploaded Successfully!")
      dispatch(SearchOrganizations({ search: "" }))
      dispatch(OrganizationById({ organizationId: orgData?.id }));
      dispatch(LogoStatesReset())
    } else if (errorlogo) {
      errorToast(errorlogo)
      dispatch(LogoStatesReset())
    }
  }, [successlogo, errorlogo, orgData])

  useEffect(() => {
    if (successDeletelogo && orgData) {
      successToast("Logo deleted Successfully!")
      dispatch(SearchOrganizations({ search: "" }))
      dispatch(OrganizationById({ organizationId: orgData?.id }));
      dispatch(DeleteLogoStatesReset())
      toggleRemove()

    } else if (errorDeletelogo) {
      errorToast(errorDeletelogo)
      dispatch(DeleteLogoStatesReset())
    }
  }, [successDeletelogo, errorDeletelogo, orgData])

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      if (screen === "add") {
        setRoles(data)
      } else if (screen === "edit") {
        let temp: any
        data?.items?.forEach((item: any) => {
          temp = {
            ...temp,
            [item.name]: item.permissions,
          }
        })
        setRoles(temp)
      }
    }
  }, [data])

  useEffect(() => {
    if (roles && Object.keys(roles).length > 0) {
      const superUser = localStorage.getItem("@super")

      if (superUser === "true") {
        setHeadings(Object.keys(roles))
      } else {
        const data = Async.getItem("@auth")
        const role2 = JSON.parse(JSON.stringify(roles))

        Object.keys(role2).forEach(function (key) {
          if (data.role.name === key) {
            delete role2[key]
          }
        })

        setHeadings(Object.keys(role2))
      }
    }
  }, [roles])

  useEffect(() => {
    let checkBoxesList: CheckBoxes[] = []
    if (headings.length > 0) {
      const body: CheckBoxes = {
        view: false,
        edit: false,
      }
      headings.forEach(val => {
        checkBoxesList.push(body)
      })
      let rowsList: TableRow[] = []
      for (let i = 0; i <= 9; i++) {
        const row: TableRow = {
          data: checkBoxesList,
        }
        rowsList.push(row)
      }
      mapData(rowsList)
    }
  }, [headings])

  const rolesUpdated = () => {
    let newData: any = { roles: [] }
    data?.items?.map((item: any, index: number) => {
      let newRole: any
      let newPram: any
      tableData.map((perm: any, i: number) => {
        if (i === 0) {
          newPram = {
            ...newPram,
            ["organization-details-view"]: perm?.data[index]?.view,
            ["organization-details-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 1) {
          newPram = {
            ...newPram,
            ["roles-view"]: perm?.data[index]?.view,
            ["roles-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 2) {
          newPram = {
            ...newPram,
            ["practitioner-view"]: perm?.data[index]?.view,
            ["practitioner-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 3) {
          newPram = {
            ...newPram,
            ["patient-view"]: perm?.data[index]?.view,
            ["patient-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 4) {
          newPram = {
            ...newPram,
            ["dashboard-overview-view"]: perm?.data[index]?.view,
            ["dashboard-overview-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 5) {
          newPram = {
            ...newPram,
            ["dashboard-blood-pressure-view"]: perm?.data[index]?.view,
            ["dashboard-blood-pressure-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 6) {
          newPram = {
            ...newPram,
            ["dashboard-blood-glucose-view"]: perm?.data[index]?.view,
            ["dashboard-blood-glucose-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 7) {
          newPram = {
            ...newPram,
            ["dashboard-bmi-view"]: perm?.data[index]?.view,
            ["dashboard-bmi-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 8) {
          newPram = {
            ...newPram,
            ["data-collection-view"]: perm?.data[index]?.view,
            ["data-collection-edit"]: perm?.data[index]?.edit,
          }
        } else if (i === 9) {
          newPram = {
            ...newPram,
            ["archive-view"]: perm?.data[index]?.view,
            ["archive-edit"]: perm?.data[index]?.edit,
          }
        }
      })
      newRole = {
        id: item.id,
        permissions: newPram,
      }
      newData.roles.push(newRole)
    })
    return newData
  }

  const mapData = (rowsList: TableRow[]) => {
    let tempData = JSON.parse(JSON.stringify(rowsList))
    for (let i = 0; i < tempData.length; i++) {
      if (i === 0) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["organization-details-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["organization-details-edit"]
        })
      } else if (i === 1) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["roles-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["roles-edit"]
        })
      } else if (i === 2) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["practitioner-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["practitioner-edit"]
        })
      } else if (i === 3) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["patient-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["patient-edit"]
        })
      } else if (i === 4) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["dashboard-overview-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["dashboard-overview-edit"]
        })
      } else if (i === 5) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["dashboard-blood-pressure-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["dashboard-blood-pressure-edit"]
        })
      } else if (i === 6) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["dashboard-blood-glucose-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["dashboard-blood-glucose-edit"]
        })
      } else if (i === 7) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["dashboard-bmi-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["dashboard-bmi-edit"]
        })
      } else if (i === 8) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["data-collection-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["data-collection-edit"]
        })
      } else if (i === 9) {
        headings.forEach((val, index) => {
          tempData[i].data[index].view =
            Object.values<any>(roles)[index]["archive-view"]
          tempData[i].data[index].edit =
            Object.values<any>(roles)[index]["archive-edit"]
        })
      }

    }
    setTableData(tempData)
  }
  useEffect(() => {
    const per = Async.getItem("@auth")
    setRolesView(per.role.permissions["roles-view"])
    setRolesEdit(per.role.permissions["roles-edit"])
    setOrgEdit(per.role.permissions["organization-details-edit"])

    const superUser = localStorage.getItem("@super")

    if (superUser === "true") {
      var body: HTMLElement = document.body
      dispatch(setSecondaryBarTrue())
      if (!collapsed) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      dispatch(setSecondaryBarTrue())
    }

    if (screen === "edit" && orgData) {
      dispatch(ResetRolesStates()) //TODO chk
      dispatch(resetSignUpLink()) //TODO chk

      setAllowPractitionerSignup(orgData?.allowPractitionerSignup)

      dispatch(OrganizationRoles({ organizationId: orgData.id }))
      dispatch(FetchSignUpLink({ organizationId: orgData.id }))
    } else {
    }
  }, [orgData])

  useEffect(() => {
    if (successSave) {
      successToast("Organization added successfully")
      dispatch(ResetSaveOrganizationStates())
      dispatch(SearchResetOrganizationStates())

      const superUser = localStorage.getItem("@super")

      if (superUser === "true") {
        history.push("/dashboard")
      }
    } else if (errorSave) {
      dispatch(ResetSaveOrganizationStates())
      errorToast(errorSave)
    }
    if (successUpdate && orgData) {

      dispatch(organizationUpdateReset())
      //Update Role
      const data = rolesUpdated()
      data.organizationId = orgData.id
      dispatch(RolesUpdateBatch(data))
      dispatch(FetchSignUpLink({ organizationId: orgData.id }))

    } else if (errorUpdate) {
      dispatch(organizationUpdateReset())
      errorToast(errorUpdate)
    }
  }, [successSave, errorSave, successUpdate, errorUpdate, orgData])

  useEffect(() => {
    if (successDelete && orgData) {
      dispatch(OrganizationRoles({ organizationId: orgData.id }))
      dispatch(ResetDeleteRoleStates())
      successToast("Role deleted successfully!")
      toggleRemove()
    } else if (errorDelete) {
      dispatch(ResetDeleteRoleStates())
      errorToast(errorDelete)
      toggleRemove()
    }
  }, [successDelete, errorDelete, orgData])
  useEffect(() => {
    if (roleUpdateSuccess && orgData) {
      successToast("Updated successfully")
      dispatch(ResetRolesUpdateStates())
      dispatch(SearchResetOrganizationStates())
      const superUser = localStorage.getItem("@super")

      // if (superUser === "true") {
        // history.push("/dashboard")
        dispatch(OrganizationById({ organizationId: orgData?.id }));
      // }
      dispatch(OrganizationRoles({ organizationId: orgData.id }))
    } else if (roleUpdateError) {
      dispatch(ResetRolesUpdateStates())
      errorToast(roleUpdateError)
    }
  }, [roleUpdateSuccess, roleUpdateError, orgData])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      nameOfOrganization: screen === "edit" ? orgData?.name : "",
      emailExtension: screen === "edit" ? orgData?.emailExtension : "",
      supportEmail: screen === "edit" ? orgData?.supportEmail === null ? "" : orgData?.supportEmail : "",
      physicalAddress: screen === "edit" ? orgData?.address : "",
      signUpLink: screen === "edit" ? signUpLink : "",
    },
    validationSchema: Yup.object({
      nameOfOrganization: Yup.string().matches(
        /^(?=.*\S).+$/,
        "Only spaces are not allowed"
      )
        .required(
          "Please enter name of organization"
        ),
      supportEmail: Yup.string().email('Invalid email format'),
    }),
    onSubmit: values => {
      if (screen === "edit") {
        const updateBody: OrgProp = {
          organizationId: orgData?.id,
          name: values.nameOfOrganization,
          emailExtension:
            values?.emailExtension !== undefined && values?.emailExtension?.length > 0
              ? values.emailExtension
              : null,
          supportEmail:
            values?.supportEmail !== undefined && values?.supportEmail?.length > 0 ? values.supportEmail : null,
          address:
            values?.physicalAddress !== undefined && values?.physicalAddress?.length > 0
              ? values.physicalAddress
              : "",
          allowPractitionerSignup: allowPractitionerSignup,
        }

        dispatch(updateOrganizations(updateBody))
      } else {
        const body: OrgProp = {
          name: values.nameOfOrganization,
          emailExtension:
            values?.emailExtension !== undefined && values.emailExtension.length > 0
              ? values.emailExtension
              : null,
          supportEmail:
            values?.supportEmail !== undefined && values.supportEmail.length > 0 ? values.supportEmail : null,
          address:
            values?.physicalAddress !== undefined && values.physicalAddress.length > 0
              ? values.physicalAddress
              : undefined,
          allowPractitionerSignup: allowPractitionerSignup,
        }
        dispatch(saveOrganizations(body))
      }
    },
  })

  useEffect(() => {
    if (successCreateRole && orgData) {
      successToast("Role added successfully")
      dispatch(createRoleReset())
      setmodal_center(false)
      setNewRole("")

      dispatch(OrganizationRoles({ organizationId: orgData.id }))
    } else if (errorCreateRole) {
      dispatch(createRoleReset())
      errorToast(errorCreateRole)
    }
  }, [successCreateRole, errorCreateRole, loadingCreateRole, orgData])

  const addRole = (role: string) => {
    if (!role.replace(/\s/g, '').length) {
      setNewRoleError('Only spaces are not allowed')
    } else {
      const permissions = {
        superuser: true,
        "organization-details-view": true,
        "organization-details-edit": true,
        "roles-view": true,
        "roles-edit": true,
        "practitioner-view": true,
        "practitioner-edit": true,
        "patient-view": true,
        "patient-edit": true,
        "messaging-view": true,
        "messaging-edit": true,
        "dashboard-overview-view": true,
        "dashboard-overview-edit": true,
        "dashboard-blood-pressure-view": true,
        "dashboard-blood-pressure-edit": true,
        "dashboard-blood-glucose-view": true,
        "dashboard-blood-glucose-edit": true,
        "dashboard-bmi-view": true,
        "dashboard-bmi-edit": true,
        "data-collection-view": true,
        "data-collection-edit": true,
        "archive-edit": true,
        "archive-view": true
      }
      const body = {
        organizationId: orgData?.id,
        name: role,
        permissions,
      }
      dispatch(CreateOrgDetail(body))
    }
  }

  const [height, setHeight] = useState(window.innerHeight)

  useEffect(() => {
    window.addEventListener("resize", HandleWidth)
    return () => window.removeEventListener("resize", HandleWidth)
  }, [])

  const HandleWidth = () => {
    setHeight(window.innerHeight)
  }

  const toggleRemove = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const onConfirm = () => {
    if (!loadingDelete && orgData) {
      dispatch(
        RolesDeleteCustomRole({
          id: data?.items.find((value: any) => value.name === deleteHeading).id,
          organizationId: orgData?.id,
        })
      )
    }
  }
  const onLogoConfirm = () => {
    if (orgData) {
      dispatch(
        DeleteOrganizationLogo({
          organizationId: orgData.id,
        })
      )
    }
  }
  return (
    <React.Fragment>
      {access ?
        <div>


          {showConfirmationModal && (
            <ConfirmationModal
              toggle={toggleRemove}
              isOpen={true}
              onConfirm={logoDel ? onLogoConfirm : onConfirm}
              text={deleteHeading}
              loading={loadingDeletelogo}
            />
          )}

          {loading ? <div
            style={{
              width: "100%",
              minHeight: "800px",
              display: "flex",
              flex: "1",
              justifyContent: "center",
            }}
          >
            <Spinner
              style={{
                color: "#2a45cd",
                alignSelf: "center",
                width: "70px",
                height: "70px",
              }}
              animation="border"
              variant="primary"
            />
          </div> :
            <div
              className="page-content ms-3"
              style={{ maxHeight: `${height - 10}px` }}
            >
              <div>
                <Form
                  className="form-horizontal col-md-12"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div className="d-sm-flex flex-wrap mb-4">
                    <h2>
                      <b className="text-nowrap sbl24">Organization Settings</b>
                    </h2>
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="discard-btn px-3"
                        onClick={() => {
                          const addScreen = screen !== "edit";
                          validation.setFieldValue('nameOfOrganization', addScreen ? '' : orgData?.name)
                          validation.setFieldValue('emailExtension', addScreen ? '' : orgData?.emailExtension)
                          validation.setFieldValue('physicalAddress', addScreen ? '' : orgData?.address)
                          validation.setFieldValue('supportEmail', addScreen ? '' : orgData?.supportEmail === null ? '' : orgData?.supportEmail)
                          setAllowPractitionerSignup(addScreen ? false : orgData !== undefined ? orgData?.allowPractitionerSignup : false)
                          if (!addScreen) {
                            let checkBoxesList: CheckBoxes[] = []
                            if (headings.length > 0) {
                              const body: CheckBoxes = {
                                view: false,
                                edit: false,
                              }
                              headings.forEach(val => {
                                checkBoxesList.push(body)
                              })
                              let rowsList: TableRow[] = []
                              for (let i = 0; i <= 9; i++) {
                                const row: TableRow = {
                                  data: checkBoxesList,
                                }
                                rowsList.push(row)
                              }
                              mapData(rowsList)
                            }
                          }
                        }}
                      >
                        <h6 className="my-auto">Discard</h6>
                      </button>
                      <button
                        className={(loadingSave || loadingUpdate) ?
                          "btn btn-primary ms-3 pointer-none" : "btn btn-primary ms-3"
                        }
                        type="submit"
                      >
                        Save changes
                        {(loadingSave || loadingUpdate) && (
                          <i className="bx bx-loader bx-spin font-size-14 ms-2" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className="horizontal-line"></div>
                  <div
                    style={{
                      maxHeight: `${height - 170}px`,
                      overflowX: "hidden",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-3">
                      <div className="d-flex flex-row justify-content-space-between">
                        <div className="mt-3 mb-3 col-md-6">
                          <h5 className="d-flex align-self-center im14">
                            Name of organization <span className="text-danger">*</span>
                          </h5>
                        </div>

                        <div className="col-md-6 mt-2">
                          <Input
                            name="nameOfOrganization"
                            placeholder="Enter name of organization"
                            type="text"
                            className={
                              orgEdit
                                ? "form-control input d-flex align-self-center"
                                : "pointer-none"
                            }
                            onChange={validation.handleChange}
                            value={validation.values.nameOfOrganization || ""}
                            invalid={
                              validation.touched.nameOfOrganization &&
                                validation.errors.nameOfOrganization
                                ? true
                                : false
                            }
                          />
                          {validation.touched.nameOfOrganization &&
                            validation.errors.nameOfOrganization ? (
                            <FormFeedback type="invalid">
                              {validation.errors.nameOfOrganization}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="d-flex flex-row justify-content-space-between">
                        <div className="mt-3 mb-3 col-md-6">
                          <h5 className="d-flex align-self-center im14">
                            Email extension
                          </h5>
                        </div>

                        <div className="col-md-6 mt-2">
                          <Input
                            name="emailExtension"
                            className={
                              orgEdit
                                ? "form-control input d-flex align-self-center"
                                : "pointer-none"
                            }
                            placeholder="Enter email extension"
                            type="text"
                            onChange={validation.handleChange}
                            value={validation.values.emailExtension || ""}
                            invalid={
                              validation.touched.emailExtension &&
                                validation.errors.emailExtension
                                ? true
                                : false
                            }
                          />
                          {validation.touched.emailExtension &&
                            validation.errors.emailExtension ? (
                            <FormFeedback type="invalid">
                              {validation.errors.emailExtension}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="d-flex flex-row justify-content-space-between">
                        <div className="mt-3 mb-3 col-md-6">
                          <h5 className="d-flex align-self-center im14">
                            Support email
                          </h5>
                        </div>

                        <div className="col-md-6 mt-2">
                          <Input
                            name="supportEmail"
                            className={
                              orgEdit
                                ? "form-control input d-flex align-self-center"
                                : "pointer-none"
                            }
                            placeholder="Enter support email"
                            type="email"
                            onChange={validation.handleChange}
                            value={validation.values.supportEmail || ""}
                            invalid={
                              validation.touched.supportEmail &&
                                validation.errors.supportEmail
                                ? true
                                : false
                            }
                          />
                          {validation.touched.supportEmail &&
                            validation.errors.supportEmail ? (
                            <FormFeedback type="invalid">
                              {validation.errors.supportEmail}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="d-flex flex-row justify-content-space-between">
                        <div className="mt-3 mb-3 col-md-6">
                          <h5 className="d-flex align-self-center im14">
                            Physical address
                          </h5>
                        </div>

                        <div className="col-md-6 mt-2">
                          <Input
                            name="physicalAddress"
                            className={
                              orgEdit
                                ? "form-control input d-flex align-self-center"
                                : "pointer-none"
                            }
                            placeholder="Enter physical address"
                            type="text"
                            onChange={validation.handleChange}
                            value={validation.values.physicalAddress || ""}
                            invalid={
                              validation.touched.physicalAddress &&
                                validation.errors.physicalAddress
                                ? true
                                : false
                            }
                          />
                          {validation.touched.physicalAddress &&
                            validation.errors.physicalAddress ? (
                            <FormFeedback type="invalid">
                              {validation.errors.physicalAddress}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      {screen === "edit" && (
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">
                              Sign up link
                            </h5>
                          </div>

                          <div className="col-md-6 mt-2">
                            <div className="container-icon">
                              {!validation.values.signUpLink.includes(
                                "sign up is disabled"
                              ) && (
                                  <div
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        validation.values.signUpLink
                                      )
                                      successToast("Copied!")
                                    }}
                                    className="d-flex flex-row copy-icon bg-white ps-1"
                                  >
                                    { }
                                    <i className="fas fa-copy mt-1"></i>
                                    <h5 className="ms-1">Copy</h5>
                                  </div>
                                )}

                              <Input
                                name="signUpLink"
                                placeholder="Enter sign up link"
                                type="text"
                                onChange={validation.handleChange}
                                value={validation.values.signUpLink || ""}
                                className={
                                  "form-control d-flex align-self-center text-primary pointer-none"
                                }
                              />
                              {validation.touched.signUpLink &&
                                validation.errors.signUpLink ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.signUpLink}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="mb-3 col-md-6 offset-md-6">
                        <Label
                          check
                          className={
                            orgEdit
                              ? "d-flex flex-row"
                              : " d-flex flex-row pointer-none"
                          }
                        >
                          <Input
                            type="checkbox"
                            id="checkbox2"
                            checked={allowPractitionerSignup}
                            onChange={val => {
                              const { checked } = val.target
                              setAllowPractitionerSignup(checked)

                            }}
                          />{" "}
                          <h6 className="ms-1 check-text im14">Allow worker sign up</h6><span>
                          </span>
                          <i id='toolTipIcon' className="bx bx-info-circle ms-2 font-size-16">
                          </i>
                          <Tooltip
                            isOpen={toolTip}
                            placement="right"
                            target="toolTipIcon"
                            toggle={() => { setToolTip(!toolTip) }}
                          >
                            Check this option to allow worker to sign themselves up using a self-sign up link. Admin will need to approve or reject the sign up requests.

                          </Tooltip>
                        </Label>
                      </div>
                    </div>
                    {screen === "edit" ? (
                      <div>
                        <div className="horizontal-line"></div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="im14">Logo</h5>
                              <h6 className="im14" style={{ width: "200px", opacity: '0.5' }}>
                                This will be displayed on report exports, invoices and
                                receipts
                              </h6>
                            </div>

                            {orgEdit && (
                              <Dropzone
                                multiple={false}
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles)
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="col-md-6 mt-3 mb-3">
                                    <div
                                      style={{
                                        height: '75px'
                                      }}
                                      className="img-input text-center"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <h6 className="text-muted">
                                        <span className="text-primary">
                                          Click to upload
                                        </span>{" "}
                                        or drag and drop
                                      </h6>
                                      <h6 className="text-muted">
                                        PNG or JPG (min. 800x400px)
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                            )}
                            {(orgData?.logo && orgData || loadinglogo) && screen === "edit" && (
                              <div
                                style={{
                                  height: '75px'
                                }}
                                className={
                                  orgEdit
                                    ? "m-3 p-2 margin border d-flex align-items-center"
                                    : "p-2 border d-flex align-items-center"
                                }
                              >
                                {loadinglogo ? (
                                  <i className="bx bx-loader bx-spin font-size-14 m-2 " />
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <img
                                      height="55"
                                      width="55"
                                      className="avatar rounded bg-light border"
                                      src={orgData?.logo}
                                    />
                                    <p className="text-muted font-weight-bold mx-1 mb-0"></p>
                                    {loadingDeletelogo ? (
                                      <i className="bx bx-loader bx-spin font-size-14 ms-2" />
                                    ) : (
                                      <button
                                        onClick={() => {
                                          toggleRemove()
                                          setLogoDel(true)
                                        }}
                                        type="button"
                                        className="close p-1 my-auto"
                                        style={{ position: "relative" }}
                                      ></button>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {screen === "edit" && rolesView && (
                      <>
                        <div className="d-sm-flex flex-wrap head-content mt-2">
                          <h2>
                            <span className="sb font-size-18">Organization roles and permissions</span>
                          </h2>
                        </div>
                        <div className="add-button">
                          {rolesEdit && (
                            <button
                              type="button"
                              className="discard-btn px-3 mt-3"
                              style={{ marginRight: "30px" }}
                              onClick={() => {
                                setNewRole('')
                                setmodal_center(true)
                              }}
                            >
                              <h6 className="d-flex flex-row pt-2">
                                <i className={"bx bx-user-plus kin-icon"}></i>

                                <b className="my-auto font-size-12 ms-1 sb">
                                  Add custom role
                                </b>
                              </h6>
                            </button>
                          )}

                          <Modal
                            className="modal-sm"
                            isOpen={modal_center}
                            toggle={() => {
                              setmodal_center(!modal_center)
                            }}
                            onClosed={() => setNewRoleError('')}
                            centered
                          >
                            <div className="modal-header">
                              <h5 className="modal-title mt-0">Add Role</h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setmodal_center(false)
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body margin-role">
                              <label
                                htmlFor="example-text-input"
                                className="col-md-2 col-form-label"
                              >
                                Name<span className="text-danger">*</span>
                              </label>
                              <div className="col-md-12">
                                <Input
                                  className="form-control"
                                  type="text"
                                  value={newRole}
                                  onChange={e => {
                                    setNewRole(e.target.value);
                                    e.target.value?.length > 0 && setNewRoleError('')
                                  }}
                                  placeholder="Role name.."
                                  invalid={(newRoleError) ? true : false}
                                />
                                {newRoleError ? (
                                  <FormFeedback type="invalid">
                                    {newRoleError}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="px-3 pb-3 d-flex justify-content-around">
                              <button
                                type="button"
                                className="discard-btn px-3"
                                onClick={() => {
                                  setmodal_center(false)
                                }}
                              >
                                <h6 className="my-auto">Discard</h6>
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  if (newRole !== "" && newRole.length <= 20) {
                                    addRole(newRole)
                                  } else if (newRole.length > 20) {
                                    setNewRoleError('Name is too long!')
                                  } else if (newRole === '') {
                                    setNewRoleError('Please enter role name!')
                                  }

                                }}
                              >
                                Add Role
                                {loadingCreateRole && (
                                  <i className="bx bx-loader bx-spin font-size-14 ms-2" />
                                )}
                              </button>
                            </div>
                          </Modal>
                        </div>
                        {loadingRoles ? (
                          <div
                            style={{
                              width: "100%",
                              minHeight: "500px",
                              display: "flex",
                              flex: "1",
                              justifyContent: "center",
                            }}
                          >
                            <Spinner
                              style={{
                                color: "#2a45cd",
                                alignSelf: "center",
                                width: "70px",
                                height: "70px",
                              }}
                              animation="border"
                              variant="primary"
                            />
                          </div>
                        ) : (
                          <div className="table-container mt-2">
                            <div className="head-container border ">
                              <h5 className="heading-1 ms-2 ib font-size-13">Tasks</h5>
                              {headings.map((val, i) => (
                                <h5
                                  className="heading-1 text-center ib font-size-13"
                                  key={i}
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {val}
                                  {val === "Team Lead" ||
                                    val === "Clinician" ||
                                    val === "Field Agent" ||
                                    val === "Administrator" ? null : (
                                    <i
                                      className="bx bx-x-circle ms-1"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        toggleRemove()
                                        setDeleteHeading(val)
                                        setLogoDel(false)
                                      }}
                                    />
                                  )}
                                </h5>
                              ))}
                            </div>
                            <div className="head-container2">
                              <h6 className="heading-1"></h6>
                              {headings.map((val, i) => (
                                <div className="heading-1" key={i}>
                                  <h6 className="color-gray ib font-size-13">View</h6>
                                  <h6 className="color-gray ib font-size-13">Edit</h6>
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2 ps-2">
                                <h6 className="im14">Organization details</h6>
                              </div>
                              {tableData[0]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[0].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[0].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Roles and permissions</h6>
                              </div>
                              {tableData[1]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[1].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[1].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>

                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Worker management</h6>
                              </div>
                              {tableData[2]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[2].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[2].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Patient management</h6>
                              </div>
                              {tableData[3]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[3].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[3].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Dashboard: Summary</h6>
                              </div>
                              {tableData[4]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[4].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[4].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Dashboard: Blood pressure</h6>
                              </div>
                              {tableData[5]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[5].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[5].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Dashboard: Blood sugar</h6>
                              </div>
                              {tableData[6]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[6].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[6].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Dashboard: BMI</h6>
                              </div>
                              {tableData[7]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[7].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[7].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2">
                                <h6 className="im14">Data collection</h6>
                              </div>
                              {tableData[8]?.data?.map((val2, index) => (
                                <div className="heading-1 " key={index}>
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[8].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    type="checkbox"
                                    className={rolesEdit ? "" : "pointer-none"}
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[8].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="head-container2">
                              <div className="heading-2  ps-2 -bottom ">
                                <h6 className="im14">Archive Data</h6>
                              </div>
                              {tableData[9]?.data?.map((val2, index) => (
                                <div
                                  className="heading-1  -bottom "
                                  key={index}
                                >
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.view}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[9].data[index].view = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                  <Input
                                    className={rolesEdit ? "" : "pointer-none"}
                                    type="checkbox"
                                    checked={val2.edit}
                                    onChange={e => {
                                      const { checked } = e.target
                                      let tempData = JSON.parse(
                                        JSON.stringify(tableData)
                                      )
                                      tempData[9].data[index].edit = checked
                                      setTableData(tempData)
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          }
        </div> : <div className="permissionWarning">User does not have permissions to view this screen!</div>}
    </React.Fragment>
  )
}

export default OrganizationDetails
