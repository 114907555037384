import { useHistory } from "react-router-dom"
import React, { FC, useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import { WithRouterProps } from "~/models/WithRouterProps.model"
import "./vitalsHistory.scss"

import ListVitals from "./List"
import GraphVitals from "./Graph"
import Avatar from "react-avatar"
import { GetUsers } from "~/store/reducers/Select/thunk"
import { Card, Spinner, Tooltip } from "reactstrap"
import Async from "~/utils/Async"
import { setVitalScreenRedux } from "~/store/reducers/Select"

interface PageType {
  id: string
}

const VitalsHistory: FC<WithRouterProps> = ({ location }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const { patient, success, loading, error } = useAppSelector(
    state => state.SelectReducer
  )
  const [access, setAccess] = useState<boolean>(true);
  //graph-list toggle
  const [vitalScreen, setVitalScreen] = useState<boolean>(false)
  const [toolTip, setToolTip] = useState<boolean>(false)

  useEffect(() => {
    const locationState: PageType = location?.state as PageType
    const organizationLocalData = Async.getItem("@organization")
    dispatch(setVitalScreenRedux(location.pathname))
    dispatch(
      GetUsers({
        patient: {
          filter: {
            id: locationState?.id,
            organizationId: organizationLocalData.id,
          },
          include: { vitalsMeasurements: true },
        },
      })
    )
  }, [location])

  useEffect(() => {
    const superUser = localStorage.getItem("@super")

    if (superUser === "true") {
      dispatch(setSecondaryBarTrue())
      var body: HTMLElement = document.body
      if (!collapsed) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      const data = Async.getItem("@auth")
      setAccess(data?.role?.permissions["patient-view"])
      dispatch(setSecondaryBarTrue())
    }
  }, [])

  return (
    <React.Fragment>
      {access ?
        <div className="page-content">
          {loading ? (
            <div
              style={{
                width: "100%",
                minHeight: "500px",
                display: "flex",
                flex: "1",
                justifyContent: "center",
              }}
            >
              <Spinner
                style={{
                  color: "#2a45cd",
                  alignSelf: "center",
                  width: "70px",
                  height: "70px",
                }}
                animation="border"
                variant="primary"
              />
            </div>
          ) : (
            <>
              <div className="d-sm-flex flex-wrap head-content">
                <div className="d-flex flex-row" style={{ alignItems: "center" }}>
                  <Avatar
                    name={
                      patient?.user?.profile?.firstName +
                      " " +
                      patient?.user?.profile?.lastName
                    }
                    maxInitials={2}
                    textSizeRatio={2.7}
                    size="40"
                    color="#2A45CD20"
                    fgColor="#2A45CD"
                    round
                  />
                  <h2 className="ms-3">
                    <span className="sbl24">
                      {patient?.user?.profile?.firstName}{" "}
                      {patient?.user?.profile?.middleName}{" "}
                      {patient?.user?.profile?.lastName}
                    </span>
                  </h2>
                  <h5
                    className="ms-3 text-primary im14 my-auto"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      Async.setItem("@patient", patient)
                      Async.setItem("@ScreenPatient", "edit"),
                        history.push({
                          pathname: "/AddEditPatientManagement",
                          state: { page: "edit", data: patient },
                        })
                    }}
                  >
                    Edit patient information{" "}
                  </h5>
                  {/* <button
                  type="button"
                  className="light-btn ms-4"
                  onClick={() => {
                    history.push("/")
                  }}
                >
                  <i className="bx bx-envelope envelope-icon"></i>
                </button> */}
                </div>
              </div>
              <div className="horizontal-line mt-3 mb-2"></div>
              <div className="d-flex mb-2">
                <h4 className="mt-2">
                  Vitals history
                  <span>
                    <i id='toolTipIcon' className="bx bx-info-circle ms-2 font-size-15"></i>
                  </span>
                </h4>
                <Tooltip
                  isOpen={toolTip}
                  placement="right"
                  target="toolTipIcon"
                  toggle={() => { setToolTip(!toolTip) }}
                >
                  Use the toggle to switch between table view or chart view for better result comparison.
                </Tooltip>
                <div className="d-flex gap-2 ms-auto my-auto bg-light screenSwitch p-1">
                  <i
                    className={`bx bx-list-ul edit-icons font-size-20 rounded-circle p-1 ${vitalScreen ? "bg-white" : ""
                      }`}
                    onClick={() => {
                      setVitalScreen(true)
                    }}
                  ></i>
                  <i
                    className={`bx bx-line-chart edit-icons font-size-20 rounded-circle p-1 ${!vitalScreen ? "bg-white" : ""
                      }`}
                    onClick={() => {
                      setVitalScreen(false)
                    }}
                  ></i>
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div>
                {vitalScreen ? (
                  <ListVitals data={patient?.vitalsMeasurements}></ListVitals>
                ) : (
                  <GraphVitals data={patient?.vitalsMeasurements}></GraphVitals>
                )}
              </div>
            </>
          )}
        </div>
        : <div className="permissionWarning">User does not have permissions to view this screen!</div>}
    </React.Fragment>
  )
}

export default VitalsHistory
