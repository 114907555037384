import React, { FC, useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Progress, Row } from "reactstrap"
import BPGraphs from "~/components/Common/Graphs/BPGraphs"
import AgentFilter from "~/components/Common/Modals/Filter/AgentFilter"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import "../BloodPressure/bloodPressure.scss"

const BMI: FC = () => {
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const dispatch = useAppDispatch()

  const [filterModel, setFilterModel] = useState<boolean>(false)
  const toggleFilterModal = () => setFilterModel(!filterModel)

  useEffect(() => {
    const superUser = localStorage.getItem("@super")

    if (superUser === "true") {
      var body: HTMLElement = document.body
      dispatch(setSecondaryBarTrue())
      if (!collapsed) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      dispatch(setSecondaryBarTrue())
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <AgentFilter isOpen={filterModel} toggle={toggleFilterModal} />
          <div className="d-sm-flex flex-wrap">
            <h2 className="m-0 align-self-center">
              <b>BMI</b>
            </h2>
            <div className="ms-auto">
              <button
                type="button"
                className="btn btn-light d-inline-flex"
                style={{
                  backgroundColor: "white",
                  color: "#2C3242",
                  width: "auto",
                }}
              >
                <i className="bx bx-export text-primary font-size-18 align-middle me-2"></i>
                <h6 className="m-0 my-auto">Export filtered data</h6>
              </button>
              <button
                type="button"
                className="btn btn-light d-inline-flex ms-2"
                style={{
                  backgroundColor: "white",
                  color: "#2C3242",
                  border: "1px solid #E3E3E3",
                  width: "auto",
                }}
                onClick={() => {
                  toggleFilterModal()
                }}
              >
                <i className="bx bx-filter font-size-22 align-middle me-2"></i>
                <h6 className="m-0 my-auto">Filters</h6>
              </button>
            </div>
          </div>
          <Row className="mt-2" cals>
            <Col lg={5} md={6} sm={12}>
              {/* Graph 1 */}
              <Card className="shadow-sm graphCard">
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <p className="text-muted heading mb-2">
                        Gender Distribution
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <h5 className="textMain">
                        <span className="textSpan">Male</span>
                        12312
                      </h5>
                    </Col>
                    <Col className="text-end" xs={6}>
                      <h5 className="textMain">
                        <span className="textSpan">Female</span>
                        12312
                      </h5>
                    </Col>
                  </Row>
                  <Progress
                    color="primary"
                    style={{ height: "120px" }}
                    value={45}
                  ></Progress>
                  <Row className="mt-2">
                    <Col xs={6}>
                      <h5 className="textSecondary">45.0%</h5>
                    </Col>
                    <Col className="text-end" xs={6}>
                      <h5 className="textSecondary">55.0%</h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <BPGraphs
                title="BPs Recorded"
                leftText="5,119"
                leftSub="from 5,853"
                rightText="2.7"
                type="line"
              />
            </Col>
            <Col lg={5} md={6} sm={12}>
              <BPGraphs
                title="Age Distribution"
                leftText="207,977"
                leftSub="Age 55-64"
                rightText="0.9"
                type="bar"
              />
              <BPGraphs title="Distribution of BP groups" type="table" />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BMI
