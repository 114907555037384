import { createAsyncThunk } from "@reduxjs/toolkit"
import { AxiosError } from "axios";
import { MyKnownError } from "~/models/Error.model";
import { Instance } from "~/utils/axios";

const getToken = () => {
    const data = localStorage.getItem("@auth");
    if (data) {
        const token = (JSON.parse(data)).token;
        return token;
    }
    return '';
}

export const countDashboard = createAsyncThunk<
    any,
    any,
    { rejectValue: MyKnownError }
>("count", async (data: any, thunkApi) => {

    const headers = {
        "X-Access-Token": false,
    }
    headers["X-Access-Token"] = await getToken()
    try {
        const response = await Instance.post("/rpc/count", data, { headers: headers })
        if (response.status !== 200) {
            return thunkApi.rejectWithValue(response.data as MyKnownError)
        }
        return response.data.data
    } catch (err: any) {
        const error: AxiosError<MyKnownError> = err.response.data.error
        if (err.response.status === 401) {
            localStorage.clear()
            window.location.reload();
        }
        if (!error.response) {
            throw error
        }

        return thunkApi.rejectWithValue(error)
    }
});


export const aggregateDashboard = createAsyncThunk<
    any,
    any,
    { rejectValue: MyKnownError }
>("count", async (data: any, thunkApi) => {

    const headers = {
        "X-Access-Token": false,
    }
    headers["X-Access-Token"] = await getToken()
    try {
        const response = await Instance.post("/rpc/selectForAggregation", data, { headers: headers })
        if (response.status !== 200) {
            return thunkApi.rejectWithValue(response.data as MyKnownError)
        }
        return response.data.data
    } catch (err: any) {
        const error: AxiosError<MyKnownError> = err.response.data.error
        if (err.response.status === 401) {
            localStorage.clear()
            window.location.reload();
        }
        if (!error.response) {
            throw error
        }

        return thunkApi.rejectWithValue(error)
    }
});

