import { createAsyncThunk } from "@reduxjs/toolkit"
import { MyKnownError } from "~/models/Error.model";
import { postRequest } from "~/store/apiHelper"

export const GetUsers = createAsyncThunk<
  any,
  {},
  { rejectValue: MyKnownError }
>("select/users", async (data, thunkApi) => {
  return postRequest("/rpc/select", data, true, thunkApi)
});

export const GetUserSelf = createAsyncThunk<
  any,
  {},
  { rejectValue: MyKnownError }
>("select/userSelf", async (data, thunkApi) => {
  return postRequest("/rpc/selectSelf", data, true, thunkApi)
});

export const SelectsAggregation = createAsyncThunk<
  any,
  {},
  { rejectValue: MyKnownError }
>("selectForAggregation/users", async (data, thunkApi) => {
  return postRequest("/rpc/selectForAggregation", data, true, thunkApi)
});
