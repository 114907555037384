import React, { FC, useEffect, useState } from "react"
import { Modal } from "reactstrap"
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { SendTemporaryPassword } from "~/store/reducers/AuthSlice/thunks";
import { usersSendTemporaryPassword } from "~/store/reducers/AuthSlice/types";
import { errorToast, successToast } from "~/utils/toast";
import { ResetSendTemporaryPassword } from "~/store/reducers/AuthSlice";
interface ModalProps {
  isOpen: boolean
  toggle: () => void
  dataResetPass: usersSendTemporaryPassword
}
type OptionType = {
  value: string;
  label: string;
};
type InvalidData = {
  value: boolean;
  label: string;
};
const ConfirmPasswordResetModal: FC<ModalProps> = ({ isOpen, toggle, dataResetPass }) => {

  const dispatch = useAppDispatch()
  const {
    errorSendTemporaryPassword,
    successSendTemporaryPassword,
    loadingSendTemporaryPassword
  } = useAppSelector(state => state.AuthReducer)
  const options: OptionType[] = [
    { value: 'BOTH', label: 'Both email and SMS' },
    { value: 'EMAIL', label: 'Email' },
    { value: 'PHONE', label: 'SMS' },
  ];
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(options[0]);
  const [invalid, setInvalid] = useState<InvalidData>();
  useEffect(() => {
    if (successSendTemporaryPassword) {
      successToast('Temporary password sent')
      dispatch(ResetSendTemporaryPassword());
      toggle();
    } else if (errorSendTemporaryPassword) {
      dispatch(ResetSendTemporaryPassword());
      errorToast(errorSendTemporaryPassword)
    }
  }, [successSendTemporaryPassword, errorSendTemporaryPassword])
  return (
    <Modal
      size="lg"
      style={{ width: "750px" }}
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
    >
      <div className="modal-body mx-2">
        <div >
          <div className="d-flex flex-row font-size-13 mb-3">
            <h5 className="heading-1">Confirm Password Reset</h5>
            <i
              className='bx bx-x ms-auto font-size-20'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toggle();
              }}></i>
          </div>
          <div
            className="d-flex px-3 py-2"
            style={{ background: '#D4DAF5', borderRadius: '8px' }}>
            <i className='bx bx-info-circle m-1' style={{ color: '#2A45CD' }}></i>
            <b className="ms-2" style={{ color: '#2A45CD' }}>
              Confirming this action will reset the user’s password and send a temporary one-time password via email and SMS that will be used to log in. On successful log in, the user must create a new password.
            </b>
          </div>
          <div className="my-3">
            <label className="col-md-2 col-form-label">Send reset link via</label>
            <Select
              className="w-50"
              defaultValue={selectedOption}
              onChange={val => {
                setSelectedOption(val)
                setInvalid({
                  value: false,
                  label: ''
                })
              }}
              options={options}
              placeholder="Select.."
            />
            {invalid?.value &&
              <p className="text-danger font-size-10">{invalid.label}</p>
            }
          </div>
        </div>
      </div>
      <div className="modal-footer p-2">
        <div className="ms-auto px-3">
          <button
            type="button"
            className="discard-btn"
            onClick={() => {
              toggle();
            }}
          >
            <h6 className="my-auto px-2">
              Dismiss
            </h6>
          </button>
          <button
            className="btn btn-primary ms-3"
            onClick={() => {
              let run: boolean = false;

              let body = {};
              if (selectedOption?.value === 'EMAIL') {
                if (dataResetPass?.email) {
                  run = true;
                  body = {
                    mode: dataResetPass?.mode,
                    userId: dataResetPass?.userId,
                    email: dataResetPass?.email
                  }
                } else {
                  run = false;
                  setInvalid({
                    value: true,
                    label: 'No email for this user'
                  })
                }
              }
              else if (selectedOption?.value === 'PHONE') {
                if (dataResetPass?.phone) {
                  run = true;
                  body = {
                    mode: dataResetPass?.mode,
                    userId: dataResetPass?.userId,
                    phone: dataResetPass?.phone
                  }
                } else {
                  run = false;
                  setInvalid({
                    value: true,
                    label: 'No phone number for this user'
                  })
                }
              }
              else if (selectedOption?.value === 'BOTH') {
                if (dataResetPass?.email || dataResetPass?.phone) {
                  run = true;
                  body = {
                    mode: dataResetPass?.mode,
                    userId: dataResetPass?.userId,
                    phone: dataResetPass?.phone,
                    email: dataResetPass?.email,
                  }
                } else {
                  run = false;
                  setInvalid({
                    value: true,
                    label: 'No phone number or email for this user'
                  })
                }
              }
              if (run) {
                dispatch(SendTemporaryPassword(body))
              }
            }}
          >
            Send reset link
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmPasswordResetModal
