import React, { FC, useEffect, useState } from "react"

import "./vitalsHistory.scss"
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap"
import moment from "moment"
import Highcharts from "highcharts/highstock"
import HighchartsReact from "highcharts-react-official"
import highchartsDumbbell from "highcharts/modules/dumbbell"
import HC_more from "highcharts/highcharts-more"

interface GraphBox {
  value: boolean
  index: number
}

interface VitalProps {
  data: any
}

interface VitalData {
  batchRecordingId: string
  component: any
  createdAt: string
  id: string
  organizationId: string
  patientId: string
  recordedAt: string
  recorderId: string
  type: string
  updatedAt: string
}
interface headingsData {
  name: string
  type: string
}

interface GraphData {
  name: string
  low: number
  high: number
  complete: string
}
interface Series {
  name: string
  data: number[]
  complete: string[]
  dates: string[]
  marker?: any
  color?: string
  readingType?: string[]
}
HC_more(Highcharts)
highchartsDumbbell(Highcharts)

const GraphVitals: FC<VitalProps> = ({ data }) => {
  //toogleGraphDetails
  const [vitalGraphBox, setVitalGraphBox] = useState<GraphBox>({
    value: true,
    index: 0,
  })
  const [dropdown, setDropdown] = useState<boolean>(false)
  const [dropdownBS, setDropdownBS] = useState<boolean>(false)
  const [datesList, setDatesList] = useState<string[]>([])
  const [filteredData, setFilteredData] = useState<any>([])

  const [graphHeading, setGraphHeading] = useState<headingsData[]>([
    { name: "Blood pressure", type: "BLOOD_PRESSURE_S_D" },
    { name: "Heart rate", type: "HEART_RATE" },
    { name: "Blood sugar", type: "BLOOD_GLUCOSE" },
  ])
  //days filter
  const dataTimeframe = [7, 15, 30, 60, 90, 365]
  const [timeFilter, setTimeFilter] = useState<number>(30)
  //BG filter
  const [glucoseValueType, setGlucoseValueType] = useState<any>([
    { name: "Post Meal", type: "POST_MEAL" },
    { name: "Fasting", type: "FASTING" },
    { name: "Pre Meal", type: "PRE_MEAL" },
    { name: "All Readings", type: "all" },
  ])
  const [glucoseValue, setGlucoseValue] = useState<number>(3)
  const [newData, setNewData] = useState<GraphData[]>([])
  const [heartRateList, setHeartRateList] = useState<Series[]>([])
  const [bloodSugarList, setBloodSugarList] = useState<Series[]>([])

  useEffect(() => {
    if (data?.length > 0) {
      let arr = []
      const filterDate = new Date()
      filterDate.setDate(filterDate.getDate() - timeFilter)
      var visitDate = new Date(
        filterDate.getUTCFullYear(),
        filterDate.getUTCMonth(),
        filterDate.getUTCDate()
      )

      for (let i = 0; i < data.length; i++) {
        var tempDate2 = new Date(data[i].recordedAt)
        var visitDate2 = new Date(
          tempDate2.getUTCFullYear(),
          tempDate2.getUTCMonth(),
          tempDate2.getUTCDate()
        )
        if (visitDate <= visitDate2) {
          arr.push(data[i])
        }
      }
      setFilteredData(arr)
    } else if (data?.length === 0) {
      setFilteredData([])
    }
  }, [data, timeFilter])

  useEffect(() => {
    if (graphHeading?.length > 0 && filteredData?.length > 0) {
      let dates1: string[] = []
      graphHeading.forEach(value => {
        const arr = filteredData.filter(
          (val: VitalData) => val.type === value.type
        )
        if (value.type === "BLOOD_PRESSURE_S_D") {
          let newData: GraphData[] = []
          arr.forEach((element: VitalData, index: number) => {
            if (element.type === "BLOOD_PRESSURE_S_D") {
              if (element?.component?.length > 0) {
                const body: GraphData = {
                  name: moment(element.recordedAt).format("hh:mm A"),
                  low: element?.component[1]?.valueQuantity?.value,
                  high: element?.component[0]?.valueQuantity?.value,
                  complete: element.recordedAt,
                }
                newData.push(body)
              }
            }
          })
          setNewData(newData.reverse())
        } else if (value.type === "HEART_RATE") {
          let heartRateData: number[] = []
          let dateArray: string[] = []
          let compArray: string[] = []

          arr.forEach((element: VitalData) => {
            if (element.type === "HEART_RATE") {
              if (element?.component?.length > 0) {
                heartRateData.push(element?.component[0]?.valueQuantity?.value)
                dateArray.push(moment(element.recordedAt).format("hh:mm A"))
                compArray.push(element.recordedAt)
              }
            }
          })
          const body: Series[] = [
            {
              name: "Heart rate",
              data: heartRateData.reverse(),
              dates: dateArray.reverse(),
              complete: compArray.reverse(),
              color: '#2A45CD',
              marker: {
                fillColor: '#2A45CD',
              }
            },
          ]
          setHeartRateList(body)
        } else if (value.type === "BLOOD_GLUCOSE") {
          let bloodSugarData: number[] = []
          let bloodSugarDate: string[] = []
          let dateArray: string[] = []
          let typeArray: string[] = []

          arr.forEach((val: VitalData) => {
            if (val?.component?.length > 0) {
              if (
                (val?.component[1]?.valueQuantity?.value ===
                  glucoseValueType[glucoseValue].type) || (glucoseValueType[glucoseValue].type === 'all')
              ) {
                typeArray.push(val.component[1].valueQuantity.value);
                bloodSugarDate.push(val.recordedAt)
                bloodSugarData.push(val?.component[0]?.valueQuantity?.value)
                dateArray.push(moment(val.recordedAt).format("hh:mm A"))
              }
            }
          })

          const body: Series[] = [
            {
              name: "Blood glucose",
              data: bloodSugarData.reverse(),
              complete: bloodSugarDate.reverse(),
              readingType: typeArray.reverse(),
              dates: dateArray.reverse(),
              color: '#2A45CD',
              marker: {
                fillColor: '#2A45CD',
              }
            },
          ]
          setBloodSugarList(body)
        }
      })
      setDatesList(dates1)
    } else if (filteredData?.length === 0) {
      setHeartRateList([])
      setBloodSugarList([])
      setNewData([])
    }
  }, [graphHeading, filteredData, glucoseValue])

  return (
    <div className="vitalsTable">
      {graphHeading?.map((title, index) => (
        <div key={title.name}>
          <div
            className="headingVital"
            onClick={() => {
              vitalGraphBox.index == index
                ? setVitalGraphBox({ value: !vitalGraphBox.value, index })
                : setVitalGraphBox({ value: true, index })
            }}
          >
            <i
              className={`bx bx-chevron-down font-size-20 ${vitalGraphBox.index == index && vitalGraphBox.value
                ? " arrowIcon"
                : ""
                }`}
            ></i>
            <b className="ms-3">{title.name}</b>
            <i className="bx bx-dots-vertical-rounded font-size-18 edit-icons ms-auto"></i>
          </div>
          {vitalGraphBox.index == index && vitalGraphBox.value ? (
            <div style={{ minHeight: "150px" }} className="bodyVital">
              <Row className="contentBody">
                <div className="d-flex flex-row">
                  {newData?.length > 0 && index === 0 && (
                    <div className="ps-4">
                      <h6 className="vital mt-4 d-flex flex-wrap">
                        Latest Reading
                      </h6>
                      <div className="value">
                        <h6 className="mb-1 text-black font-size-14">
                          {newData[0].high}/{newData[0].low} mm Hg
                        </h6>
                      </div>
                      <div>
                        <h6 className="mt-1 text-black font-size-14">
                          <span
                            style={{
                              color: "#E53C41",
                              letterSpacing: "-2px",
                            }}
                            className="ms-3 me-1"
                          >
                            ----
                          </span>{" "}
                          High
                          <span
                            style={{
                              color: "#19B707",
                              letterSpacing: "-2px",
                            }}
                            className="ms-3 me-1"
                          >
                            ----
                          </span>{" "}
                          Low
                        </h6>
                      </div>
                    </div>
                  )}

                  {heartRateList?.length > 0 && index === 1 && (
                    <div className="ps-4 ms-2">
                      <h6 className="vital mb-1 mt-4 d-flex flex-wrap">
                        Latest Reading
                      </h6>
                      <div className="value">
                        <h6 className="mb-1 text-black font-size-14">
                          {heartRateList[0].data[0]} bpm
                        </h6>
                      </div>
                      <div className="value">
                        <h6 className="mb-1 text-black font-size-14">
                          <span
                            style={{
                              color: "#E53C41",
                              letterSpacing: "-2px",
                            }}
                            className="ms-3 me-1"
                          >
                            ----
                          </span>{" "}
                          High
                          <span
                            style={{
                              color: "#19B707",
                              letterSpacing: "-2px",
                            }}
                            className="ms-3 me-1"
                          >
                            ----
                          </span>{" "}
                          Low
                        </h6>
                      </div>
                    </div>
                  )}
                  {bloodSugarList[0]?.data?.length > 0 && index === 2 && (
                    <div className="ps-4">
                      <h6 className="vital mb-1 mt-4 d-flex flex-wrap">
                        Latest Reading
                      </h6>
                      <div className="value">
                        <h6 className="mb-1 text-black font-size-14">
                          {bloodSugarList[0].data[0]} mmol/L
                        </h6>
                      </div>
                      <div className="value">
                        <h6 className="mb-1 text-black font-size-14">
                          <span
                            style={{
                              color: "#E53C41",
                              letterSpacing: "-2px",
                            }}
                            className="ms-3 me-1"
                          >
                            ----
                          </span>{" "}
                          High
                          <span
                            style={{
                              color: "#19B707",
                              letterSpacing: "-2px",
                            }}
                            className="ms-3 me-1"
                          >
                            ----
                          </span>{" "}
                          Low
                        </h6>
                      </div>
                    </div>
                  )}

                  <div className="ms-auto pe-4">
                    <div className=" mb-2">
                      {index === 2 ? (
                        <ButtonDropdown
                          className="me-2 "
                          isOpen={dropdownBS}
                          toggle={() => setDropdownBS(!dropdownBS)}
                        >
                          <DropdownToggle
                            caret
                            color="light"
                            className="btn bg-white btn-sm "
                            style={{
                              borderRadius: "17px",
                              border: "1px solid #E3E3E3",
                            }}
                          >
                            {glucoseValueType[glucoseValue].name}
                            <i className="bx bx-chevron-down ms-1" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {glucoseValueType.map((val: any, index: number) => (
                              <DropdownItem
                                key={index}
                                onClick={() => {
                                  setGlucoseValue(index)
                                }}
                              >
                                {val.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </ButtonDropdown>
                      ) : null}
                      <ButtonDropdown
                        isOpen={dropdown}
                        toggle={() => setDropdown(!dropdown)}
                      >
                        <DropdownToggle
                          caret
                          color="light"
                          className="btn bg-white btn-sm"
                          style={{
                            borderRadius: "17px",
                            border: "1px solid #E3E3E3",
                          }}
                        >
                          {timeFilter === 365
                            ? "Last 1 year"
                            : `Last ${timeFilter} days`}
                          <i className="bx bx-chevron-down ms-1" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {dataTimeframe.map((val: number) => (
                            <DropdownItem
                              key={val}
                              onClick={() => {
                                setTimeFilter(val)
                              }}
                            >
                              {val === 365 ? "Last 1 year" : `Last ${val} days`}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </ButtonDropdown>
                    </div>
                  </div>
                </div>

                {newData && newData.length > 0 && index === 0 ? (
                  <div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        credits: {
                          enabled: true,
                          position: {
                            align: "right",
                            x: -10,
                            verticalAlign: "bottom",
                            y: -5,
                          },
                          text: "",
                        },

                        plotOptions: {
                          column: {
                            pointPadding: 0.2,
                            borderWidth: 0.5,
                          },
                        },

                        chart: {
                          type: "dumbbell",
                          inverted: false,
                          backgroundColor: "#FBFBFB",
                          height: 251,
                        },
                        title: {
                          text: "",
                        },
                        legend: {
                          enabled: false,
                        },
                        subtitle: {},

                        tooltip: {
                          shared: true,
                          useHTML: true,
                          backgroundColor: null,
                          borderWidth: 0,
                          shadow: false,

                          formatter: function () {
                            return (
                              "<div>" +
                              '<a class="toolTip-heading">Systolic</a>' +
                              "<br>" +
                              `<a class="toolTip-value">${newData[this.points[0].x].high
                              } mmHg</a>` +
                              "<br>" +
                              "</div>" +
                              "<br>" +
                              "<div>" +
                              '<a class="toolTip-heading">Diastolic</a>' +
                              "<br>" +
                              `<a class="toolTip-value">${newData[this.points[0].x].low
                              } mmHg</a>` +
                              "<br>" +
                              "</div>" +
                              "<br>" +
                              "<div>" +
                              `<a class="date">
                            ${moment(newData[this.points[0].x].complete).format(
                                "DD MMM YY"
                              )}
                            </a>` +
                              "<br>" +
                              `<a class="date">${moment(
                                newData[this.points[0].x].complete
                              ).format("hh:mm A")}</a>` +
                              "</div>"
                            )
                          },
                        },
                        xAxis: {
                          type: "category",
                          uniqueNames: false,
                          min: 0,
                          max:
                            newData.length > 10
                              ? 9
                              : newData.length - 1,
                          reversed: true,
                          scrollbar: {
                            enabled: true,
                            showFull: false,
                          },
                        },

                        yAxis: {
                          title: {
                            text: "",
                          },
                          minRange: 150,
                          plotLines: [
                            {
                              color: "#E53C41",
                              width: 0.6,
                              value: 140,
                            },
                            {
                              color: "#19B707",
                              width: 0.6,
                              value: 90,
                            },
                          ],
                        },

                        series: [
                          {
                            name: "",
                            data: newData,

                            lowColor: '#2A45CD',
                            connectorColor: '#2A45CD',
                            marker: {
                              fillColor: '#2A45CD',
                            }
                          },
                        ],
                      }}
                    />
                  </div>
                ) : newData && newData.length === 0 && index === 0 ? (
                  <div className="text-center m-3">
                    {timeFilter === 365 ? "No record found for last 1 year" : `No record found for last ${timeFilter} days`}
                  </div>
                ) : null}

                {/* heart rate */}
                {heartRateList && heartRateList.length > 0 && index === 1 ? (
                  <div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        credits: {
                          enabled: true,
                          position: {
                            align: "right",
                            x: -10,
                            verticalAlign: "bottom",
                            y: -5,
                          },
                          text: "",
                        },

                        plotOptions: {
                          column: {
                            pointPadding: 0.2,
                            borderWidth: 0.5,
                          },
                        },

                        chart: {
                          height: 251,
                          backgroundColor: "#FBFBFB",
                        },
                        title: {
                          text: "",
                        },
                        legend: {
                          enabled: false,
                        },
                        subtitle: {},

                        tooltip: {
                          shared: true,
                          useHTML: true,
                          backgroundColor: null,
                          borderWidth: 0,
                          shadow: false,

                          formatter: function () {
                            return (
                              "<div>" +
                              '<a class="toolTip-heading">Heart rate</a>' +
                              "<br>" +
                              `<a class="toolTip-value">${heartRateList[0].data[
                              this.points[0].point.index
                              ]
                              } bpm</a>` +
                              "<br>" +
                              "</div>" +
                              "<br>" +
                              "<div>" +
                              `<a class="date">
                              ${moment(
                                heartRateList[0].complete[
                                this.points[0].point.index
                                ]
                              ).format("DD MMM YY")}
                              </a>` +
                              "<br>" +
                              `<a class="date">${moment(
                                heartRateList[0].complete[
                                this.points[0].point.index
                                ]
                              ).format("hh:mm A")}</a>` +
                              "</div>"
                            )
                          },
                        },
                        xAxis: {
                          type: "category",
                          uniqueNames: false,
                          min: 0,
                          max:
                            heartRateList[0].dates.length > 10
                              ? 9
                              : heartRateList[0].dates.length - 1,
                          reversed: true,
                          scrollbar: {
                            enabled: true,
                            showFull: false,
                          },
                          categories: heartRateList[0].dates,
                        },
                        yAxis: {
                          title: {
                            text: "",
                          },
                          minRange: 50,
                          plotLines: [
                            {
                              color: "#E53C41",
                              width: 0.6,
                              value: 100.5,
                            },
                            {
                              color: "#19B707",
                              width: 0.6,
                              value: 50.1,
                            },
                          ],
                        },

                        series: heartRateList,
                      }}
                    />
                  </div>
                ) : index === 1 ? (
                  <div className="text-center m-3">
                    {timeFilter === 365 ? "No record found for last 1 year" : `No record found for last ${timeFilter} days`}
                  </div>
                ) : null}

                {/* blood sugar*/}
                {bloodSugarList && bloodSugarList[0]?.dates?.length > 0 && index === 2 ? (
                  <div>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        credits: {
                          enabled: true,
                          position: {
                            align: "right",
                            x: -10,
                            verticalAlign: "bottom",
                            y: -5,
                          },
                          text: "",
                        },

                        plotOptions: {
                          column: {
                            pointPadding: 0.2,
                            borderWidth: 0.5,
                          },
                        },

                        chart: {
                          height: 251,
                          backgroundColor: "#FBFBFB",
                        },
                        title: {
                          text: "",
                        },
                        legend: {
                          enabled: false,
                        },
                        subtitle: {},

                        tooltip: {
                          shared: true,
                          useHTML: true,
                          backgroundColor: null,
                          borderWidth: 0,
                          shadow: false,

                          formatter: function () {
                            return (
                              "<div>" +
                              '<a class="toolTip-heading">Blood Glucose</a>' +
                              "<br>" +
                              `<a class="toolTip-value">${bloodSugarList[0].data[
                              this.points[0].point.index
                              ]
                              } mmol/L</a>` +
                              "<br>" +
                              "</div>" +
                              `
                              <div>
                              ${bloodSugarList[0]?.readingType[this.points[0]?.point.index] === 'POST_MEAL' ? 'Post meal' :
                                bloodSugarList[0]?.readingType[this.points[0]?.point.index] === 'PRE_MEAL' ? 'Pre meal' :
                                  bloodSugarList[0]?.readingType[this.points[0]?.point.index] === 'FASTING' ? 'Fasting' : ''
                              }</div>
                              `+
                              "<br>" +
                              "<div>" +
                              `<a class="date">
                              ${moment(
                                bloodSugarList[0].complete[
                                this.points[0].point.index
                                ]
                              ).format("DD MMM YY")}
                              </a>` +
                              "<br>" +
                              `<a class="date">${moment(
                                bloodSugarList[0].complete[
                                this.points[0].point.index
                                ]
                              ).format("hh:mm A")}</a>` +
                              "</div>"
                            )
                          },
                        },
                        xAxis: {
                          type: "category",
                          uniqueNames: false,
                          min: 0,
                          max:
                            bloodSugarList[0].dates.length > 10
                              ? 9
                              : bloodSugarList[0].dates.length - 1,
                          reversed: true,
                          scrollbar: {
                            enabled: true,
                            showFull: false,
                          },
                          categories: bloodSugarList[0].dates,
                        },
                        yAxis: {
                          minRange: 12,
                          title: {
                            text: "",
                          },
                          plotLines: [
                            {
                              color: "#E53C41",
                              width: 0.6,
                              value: glucoseValue === 1 ? 7 : 11,
                            },
                            {
                              color: "#E53C41",
                              width: 0.6,
                              value: glucoseValue === 3 ? 7 : null,
                            },
                            {
                              color: "#19B707",
                              width: 0.6,
                              value: 3.9,
                            },
                          ],
                        },

                        series: bloodSugarList,
                      }}
                    />
                  </div>
                ) : index === 2 ? (
                  <div className="text-center m-3">
                    {timeFilter === 365 ? "No record found for last 1 year" : `No record found for last ${timeFilter} days`}

                  </div>
                ) : null}
              </Row>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  )
}

export default GraphVitals
