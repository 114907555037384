import { createSlice } from "@reduxjs/toolkit"
import Async from "~/utils/Async"
import {
  GetListDefaultRoles,
  OrganizationRoles,
  RolesDeleteCustomRole,
  RolesUpdateBatch,
  UpdateUserRole,
} from "./thunk"

const initialState = {
  success: false,
  loading: false,
  error: "",
  successDelete: false,
  loadingDelete: false,
  errorDelete: "",
  roles: {},
  roleUpdateSuccess: false,
  roleUpdateError: "",
  roleUpdateLoading: false,
  userRoleUpdateSuccess: false,
  userRoleUpdateError: "",
  userRoleUpdateLoading: false,
}
const rolesSlice = createSlice({
  name: "roles",
  initialState,
  extraReducers: builder => {
    builder
      .addCase(GetListDefaultRoles.pending, state => {
        state.loading = true
        state.success = false
        state.error = ""
      })
      .addCase(GetListDefaultRoles.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.error = ""
        state.roles = payload
      })
      .addCase(GetListDefaultRoles.rejected, (state, action) => {
        state.loading = false
        state.success = false
        if (action.error) {
          state.error = action.error.message as string
        } else {
          state.error = "Error getting roles"
        }
      })
      .addCase(OrganizationRoles.pending, state => {
        state.loading = true
        state.success = false
        state.error = ""
      })
      .addCase(OrganizationRoles.fulfilled, (state, { payload }) => {
        state.loading = false
        state.success = true
        state.error = ""
        state.roles = payload
      })
      .addCase(OrganizationRoles.rejected, (state, action) => {
        state.loading = false
        state.success = false
        if (action.error) {
          state.error = action.error.message as string
        } else {
          state.error = "Error getting organization roles"
        }
      })
      .addCase(RolesUpdateBatch.pending, state => {
        state.roleUpdateLoading = true
        state.roleUpdateSuccess = false
        state.roleUpdateError = ""
      })
      .addCase(RolesUpdateBatch.fulfilled, (state, { payload }) => {
        state.roleUpdateLoading = false
        state.roleUpdateSuccess = true
        state.roleUpdateError = ""
      })
      .addCase(RolesUpdateBatch.rejected, (state, action) => {
        state.roleUpdateLoading = false
        state.roleUpdateSuccess = false
        if (action.error) {
          state.roleUpdateError = action.error.message as string
        } else {
          state.roleUpdateError = "Error updating roles"
        }
      })
      // user role update
      .addCase(UpdateUserRole.pending, state => {
        state.userRoleUpdateLoading = true
        state.userRoleUpdateSuccess = false
        state.userRoleUpdateError = ""
      })
      .addCase(UpdateUserRole.fulfilled, state => {
        state.userRoleUpdateLoading = false
        state.userRoleUpdateSuccess = true
        state.userRoleUpdateError = ""
      })
      .addCase(UpdateUserRole.rejected, (state, action) => {
        state.userRoleUpdateLoading = false
        state.userRoleUpdateSuccess = false
        if (action.error) {
          state.userRoleUpdateError = action.error.message as string
        } else {
          state.userRoleUpdateError = "Error updating user roles"
        }
      })
      .addCase(RolesDeleteCustomRole.pending, state => {
        state.loadingDelete = true
        state.successDelete = false
        state.errorDelete = ""
      })
      .addCase(RolesDeleteCustomRole.fulfilled, (state, { payload }) => {
        state.loadingDelete = false
        state.successDelete = true
        state.errorDelete = ""
      })
      .addCase(RolesDeleteCustomRole.rejected, (state, action) => {
        state.loadingDelete = false
        state.successDelete = false
        if (action.error) {
          state.errorDelete = action.error.message as string
        } else {
          state.errorDelete = "Error getting roles"
        }
      })
  },
  reducers: {
    ResetRolesStates: state => {
      state.success = false
      state.loading = false
      state.error = ""
      state.roles = {}
    },
    ResetRolesUpdateStates: state => {
      state.roleUpdateSuccess = false
      state.roleUpdateLoading = false
      state.roleUpdateError = ""
      state.roles = {}
    },
    ResetUpdateUserRole: state => {
      state.userRoleUpdateSuccess = false
      state.userRoleUpdateLoading = false
      state.userRoleUpdateError = ""
    },
    ResetDeleteRoleStates: state => {
      state.successDelete = false
      state.loadingDelete = false
      state.errorDelete = ""
    },
  },
})

export const {
  ResetRolesStates,
  ResetRolesUpdateStates,
  ResetDeleteRoleStates,
  ResetUpdateUserRole,
} = rolesSlice.actions
export default rolesSlice.reducer
