import { useHistory } from "react-router-dom"
import React, { FC, useEffect, useState } from "react"
import { Form, FormFeedback, Input, Spinner } from "reactstrap"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar"
import { useFormik } from "formik"
import * as Yup from "yup"
import ResetPassword from "~/components/Common/Modals/Authentication/ResetPassword"
import { WithRouterProps } from "~/models/WithRouterProps.model"
import { CreatePatient, EditPatient } from "~/store/reducers/Patient/thunk"
import DatePicker from 'reactstrap-date-picker'
import {
  ResetPatientStates,
  ResetPatientUpdateStates,
} from "~/store/reducers/Patient"
import { errorToast, successToast } from "~/utils/toast"
import Avatar from "react-avatar"
import { GetUsers } from "~/store/reducers/Select/thunk"
import Async from "~/utils/Async"
import moment from "moment"
import {
  contacts,
  IdentifiersType,
  nextOfKinBatch,
} from "~/models/Identifier.model"
import { isValidPhoneNumber } from "libphonenumber-js"

import { SearchOrganizations } from "~/store/reducers/Organizations/thunk"
import { Patient } from "~/store/reducers/Patient/types"
import { setVitalScreenRedux } from "~/store/reducers/Select"
import {
  BatchAddIdentifier,
  BatchDeleteIdentifier,
  BatchEditIdentifier,
} from "~/store/reducers/Agent/thunk"
import { identifiersEdit } from "~/store/reducers/Agent/types"
import {
  BatchAddNextOfKin,
  BatchEditNextOfKin,
  DeleteNextOfKin,
} from "~/store/reducers/NextOfKin/thunk"
import NextOfKin, {
  ResetBatchAddNextOfKin,
  ResetBatchEditNextOfKin,
  ResetDeleteNextOfKin,
} from "~/store/reducers/NextOfKin"
import { ResetAddIdentifier, ResetDeleteIdentifier, ResetEditIdentifier } from "~/store/reducers/Agent"
import { GenerateUsername } from "~/store/reducers/AuthSlice/thunks"
import { ResetGenerateUsername } from "~/store/reducers/AuthSlice"
import ConfirmationModal from "~/components/Common/Modals/confirmationModal"
import ConfirmPasswordResetModal from "~/components/Common/Modals/confirmPasswordResetModal"
import { usersSendTemporaryPassword } from "~/store/reducers/AuthSlice/types"
interface PageType {
  page: string
  data: Patient
}

const AddEditPatientManagement: FC<WithRouterProps> = props => {
  const { location } = props
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { collapsed } = useAppSelector(state => state.sideBarReducer)
  const [editPatient, setEditPatient] = useState<boolean>(false)

  const {
    successKinBatch,
    errorKinBatch,
    loadingKinBatch,
    successDeleteKin,
    errorDeleteKin,
    loadingDeleteKin,
    successKinBatchEdit,
    errorKinBatchEdit,
    loadingKinBatchEdit,
  } = useAppSelector(state => state.NextOfKinReducer)
  const {
    generatedName,
    errorGenerateUsername,
    successGenerateUsername,
    loadingGenerateUsername,
  } = useAppSelector(state => state.AuthReducer)
  const [resetPasswordModel, setResetPasswordModel] = useState<boolean>(false)
  const [addPage, setAddPage] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  const [emailError, setEmailError] = useState<boolean>(false)

  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [nextOfKin, setNextOfKin] = useState<contacts[]>([])
  const [nextOfKinErrors, setNextOfKinErrors] = useState<any[]>([])

  const [emailID, setEmailID] = useState<string>("")
  const [phoneNumberID, setPhoneNumberID] = useState<string>("")
  const [patientProfile, setPatientProfile] = useState<Patient>()
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false)
  const [dataResetPass, setDataResetPass] =
    useState<usersSendTemporaryPassword>({
      mode: undefined,
      userId: undefined,
      email: undefined,
      phone: undefined,
    })
  const [confirmPasswordResetModal, setConfirmPasswordResetModal] =
    useState<boolean>(false)

  const { loading, error, success, updateLoading, updateSuccess, updateError } =
    useAppSelector(state => state.PatientReducer)

  const [deleteIndex, setDeleteIndex] = useState<number>(0)

  const {
    success: updatedDataSuccess,
    loading: updatedDataLoading,
    error: updatedDataError,
    patient,
  } = useAppSelector(state => state.SelectReducer)

  const { loadingIdentifier,
    successIdentifier,
    errorIdentifier,
    loadingAddIdentifier,
    successAddIdentifier,
    errorAddIdentifier,
    loadingDeleteIdentifier,
    successDeleteIdentifier,
    errorDeleteIdentifier } =
    useAppSelector(state => state.AgentReducer)

  const toggleConfirmPasswordResetModal = () =>
    setConfirmPasswordResetModal(!confirmPasswordResetModal)
  const screen: string = Async.getItem("@ScreenPatient")

  const [access, setAccess] = useState<boolean>(true);

  useEffect(() => {
    const page: PageType = location?.state as PageType
    dispatch(ResetGenerateUsername())
    if (page?.page === "add") {
      setAddPage(true)
    } else if (page?.page === "edit") {
      setPatientProfile(page.data)
      setAddPage(false)
      //fetch updated data on page load
      if (!updatedDataLoading) {
        fetchUpdatedData(page?.data?.id!)
      }
    }
  }, [location])

  useEffect(() => {
    const superUser = localStorage.getItem("@super")
    const per = Async.getItem("@auth")
    Async.setItem('self', false);
    setEditPatient(per.role.permissions["patient-edit"])

    if (superUser === "true") {
      var body: HTMLElement = document.body
      dispatch(setSecondaryBarTrue())
      if (!collapsed) {
        dispatch(toggle(true))
        body.classList.toggle("vertical-collpsed")
        body.classList.toggle("sidebar-enable")
      }
    } else {
      const data = Async.getItem("@auth")
      setAccess(data?.role?.permissions["patient-view"])
      dispatch(setSecondaryBarTrue())
    }
  }, [])
  useEffect(() => {
    validation.setFieldValue('phoneNumber', phoneNumber)
  }, [phoneNumber])
  useEffect(() => {
    if (updatedDataSuccess && Object.keys(patient)?.length > 0) {
      setPatientProfile(patient)
    } else if (updatedDataError) {
      errorToast("Error Fetching updated data")
      const organizationLocalData = Async.getItem("@organization")
      dispatch(
        GetUsers({
          practitioner: {
            filter: {
              organizationId: organizationLocalData.id,
            },
            include: {
              vitalsMeasurements: false,
              identifiers: true,
              nextOfKin: true,
              roleAssignments: true,
            },
          },
        })
      )

      history.push("/patientManagement")
    }
  }, [updatedDataSuccess, updatedDataError])

  useEffect(() => {
    if (patientProfile && Object.keys(patientProfile)?.length > 0 && !addPage) {
      //set identifiers
      patientProfile?.user?.identifiers?.forEach((element: IdentifiersType) => {
        if (element.type === "EMAIL") {
          setEmail(element.identifier)
          setEmailID(element.id)
        }
        if (element.type === "PHONE_NUMBER") {
          setPhoneNumber(element.identifier)
          setPhoneNumberID(element.id)
        }
      })
      //set kin
      let newKin: contacts[] = []
      let list: any = []

      patientProfile?.user?.profile?.nextOfKin?.forEach(
        (kin: contacts, index: number) => {
          const body = {
            // email: false,
            firstName: false,
            lastName: false,
            phone: false,
            relationship: false,
          }

          newKin.push(kin)
          list.push(body)
        }
      )
      setNextOfKinErrors(list)
      setNextOfKin(newKin)
    }
  }, [patientProfile])

  useEffect(() => {
    if (success) {
      dispatch(setVitalScreenRedux(""))
      dispatch(ResetPatientStates())
      const superUser = localStorage.getItem("@super")
      if (superUser === "true") {
        dispatch(
          SearchOrganizations({
            search: "",
            filter: {
              take: 10,
              skip: 0,
            },
            sort: [
              {
                field: "createdAt",
                order: "desc",
              },
            ],
          })
        )
      }
      successToast("Patient added successfully")
      history.push("/patientManagement")
    }
    if (error) {
      errorToast(error)
      dispatch(ResetPatientStates())
    }
  }, [success, error])

  useEffect(() => {
    // edit Data states
    if (updateSuccess) {
      successToast("Patient information updated")
      if (!loadingKinBatch || !loadingKinBatchEdit || !loadingIdentifier) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetPatientUpdateStates())
      }
    }
    if (updateError) {
      errorToast(updateError)
    }
    //adding kin states
    if (successKinBatch) {
      successToast("Kin added successfully")
      if (!updateLoading || !loadingKinBatchEdit || !loadingIdentifier) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetBatchAddNextOfKin())
      }
    } else if (errorKinBatch) {
      errorToast(errorKinBatch)
    }
    //edit kin states
    if (successKinBatchEdit) {
      successToast("Kin information updated")
      if (!updateLoading || !loadingKinBatch || !loadingIdentifier) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetBatchEditNextOfKin())
      }
    } else if (errorKinBatchEdit) {
      errorToast(errorKinBatchEdit)
    }
    //add identifier states
    if (successAddIdentifier) {
      successToast("Identifier Added")
      if (
        !updateLoading ||
        !loadingKinBatch ||
        !loadingKinBatchEdit ||
        !loadingIdentifier
      ) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetAddIdentifier())
      }
    } else if (errorAddIdentifier) {
      errorToast(errorAddIdentifier)
    }
    //edit identifier states
    if (successIdentifier) {
      successToast("Identifier updated")
      if (!updateLoading || !loadingKinBatch || !loadingKinBatchEdit) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetEditIdentifier())
      }
    } else if (errorIdentifier) {
      errorToast(errorIdentifier)
    }
    //delete identifier states
    if (successDeleteIdentifier) {
      successToast("Identifier deleted")
      if (!updateLoading || !loadingKinBatch || !loadingKinBatchEdit) {
        if (!updatedDataLoading) {
          fetchUpdatedData()
        }
        dispatch(ResetDeleteIdentifier())
      }
    } else if (errorDeleteIdentifier) {
      errorToast(errorDeleteIdentifier)
    }
    //on any error
    if (updateError || errorKinBatch || errorKinBatchEdit || errorIdentifier || errorAddIdentifier || errorDeleteIdentifier) {
      dispatch(ResetPatientUpdateStates())
      dispatch(ResetBatchAddNextOfKin())
      dispatch(ResetBatchEditNextOfKin())
      dispatch(ResetAddIdentifier())
      dispatch(ResetEditIdentifier())
      dispatch(ResetDeleteIdentifier())
    }
    // on success
    if (
      updateSuccess &&
      (successKinBatch || successKinBatchEdit) &&
      successIdentifier
    ) {
      dispatch(ResetPatientUpdateStates())
      dispatch(ResetBatchAddNextOfKin())
      dispatch(ResetBatchEditNextOfKin())
      dispatch(ResetAddIdentifier())
      dispatch(ResetEditIdentifier())
      if (!updatedDataLoading) {
        fetchUpdatedData()
      }
    }
  }, [
    updateError,
    updateSuccess,
    successKinBatch,
    errorKinBatch,
    successIdentifier,
    errorIdentifier,
    successKinBatchEdit,
    errorKinBatchEdit,
    successAddIdentifier,
    errorAddIdentifier,
    successDeleteIdentifier,
    errorDeleteIdentifier
  ])

  useEffect(() => {
    if (generatedName) {
      validation.setFieldValue("username", generatedName)
    } else {
      validation.setFieldValue("username", "")
    }
  }, [generatedName])

  useEffect(() => {
    if (errorGenerateUsername) {
      errorToast(errorGenerateUsername)
    }
  }, [errorGenerateUsername])

  useEffect(() => {
    if (successDeleteKin) {
      fetchUpdatedData()
      dispatch(ResetDeleteNextOfKin())
      successToast('Kin deleted successfully!')
      toggleRemove()
    } else if (errorDeleteKin) {
      dispatch(ResetDeleteNextOfKin())
      errorToast(errorDeleteKin)
    }
  }, [successDeleteKin, errorDeleteKin])
  const toggleResetPasswordModal = () => {
    setResetPasswordModel(!resetPasswordModel)
  }
  const fetchUpdatedData = (id?: string) => {
    dispatch(setVitalScreenRedux(location.pathname))
    const organizationLocalData = Async.getItem("@organization")
    dispatch(
      GetUsers({
        patient: {
          filter: {
            id: id ? id : patientProfile?.id,
            organizationId: organizationLocalData?.id,
          },
          include: {
            vitalsMeasurements: false,
            identifiers: true,
            nextOfKin: true,
            roleAssignments: true,
          },
        },
      })
    )
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName:
        screen === "edit" ? `${patientProfile?.user?.profile.firstName}` : "",
      middleName:
        screen === "edit"
          ? `${patientProfile?.user?.profile.middleName !== null
            ? patientProfile?.user?.profile.middleName
            : ""
          }`
          : "",
      lastName:
        screen === "edit" ? `${patientProfile?.user?.profile.lastName}` : "",
      username: screen === "edit" ? `${patientProfile?.user?.username}` : "",
      phoneNumber: !addPage ? `` : "",
      address:
        screen === "edit"
          ? `${patientProfile?.user?.profile.address !== null
            ? patientProfile?.user?.profile.address
            : ''
          }`
          : "",
      birthDate:
        screen === "edit"
          ? `${moment(patientProfile?.user?.profile.birthDate).format(
            "yyyy-MM-DD"
          )}`
          : "",
      gender:
        screen === "edit" ? `${patientProfile?.user?.profile.gender}` : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().matches(
        /^(?=.*\S).+$/,
        "Only spaces are not allowed"
      ).required("Please enter first name"),
      lastName: Yup.string().matches(
        /^(?=.*\S).+$/,
        "Only spaces are not allowed"
      ).required("Please enter last name"),
      address: Yup.string(),
      gender: Yup.string().required("Please select gender"),
      birthDate: Yup.string()
        .required("Please enter date of birth")
        .test(
          "Only past dates check",
          "Date cannot be in future.",
          e => (e && new Date(e) < new Date()) || false
        ),
      phoneNumber: Yup.string().test(
        "Required check",
        "Phone number is invalid",
        e => isValidPhoneNumber(phoneNumber)
      ),
      username: Yup.string().min(5, 'Too Short!').max(20, 'Too Long!').matches(
        /^(?!\d)(?!.*-.*-)(?!.*-$)(?!-)[a-zA-Z0-9-]{5,20}$/,
        "Username must start with a letter. Contains only letters, numbers, and dashes or hyphens."
      ).required("Please enter username"),
    }),
    onSubmit: values => {
      const localData = localStorage.getItem("@organization")
      if (screen === "add") {
        if (localData) {
          if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${email}`) && email!?.length > 0) {
            setEmailError(true)
          } else {
            const data = {
              organizationId: JSON.parse(localData)?.id,
              username: values.username,
              identifiers: {
                email: email === '' ? undefined : email,
                phone: phoneNumber,
              },
              profile: {
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                birthDate: moment.utc(new Date(values.birthDate)).toISOString(),
                gender: values.gender,
                address: values.address,
              },
              sendInviteMessage: true,
            }
            dispatch(CreatePatient(data))
          }
        }
      } else if (screen === "edit") {
        let bool = true
        let data = [...nextOfKinErrors]

        nextOfKin.forEach((val, i) => {
          if (val.firstName === "" || !val.firstName.replace(/\s/g, '').length) {
            data[i].firstName = true

            bool = false
          }
          if (val.lastName === "" || !val.lastName.replace(/\s/g, '').length) {
            data[i].lastName = true

            bool = false
          }
          let check = isValidPhoneNumber(val.phone)
          if (val.phone === "" || !check) {
            data[i].phone = true

            bool = false
          }
          if (val.relationship === "") {
            data[i].relationship = true

            bool = false
          }
          if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${val.email}`) && val?.email!?.length > 0) {
            data[i].email = true
            bool = false
          }
        })

        setNextOfKinErrors(data)

        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${email}`) && email!?.length > 0) {
          setEmailError(true)
          bool = false
        }

        if (bool) {
          if (patientProfile?.user?.profile.id) {
            let mid = patientProfile?.user?.profile.middleName === null ? "" : patientProfile?.user?.profile.middleName;
            if (
              values.firstName !== patientProfile?.user?.profile.firstName ||
              values.middleName !== mid ||
              values.lastName !== patientProfile?.user?.profile.lastName ||
              values.address !== patientProfile?.user?.profile.address ||
              values.gender !== patientProfile?.user?.profile.gender ||
              (!patientProfile?.user?.profile.birthDate.includes(values.birthDate))
            ) {
              const data = {
                id: patientProfile?.user?.profile.id,
                firstName: values.firstName,
                middleName: values.middleName,
                lastName: values.lastName,
                birthDate: moment.utc(new Date(values.birthDate)).toISOString(),
                gender: values.gender,
                address: values.address,
              }
              dispatch(EditPatient(data))
            }
          }

          //edit identifier
          let identifierChanged = false
          let identifierAdd = false
          let tempIdentifier: identifiersEdit[] = []
          let tempAddIdentifier: identifiersEdit[] = []

          patientProfile?.user?.identifiers.forEach((identifier, index) => {

            if (
              email !== undefined &&
              email !== identifier.identifier &&
              identifier.type === "EMAIL"
            ) {
              if (emailID) {
                if (email) {
                  identifierChanged = true
                  tempIdentifier.push({
                    id: emailID,
                    type: "EMAIL",
                    value: email,
                  })
                } else {
                  dispatch(BatchDeleteIdentifier({ identifiers: [emailID] }))
                }
              }
            }
            if (
              phoneNumber !== identifier.identifier &&
              identifier.type === "PHONE_NUMBER"
            ) {
              if (phoneNumberID) {

                identifierChanged = true
                tempIdentifier.push({
                  id: phoneNumberID,
                  type: "PHONE_NUMBER",
                  value: phoneNumber,
                })
              }
            }
          })
          if (phoneNumber && patientProfile?.user?.identifiers && !(patientProfile?.user?.identifiers?.filter((i) => i.type === "PHONE_NUMBER")?.length > 0)) {
            //add

            identifierAdd = true
            tempAddIdentifier.push({
              type: "PHONE_NUMBER",
              value: phoneNumber,
            })
          }
          if (email && patientProfile?.user?.identifiers && !(patientProfile?.user?.identifiers?.filter((i) => i.type === "EMAIL")?.length > 0)) {
            //add

            identifierAdd = true
            tempAddIdentifier.push({
              type: "EMAIL",
              value: email,
            })
          }
          if (identifierAdd) {

            dispatch(
              BatchAddIdentifier({
                userId: `${patientProfile?.user?.profile?.userId}`,
                identifiers: tempAddIdentifier,
              })
            )
          }
          if (identifierChanged) {
            dispatch(BatchEditIdentifier({ identifiers: tempIdentifier }))
          }

          //kin add
          if (
            patientProfile &&
            nextOfKin?.length > patientProfile?.user?.profile?.nextOfKin?.length!
          ) {
            let tempKin: nextOfKinBatch
            tempKin = {
              userProfileId: patientProfile?.userProfileId,
              contacts: [],
            }
            nextOfKin.forEach((kin, index) => {
              if (index >= patientProfile?.user?.profile?.nextOfKin?.length!) {
                // !kin?.middleName && delete kin.middleName
                // !kin?.email && delete kin.email
                if (!kin?.email) {
                  kin.email = null
                }

                tempKin.contacts.push(kin)
              }
            })

            dispatch(BatchAddNextOfKin(tempKin))
          }
          //edit kin
          else if (
            patientProfile &&
            nextOfKin?.length ===
            patientProfile?.user?.profile?.nextOfKin?.length!
          ) {
            let tempcontacts: contacts[]
            let changed = false
            tempcontacts = []
            nextOfKin?.forEach((kin, i) => {
              if (
                kin.firstName !==
                patientProfile?.user?.profile?.nextOfKin![i]?.firstName ||
                kin.middleName !==
                patientProfile?.user?.profile?.nextOfKin![i]?.middleName ||
                kin.lastName !==
                patientProfile?.user?.profile?.nextOfKin![i]?.lastName ||
                kin.email !==
                patientProfile?.user?.profile?.nextOfKin![i]?.email ||
                kin.phone !==
                patientProfile?.user?.profile?.nextOfKin![i]?.phone ||
                kin.relationship !==
                patientProfile?.user?.profile?.nextOfKin![i]?.relationship
              ) {
                //data changed
                changed = true
                delete kin.userProfileId
                delete kin.address
                //delete optional fields if empty
                // !kin?.middleName && delete kin.middleName
                // !kin?.email && delete kin.email
                if (!kin?.email) {
                  kin.email = null
                }
                tempcontacts.push(kin)
              }
            })
            if (changed) {
              dispatch(BatchEditNextOfKin({ contacts: tempcontacts }))
            }
          }
        }
      }
    },
  })

  const HandleVital = () => {
    history.push({
      pathname: "/vitalsHistory",
      state: { id: patientProfile?.id },
    })
  }

  const toggleRemove = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const onConfirm = () => {
    if (nextOfKin[deleteIndex]?.id) {
      dispatch(DeleteNextOfKin({ id: nextOfKin[deleteIndex].id! }))
    } else {
      let newKin = [...nextOfKin]
      newKin.splice(deleteIndex, 1)
      setNextOfKin(newKin)

      let list = [...nextOfKinErrors]
      list.splice(deleteIndex, 1)
      setNextOfKinErrors(list)
      toggleRemove()
    }
  }
  return (
    <React.Fragment>
      {access ?
        <div>
          <ResetPassword
            isOpen={resetPasswordModel}
            toggle={toggleResetPasswordModal}
            userId={`${patientProfile?.userId}`}
          />
          {showConfirmationModal && (
            <ConfirmationModal
              toggle={toggleRemove}
              isOpen={true}
              onConfirm={onConfirm}
              text={"next of kin"}
            />
          )}
          {confirmPasswordResetModal && (
            <ConfirmPasswordResetModal
              isOpen={confirmPasswordResetModal}
              toggle={toggleConfirmPasswordResetModal}
              dataResetPass={dataResetPass}
            />
          )}
          <div className="page-content ms-3">
            {false ? ( //TODO in case we need loading
              <div
                style={{
                  width: "100%",
                  minHeight: "500px",
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                }}
              >
                <Spinner
                  style={{
                    color: "#2a45cd",
                    alignSelf: "center",
                    width: "70px",
                    height: "70px",
                  }}
                  animation="border"
                  variant="primary"
                />
              </div>
            ) : (
              <div>
                <Form
                  className="form-horizontal col-md-12"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <div className="d-sm-flex flex-wrap head-content">
                    {!addPage ? (
                      <div className="d-flex flex-row">
                        <Avatar
                          name={`${patientProfile?.user?.profile.firstName} ${patientProfile?.user?.profile.lastName}`}
                          maxInitials={2}
                          textSizeRatio={2.7}
                          size="40"
                          color="#2A45CD30"
                          fgColor="#2A45CD"
                          round
                        />
                        {patientProfile && patientProfile !== null && (
                          <h2 className="ms-2">
                            <span className="sbl24">
                              {`${patientProfile?.user?.profile.firstName !== null
                                ? patientProfile?.user?.profile.firstName
                                : ""
                                } ${patientProfile?.user?.profile.middleName !== null
                                  ? patientProfile?.user?.profile.middleName
                                  : ""
                                } ${patientProfile?.user?.profile.lastName}`}
                            </span>
                          </h2>
                        )}
                        <h5
                          className="ms-3 text-primary my-auto im14"
                          style={{ cursor: 'pointer' }}
                          onClick={HandleVital}
                        >
                          View patient vitals{" "}
                        </h5>
                      </div>
                    ) : (
                      <h2 className="mb-3">
                        <b>Add new patient</b>
                      </h2>
                    )}
                    <div className="ms-auto">
                      <button
                        type="button"
                        className="discard-btn px-3"
                        onClick={() => {
                          validation.setFieldValue('firstName', addPage ? '' : patientProfile?.user?.profile?.firstName)
                          validation.setFieldValue('middleName', addPage ? '' : patientProfile?.user?.profile?.middleName)
                          validation.setFieldValue('lastName', addPage ? '' : patientProfile?.user?.profile?.lastName)
                          validation.setFieldValue('address', addPage ? '' : patientProfile?.user?.profile?.address)
                          validation.setFieldValue('username', addPage ? '' : patientProfile?.user?.username)
                          validation.setFieldValue('birthDate', addPage ? '' : patientProfile?.user?.profile?.birthDate)
                          validation.setFieldValue('gender', addPage ? '' : patientProfile?.user?.profile?.gender)

                          let p = false
                          let e = false;

                          //set identifiers
                          patientProfile?.user?.identifiers?.forEach((element: IdentifiersType) => {
                            if (element.type === "EMAIL") {
                              if (addPage) {
                                setEmail('')
                                setEmailID('')
                              } else {
                                setEmail(element.identifier)
                                setEmailID(element.id)
                              }
                              e = true
                            }
                            if (element.type === "PHONE_NUMBER") {
                              if (addPage) {
                                setPhoneNumber('')
                                setPhoneNumberID('')
                              } else {
                                setPhoneNumber(element.identifier)
                                setPhoneNumberID(element.id)
                              }
                              p = true
                            }
                          })
                          !p && setPhoneNumber('');
                          !e && setEmail('');

                          validation.setFieldValue('phoneNumber', phoneNumber)
                          //set kin
                          let newKin: contacts[] = []
                          let list: any = []

                          patientProfile?.user?.profile?.nextOfKin?.forEach(
                            (kin: contacts, index: number) => {
                              const body = {
                                // email: false,
                                firstName: false,
                                lastName: false,
                                phone: false,
                                relationship: false,
                              }

                              newKin.push(kin)
                              list.push(body)
                            }
                          )
                          setNextOfKinErrors(list)
                          setNextOfKin(newKin)
                        }}
                      >
                        <h6 className="mt-2">
                          <span className="my-auto">Discard</span>
                        </h6>
                      </button>
                    </div>
                    {editPatient && (
                      <div>
                        <button
                          className={(loading ||
                            updateLoading ||
                            loadingKinBatch ||
                            loadingKinBatchEdit ||
                            loadingIdentifier ||
                            loadingAddIdentifier ||
                            loadingDeleteIdentifier) ?
                            "btn btn-primary ms-3 pointer-none" : "btn btn-primary ms-3"
                          }
                          type="submit">
                          Save changes
                          {(loading ||
                            updateLoading ||
                            loadingKinBatch ||
                            loadingKinBatchEdit ||
                            loadingIdentifier ||
                            loadingAddIdentifier ||
                            loadingDeleteIdentifier) && (
                              <i className="bx bx-loader bx-spin font-size-14 ms-2" />
                            )}
                        </button>
                      </div>
                    )}
                  </div>
                  {!addPage && (
                    <h4 className="mt-3 mb-3">
                      <span className="sb font-size-18">Edit patient information</span>
                    </h4>
                  )}
                  <div className="horizontal-line"></div>
                  {updatedDataLoading ? (
                    <div
                      style={{
                        width: "100%",
                        minHeight: "500px",
                        display: "flex",
                        flex: "1",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner
                        style={{
                          color: "#2a45cd",
                          alignSelf: "center",
                          width: "70px",
                          height: "70px",
                        }}
                        animation="border"
                        variant="primary"
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-4 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">
                              First Name<span className="text-danger">*</span>
                            </h5>
                          </div>

                          <div className="col-md-6 mt-3">
                            <Input
                              className={
                                editPatient
                                  ? "form-control input d-flex align-self-center"
                                  : "form-control input d-flex align-self-center pointer-none"
                              }
                              name="firstName"
                              placeholder="Enter first name"
                              type="text"
                              onChange={validation.handleChange}
                              value={validation.values.firstName || ""}
                              invalid={
                                validation.touched.firstName &&
                                  validation.errors.firstName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.firstName &&
                              validation.errors.firstName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.firstName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">
                              Middle Name
                            </h5>
                          </div>

                          <div className="col-md-6 mt-1">
                            <Input
                              name="middleName"
                              className={
                                editPatient
                                  ? "form-control input d-flex align-self-center"
                                  : "form-control input d-flex align-self-center pointer-none"
                              }
                              placeholder="Enter middle name"
                              type="text"
                              onChange={validation.handleChange}
                              value={validation.values.middleName || ""}
                              invalid={
                                validation.touched.middleName &&
                                  validation.errors.middleName
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">Last Name<span className="text-danger">*</span> </h5>
                          </div>

                          <div className="col-md-6 mt-1">
                            <Input
                              name="lastName"
                              className={
                                editPatient
                                  ? "form-control input d-flex align-self-center"
                                  : "form-control input d-flex align-self-center pointer-none"
                              }
                              placeholder="Enter last name"
                              type="text"
                              onChange={validation.handleChange}
                              value={validation.values.lastName || ""}
                              invalid={
                                validation.touched.lastName &&
                                  validation.errors.lastName
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.lastName &&
                              validation.errors.lastName ? (
                              <FormFeedback type="invalid">
                                {validation.errors.lastName}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 email-margin">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">Email</h5>
                          </div>

                          <div className="col-md-6 mt-1">
                            <Input
                              name="email"
                              className={
                                editPatient
                                  ? "form-control input d-flex align-self-center"
                                  : "form-control input d-flex align-self-center pointer-none"
                              }
                              placeholder="Enter email"
                              type="text"
                              invalid={emailError}
                              onChange={e => {
                                setEmail(e.target.value)
                                if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${e.target.value}`) && e.target.value!?.length > 0) {
                                  setEmailError(true)
                                } else {
                                  setEmailError(false)
                                }
                              }}
                              value={email}
                            />
                            {emailError &&
                              <p className="font-size-10 text-danger pt-1">
                                Invalid Email Address
                              </p>
                            }
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">
                              Phone Number <span className="text-danger">*</span>
                            </h5>
                          </div>

                          <div className="col-md-6 mt-1">
                            <Input
                              name="phoneNumber"
                              className={
                                editPatient
                                  ? "form-control input d-flex align-self-center"
                                  : "form-control input d-flex align-self-center pointer-none"
                              }
                              placeholder="Enter phone number"
                              type="text"
                              onChange={e => {
                                setPhoneNumber(e.target.value)
                              }}
                              value={phoneNumber}
                              invalid={
                                validation.touched.phoneNumber &&
                                  validation.errors.phoneNumber
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phoneNumber &&
                              validation.errors.phoneNumber ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phoneNumber}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">
                              Address
                            </h5>
                          </div>

                          <div className="col-md-6 mt-1">
                            <Input
                              name="address"
                              className={
                                editPatient
                                  ? "form-control input d-flex align-self-center"
                                  : "form-control input d-flex align-self-center pointer-none"
                              }
                              placeholder="Enter address"
                              type="text"
                              onChange={validation.handleChange}
                              value={validation.values.address || ""}
                              invalid={
                                validation.touched.address &&
                                  validation.errors.address
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address &&
                              validation.errors.address ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">Gender<span className="text-danger">*</span>  </h5>
                          </div>
                          <div className="col-md-6 mt-1">
                            <Input
                              type="select"
                              name="gender"
                              className={editPatient ? "" : " pointer-none"}
                              id="gender"
                              onChange={validation.handleChange}
                              value={validation.values.gender || ""}
                              invalid={
                                validation.touched.gender &&
                                  validation.errors.gender
                                  ? true
                                  : false
                              }
                            >
                              <option value="">Select Gender</option>
                              <option value="MALE">Male</option>
                              <option value="FEMALE">Female</option>
                              <option value="OTHER">Other</option>
                            </Input>
                            {validation.touched.gender &&
                              validation.errors.gender ? (
                              <FormFeedback type="invalid">
                                {validation.errors.gender}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">
                              Date of birth<span className="text-danger">*</span>
                            </h5>
                          </div>
                          <div className="col-md-6 mt-1">
                            <DatePicker
                              id="birthDate"
                              dateFormat="DD/MM/YYYY"
                              placeholder="DD/MM/YYYY"
                              showClearButton={false}
                              style={
                                validation.touched.birthDate &&
                                validation.errors.birthDate && { border: '1px #f46a6a solid' }
                              }
                              className={
                                editPatient
                                  ? "rounded"
                                  : "rounded pointer-none"
                              }
                              value={validation.values.birthDate || ""}
                              onChange={(e: string) => validation.setFieldValue('birthDate', e)}
                              invalid={
                                validation.touched.birthDate &&
                                  validation.errors.birthDate
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.birthDate &&
                              validation.errors.birthDate ? (
                              <FormFeedback type="invalid">
                                {validation.errors.birthDate}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="horizontal-line"></div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12  mt-3">
                        <div className="d-flex flex-row justify-content-space-between">
                          <div className="mt-3 mb-3 col-md-6">
                            <h5 className="d-flex align-self-center im14">
                              {addPage ? "Temporary username" : "Username"} <span className="text-danger">*</span>
                            </h5>
                          </div>

                          <div
                            className="col-md-6 mt-2"
                            style={{ display: "flex" }}
                          >
                            <div className="col-md-12">
                              <Input
                                name="username"
                                className="form-control  input d-flex align-self-center"
                                placeholder="Enter username"
                                type="text"
                                disabled={true}
                                onChange={validation.handleChange}
                                value={validation.values.username || ""}
                                valid={
                                  addPage && validation.values.username?.length > 0
                                }
                                invalid={
                                  validation.touched.username &&
                                    validation.errors.username
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.username &&
                                validation.errors.username ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.username}
                                </FormFeedback>
                              ) : null}
                              {!validation.touched.username &&
                                validation.values.username?.length > 0 &&
                                !validation.errors.username && (
                                  <FormFeedback valid>
                                    Username is available
                                  </FormFeedback>
                                )}
                            </div>
                            {addPage && (
                              <a
                                style={{
                                  marginLeft: "20px",
                                  marginTop: "1.5%",
                                  color: "#2A45CD",
                                  fontSize: "14px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  dispatch(
                                    GenerateUsername({
                                      firstName: validation.values.firstName,
                                      lastName: validation.values.lastName,
                                    })
                                  )
                                }}
                              >
                                Generate
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* Password Inputs */}

                      {!addPage && (
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                          <div className="d-flex flex-row justify-content-space-between">
                            <div className="mt-3 mb-3 col-md-6">
                              <h5 className="d-flex align-self-center im14">Password</h5>
                            </div>
                            <div>
                              <button
                                type="button"
                                className={
                                  editPatient
                                    ? "discard-btn px-3"
                                    : "discard-btn px-3 pointer-none"
                                }
                                onClick={() => {
                                  setDataResetPass({
                                    mode: "PATIENT",
                                    userId: `${patientProfile?.userId}`,
                                    email: email ? email : undefined,
                                    phone: phoneNumber ? phoneNumber : undefined,
                                  })
                                  setConfirmPasswordResetModal(true)
                                }}
                              >
                                <h6 className="d-flex flex-row pt-2">
                                  <i className="bx bx-link-alt link-icon"></i>

                                  <b className="my-auto font-size-12 ms-1 sb">
                                    Send password reset link
                                  </b>
                                </h6>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-sm-flex flex-wrap head-content mt-3">
                        <h2 className="font-size-18">
                          <span className="sb font-size-18">Next of kin information</span>
                        </h2>
                      </div>
                      <div className="horizontal-line mt-2"></div>
                      {nextOfKin.map((kin: any, i: number) => (
                        <div key={i}>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center im14">
                                  First name<span className="text-danger">*</span>
                                </h5>
                              </div>

                              <div className="col-md-6 mt-3 mb-3">
                                <Input
                                  name="role"
                                  className={
                                    editPatient
                                      ? "form-control input d-flex align-self-center"
                                      : "form-control input d-flex align-self-center pointer-none"
                                  }
                                  placeholder="Enter first name"
                                  type="text"
                                  disabled={addPage}
                                  onChange={e => {
                                    let kin = JSON.parse(JSON.stringify(nextOfKin))
                                    kin[i].firstName = e.target.value

                                    setNextOfKin(kin)

                                    let errors = [...nextOfKinErrors]

                                    if (e.target.value === "" || !e.target.value.replace(/\s/g, '').length) {
                                      errors[i].firstName = true
                                    } else {
                                      errors[i].firstName = false
                                    }
                                    setNextOfKinErrors(errors)
                                  }}
                                  invalid={
                                    nextOfKinErrors[i]?.firstName ? true : false
                                  }
                                  value={nextOfKin[i]?.firstName}
                                />
                                {/* {kin[i]?.firstName &&
                              <span className="font-size-10 text-danger pt-1">
                                Only spaces are not allowed
                              </span>
                            } */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center im14">
                                  Middle name
                                </h5>
                              </div>

                              <div className="col-md-6 mt-3 mb-3">
                                <Input
                                  name="role"
                                  className={
                                    editPatient
                                      ? "form-control input d-flex align-self-center"
                                      : "form-control input d-flex align-self-center pointer-none"
                                  }
                                  placeholder="Enter middle name"
                                  type="text"
                                  disabled={addPage}
                                  onChange={e => {
                                    let kin = JSON.parse(JSON.stringify(nextOfKin))
                                    kin[i].middleName = e.target.value
                                    setNextOfKin(kin)
                                  }}
                                  value={nextOfKin[i].middleName}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center im14">
                                  Last name <span className="text-danger">*</span>
                                </h5>
                              </div>

                              <div className="col-md-6 mt-3 mb-3">
                                <Input
                                  name="role"
                                  className={
                                    editPatient
                                      ? "form-control input d-flex align-self-center"
                                      : "form-control input d-flex align-self-center pointer-none"
                                  }
                                  placeholder="Enter last name"
                                  type="text"
                                  invalid={
                                    nextOfKinErrors[i]?.lastName ? true : false
                                  }
                                  disabled={addPage}
                                  onChange={e => {
                                    let kin = JSON.parse(JSON.stringify(nextOfKin))

                                    kin[i].lastName = e.target.value
                                    setNextOfKin(kin)

                                    let errors = [...nextOfKinErrors]

                                    if (e.target.value === "" || !e.target.value.replace(/\s/g, '').length) {
                                      errors[i].lastName = true
                                    } else {
                                      errors[i].lastName = false
                                    }
                                    setNextOfKinErrors(errors)
                                  }}
                                  value={nextOfKin[i].lastName}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center im14">
                                  Phone Number <span className="text-danger">*</span>
                                </h5>
                              </div>

                              <div className="col-md-6 mt-2">
                                <Input
                                  name="role"
                                  className={
                                    editPatient
                                      ? "form-control input d-flex align-self-center"
                                      : "form-control input d-flex align-self-center pointer-none"
                                  }
                                  placeholder="Enter phone number"
                                  type="text"
                                  disabled={addPage}
                                  invalid={nextOfKinErrors[i]?.phone ? true : false}
                                  onChange={e => {
                                    let kin = JSON.parse(JSON.stringify(nextOfKin))

                                    kin[i].phone = e.target.value
                                    setNextOfKin(kin)

                                    let errors = [...nextOfKinErrors]
                                    let check = isValidPhoneNumber(e.target.value)
                                    if (e.target.value === "" || !check) {
                                      errors[i].phone = true
                                    } else {
                                      errors[i].phone = false
                                    }
                                    setNextOfKinErrors(errors)
                                  }}
                                  value={nextOfKin[i].phone}
                                />
                                {!isValidPhoneNumber(nextOfKin[i].phone) &&
                                  nextOfKin[i]?.phone?.length > 0 && (
                                    <p className="font-size-10 text-danger pt-1">
                                      Invalid Phone Number
                                    </p>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center im14">
                                  Relationship <span className="text-danger">*</span>
                                </h5>
                              </div>

                              <div className="col-md-6 mt-3 mb-3">
                                <Input
                                  type="select"
                                  name="role"
                                  id="role"
                                  className={editPatient ? "" : "pointer-none"}
                                  disabled={addPage}
                                  invalid={
                                    nextOfKinErrors[i]?.relationship ? true : false
                                  }
                                  onChange={e => {
                                    let kin = JSON.parse(JSON.stringify(nextOfKin))

                                    kin[i].relationship = e.target.value
                                    setNextOfKin(kin)

                                    let errors = [...nextOfKinErrors]

                                    if (e.target.value === "") {
                                      errors[i].relationship = true
                                    } else {
                                      errors[i].relationship = false
                                    }
                                    setNextOfKinErrors(errors)
                                  }}
                                  value={nextOfKin[i].relationship}
                                >
                                  <option value="">Select relation</option>
                                  <option value="PARENT">Parent</option>
                                  <option value="CHILD">Child</option>
                                  <option value="SIBLING">Sibling</option>
                                  <option value="SPOUSE">Spouse</option>
                                  <option value="GUARDIAN">Guardian</option>
                                </Input>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="d-flex flex-row justify-content-space-between">
                              <div className="mt-3 mb-3 col-md-6">
                                <h5 className="d-flex align-self-center im14">Email</h5>
                              </div>

                              <div className="col-md-6 mt-2">
                                <Input
                                  name="email"
                                  className={
                                    editPatient
                                      ? "form-control input d-flex align-self-center"
                                      : "form-control input d-flex align-self-center pointer-none"
                                  }
                                  placeholder="Enter email"
                                  disabled={addPage}
                                  type="text"
                                  invalid={nextOfKinErrors[i]?.email ? true : false}
                                  onChange={e => {
                                    let kin = JSON.parse(JSON.stringify(nextOfKin))

                                    kin[i].email = e.target.value
                                    let data = [...nextOfKinErrors]

                                    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${kin[i].email}`) && kin[i]?.email!?.length > 0) {
                                      data[i].email = true
                                    } else {
                                      data[i].email = false
                                    }
                                    setNextOfKinErrors(data)
                                    setNextOfKin(kin)

                                  }
                                  }
                                  value={nextOfKin[i].email || ''}
                                />
                                {!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                  `${nextOfKin[i].email}`
                                ) && nextOfKin[i]?.email!?.length > 0 ? (
                                  <p className="font-size-10 text-danger pt-1">
                                    Invalid Email Address
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <button
                            type="button"
                            style={{ width: "170px" }}
                            className={
                              editPatient
                                ? "discard-btn px-3 mt-3"
                                : "discard-btn px-3 mt-3 pointer-none"
                            }
                            disabled={addPage || loadingDeleteKin}
                            onClick={() => {
                              toggleRemove()
                              setDeleteIndex(i)
                            }}
                          >
                            <h6 className="d-flex flex-row pt-2">
                              <i
                                className={
                                  addPage
                                    ? "bx bx-trash-alt trash-icon  text-muted"
                                    : "bx bx-trash-alt trash-icon"
                                }
                              ></i>

                              <b
                                className={
                                  addPage
                                    ? "my-auto font-size-12 sb ms-1  text-muted"
                                    : "my-auto font-size-12 sb ms-1"
                                }
                              >
                                Remove next of kin
                              </b>
                            </h6>
                          </button>
                          <div className="horizontal-line mt-2"></div>
                        </div>
                      ))}

                      <button
                        type="button"
                        style={{ width: "170px" }}
                        className={
                          editPatient
                            ? "discard-btn px-3 mt-3"
                            : "discard-btn px-3 mt-3 pointer-none"
                        }
                        disabled={addPage}
                        onClick={() => {
                          let newKin = [...nextOfKin]
                          newKin.push({
                            firstName: "",
                            middleName: "",
                            lastName: "",
                            relationship: "",
                            phone: "",
                            email: "",
                          })
                          let list = [...nextOfKinErrors]

                          const body = {
                            email: false,
                            firstName: false,
                            lastName: false,
                            phone: false,
                            relationship: false,
                          }
                          list.push(body)
                          setNextOfKinErrors(list)
                          setNextOfKin(newKin)
                        }}
                      >
                        <h6 className="d-flex flex-row pt-2">
                          <i
                            className={
                              addPage
                                ? "bx bx-user-plus kin-icon  text-muted"
                                : "bx bx-user-plus kin-icon"
                            }
                          ></i>

                          <b
                            className={
                              addPage
                                ? "my-auto font-size-12 sb ms-1  text-muted"
                                : "my-auto font-size-12 sb ms-1"
                            }
                          >
                            Add next of kin
                          </b>
                        </h6>
                      </button>
                    </div>
                  )}
                </Form>
              </div>
            )}
          </div>
        </div> : <div className="permissionWarning">User does not have permissions to view this screen!</div>}
    </React.Fragment>
  )
}

export default AddEditPatientManagement
