import React, { FC, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Card, Form, Label, Input, FormFeedback } from "reactstrap"
import LogoSvg from "../../assets/images/svg/LogoWhite.svg"
import backgroundSvg from "../../assets/images/png/background1.png"
import backgroundCropedSvg from "../../assets/images/png/background_swipe.png"
import DatePicker from 'reactstrap-date-picker'
import "./login.scss"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { CheckCredentialsAvailable, PractitionerSelfSignUp } from "~/store/reducers/AuthSlice/thunks"
import { isValidPhoneNumber } from "libphonenumber-js"
import { errorToast, successToast } from "~/utils/toast"
import { ResetCheckCredentials, ResetPractitionerSelfSignUp } from "~/store/reducers/AuthSlice"
import moment from "moment"

const Signup: FC = () => {
    const history = useHistory()
    const params: { id: string } = useParams();
    const { loadingCheckCredentials, successCheckCredentials, errorCheckCredentials,
        loadingSelfSignup, successSelfSignup, errorSelfSignup } = useAppSelector(
            state => state.AuthReducer
        )
    const [showPass, setShowPass] = useState<boolean>(false)
    const [showPass2, setShowPass2] = useState<boolean>(false)
    const [resetPassword, setResetPassword] = useState<number>(0)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (successCheckCredentials) {
            successToast('Credentials available, please provide your personal info and continue!')
            setResetPassword(1)
            dispatch(ResetCheckCredentials())
        } else if (errorCheckCredentials) {
            errorToast(errorCheckCredentials)
            dispatch(ResetCheckCredentials())
        }
    }, [successCheckCredentials, errorCheckCredentials])
    useEffect(() => {
        if (successSelfSignup) {
            successToast('Account created successfully, Please contact your administrator for verification!')
            history.push('/login')
            dispatch(ResetPractitionerSelfSignUp())
        } else if (errorSelfSignup) {
            errorToast(errorSelfSignup)
            dispatch(ResetPractitionerSelfSignUp())
        }
    }, [successSelfSignup, errorSelfSignup])

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            username: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            username: Yup.string().min(5, 'Too short!').max(20, 'Too long!').matches(
                /^(?!\d)(?!.*-.*-)(?!.*-$)(?!-)[a-zA-Z0-9-]{5,20}$/,
                "Username must start with a letter. Contains only letters, numbers, and dashes or hyphens."
            ).required("Please enter username"),
            password: Yup.string()
                .min(8, "Password too Short!")
                .required("Please enter your password"),
            confirmPassword: Yup.string()
                .test('Check if password same',
                    'Confirm password must be same as new password',
                    e => {
                        if (validation.values.password === e)
                            return true
                        else return false
                    }
                )
                .min(8, "Password too short!")
                .required("Please enter your password"),
        }),
        onSubmit: values => {
            dispatch(CheckCredentialsAvailable({
                username: values.username,
                password: values.password
            }))
        },
    })


    const validationPersonalForm = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            gender: "UNKNOWN",
            dob: "",
            termsConditions: false,
        },
        validationSchema: Yup.object({
            firstName: Yup.string().matches(
                /^(?=.*\S).+$/,
                "Only spaces are not allowed"
              ).required("Please enter first name"),
            lastName: Yup.string().matches(
                /^(?=.*\S).+$/,
                "Only spaces are not allowed"
              ).required("Please enter last name"),
            phone: Yup.string().test('VALIDITY CHECK', 'Invalid phone number', e => e ? isValidPhoneNumber(e) : false).required("Please enter phone number"),
            dob: Yup.string().test(
                "Only past dates check",
                "Date cannot be in future.",
                e => {
                    if (e) {
                        return new Date(e) < new Date()
                    } else {
                        return true
                    }
                }
            ),
            termsConditions: Yup.boolean().oneOf([true], 'Please agree to Terms and Conditions in order to continue'),
        }),
        onSubmit: values => {
            setResetPassword(2)
        },
    })
    const validationKin = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            relation: "",
            address: "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().matches(
                /^(?=.*\S).+$/,
                "Only spaces are not allowed"
              ).required("Please enter first name"),
            lastName: Yup.string().matches(
                /^(?=.*\S).+$/,
                "Only spaces are not allowed"
              ).required("Please enter last name"),
            phone: Yup.string().test('VALIDITY CHECK', 'Invalid phone number', e => (e && isValidPhoneNumber(e)) || false).required("Please enter phone number"),
            relation: Yup.string().required("Please select relation"),
            address: Yup.string(),
        }),
        onSubmit: values => {
            dispatch(PractitionerSelfSignUp({
                username: validation?.values.username,
                organizationId: params?.id,
                profile: {
                    firstName: validationPersonalForm?.values.firstName,
                    lastName: validationPersonalForm?.values.lastName,
                    gender: validationPersonalForm?.values.gender,
                    birthDate: validationPersonalForm?.values.dob ? moment.utc(new Date(validationPersonalForm?.values.dob)).toISOString() : undefined,
                },
                password: validation?.values.password,
                identifiers: {
                    email: validationPersonalForm?.values.email ? validationPersonalForm?.values.email : undefined,
                    phone: validationPersonalForm?.values.phone ? validationPersonalForm?.values.phone : undefined
                },
                nextOfKin: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    relationship: values.relation,
                    phone: values.phone,
                    email: values.email ? values.email : undefined,
                    address: values.address ? values.address : undefined,
                }
            }))
        },
    })

    return (
        <React.Fragment>
            <div className="bg-img bg-primary" />
            <img src={resetPassword === 0 ? backgroundSvg : backgroundCropedSvg} alt="" className="bg-img" />
            <div className="col-md-10 mt-5 offset-1 card-container">
                <div className="content">
                    <div>
                        <div className="logoContainer">
                            <div className="logoContent">
                                <img src={LogoSvg} alt="" height="36" />
                                <p
                                    className="logoText align-self-center ms-1 text-white sbl"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    ILARA HEALTH
                                </p>
                            </div>
                        </div>
                        <h5 className="ms-2 mt-2 text-white">Patient Monitoring App</h5>
                        <h5 className="ms-2 mb-4 text-white">Admin Panel</h5>
                    </div>

                    <div className="right-text">
                        <h2 className="text-is-white">
                            A suite of proprietary digital
                        </h2>
                        <h2 className="text-is-white">
                            health tools to digitize our
                        </h2>
                        <h2 className="text-is-white">clinic partners</h2>
                    </div>
                </div>

                {resetPassword === 0 && (
                    <div className="col-sm-4 pe-5">
                        <Card className="p-3 shadow-lg ">
                            <h5 className="mt-2 text-head">
                                <span className="sb font-size-18">Sign up</span>
                            </h5>
                            <h6 className="text-muted">
                                Set up your account to access the admin panel
                            </h6>
                            <Form
                                className="form-horizontal"
                                id="loginForm"
                                onSubmit={e => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >
                                <div className="my-2">
                                    <Label className="form-label im">Username</Label>
                                    <Input
                                        name="username"
                                        className="form-control"
                                        placeholder="Enter Username"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.username || ""}
                                        invalid={
                                            validation.touched.username && validation.errors.username
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.username && validation.errors.username ? (
                                        <div className="error-container mt-1 mb-1">
                                            <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                                            <h6 className="text-danger mt-2 ms-2">
                                                {validation.errors.username}
                                            </h6>
                                        </div>
                                    ) : null}
                                </div>

                                <div className="mb-2">
                                    <Label className="form-label im">Password</Label>
                                    <div className="passwordInput">
                                        <Input
                                            name="password"
                                            value={validation.values.password || ""}
                                            type={showPass ? "text" : "password"}
                                            placeholder="Enter Password"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            className={
                                                validation.touched.password &&
                                                    validation.errors.password
                                                    ? "form-control border-danger"
                                                    : "form-control"
                                            }
                                        />
                                        {showPass ? (
                                            <i
                                                onClick={() => setShowPass(false)}
                                                className="fas fa-eye-slash eyeIcon"
                                            />
                                        ) : (
                                            <i
                                                onClick={() => setShowPass(true)}
                                                className="fas fa-eye eyeIcon"
                                            />
                                        )}
                                    </div>
                                    {validation.touched.password && validation.errors.password ? (
                                        <div className="error-container mt-1 mb-1">
                                            <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                                            <h6 className="text-danger mt-2 ms-2">
                                                {validation.errors.password}
                                            </h6>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label className="form-label im">Confirm password</Label>
                                    <div className="passwordInput">
                                        <Input
                                            name="confirmPassword"
                                            value={validation.values.confirmPassword || ""}
                                            type={showPass2 ? "text" : "password"}
                                            placeholder="Re-enter password"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            className={
                                                validation.touched.confirmPassword &&
                                                    validation.errors.confirmPassword
                                                    ? "form-control border-danger"
                                                    : "form-control"
                                            }
                                        />
                                        {showPass2 ? (
                                            <i
                                                onClick={() => setShowPass2(false)}
                                                className="fas fa-eye-slash eyeIcon"
                                            />
                                        ) : (
                                            <i
                                                onClick={() => setShowPass2(true)}
                                                className="fas fa-eye eyeIcon"
                                            />
                                        )}
                                    </div>
                                    {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                                        <div className="error-container mt-1 mb-1">
                                            <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                                            <h6 className="text-danger mt-2 ms-2">
                                                {validation.errors.confirmPassword}
                                            </h6>
                                        </div>
                                    ) : null}
                                </div>

                                {errorCheckCredentials && errorCheckCredentials !== "" && (
                                    <div className="error-container mt-1 mb-1">
                                        <i className="fas fa-times-circle error-icon d-flex align-self-center ms-2" />
                                        <h6 className="text-danger mt-2 ms-2">{errorCheckCredentials}</h6>
                                    </div>
                                )}
                                <div className="mt-3 d-grid">
                                    <button
                                        className="btn btn-primary btn-block "
                                        disabled={loadingCheckCredentials}
                                        type="submit">
                                        Continue
                                        {loadingCheckCredentials && (
                                            <i className="bx bx-loader bx-spin font-size-16 loader" />
                                        )}
                                    </button>
                                </div>
                                <div className="d-flex justify-content-center mt-4">
                                    <Label className="form-label im">Already have an account?</Label>
                                    <button
                                        className="bg-transparent border-0"
                                        type="button"
                                        onClick={() => history.push('/login')}
                                    >
                                        <h6 className="text-primary im">Log in</h6>
                                    </button>
                                </div>
                            </Form>
                        </Card>
                    </div>
                )}
                {resetPassword === 1 && (
                    <div className="col-sm-6">
                        <Card className="p-3 shadow-lg ">
                            <h5 className="mt-2 text-head">
                                <span className="sb font-size-18">Personal details</span>
                            </h5>
                            <h6 className="text-muted im">
                                Complete your profile to create account and gain access to the
                                dashboard
                            </h6>
                            {/* <div className="d-flex flex-row"> */}
                            <Form
                                id="personalInfo"
                                className="form-horizontal"
                                onSubmit={e => {
                                    e.preventDefault()
                                    validationPersonalForm.handleSubmit()
                                    return false
                                }}>
                                <div className="row mt-2">
                                    <div className="col-sm-6">
                                        <h6 className="im">First name*</h6>
                                        <Input
                                            className={"form-control input-next"}
                                            name="firstName"
                                            placeholder="Enter first name"
                                            type="text"
                                            onChange={validationPersonalForm.handleChange}
                                            value={validationPersonalForm.values.firstName || ""}
                                            invalid={
                                                validationPersonalForm.touched.firstName && validationPersonalForm.errors.firstName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationPersonalForm.touched.firstName && validationPersonalForm.errors.firstName ? (
                                            <FormFeedback type="invalid">
                                                {validationPersonalForm.errors.firstName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6">
                                        <h6>Last name*</h6>
                                        <Input
                                            className={"form-control input-next "}
                                            name="lastName"
                                            placeholder="Enter last name"
                                            type="text"
                                            onChange={validationPersonalForm.handleChange}
                                            value={validationPersonalForm.values.lastName || ""}
                                            invalid={
                                                validationPersonalForm.touched.lastName && validationPersonalForm.errors.lastName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationPersonalForm.touched.lastName && validationPersonalForm.errors.lastName ? (
                                            <FormFeedback type="invalid">
                                                {validationPersonalForm.errors.lastName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                {/* </div> */}
                                <div className="row mt-2">
                                    <div className="col-sm-6">
                                        <h6 className="im">Phone number*</h6>
                                        <Input
                                            className={"form-control input-next"}
                                            name="phone"
                                            placeholder="Enter phone number"
                                            type="text"
                                            onChange={validationPersonalForm.handleChange}
                                            value={validationPersonalForm.values.phone || ""}
                                            invalid={
                                                validationPersonalForm.touched.phone && validationPersonalForm.errors.phone
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationPersonalForm.touched.phone && validationPersonalForm.errors.phone ? (
                                            <FormFeedback type="invalid">
                                                {validationPersonalForm.errors.phone}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="im">Email</h6>
                                        <Input
                                            className={"form-control input-next "}
                                            name="email"
                                            placeholder="Enter email address"
                                            type="email"
                                            onChange={validationPersonalForm.handleChange}
                                            value={validationPersonalForm.values.email || ""}
                                            invalid={
                                                validationPersonalForm.touched.email && validationPersonalForm.errors.email
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationPersonalForm.touched.email && validationPersonalForm.errors.email ? (
                                            <FormFeedback type="invalid">
                                                {validationPersonalForm.errors.email}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-6">
                                        <h6 className="im">Gender</h6>
                                        <Input
                                            type="select"
                                            name="gender"
                                            onChange={validationPersonalForm.handleChange}
                                            value={validationPersonalForm.values.gender || ""}
                                            invalid={
                                                validationPersonalForm.touched.gender &&
                                                    validationPersonalForm.errors.gender
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <option value="UNKNOWN">Select Gender</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                            <option value="OTHER">Other</option>
                                        </Input>
                                        {validationPersonalForm.touched.gender &&
                                            validationPersonalForm.errors.gender ? (
                                            <FormFeedback type="invalid">
                                                {validationPersonalForm.errors.gender}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="im">Date of birth</h6>
                                        {/* <Input
                                            className={"form-control input-next "}
                                            name="dob"
                                            placeholder="Enter date of birth"
                                            type="date"
                                            onChange={validationPersonalForm.handleChange}
                                            value={validationPersonalForm.values.dob || ""}
                                            invalid={
                                                validationPersonalForm.touched.dob && validationPersonalForm.errors.dob
                                                    ? true
                                                    : false
                                            }
                                        /> */}
                                        <DatePicker
                                            id="dob"
                                            name="dob"
                                            dateFormat="DD/MM/YYYY"
                                            placeholder="DD/MM/YYYY"
                                            style={
                                                validationPersonalForm.touched.dob &&
                                                validationPersonalForm.errors.dob && { border: '1px #f46a6a solid' }
                                            }
                                            showClearButton={false}
                                            className='rounded input-next'
                                            value={validationPersonalForm.values.dob || ""}
                                            onChange={(e: string) => validationPersonalForm.setFieldValue('dob', e)}
                                            invalid={
                                                validationPersonalForm.touched.dob && validationPersonalForm.errors.dob
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationPersonalForm.touched.dob && validationPersonalForm.errors.dob ? (
                                            <FormFeedback type="invalid">
                                                {validationPersonalForm.errors.dob}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-check form-check-end mt-3">
                                    <input
                                        className="form-check-input"
                                        name='termsConditions'
                                        type="checkbox"
                                        checked={validationPersonalForm.values.termsConditions}
                                        onChange={validationPersonalForm.handleChange}
                                    />
                                    <h6 className="im">I accept the{' '}
                                        <span className="text-primary">{' '}
                                            Terms & Conditions
                                        </span>{' '}
                                        and{' '}
                                        <a href='https://ilarahealth.com/privacy-policy/' target="_blank" className="text-primary">
                                            Privacy Policy
                                        </a>
                                    </h6>
                                </div>
                                <div className="mb-2 d-grid col-sm-6 mt-3">
                                    <button
                                        className="btn btn-primary btn-block"
                                        type="submit"
                                    >
                                        Next
                                    </button>
                                </div>
                            </Form>
                        </Card>
                    </div>
                )}
                {resetPassword === 2 && (
                    <div className="col-sm-6">
                        <Card className="p-3 shadow-lg ">
                            <h5 className="mt-2 text-head">
                                <span className="sb font-size-18">Next of kin details</span>
                            </h5>
                            <h6 className="text-muted im">
                                Complete your profile to create account and gain access to the dashboard
                            </h6>
                            {/* <div className="d-flex flex-row"> */}
                            <Form
                                id="validationKin"
                                className="form-horizontal"
                                onSubmit={e => {
                                    e.preventDefault()
                                    validationKin.handleSubmit()
                                    return false
                                }}>
                                <div className="row mt-2">
                                    <div className="col-sm-6">
                                        <h6 className="im">First name*</h6>
                                        <Input
                                            className={"form-control input-next"}
                                            name="firstName"
                                            placeholder="Enter first name"
                                            type="text"
                                            onChange={validationKin.handleChange}
                                            value={validationKin.values.firstName || ""}
                                            invalid={
                                                validationKin.touched.firstName && validationKin.errors.firstName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationKin.touched.firstName && validationKin.errors.firstName ? (
                                            <FormFeedback type="invalid">
                                                {validationKin.errors.firstName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="im">Last name*</h6>
                                        <Input
                                            className={"form-control input-next "}
                                            name="lastName"
                                            placeholder="Enter last name"
                                            type="text"
                                            onChange={validationKin.handleChange}
                                            value={validationKin.values.lastName || ""}
                                            invalid={
                                                validationKin.touched.lastName && validationKin.errors.lastName
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationKin.touched.lastName && validationKin.errors.lastName ? (
                                            <FormFeedback type="invalid">
                                                {validationKin.errors.lastName}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                {/* </div> */}
                                <div className="row mt-2">
                                    <div className="col-sm-6">
                                        <h6 className="im">Phone number*</h6>
                                        <Input
                                            className={"form-control input-next"}
                                            name="phone"
                                            placeholder="Enter phone number"
                                            type="text"
                                            onChange={validationKin.handleChange}
                                            value={validationKin.values.phone || ""}
                                            invalid={
                                                validationKin.touched.phone && validationKin.errors.phone
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationKin.touched.phone && validationKin.errors.phone ? (
                                            <FormFeedback type="invalid">
                                                {validationKin.errors.phone}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="im">Email</h6>
                                        <Input
                                            className={"form-control input-next "}
                                            name="email"
                                            placeholder="Enter email address"
                                            type="email"
                                            onChange={validationKin.handleChange}
                                            value={validationKin.values.email || ""}
                                            invalid={
                                                validationKin.touched.email && validationKin.errors.email
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationKin.touched.email && validationKin.errors.email ? (
                                            <FormFeedback type="invalid">
                                                {validationKin.errors.email}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-sm-6">
                                        <h6 className="im">Relationship*</h6>
                                        <Input
                                            type="select"
                                            name="relation"
                                            onChange={validationKin.handleChange}
                                            value={validationKin.values.relation || ""}
                                            invalid={
                                                validationKin.touched.relation &&
                                                    validationKin.errors.relation
                                                    ? true
                                                    : false
                                            }
                                        >
                                            <option value="">Select relation</option>
                                            <option value="PARENT">Parent</option>
                                            <option value="CHILD">Child</option>
                                            <option value="SIBLING">Sibling</option>
                                            <option value="SPOUSE">Spouse</option>
                                            <option value="GUARDIAN">Guardian</option>
                                        </Input>
                                        {validationKin.touched.relation &&
                                            validationKin.errors.relation ? (
                                            <FormFeedback type="invalid">
                                                {validationKin.errors.relation}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="im">Address</h6>
                                        <Input
                                            className={"form-control input-next "}
                                            name="address"
                                            placeholder="Enter address"
                                            type="text"
                                            onChange={validationKin.handleChange}
                                            value={validationKin.values.address || ""}
                                            invalid={
                                                validationKin.touched.address && validationKin.errors.address
                                                    ? true
                                                    : false
                                            }
                                        />
                                        {validationKin.touched.address && validationKin.errors.address ? (
                                            <FormFeedback type="invalid">
                                                {validationKin.errors.address}
                                            </FormFeedback>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="mb-2 d-grid col-sm-6 mt-3">
                                    <button
                                        className="btn btn-primary btn-block"
                                        disabled={loadingSelfSignup}
                                        type="submit"
                                    >
                                        Create account
                                        {loadingSelfSignup && (
                                            <i className="bx bx-loader bx-spin font-size-16 loader" />
                                        )}
                                    </button>
                                </div>
                            </Form>
                        </Card>
                    </div>
                )}
            </div>
        </React.Fragment>
    )
}

export default Signup
