import React, { FC, useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Container, Row, Spinner,
} from "reactstrap"
import BPGraphs from "~/components/Common/Graphs/BPGraphs";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { aggregateDashboard } from "~/store/reducers/dashboard/thunk";
import { setSecondaryBarTrue, toggle } from "~/store/reducers/sideBar";
import '../BloodPressure/bloodPressure.scss'
import { Series } from "~/models/Org.model"
import Async from "~/utils/Async";
import moment from "moment";
import GraphFilter from "~/components/Common/Modals/Filter/GraphFilter";
import { setPreloader } from "~/store/reducers/loader";
import Highcharts from "highcharts/highstock"
import HC_more from "highcharts/highcharts-more"
HC_more(Highcharts)

type AgeBody = {
    categories: string[];
    data: number[];
}

type OptionType = {
    value: string | number
    label: string
    set?: boolean
}
const BloodSugar: FC = () => {
    const { collapsed } = useAppSelector(state => state.sideBarReducer)
    const dispatch = useAppDispatch();

    const [access, setAccess] = useState<boolean>(true);

    const [filterModel, setFilterModel] = useState<boolean>(false);
    const toggleFilterModal = () => setFilterModel(!filterModel);

    const [barGraph, setBarGraph] = useState<AgeBody>()
    const [genderData, setGenderData] = useState<any>([])
    const [genderGraphData, setGenderGraphData] = useState<any>({})

    const [bsGraph, setBsGraph] = useState<Series[]>([])
    const [bs, setBs] = useState<number>(0)


    const [tableData, setTableData] = useState<any>()

    const [dropValueBs, setDropValueBs] = useState<number>(0)
    const [subBs, setSubBs] = useState<number>(0)
    const [bsKpi, setBsKpi] = useState<number>(0)

    const [filterList, setFilterList] = useState<any>()
    const [tempFilterList, setTempFilterList] = useState<any>([])

    const [ageSum, setAgeSum] = useState<number>(0)

    //loadings
    const [genderLoading, setGenderLoading] = useState<boolean>(false)
    const [bsLoading, setBsLoading] = useState<boolean>(false)
    const [ageLoading, setAgeLoading] = useState<boolean>(false)
    const [bsGroupLoading, setBsGroupLoading] = useState<boolean>(false)
    const [bsTotal, setBsTotal] = useState<number>(0)

    useEffect(() => {
        const superUser = localStorage.getItem("@super")

        if (superUser === "true") {
            var body: HTMLElement = document.body
            dispatch(setSecondaryBarTrue())
            if (!collapsed) {
                dispatch(toggle(true))
                body.classList.toggle("vertical-collpsed")
                body.classList.toggle("sidebar-enable")
            }
        } else {
            const data = Async.getItem("@auth")
            setAccess(data?.role?.permissions["dashboard-blood-glucose-view"])
            dispatch(setSecondaryBarTrue())
            setTimeout(() => {
                dispatch(setPreloader(false))
            }, 1000)
        }
    }, [])

    useEffect(() => {
        setAgeLoading(true)
        setGenderLoading(true)
        const organizationLocalData = Async.getItem("@organization")
        let conditionArr: string[] = []
        let dateStart: string = ""
        let dateEnd: string = ""
        let ageTo: string | null = null
        let ageFrom: string | null = null
        let gender: string | null = null

        filterList?.forEach((itemList: any) => {
            if (itemList.set) {
                if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
                    conditionArr.push(itemList.contain)
                } else if (itemList.value === 9) {
                    dateStart = itemList.contain
                } else if (itemList.value === 10) {
                    dateEnd = itemList.contain
                } else if (itemList.value === 11) {
                    ageFrom = itemList.contain
                } else if (itemList.value === 12) {
                    ageTo = itemList.contain
                } else if (itemList.value === 13) {
                    gender = itemList.contain.value
                }
            }
        })
        const strt = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 6,
            new Date().getDate()
        )

        dispatch(aggregateDashboard({
            select: {
                vitalsMeasurements: {
                    filter: {
                        organizationId: organizationLocalData.id,
                        type: "BLOOD_GLUCOSE",
                        recordedBetween: {
                            start: dateStart ? new Date(dateStart + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date(strt).toISOString(),
                            end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date().toISOString()
                        },
                        patient: {
                            gender: gender ? gender : undefined,
                            age: {
                                start: ageFrom ? ageFrom : undefined,
                                end: ageTo ? ageTo : undefined,
                            },
                            conditions: conditionArr,
                        }
                    },
                    include: {
                        patient: true
                    }
                }
            },
            mappers: [
                {
                    on: "vitalsMeasurements",
                    function: "dedupe",
                    args: ["patientId"]
                }
            ],
            aggregate: [
                {
                    on: "vitalsMeasurements",
                    type: "count",
                    path: "",
                    groupBy: "patient.userProfile.gender"
                },
                {
                    on: "vitalsMeasurements",
                    type: "count",
                    path: "",
                    groupBy: "patient.userProfile.birthDate",
                    groupByTransform: [
                        {
                            function: "ageRange",
                            args: [
                                "5"
                            ]
                        }
                    ]
                }
            ]


        })).then((response: any) => {
            if (response?.payload?.aggregationResults?.length > 0) {
                //gender graph
                const tempGender = response.payload.aggregationResults[0]
                if (tempGender.MALE === undefined) {
                    tempGender.MALE = 0;
                }
                if (tempGender.FEMALE === undefined) {
                    tempGender.FEMALE = 0;
                }
                if (tempGender.OTHER === undefined) {
                    tempGender.OTHER = 0;
                }
                setGenderData(tempGender)
                const data = {
                    FEMALE: ((tempGender.FEMALE / (tempGender.MALE + tempGender.FEMALE + tempGender.OTHER) * 100)
                        ? Math.floor(tempGender.FEMALE / (tempGender.MALE + tempGender.FEMALE + tempGender.OTHER) * 100)
                        : tempGender.FEMALE ? 100 : 0),

                    OTHER: ((tempGender.OTHER / (tempGender.MALE + tempGender.FEMALE + tempGender.OTHER) * 100)
                        ? Math.floor(tempGender.OTHER / (tempGender.MALE + tempGender.FEMALE + tempGender.OTHER) * 100)
                        : tempGender.OTHER ? 100 : 0),

                    MALE: ((tempGender.MALE / (tempGender.MALE + tempGender.FEMALE + tempGender.OTHER) * 100)
                        ? Math.floor(tempGender.MALE / (tempGender.MALE + tempGender.FEMALE + tempGender.OTHER) * 100)
                        : tempGender.MALE ? 100 : 0)
                }

                setGenderGraphData(data)

                setGenderLoading(false)
                //age distribution
                const body: AgeBody = {
                    categories: Object.keys(response.payload.aggregationResults[1]),
                    data: Object.values(response.payload.aggregationResults[1]),
                }
                setBarGraph(body)
                const sum = Object.values(response.payload.aggregationResults[1]).reduce((partialSum: number, a: any) => partialSum + a, 0);
                setAgeSum(sum)
                setAgeLoading(false)
            } else if (response.error) {
                setGenderLoading(false)
                setAgeLoading(false)
            }
        })
        //
        setBsGroupLoading(true)
        dispatch(aggregateDashboard({
            select: {
                vitalsMeasurements: {
                    filter: {
                        organizationId: organizationLocalData.id,
                        type: "BLOOD_GLUCOSE",
                        recordedBetween: {
                            start: dateStart ? new Date(dateStart + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date(strt).toISOString(),
                            end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date().toISOString()
                        },
                        patient: {
                            gender: gender ? gender : undefined,
                            age: {
                                start: ageFrom ? ageFrom : undefined,
                                end: ageTo ? ageTo : undefined,
                            },
                            conditions: conditionArr,
                        }
                    },
                },
            },
            aggregate: [
                {
                    on: "vitalsMeasurements",
                    type: "count",
                    path: "",
                    groupBy: "condition"
                }
            ]

        })).then((response: any) => {
            if (response?.payload?.aggregationResults?.length > 0) {
                setTableData(response?.payload?.aggregationResults[0])
                setBsGroupLoading(false)
            } else if (response.error) {
                setBsGroupLoading(false)
            }
        })
    }, [filterList])

    useEffect(() => {
        const organizationLocalData = Async.getItem("@organization")
        let conditionArr: string[] = []
        let dateStart: string = ""
        let dateEnd: string = ""
        let ageTo: string | null = null
        let ageFrom: string | null = null
        let gender: string | null = null

        filterList?.forEach((itemList: any) => {
            if (itemList.set) {
                if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
                    conditionArr.push(itemList.contain)
                } else if (itemList.value === 9) {
                    dateStart = itemList.contain
                } else if (itemList.value === 10) {
                    dateEnd = itemList.contain
                } else if (itemList.value === 11) {
                    ageFrom = itemList.contain
                } else if (itemList.value === 12) {
                    ageTo = itemList.contain
                } else if (itemList.value === 13) {
                    gender = itemList.contain.value
                }
            }
        })
        const strt = new Date(
            new Date().getFullYear(),
            new Date().getMonth() - 6,
            new Date().getDate()
        )
        ///
        dispatch(aggregateDashboard({
            select: {
                vitalsMeasurements: {
                    filter: {
                        organizationId: organizationLocalData.id,
                        type: "BLOOD_GLUCOSE",
                        recordedBetween: {
                            start: dateStart ? new Date(dateStart + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date(strt).toISOString(),
                            end: dateEnd ? new Date(dateEnd + 'T' + moment(new Date().toUTCString()).format('HH:mm')).toISOString() : new Date().toISOString()
                        },
                        patient: {
                            gender: gender ? gender : undefined,
                            age: {
                                start: ageFrom ? ageFrom : undefined,
                                end: ageTo ? ageTo : undefined,
                            },
                            conditions: conditionArr,
                        }
                    },
                    include: {
                        patient: true
                    },
                }
            },
            aggregate:
                dropValueBs === 0 ?
                    [
                        {
                            on: "vitalsMeasurements",
                            type: "count",
                            path: "",
                            groupBy: "recordedAt",
                            groupByTransform: [
                                {
                                    "function": "week",
                                    "args": []
                                },
                            ]
                        },
                        {
                            on: "vitalsMeasurements",
                            type: "count",
                            path: "",
                        },
                    ]
                    :
                    [
                        {
                            on: "vitalsMeasurements",
                            type: "count",
                            path: "",
                            groupBy: "recordedAt",
                            groupByTransform: [
                                {
                                    "function": "dateTrunc",
                                    "args": ["month"]
                                },
                            ]
                        },
                        {
                            on: "vitalsMeasurements",
                            type: "count",
                            path: "",
                        },
                    ]
        })).then((response: any) => {
            if (response) {
                if (response?.payload?.aggregationResults?.length > 0) {
                    setBsTotal(response?.payload?.aggregationResults[1])

                    let dateList1 = [];
                    let data1: number[] = [];
                    dateList1 = Object.keys(response.payload.aggregationResults[0]).sort().reverse();

                    Object.keys(response.payload.aggregationResults[0]).sort().reverse().forEach(val => {
                        data1.push(response.payload.aggregationResults[0][`${val}`])
                    });

                    let list1: Series[] = [];
                    const body1: Series =
                    {
                        name: "Patients",
                        data: data1,
                        dates: dateList1,
                        complete: [],
                        color: '#2A45CD',
                        marker: {
                            fillColor: '#2A45CD',
                        }
                    }

                    list1.push(body1)
                    setBsGraph(list1)
                }
            }
        })

    }, [filterList, dropValueBs])

    //Bs kpi
    useEffect(() => {
        const organizationLocalData = Async.getItem("@organization")
        let conditionArr: string[] = []
        let ageTo: string | null = null
        let ageFrom: string | null = null
        let gender: string | null = null

        filterList?.forEach((itemList: any) => {
            if (itemList.set) {
                if ((itemList.value >= 1 && itemList.value <= 8) || itemList.value === 14 || itemList.value === 15) {
                    conditionArr.push(itemList.contain)
                } else if (itemList.value === 11) {
                    ageFrom = itemList.contain
                } else if (itemList.value === 12) {
                    ageTo = itemList.contain
                } else if (itemList.value === 13) {
                    gender = itemList.contain.value
                }
            }
        })
        setBsLoading(true)
        let startDate;
        let endDate;
        if (dropValueBs === 0) {
            startDate = moment().subtract(1, 'week').startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDate = moment().subtract(1, 'week').endOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T23:59:59.000Z';
        } else if (dropValueBs === 1) {
            startDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
            endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD') + 'T23:59:59.000Z';
        }
        let lastweek = 0;
        let currentweek = 0;
        dispatch(aggregateDashboard({
            select: {

                vitalsMeasurements: {
                    filter: {
                        organizationId: organizationLocalData.id,
                        type: "BLOOD_GLUCOSE",
                        recordedBetween: {
                            start: startDate,
                            end: endDate
                        },
                        patient: {
                            gender: gender ? gender : undefined,
                            age: {
                                start: ageFrom ? ageFrom : undefined,
                                end: ageTo ? ageTo : undefined,
                            },
                            conditions: conditionArr,
                        }
                    },
                    include: {
                        patient: true
                    }
                }
            },
            aggregate: [
                {
                    on: "vitalsMeasurements",
                    type: "count",
                    path: "",
                },
            ]
        })).then((response: any) => {
            if (response?.payload?.aggregationResults?.length > 0) {
                lastweek = response?.payload?.aggregationResults[0]
                setSubBs(lastweek)
                let startDateCurrent;
                let endDateCurrent;
                if (dropValueBs === 0) {
                    startDateCurrent = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD') + 'T00:00:00.000Z';
                    endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
                } else if (dropValueBs === 1) {
                    startDateCurrent = moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00.000Z';
                    endDateCurrent = moment().add(1, 'days').format("YYYY MM DD");
                }
                let ed2 = moment(endDateCurrent).toISOString()

                dispatch(aggregateDashboard({
                    select: {
                        vitalsMeasurements: {
                            filter: {
                                organizationId: organizationLocalData.id,
                                type: "BLOOD_GLUCOSE",
                                recordedBetween: {
                                    start: startDateCurrent,
                                    end: ed2
                                },
                                patient: {
                                    gender: gender ? gender : undefined,
                                    age: {
                                        start: ageFrom ? ageFrom : undefined,
                                        end: ageTo ? ageTo : undefined,
                                    },
                                    conditions: conditionArr,
                                }
                            },
                            include: {
                                patient: true
                            }
                        }
                    },
                    aggregate: [
                        {
                            on: "vitalsMeasurements",
                            type: "count",
                            path: "",
                        },
                    ]
                })).then((response: any) => {
                    if (response?.payload?.aggregationResults?.length > 0) {
                        currentweek = response?.payload?.aggregationResults[0]
                        setBs(response?.payload?.aggregationResults[0])

                        if (lastweek > currentweek) {
                            const a = ((currentweek / lastweek) * 100) - 100;
                            setBsKpi(Math.round(a))
                            setBsLoading(false)
                        } else if (currentweek > lastweek) {
                            const a = 100 - ((lastweek / currentweek) * 100);
                            setBsKpi(Math.round(a))
                            setBsLoading(false)

                        }
                        else if (currentweek === lastweek) {
                            setBsKpi(0)
                            setBsLoading(false)

                        }
                    } else if (response.error) {
                        setBsLoading(false)
                    }
                })
            } else if (response.error) {
                setBsLoading(false)
            }
        })
    }, [dropValueBs, filterList])

    return (
        <React.Fragment>
            {access ?
                <>
                    <GraphFilter
                        isOpen={filterModel}
                        toggle={toggleFilterModal}
                        setFilterList={setFilterList}
                        tempFilterList={tempFilterList}
                        setTempFilterList={setTempFilterList}
                    />
                    <div className="page-content">
                        <Container fluid>
                            <Row>
                                <Col lg="3">
                                    <h2 className="m-0 align-self-center text-nowrap">
                                        <b className="text-nowrap sbl24">Blood sugar dashboard</b>
                                    </h2>
                                </Col>
                                <Col lg="7" className="d-flex flex-row-reverse flex-wrap gap-1">
                                    {filterList?.map((item: OptionType, index: number) => (
                                        <div
                                            key={index}
                                            className="btn ilaraLigthBlue d-flex align-items-center text-nowrap py-auto px-2 btnSelectedFilter"
                                            style={{ height: "38px" }}
                                        >
                                            <p className="mb-0">{item.label}</p>
                                            <i
                                                onClick={() => {
                                                    filterList.forEach((itemf: any, i: number) => {
                                                        if (itemf.value === item.value) {
                                                            let temp = [...filterList]
                                                            temp[i].set = false
                                                            temp.splice(i, 1)
                                                            setFilterList(temp)
                                                        }
                                                    })
                                                }}
                                                className="bx bx-x font-size-20 ms-2 closeFilter"
                                            ></i>
                                        </div>
                                    ))}
                                </Col>
                                <Col lg="2" className="text-end text-nowrap">
                                    <button
                                        type="button"
                                        className="btn btn-light"
                                        style={{
                                            backgroundColor: "white",
                                            color: "#2C3242",
                                            border: "1px solid #E3E3E3",
                                            width: "auto",
                                        }}
                                        onClick={() => {
                                            setTempFilterList(filterList)
                                            toggleFilterModal()
                                        }}
                                    >
                                        <i className="bx bx-filter font-size-22 align-middle me-1"></i>
                                        <span className="sbold someTextClr">Filters</span>
                                    </button>
                                </Col>
                            </Row>
                            <Row className="mt-2" cals>
                                <Col lg={5} md={6} sm={12}>
                                    {/* Graph 1 */}
                                    <Card className="shadow-sm graphCard">
                                        <CardBody>
                                            <Row >
                                                <Col xs={6}>
                                                    <p className="heading mb-2">
                                                        Gender Distribution
                                                    </p>
                                                </Col>
                                            </Row>
                                            {
                                                genderLoading ?
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            minHeight: "180px",
                                                            display: "flex",
                                                            flex: "1",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <Spinner
                                                            style={{
                                                                color: "#2a45cd",
                                                                alignSelf: "center",
                                                                width: "50px",
                                                                height: "50px",
                                                            }}
                                                            animation="border"
                                                            variant="primary"
                                                        />
                                                    </div>
                                                    :
                                                    genderData.MALE === 0 && genderData.OTHER === 0 && genderData.FEMALE === 0 ?
                                                        <p className="text-center"
                                                            style={{
                                                                width: "100%",
                                                                minHeight: !open ? "10px" : "190px",
                                                                display: "flex",
                                                                alignItems: 'center',
                                                                flex: "1",
                                                                justifyContent: "center",
                                                            }}>
                                                            No Data
                                                        </p>

                                                        :
                                                        Object.keys(genderData).length > 0 && Object.keys(genderGraphData).length > 0 ?
                                                            <div>
                                                                <div className="d-flex flex-row justify-content-between">
                                                                    <h5 className="textMain isb">
                                                                        <span className="textSpan isb">Male</span>
                                                                        {genderData.MALE ? genderData.MALE : 0}
                                                                    </h5>
                                                                    <h5 className="textMain isb">
                                                                        <span className="textSpan">Other</span>
                                                                        {genderData.OTHER ? genderData.OTHER : 0}
                                                                    </h5>
                                                                    <h5 className="textMain isb">
                                                                        <span className="textSpan">Female</span>
                                                                        {genderData.FEMALE ? genderData.FEMALE : 0}
                                                                    </h5>
                                                                </div>

                                                                <div className="d-flex flex-row mt-2 mb-3">
                                                                    <div className="progress-bar-1"
                                                                        style={
                                                                            {
                                                                                width: genderGraphData.MALE ? `${genderGraphData.MALE}%` : '0%',
                                                                                borderTopLeftRadius: genderGraphData.MALE > 0 ? '8px' : '0px',
                                                                                borderBottomLeftRadius: genderGraphData.MALE > 0 ? '8px' : '0px',
                                                                                borderTopRightRadius: genderGraphData.MALE === 100 ? '8px' : '0px',
                                                                                borderBottomRightRadius: genderGraphData.MALE === 100 ? '8px' : '0px',

                                                                            }}>
                                                                    </div>
                                                                    <div className="progress-bar-2"
                                                                        style={
                                                                            {
                                                                                width: genderGraphData.OTHER ? `${genderGraphData.OTHER}%` : '0%',
                                                                                borderTopLeftRadius: genderGraphData.MALE === 0 ? '8px' : '0px',
                                                                                borderBottomLeftRadius: genderGraphData.MALE === 0 ? '8px' : '0px',
                                                                                borderTopRightRadius: genderGraphData.FEMALE === 0 ? '8px' : '0px',
                                                                                borderBottomRightRadius: genderGraphData.FEMALE === 0 ? '8px' : '0px',
                                                                            }}>
                                                                    </div>
                                                                    <div className="progress-bar-3"
                                                                        style={
                                                                            {
                                                                                width: genderGraphData.FEMALE ? `${genderGraphData.FEMALE}%` : '0%',
                                                                                borderTopLeftRadius: genderGraphData.FEMALE === 100 ? '8px' : '0px',
                                                                                borderBottomLeftRadius: genderGraphData.FEMALE === 100 ? '8px' : '0px',
                                                                                borderTopRightRadius: genderGraphData.FEMALE > 0 ? '8px' : '0px',
                                                                                borderBottomRightRadius: genderGraphData.FEMALE > 0 ? '8px' : '0px',
                                                                            }}>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-row justify-content-between">
                                                                    <h5 className="textSecondary isb">{genderGraphData.MALE ? genderGraphData.MALE : 0
                                                                    }%</h5>
                                                                    <h5 className="textSecondary isb">{genderGraphData.OTHER ? genderGraphData.OTHER : 0
                                                                    }%</h5>
                                                                    <h5 className="textSecondary isb">{genderGraphData.FEMALE ? genderGraphData.FEMALE : 0
                                                                    }%</h5>
                                                                </div>
                                                            </div>
                                                            : null
                                            }
                                        </CardBody>
                                    </Card>
                                    <BPGraphs
                                        title="Blood Sugar recorded"
                                        type="line"
                                        leftText={bs}
                                        leftSub={`from ${subBs}`}
                                        rightText={bsKpi}
                                        setDropValue={setDropValueBs}
                                        dropValue={dropValueBs}
                                        graphData={bsGraph}
                                        loading={bsLoading}
                                        total={bsTotal}
                                    />
                                </Col>
                                <Col lg={5} md={6} sm={12}>
                                    <BPGraphs
                                        title='Age Distribution'
                                        graphData={barGraph}
                                        type='bar'
                                        leftText={ageSum}
                                        loading={ageLoading}
                                    />

                                    <BPGraphs
                                        title='Distribution of blood sugar groups'
                                        type='table'
                                        graphData={tableData}
                                        loading={bsGroupLoading}
                                        typeDashboard={'BS'}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
                : <div className="permissionWarning">User does not have permissions to view this screen!</div>}

        </React.Fragment>
    )
}

export default BloodSugar;
