import React, { FC } from "react"
import { Modal } from "reactstrap"

interface ResetPasswordProps {
    isOpen?: boolean
    toggle: (id?: string) => void
    onConfirm: () => void
    loading?: boolean
}

const ArchiveConfirmationModal: FC<ResetPasswordProps> = ({
    isOpen,
    toggle,
    onConfirm,
    loading
}) => {
    return (
        <Modal
            isOpen={isOpen}
            className="modal-sm"
            role="dialog"
            autoFocus={true}
            centered={true}
        // toggle={toggle}
        >
            <div className='p-2'>
                <h3 className="heading-1 ms-2 mt-3 ">
                    Confirm
                </h3>
                <p className="heading-1 font-size-15 ms-2 mt-1 ">
                    Are you sure you want to archive?
                </p>
                <div className="modal-footer ">
                    <div className="ms-auto">
                        <button
                            type="button"
                            className={loading ? "discard-btn pointer-none" : "discard-btn"}
                            onClick={() => {
                                toggle()
                            }}
                        >
                            <h6 className="my-auto px-2">Dismiss</h6>
                        </button>
                        <button className={loading ? "btn btn-primary ms-3 pointer-none" : "btn btn-primary ms-3"} onClick={onConfirm}>
                            Confirm
                            {loading && (
                                <i className="bx bx-loader bx-spin font-size-14 ms-2" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default ArchiveConfirmationModal
