import React, { useEffect, useState } from "react"
import { FC } from "react"
import { Input, Modal } from "reactstrap"
import Select from "react-select"
import "../filter.scss"
import Async from "~/utils/Async"
import { useAppSelector } from "~/store/hooks"
import moment from "moment"
import { OrganizationRoles } from "~/store/reducers/Roles/thunk"
import DatePicker from 'reactstrap-date-picker'
interface FilterProps {
  isOpen: boolean
  toggle: () => void
  dispatch: any
  setFilterList: any
  roleArray: any
  setRoleArray: any
  tempFilterList: any
  setTempFilterList: any
}
interface filterHeadProps {
  title: string
  id: number
}
type OptionType = {
  value: string | number
  label: string
  set?: boolean
  contain?: any
}
type CheckboxType = {
  name: string
  value: boolean
}

const AgentFilter: FC<FilterProps> = ({
  isOpen,
  toggle,
  dispatch,
  setFilterList,
  tempFilterList,
  setTempFilterList,
  roleArray,
  setRoleArray,
}) => {
  const {
    success: successRole,
    loading: loadingRole,
    error: errorRole,
    roles: rolesOrganization,
  } = useAppSelector(state => state.RolesReducer)

  const [firstFilterBox, setFirstFilterBox] = useState<boolean>(true)
  const [secondFilterBox, setSecondFilterBox] = useState<boolean>(true)
  const [thirdFilterBox, setThirdFilterBox] = useState<boolean>(true)
  const [selectedOptionDays, setSelectedOptionDays] =
    useState<OptionType | null>(null)
  const [selectedOptionGender, setSelectedOptionGender] =
    useState<OptionType | null>(null)
  const [dateStart, setDateStart] = useState<string>("")
  const [dateEnd, setDateEnd] = useState<string>("")
  const [ageFrom, setAgeFrom] = useState<number | null>(null)
  const [ageTo, setAgeTo] = useState<number | null>(null)
  const [role, setRole] = useState<string>()

  const resetStates = () => {
    setDateStart("")
    setDateEnd("")
    setAgeFrom(null)
    setAgeTo(null)
    setRole(undefined)
    setSelectedOptionGender(null)
    setSelectedOptionDays(null)
    let temp = JSON.parse(JSON.stringify(roleArray))
    roleArray.forEach((all: any, ind: number) => {
      temp[ind].value = false
    })
    setRoleArray(temp)
  }
  const days: OptionType[] = [
    { value: 7, label: "Last 7 days" },
    { value: 30, label: "Last 30 days" },
    { value: 90, label: "Last 3 months" },
  ]
  const gender: OptionType[] = [
    { value: "MALE", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "OTHER", label: "Other" },
  ]

  useEffect(() => {
    let tempArr: OptionType[] = []
    role &&
      tempArr.push({
        label: `Role: '${role}'`,
        value: 0,
        set: true,
        contain: role,
      })
    dateStart &&
      tempArr.push({
        label: `Dated From:  ${dateStart}`,
        value: 2,
        set: true,
        contain: dateStart,
      })
    dateEnd &&
      tempArr.push({
        label: `Dated To: ${dateEnd}`,
        value: 1,
        set: true,
        contain: dateEnd,
      })
    ageFrom &&
      tempArr.push({
        label: `Age From: ${ageFrom.toString()}`,
        value: 4,
        set: true,
        contain: ageFrom,
      })
    ageTo &&
      tempArr.push({
        label: `Age To: ${ageTo.toString()}`,
        value: 3,
        set: true,
        contain: ageTo,
      })
    selectedOptionGender &&
      tempArr.push({
        label: `Gender : ${selectedOptionGender.label}`,
        value: 5,
        set: true,
        contain: selectedOptionGender,
      })

    try {
      setTempFilterList(tempArr)
    } catch (e) {
      // console.log(e);
    }
  }, [
    dateStart,
    dateEnd,
    ageFrom,
    ageTo,
    selectedOptionGender,
    role,
    selectedOptionDays,
  ])

  useEffect(() => {
    if (Object.keys(rolesOrganization).length === 0) {
      const organizationLocalData = Async.getItem("@organization")
      try {
        dispatch(OrganizationRoles({ organizationId: organizationLocalData.id }))
      } catch (e) {
        // console.log(e);
      }
    } else {
      //set roles list
      let tempRole: CheckboxType[] = []
      rolesOrganization?.items.forEach((item: any, i: number) => {
        tempRole.push({
          name: item.name,
          value: false,
        })
      })
      try {
        setRoleArray(tempRole)
      } catch (e) {
        // console.log(e);
      }
    }
  }, [rolesOrganization])

  useEffect(() => {
    if (selectedOptionDays) {
      const filterDate = new Date()
      const strt = new Date(
        filterDate.getFullYear(),
        filterDate.getMonth(),
        filterDate.getDate() - Number(selectedOptionDays.value)
      )

      setDateEnd(`${moment(filterDate).format("yyyy-MM-DD")}`)
      setDateStart(`${moment(strt).format("yyyy-MM-DD")}`)
    }
  }, [selectedOptionDays])

  useEffect(() => {
    tempFilterList?.forEach((item: any, i: number) => {
      if (item.set === false) {
        switch (item.value) {
          case 0: {
            setRole(undefined)
            let temp = [...roleArray]
            roleArray.forEach((all: any, ind: number) => {
              temp[ind].value = false
            })
            setRoleArray(temp)
            break
          }
          case 1:
            setDateEnd("")
            break
          case 2:
            setDateStart("")
            break
          case 3:
            setAgeTo(null)
            break
          case 4:
            setAgeFrom(null)
            break
          case 5:
            setSelectedOptionGender(null)
            break
        }
      } else if (item.set === true) {
        switch (item.value) {
          case 0: {
            setRole(item.contain)
            let temp = [...roleArray]
            roleArray.forEach((all: any, ind: number) => {
              if (all.name === item.contain) {
                temp[ind].value = true
              }
            })
            setRoleArray(temp)
            break
          }
          case 1:
            setDateEnd(item.contain)
            break
          case 2:
            setDateStart(item.contain)
            break
          case 3:
            setAgeTo(item.contain)
            break
          case 4:
            setAgeFrom(item.contain)
            break
          case 5:
            setSelectedOptionGender(item.contain)
            break
        }
      }
    })
  }, [tempFilterList])

  const removeItem = (itemVal: number) => {
    tempFilterList.forEach((item: any, i: number) => {
      if (item.value === itemVal) {
        let temp = [...tempFilterList]
        temp[i].set = false
        setTempFilterList(temp)
      }
    })
  }

  const HeadFilterType = (props: filterHeadProps) => {
    return (
      <div
        className="d-flex flex-row ps-4 py-2 headerFilter"
        onClick={() => {
          props.id == 1
            ? setFirstFilterBox(!firstFilterBox)
            : props.id == 2
              ? setSecondFilterBox(!secondFilterBox)
              : props.id == 3
                ? setThirdFilterBox(!thirdFilterBox)
                : null
        }}
      >
        <h6
          className={`font-size-14 im m-0 ${(props.id == 1 && firstFilterBox) ||
            (props.id == 2 && secondFilterBox) ||
            (props.id == 3 && thirdFilterBox)
            ? "text-primary"
            : ""
            }`}
        >
          {props.title}
        </h6>
        <i
          className={`bx bx-chevron-down font-size-20 ms-2 ${(props.id == 1 && firstFilterBox) ||
            (props.id == 2 && secondFilterBox) ||
            (props.id == 3 && thirdFilterBox)
            ? "arrowIcon text-primary"
            : ""
            }`}
        ></i>
      </div>
    )
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      toggle={toggle}
      keyboard={false}
      backdrop='static'
    >
      <div className="modal-body ">
        <h4
          className="modal-title mt-0 ms-2 sb font-size-18"
          id="myLargeModalLabel"
        >
          Filter Agents
        </h4>
        <button
          onClick={() => {
            toggle()
            resetStates()
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body px-0 py-0">
        <HeadFilterType title="Date" id={1} />
        {firstFilterBox ? (
          <div className="filtersBox">
            <div className="mb-3">
              <label className="col-md-2 col-form-label im">Duration</label>
              <Select
                className="selectFiler"
                defaultValue={selectedOptionDays}
                onChange={val => setSelectedOptionDays(val)}
                options={days}
                placeholder="Select Duration"
              />
            </div>
            <div className="align-self-center im font-size-17 text-muted">
              <p> </p>
              or
            </div>
            <div className="d-flex flex-grow-1">
              <div className="mb-3 w-50 pe-3">
                <label className="col-md-2 col-form-label filterLabel im ">
                  Start date
                </label>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  showClearButton={false}
                  className="rounded form-control"
                  value={dateStart}
                  onChange={(val: string) => { setDateStart(moment(val).format("yyyy-MM-DD")) }}
                />
              </div>
              <div className="mb-3 w-50 pe-3">
                <label className="col-md-2 col-form-label filterLabel im">
                  End date
                </label>
                <DatePicker
                  dateFormat="DD/MM/YYYY"
                  placeholder="DD/MM/YYYY"
                  showClearButton={false}
                  className="rounded form-control"
                  value={dateEnd}
                  onChange={(val: string) => setDateEnd(moment(val).format("yyyy-MM-DD"))}
                />
              </div>
            </div>
          </div>
        ) : null}
        <HeadFilterType title="Biological data" id={2} />
        {secondFilterBox ? (
          <div className="filtersBox">
            <div className="mb-3">
              <label className="col-md-2 col-form-label im">Gender</label>
              <Select
                className="selectFiler"
                defaultValue={selectedOptionGender}
                onChange={val => setSelectedOptionGender(val)}
                options={gender}
                placeholder="Select Gender"
              />
            </div>
            <div className="mb-3">
              <label className="col-md-2 col-form-label filterLabel im">
                Age from
              </label>
              <Input
                type="number"
                className="form-control numberFilter"
                placeholder="0"
                value={ageFrom ? ageFrom : ""}
                onChange={val => setAgeFrom(Number(val.target.value))}
              />
            </div>
            <div className="mb-3">
              <label className="col-md-2 col-form-label filterLabel im">
                Age to
              </label>
              <Input
                type="number"
                className="form-control numberFilter"
                placeholder="100"
                value={ageTo ? ageTo : ""}
                onChange={val => setAgeTo(Number(val.target.value))}
              />
            </div>
          </div>
        ) : null}
        <HeadFilterType title="Role" id={3} />
        {thirdFilterBox ? (
          <div className="filtersBox">
            {roleArray?.map((role: any, index: number) => (
              <div key={index} className="form-check form-check-end">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={role.value}
                  onChange={val => {
                    val.target.checked ? setRole(role.name) : setRole(undefined)
                    let temp = [...roleArray] //JSON.parse(JSON.stringify(roleArray))
                    //1
                    // temp[index].value = val.target.checked;
                    //2
                    roleArray.forEach((others: any, ii: number) => {
                      if (index === ii) {
                        temp[ii].value = val.target.checked
                      } else {
                        temp[ii].value = false
                      }
                    })
                    setRoleArray(temp)
                  }}
                />
                <h6 className="form-check-label font-size-14 im ms-1">
                  {role.name}
                </h6>
              </div>
            ))}
          </div>
        ) : null}
        <div className="d-flex flex-wrap gap-2 px-4 py-3">
          {tempFilterList?.map((item: any, index: number) => (
            <div key={index}>
              <div className="btn ilaraLigthBlue d-flex align-items-center text-nowrap py-0 px-2 btnSelectedFilter"
                style={{ height: '29px' }}>
                <p className="m-0 sb">{item.label}</p>
                <i
                  onClick={() => removeItem(Number(item.value))}
                  className="bx bx-x font-size-20 ms-3 closeFilter"
                ></i>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="modal-footer px-3">
        <button
          type="button"
          className="discard-btn"
          onClick={() => {
            resetStates()
            toggle()
          }}
        >
          <h6 className="my-auto px-2 im">Dismiss</h6>
        </button>
        <div className="ms-auto">
          {tempFilterList?.length > 0 &&
            <button
              type="button"
              className="discard-btn"
              onClick={() => {
                resetStates()
              }}
            >
              <h6 className="my-auto px-2 im">Clear all filters ({tempFilterList?.length})</h6>
            </button>
          }
          <button
            onClick={() => {
              setFilterList(tempFilterList)
              resetStates()
              toggle()
            }}
            className="btn btn-primary ms-3 im"
          >
            Apply filters
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default AgentFilter
